import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  TimePicker,
  Switch,
  AutoComplete,
} from "antd";
import React from "react";
import get from "lodash/get";
import FloatLabel from "../FloatLabel";
import { RichTextEditor } from "../RichTextEditor";
import Checkbox from "antd/lib/checkbox/Checkbox";

const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
  (AntComponent) =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    submitCount,
    type,
    value,
    heading,
    optionNameKey,
    optionValueKey,
    editOption,
    suffixIcon,
    disableOptionsOn,
    fieldIndex,
    deleteInput,
    fieldValues,
    preventNextLineOnEnter,
    uniqueKey,
    characterLimit,
    ...props
  }) => {
    const touched = get(form?.touched, field?.name);
    const submitted = submitCount > 0;
    const hasError = get(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;

    const onInputChange = (e) => {
      let str = e.target.value;
      if (str.includes("\n") && preventNextLineOnEnter) {
        e.preventDefault();
      } else {
        form.setFieldValue(field.name, e.target.value);
      }
    };

    const onChange = (value) => {
      form.setFieldValue(field.name, value);
    };
    const onChecked = (value) => {
      const { target } = value;
      form.setFieldValue(field.name, !target.value);
    };

    const onDateInputChange = (target) => {
      form.setFieldValue(field.name, target);
    };

    const onToggle = (checked) => {
      form.setFieldValue(field.name, checked);
    };

    const onTimeInputChange = (target) => {
      form.setFieldValue(field.name, target);
    };

    const onChangeEditor = (value) => {
      let charCount = value?.length || 0;
      form.setFieldValue(field.name, value);
      return charCount;
    };

    const onBlur = () => form.setFieldTouched(field.name, true);

    let selectProps = {};
    if (selectOptions) {
      selectProps = {
        dropdownClassName: props.mode,
        // showRadioButton: props.showRadioButton,
        getPopupContainer: (trigger) => trigger.parentNode,
      };
    }

    return (
      <div className="field-container">
        <FloatLabel label={label} val={value}>
          <FormItem
            hasFeedback={
              (hasFeedback && submitted) || (hasFeedback && touched)
                ? true
                : false
            }
            help={submittedError || touchedError ? hasError : false}
            validateStatus={
              submittedError || touchedError ? "error" : "success"
            }
          >
            <AntComponent
              {...field}
              onBlur={onBlur}
              spellCheck="off"
              autoComplete="off"
              characterLimit={characterLimit || 5000}
              editorValue={field.value}
              // preventNextLineOnEnter
              // suffixIcon={type ? null : selectIcon}
              {...(type === "editor"
                ? {
                    onChangeEditor,
                  }
                : {
                    onChange:
                      type === "checkbox"
                        ? onChecked
                        : type === "datePicker"
                        ? onDateInputChange
                        : type === "timePicker"
                        ? onTimeInputChange
                        : type === "switch"
                        ? onToggle
                        : type
                        ? (e) => onInputChange(e)
                        : onChange,
                  })}
              type={type}
              filterOption={(input, option) =>
                option?.["children"]
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option?.children?.[1]
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              {...selectProps}
              {...props}
            >
              {selectOptions &&
                selectOptions.map((option) => (
                  <Option
                    disabled={
                      !!disableOptionsOn ? disableOptionsOn(option) : false
                    }
                    key={option?.value || option[optionValueKey]}
                    data={selectOptions}
                  >
                    {`${option?.name || option[optionNameKey]} ${
                      editOption ? editOption : ""
                    }`}
                  </Option>
                ))}
            </AntComponent>
          </FormItem>
        </FloatLabel>
      </div>
    );
  };

export const AntSelect = CreateAntField(Select);
export const AntToggle = CreateAntField(Switch);
export const AntCheckBox = CreateAntField(Checkbox);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntPasswordInput = CreateAntField(Input.Password);
export const AntNumberInput = CreateAntField(InputNumber);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntTextArea = CreateAntField(TextArea);
export const RTEditor = CreateAntField(RichTextEditor); //not a ant component
export const AntAutoComplete = CreateAntField(AutoComplete);
