import { AntInput } from "components/CreateAntFields/CreateAntField";
import { FastField } from "formik";
import { RTEditor } from "components/CreateAntFields/CreateAntField";

const MarathiLanguageForm = ({values}) => {

return (
    <>
      <p className="mb-10 mt-30 field-label">Name</p>
      <FastField
        value={values?.name}
        name= {`data[mr].name`}
        type="text"
        suffix={<div>{20 - (values?.name?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter category name"}
        maxLength={20}
      />
      <p className="field-label">Product description</p>
      <FastField
        value={values.description}
        name="data[mr].description"
        type="editor"
        component={RTEditor}
        characterLimit={20000}
        placeholder={"Enter details here"}
      />
    </>
)}

export default MarathiLanguageForm