import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { FastField, Field } from "formik";
import { HINDI } from "constants/language";

const HindiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Title </p>
      <FastField
        value={values.title}
        name="data[hi].title"
        type="text"
        suffix={
          <div>{100 - (values?.[HINDI]?.title?.length || 0)} letters</div>
        }
        component={AntInput}
        placeholder={"Enter content title "}
        maxLength={100}
      />
      <p className="mb-8 field-label">Short summary</p>
      <Field
        value={values.shortSummary}
        name="data.hi.shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={1500}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
    </>
  );
};

export default HindiLanguageForm;
