import { TextCell } from "../../components/TableCells/TextCell";
import { ColumnHeaderWithSorter } from "../../components/ColumnHeaderWithSorter";
import { DateCell } from "components/TableCells/DateCell";
import { UserCell } from "components/TableCells/UserCell";
import "../../styles/scss/_variables.scss";

export const returnRolesColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Role"}
        sortKey={"displayName"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => (
      <TextCell sortedColumn={true} data={rowData.displayName} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"CreatedOn"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "Created by",
    width: "12%",
    render: (rowData) => <UserCell data={rowData.creator} />,
  },
  {
    title: "Access",
    width: "12%",
    render: (rowData) => (
      <TextCell
        isBold={true}
        isArray={true}
        color="#4c68ef"
        data={rowData.access}
      />
    ),
  },
  {
    title: "No. of users",
    width: "12%",
    render: (rowData) => <TextCell data={`${rowData.userCount} users`} />,
  },
];
