import { Button, Col, Progress, Row, Upload } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useEffect, useState } from "react";
import {
  AntInput,
  AntTextArea,
} from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import "./AddTaskForm.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  handleImageChange,
  handleImageProgress,
  uploadImage,
} from "../../utils/helpers";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { UploadButton } from "components/UploadButtons";
import { ENGLISH, HINDI } from "constants/language";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import ImageEditor from "components/ImageEditor";
import { motion } from "framer-motion";
import { AddButton, TrashIcon } from "assets/svgs";
import { AntToggle } from "components/CreateAntFields/CreateAntField";

let imageParams = {
  maxHeight: 350,
  maxWidth: 350,
  fileSize: 50,
};

const AddTaskForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  taskType,
  points,
  socialmedia,
  isEditing,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [Image, setImage] = useState(values?.image?.link);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);
  const [deepLinkImage, setDeepLinkImage] = useState(
    values?.deepLinkImage?.link
  );
  const [deepLinkImageLoading, setDeepLinkImageLoading] = useState(false);
  const [deepLinkImageProgress, setDeepLinkImageProgress] = useState(0);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError =
    submit &&
    (errors?.data?.en?.title ||
      errors.taskType ||
      errors?.mediaId ||
      errors?.reward ||
      errors?.socialMedia ||
      errors?.webUrl);
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    const totalPoints = values?.socialMedia?.reduce((acc, item) => {
      return acc + +item?.points;
    }, 0);
    values?.taskType === "newpost" && setFieldValue("totalPoints", totalPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.socialMedia]);

  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const handleChange = async (info, params) => {
    let imageValidation = await beforeUpload(info.file.originFileObj, params);
    hanldeProgress();

    if (imageValidation) {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const deleteSocialMedia = (index) => {
    let socialMedia = values?.socialMedia?.filter((item, i) => i !== index);
    if (!socialMedia.length) {
      socialMedia = [{ platform: undefined, points: "" }];
    }
    setFieldValue("socialMedia", socialMedia);
  };

  const addSocialMedia = () => {
    if (values.socialMedia?.length < 4) {
      let obj = { platform: undefined, points: "" };
      setFieldValue("socialMedia", [...values?.socialMedia, obj]);
    }
  };

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        disabled={!values.taskType}
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="field-label">Task type</p>
              <Field
                name="taskType"
                component={AntSelect}
                optionValueKey="id"
                optionNameKey="english"
                mode="single"
                value={values.taskType}
                disabled={isEditing}
                selectOptions={taskType}
                placeholder={"Select type"}
              />
              {values?.taskType === "newpost" ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, x: 400 }}
                  className="login-form"
                >
                  <p className="mb-10 mt-20 field-label">Image</p>
                  {Image ? (
                    <ImageEditor
                      image={Image}
                      width={220}
                      onDelete={() => {
                        setImage("");
                        setFieldValue("mediaId", "");
                        setUploadprogress(0);
                      }}
                      isEditing={uploadprogress}
                      handleEditing={(status) => {
                        if (status && uploadprogress) {
                          setUploadprogress(0);
                        }
                      }}
                      onSave={async (url) => {
                        let file = dataURLtoFile(
                          url,
                          `image_${Math.random()}.png`
                        );
                        let response = await uploadImage(
                          file,
                          hanldeProgress()
                        );
                        if (response) {
                          setFieldValue("mediaId", response);
                          setImage(url);
                          setLoading(false);
                        }
                      }}
                    />
                  ) : (
                    <Upload
                      name="mediaId"
                      listType={`picture-card ${
                        hasError("mediaId") ? "error" : ""
                      }`}
                      className="picture-uploader"
                      showUploadList={false}
                      beforeUpload={(file) => beforeUpload(file)}
                      onChange={handleChange}
                      accept="image/png, image/jpeg, image/jpg"
                      customRequest={dummyRequest}
                      progress={
                        <Progress percent={uploadprogress} showInfo={true} />
                      }
                    >
                      {
                        <UploadButton
                          width="350 : 350"
                          loading={loading}
                          ratio="1 : 1"
                        />
                      }
                    </Upload>
                  )}
                  {uploadprogress === 100 && (
                    <div className="success-text">
                      Image uploaded Successfully
                    </div>
                  )}
                  {uploadprogress > 0 && uploadprogress < 100 && (
                    <div className="progress-bar">
                      <Progress percent={uploadprogress} showInfo={true} />
                    </div>
                  )}
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    {hasError("mediaId")}
                  </div>
                  <p className="mt-24 field-label">Deep link cover image</p>
                  {deepLinkImage ? (
                    <ImageEditor
                      image={deepLinkImage}
                      onDelete={() => {
                        setFieldValue("deepLinkMediaId", "");
                        setDeepLinkImage("");
                        setDeepLinkImageProgress(0);
                      }}
                      isEditing={deepLinkImageProgress}
                      handleEditing={(status) => {
                        if (status && deepLinkImageProgress) {
                          setDeepLinkImageProgress(0);
                        }
                      }}
                      onSave={async (url) => {
                        let file = dataURLtoFile(
                          url,
                          `image_${Math.random()}.jpg`
                        );
                        let response = await uploadImage(
                          file,
                          handleImageProgress({
                            setUploadProgress: setDeepLinkImageProgress,
                          })
                        );
                        if (response) {
                          setFieldValue("deepLinkMediaId", response);
                          setDeepLinkImage(url);
                          setDeepLinkImageLoading(false);
                        }
                      }}
                      width={220}
                    />
                  ) : (
                    <Upload
                      name="deepLinkMediaId"
                      listType={`picture-card ${
                        hasError("deepLinkMediaId") ? "error" : ""
                      }`}
                      className="picture-uploader"
                      showUploadList={false}
                      beforeUpload={(file) => beforeUpload(file, imageParams)}
                      customRequest={dummyRequest}
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={(file) => {
                        handleImageChange({
                          info: file,
                          params: imageParams,
                          setLoading: setDeepLinkImageLoading,
                          setFieldValue: setFieldValue,
                          fieldName: "deepLinkMediaId",
                          setImage: setDeepLinkImage,
                        });
                      }}
                      progress={
                        <Progress
                          percent={deepLinkImageProgress}
                          showInfo={true}
                        />
                      }
                    >
                      {
                        <UploadButton
                          maxResolution={"350px * 350px"}
                          maxSize="50KB"
                          ratio="1:1"
                          loading={deepLinkImageLoading}
                        />
                      }
                    </Upload>
                  )}
                  {deepLinkImageProgress === 100 &&
                    !hasError("deepLinkMediaId") && (
                      <div className="success-text">
                        Image uploaded Successfully
                      </div>
                    )}
                  {deepLinkImageProgress > 0 && deepLinkImageProgress < 100 && (
                    <div className="progress-bar">
                      <Progress
                        percent={deepLinkImageProgress}
                        showInfo={true}
                      />
                    </div>
                  )}
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    {hasError("deepLinkMediaId")}
                  </div>
                  <p className="field-label">Title</p>
                  <FastField
                    value={values.title}
                    name="data[en].title"
                    type="text"
                    className="suffix-field"
                    suffix={
                      <div>
                        {70 - (values?.data?.[ENGLISH].title?.length || 0)}{" "}
                        letters
                      </div>
                    }
                    component={AntInput}
                    placeholder={"Enter task title"}
                    maxLength={70}
                  />
                  <p className="field-label">Description (optional)</p>
                  <FastField
                    value={values.description}
                    name="data[en].description"
                    type="text"
                    component={AntTextArea}
                    maxLength={160}
                    showCount={{
                      formatter: ({ maxLength, count }) => {
                        return `${maxLength - count} letters`;
                      },
                    }}
                    placeholder={"Enter short description about the task"}
                  />
                  <p className="mt-10 field-label">Social media</p>
                  {values.socialMedia?.map((item, i) => (
                    <Row
                      className="social-media"
                      justify="start"
                      align="middle"
                      key={i}
                    >
                      <Col span="11">
                        <p className="field-label">Platform</p>
                        <Field
                          name={`socialMedia[${i}].platform`}
                          component={AntSelect}
                          optionValueKey="id"
                          optionNameKey="english"
                          mode="single"
                          disabled={isEditing}
                          value={values.points}
                          selectOptions={socialmedia}
                          placeholder={"Select platform "}
                        />
                      </Col>
                      <Col span="10" offset={1}>
                        <p className="field-label">Points</p>
                        <Field
                          name={`socialMedia[${i}].points`}
                          type="text"
                          component={AntInput}
                          disabled={isEditing}
                          maxLength={4}
                          placeholder={"Enter platform points"}
                        />
                      </Col>
                      <Col
                        className="social-trash-icon"
                        disabled={isEditing}
                        offset={1}
                        onClick={() => {
                          !isEditing && deleteSocialMedia(i);
                        }}
                      >
                        <TrashIcon />
                      </Col>
                    </Row>
                  ))}
                  <Button
                    className="mb-20 add-btn"
                    type="dashed"
                    disabled={isEditing}
                    onClick={addSocialMedia}
                    style={{ background: "transparent !important" }}
                  >
                    <span>
                      <AddButton /> Add Social media
                    </span>
                  </Button>
                  <p className="field-label mt-10">Total points</p>
                  <Field
                    value={values.totalPoints}
                    name={`totalPoints`}
                    type="text"
                    component={AntInput}
                    disabled={true}
                    maxLength={4}
                    placeholder={"Enter platform points"}
                  />
                  {!isEditing && (
                    <div>
                      <p className="mt-20 field-label">Send notification</p>
                      <FastField
                        defaultChecked={values.isNotification}
                        name="isNotification"
                        type="switch"
                        component={AntToggle}
                      />
                    </div>
                  )}
                </motion.div>
              ) : values?.taskType === "existingpost" ? (
                <>
                  <p className="field-label">Title</p>
                  <FastField
                    value={values.title}
                    name="data[en].title"
                    type="text"
                    className="suffix-field"
                    suffix={
                      <div>
                        {100 - (values?.data?.[ENGLISH].title?.length || 0)}{" "}
                        letters
                      </div>
                    }
                    component={AntInput}
                    placeholder={"Enter task title"}
                    maxLength={100}
                  />
                  <p className="mb-8 mt-10 field-label">Web URL</p>
                  <FastField
                    value={values.webUrl}
                    name="webUrl"
                    type="text"
                    disabled={isEditing}
                    component={AntInput}
                    autoComplete={"off"}
                    placeholder={"Enter web url"}
                  />
                  <p className="field-label mt-10">Reward Points</p>
                  <Field
                    value={values.totalPoints}
                    name={`totalPoints`}
                    type="text"
                    component={AntInput}
                    disabled={isEditing}
                    maxLength={4}
                    placeholder={"Enter platform points"}
                  />
                  {!isEditing && (
                    <div>
                      <p className="mt-20 field-label">Send notification</p>
                      <FastField
                        defaultChecked={values.isNotification}
                        name="isNotification"
                        type="switch"
                        component={AntToggle}
                      />
                    </div>
                  )}
                </>
              ) : null}
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm
              values={values?.data?.hi}
              showDescription={values?.taskType === "newpost"}
            />
          ) : (
            <MarathiLanguageForm
              values={values?.data?.mr}
              showDescription={values?.taskType === "newpost"}
            />
          )}

          <Button
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
            className={
              language === ENGLISH && values.taskType === "newpost"
                ? "submit-btn mt-40"
                : "absolute-submit-btn submit-btn"
            }
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddTaskForm;
