import "./index.scss";

export const ImageContainer = ({ images }) => {
  let remainingImages = [...images];
  remainingImages.splice(0,1)

  return (
    <div className="image-container">
      <div className="left-image-container">
        {remainingImages?.map((e, i) => (
          <img
            className="left-images"
            key={i}
            src={remainingImages?.[i]?.link}
            alt="img"
          />
        ))}
      </div>
      <div className="primary-image-container">
        <img src={images?.[0]?.link} alt="img" />
      </div>
    </div>
  );
};
