import { TextCell } from "../../components/TableCells/TextCell";
import { ToggleCell } from "../../components/TableCells/ToggleCell";
import { COLUMN_ACTIONS } from "../../constants/dummyData";
import { ColumnHeaderWithSorter } from "../../components/ColumnHeaderWithSorter";
import { DateCell } from "components/TableCells/DateCell";
import { ImageCell } from "components/TableCells/ImageCell";
import "../../styles/scss/_variables.scss";
import { Button } from "antd";
import {EyeOutlined} from "@ant-design/icons"

export const returnCategoryColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "",
    width: "6%",
    render: (rowData) => <ImageCell data={rowData?.mediaUrl} />,
  },
  {
    title: "Name",
    width: "12%",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.name} />,
  },
  {
    title: "CreatedOn",
    width: "8%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "No of Product",
    width: "8%",
    render: (rowData) => <TextCell data={rowData.activeProductCount} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Order"}
        sortKey="displayOrder"
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "8%",
    render: (rowData) => (
      <TextCell data={rowData?.displayOrder || "-"} />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];


export const returnProductColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "",
    width: "6%",
    render: (rowData) => {
      const coverImage = rowData.variants?.find((item)=> item?.isDefault)?.media?.[0]?.url
      return <ImageCell data={coverImage} />
    },
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title="Name"
        sortKey="name"
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "8%",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.name} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Quantity Available"}
        sortKey={`quantity`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => {
      const totalQuantity = rowData.variants?.reduce((acc, cur) => acc + (cur?.inventoryCount || 0), 0)
      return <TextCell data={totalQuantity || "-"} />
    },
  },
  {
    title: "Points",
    width: "15%",
    render: (rowData) => <TextCell data={`${rowData.points || 0} Points`} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    width: "8%",
    render: (rowData) => (
      <Button
        icon={<EyeOutlined />}
        style={{
          background: "#273054",
          color: '#FFFFFF',
          paddingLeft: 25,
          paddingRight: 25,
        }}
        onClick={(e) => {
          e.stopPropagation();
          return performColumnAction && performColumnAction(
              COLUMN_ACTIONS.ACTIVE_DATA,
              rowData.id
            )
        }}
      >
        View
      </Button>
    )
  }
];

export const returnOrdersColumns = ({
  onSort,
  sortColumn,
  languageId,
  filters,
  performColumnAction,
}) => [
  {
    title: "",
    fixed: "left",
    render: (rowData) => <ImageCell data={rowData?.orderMeta?.variant?.contentMedia?.[0]?.media?.link} />,
  },
  {
    title: "Product Name",
    fixed: "left",
    render: (rowData) => <TextCell data={rowData?.orderMeta?.product?.data?.[languageId]?.name} />,
  },
  {
    title: "Order ID",
    render: (rowData) => <TextCell data={rowData?.id} />,
  },
  {
    title: "Product Category",
    render: (rowData) => {
      const categories = rowData?.orderMeta?.product?.categories?.map((item) => item.data?.[languageId]?.name)
      return (
        <TextCell
          data={categories?.join(',')}
        />
      )
    },  
  },
  {
    title: "Variant Details",
    render: (rowData) => {
      const size = rowData?.orderMeta?.variant?.data?.size
      const color = rowData?.orderMeta?.variant?.data?.color?.name
      const style = rowData?.orderMeta?.variant?.data?.style

      const data = [
        ...(size ? [size] : []),
        ...(color ? [color] : []),
        ...(style ? [style] : []),
      ]
      return (
        <TextCell
          data={data?.join(' | ')}
        />
      )
    },
  },
  {
    title: "Variant Points",
    render: (rowData) => <TextCell data={rowData?.orderMeta?.variant?.pointPrice} />,
  },
  {
    title: "User info",
    width: "30%",
    children: [
      {
        title: 'Name',
        key: 'name',
        align: "center",
        render: (rowData) => <TextCell data={rowData?.user?.name} />,
      },
      {
        title: 'Mobile No.',
        key: 'mobile',
        align: "center",
        render: (rowData) => <TextCell data={rowData?.user?.phoneNo} />,
      },
      {
        title: 'Image',
        key: 'userImage',
        render: (rowData) => <ImageCell data={rowData?.user?.media?.link} />,
      },
    ]
  },
  {
    title: 'Address',
    key: 'address',
    render: (rowData) => {
      return (
      <p>
        <TextCell data={rowData?.orderMeta?.billingAddress?.contactName} />
        <TextCell data={rowData?.orderMeta?.billingAddress?.contactNo} />
        <TextCell data={rowData?.orderMeta?.billingAddress?.addressLine1} />
        <TextCell data={rowData?.orderMeta?.billingAddress?.city} />
        <TextCell data={rowData?.orderMeta?.billingAddress?.zipcode} />
        <TextCell data={rowData?.orderMeta?.billingAddress?.state} />
        <TextCell data={rowData?.orderMeta?.billingAddress?.country} />
      </p>
      )
    },
  },
  {
    title: "Order created date",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "Order status",
    key: "latestStatus",
    fixed: "right",
    filteredValue: filters?.orderStatus ? [filters?.orderStatus] : undefined,
    filters: [
      {
        text: COLUMN_ACTIONS.PENDING_FOR_APPROVAL,
        value: COLUMN_ACTIONS.PENDING_FOR_APPROVAL,
      },
      {
        text: COLUMN_ACTIONS.ACCEPTED,
        value: COLUMN_ACTIONS.ACCEPTED,
      },
      {
        text: COLUMN_ACTIONS.REJECTED,
        value: COLUMN_ACTIONS.REJECTED,
      },
      {
        text: COLUMN_ACTIONS.SHIPPED,
        value: COLUMN_ACTIONS.SHIPPED,
      },
      {
        text: COLUMN_ACTIONS.DELIVERED,
        value: COLUMN_ACTIONS.DELIVERED,
      },
    ],
    filterMultiple: false,
    render: (rowData, data, index) => <TextCell data={rowData?.latestStatus?.status} />,
  },
  {
    title: "Take action",
    key: "takeAction",
    fixed: "right",
    width: 300,
    render: (rowData, data, index) => {
      switch (rowData?.latestStatus?.status?.trim()) {
        case COLUMN_ACTIONS.PENDING_FOR_APPROVAL:
          return (
            <>
              <Button
                style={{
                  background: "#273054",
                  color: '#FFFFFF',
                  paddingLeft: 25,
                  paddingRight: 25,
                  margin: 10,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  return performColumnAction && performColumnAction(
                      COLUMN_ACTIONS.ACCEPTED,
                      rowData.id
                    )
                }}
              >
                Accept
              </Button>
              <Button
                style={{
                  background: "#c01515",
                  color: '#FFFFFF',
                  paddingLeft: 25,
                  paddingRight: 25,
                  margin: 10,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  return performColumnAction && performColumnAction(
                      COLUMN_ACTIONS.REJECTED,
                      rowData.id
                    )
                }}
              >
                Reject
              </Button>
            </>
          )
        case COLUMN_ACTIONS.ACCEPTED:
          return (
            <Button
              style={{
                background: "#273054",
                color: '#FFFFFF',
                paddingLeft: 25,
                paddingRight: 25,
              }}
              onClick={(e) => {
                e.stopPropagation();
                return performColumnAction && performColumnAction(
                    COLUMN_ACTIONS.SHIPPED,
                    rowData.id
                  )
              }}
            >
              Ship
            </Button>
          );
        case COLUMN_ACTIONS.SHIPPED:
          return (
            <Button
              style={{
                background: "#273054",
                color: '#FFFFFF',
                paddingLeft: 25,
                paddingRight: 25,
              }}
              onClick={(e) => {
                e.stopPropagation();
                return performColumnAction && performColumnAction(
                    COLUMN_ACTIONS.DELIVERED,
                    rowData.id
                  )
              }}
            >
              Mark as delivered
            </Button>
          );
        default:
          return null;
      }
    },
  },
];

