import { TABS } from "./utils";
import * as Yup from "yup";

export const DIVISION_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Division name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Division name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Division name is required"),
    }),
  }),
  stateId: Yup.string().required("State is required"),
  divisionCode: Yup.number()
    .typeError("Code must be a number")
    .integer("Code must be a positive integer")
    .positive("Code must be a positive integer"),
});

export const DISTRICT_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("District name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("District name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("District name is required"),
    }),
  }),
  divisionId: Yup.string().required("Division is required"),
  districtCode: Yup.number()
    .typeError("Code must be a number")
    .integer("Code must be a positive integer")
    .positive("Code must be a positive integer"),
});

export const TALUKA_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Taluka name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Taluka name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Taluka name is required"),
    }),
  }),
  districtId: Yup.string().required("District is required"),
  talukaCode: Yup.number()
    .typeError("Code must be a number")
    .integer("Code must be a positive integer")
    .positive("Code must be a positive integer"),
});

export const ASSEMBLY_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Assembly name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Assembly name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Assembly name is required"),
    }),
  }),
  districtId: Yup.string().required("District is required"),
  assemblyCode: Yup.number()
    .typeError("Code must be a number")
    .integer("Code must be a positive integer")
    .positive("Code must be a positive integer"),
});

export const VILLAGE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Village name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Village name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Village name is required"),
    }),
  }),
  talukaId: Yup.string().required("Taluka is required"),
  villageCode: Yup.number()
    .typeError("Code must be a number")
    .integer("Code must be a positive integer")
    .positive("Code must be a positive integer"),
});

export const POLICY_SCHEMA = Yup.object().shape({
  policyData: Yup.object().shape({
    en: Yup.string().required("Policy is required"),
    hi: Yup.string().required("Policy is required"),
    mr: Yup.string().required("Policy is required"),
  }),
});

export const WELCOME_VIDEO_SCHEMA = Yup.object().shape({
  videoData: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
  }),
  welcome_video: Yup.string().required("Video is required"),
  welcome_thub: Yup.string().required("Thumbnail image is required"),
});

export const getDivisionInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  divisionCode: values.divisionCode || "",
  stateId: values.stateId || "8b096998-d741-4375-bcc3-4522d242eda1",
});

export const getDistrictInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  districtCode: values.districtCode || "",
  divisionId: values.divisionId || undefined,
});

export const getTalukaInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  talukaCode: values.talukaCode || "",
  districtId: values.districtId || undefined,
  divisionId: values.divisionId || undefined,
});

export const getAssemblyInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  assemblyCode: values.assemblyCode || "",
  districtId: values.districtId || undefined,
  divisionId: values.divisionId || undefined,
});

export const getVillageInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  villageCode: values.villageCode || "",
  talukaId: values.talukaId || undefined,
});

export const getPolicyInitialValues = (values = {}) => (
  {
    id: values?.id || "",
    policyData: values.policyData || {
      en: "",
      hi: "",
      mr: "",
    },
  }
);

export const getWelcomeVideoInitialValues = (values = {}) => (
  {
    id: values?.id || "",
    videoData: values?.videoData || {
      en: {
        title: "",
      },
      hi: {
        title: "",
      },
      mr: {
        title: "",
      },
    },
    welcome_thub: values?.welcome_thub?.id || "",
    welcome_video: values?.welcome_video?.id || "",
    video_link: values?.welcome_video?.link || "",
    image_link: values?.welcome_thub?.link || "",
  }
);

export const getValidationSchema = (innerTab) => {
  switch (innerTab) {
    case TABS.DIVISIONS: {
      return DIVISION_SCHEMA;
    }
    case TABS.DISTRICTS: {
      return DISTRICT_SCHEMA;
    }
    case TABS.TALUKAS: {
      return TALUKA_SCHEMA;
    }
    case TABS.ASSEMBLY: {
      return ASSEMBLY_SCHEMA;
    }
    case TABS.VILLAGES: {
      return VILLAGE_SCHEMA;
    }
    case TABS.POLICIES: {
      return POLICY_SCHEMA;
    }
    case TABS.WELCOMEVIDEO: {
      return WELCOME_VIDEO_SCHEMA;
    }
    default: {
      return null;
    }
  }
};
