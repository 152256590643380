import { Api } from "../config/request";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login(data) {
    return Api.postRequest(`user/v1/login`, data);
  },
  forgotPassword(data) {
    return Api.postRequest(`user/v1/otp-admin/`, data);
  },
  createPassword(data) {
    return Api.postRequest(`user/v1/verify-admin-otp/`, data);
  },
};
