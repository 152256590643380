import { Button, Input, Popover, Tooltip } from "antd";
import "./index.scss";
import { FilterIcon, UploadIcon } from "../../assets/svgs";
import { useEffect, useState } from "react";
import { throttle } from "lodash";
import isEmpty from "lodash/isEmpty";
import { imagesUrl } from "../../constants/imagesUrl";
import { LeftOutlined } from "@ant-design/icons";

const SEARCH_TIMEOUT = 750;

const TableToolbar = ({
  onClickAdd,
  ctaLabel = "",
  isEditMode = false,
  showSearch = true,
  onSearch = () => {},
  innerTab,
  query = "",
  onApplyFilters = () => {},
  showFilter,
  filterOverlay,
  disableModalButton = false,
  handleTable,
  exportData,
  dataAvailabilty = false,
  innerText,
  showLabel = false,
  showBackButton = false,
  backButtonName = "",
  showMultiSelectFilter,
  onFilterClick,
  isActive,
  ...props
}) => {
  // const [activeTool, setActiveTool] = useState("");
  // const [isFilterVisible, setFilterVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  let throttledOnChange = throttle(onSearch, SEARCH_TIMEOUT);
  const [throttleSearch, setThrottleSearch] = useState(() => throttledOnChange);

  useEffect(() => {
    setThrottleSearch(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      return (throttledOnChange = throttle(onSearch, SEARCH_TIMEOUT));
    });
  }, [innerTab]);

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);
  //SEARCHING_EFFECTS//

  const isFilterActive = () => {
    return (
      !isEmpty(props[innerTab]?.filters?.category__in) ||
      !isEmpty(props[innerTab]?.filters?.city__in) ||
      !isEmpty(props[innerTab]?.filters?.createdAt__gte) ||
      !isEmpty(props[innerTab]?.filters?.createdAt__lte) ||
      !isEmpty(props[innerTab]?.filters?.id__in)
    );
  };

  const handleInput = (e) => {
    setSearchQuery(e);
    throttleSearch?.(e);
  };

  return (
    <div className="pl-40 pr-40 table-toolbar">
      <div className="pt-7 flex items-center">
        <div className="flex flex-row items-center">
          {showSearch ? (
            <Input
              // size="large"
              value={searchQuery}
              onChange={(e) => {
                handleInput(e.target.value);
              }}
              placeholder="Search"
              prefix={imagesUrl.icons.searchIcon}
              className="input-search-box"
            />
          ) : showBackButton ? (
            <div className="table-header">
              <span onClick={() => handleTable(true)}>
                {" "}
                <LeftOutlined />
              </span>
              <p className="table-header">{backButtonName}</p>
            </div>
          ) : null}
        </div>
        <div className="pl-20 pr-20">{innerText}</div>
        <div
          className={["button-group", isFilterActive() ? "active" : ""].join(
            " "
          )}
        >
          {filterOverlay && (
            <Popover {...filterOverlay} trigger="click" placement="bottom">
              <div className="square-button">
                <FilterIcon />
              </div>
            </Popover>
          )}
          {showMultiSelectFilter && (
            <div onClick={onFilterClick} className= {`square-button`}>
              {isActive && <span className="filter-dot"></span>}
              <FilterIcon />
            </div>
          )}

          <Tooltip title={dataAvailabilty ? "Download data" : "Coming soon.."}>
            <div
              onClick={() => {
                dataAvailabilty && exportData();
              }}
              className={"square-button coming-soon"}
            >
              <UploadIcon />
            </div>
          </Tooltip>
          {!disableModalButton && (
            <Button
              onClick={onClickAdd}
              type="primary"
              className={"toggle-form-button"}
              style={{
                margin: 10,
                background: "#273054",
              }}
            >
              {showSearch || showLabel ? (
                <>
                  {" "}
                  {isEditMode ? "Edit" : "Add"} {ctaLabel}
                </>
              ) : (
                "Update"
              )}
            </Button>
          )}
        </div>
      </div>
      {/* <Filter
        innerTab={innerTab}
        onApply={(filters) => {
          onApplyFilters(filters);
          setFilterVisible(false);
        }}
        isFilterVisible={isFilterVisible}
        setFilterVisible={setFilterVisible}
      /> */}
    </div>
  );
};

export default TableToolbar;
