import { fetchingData, fetchingDataError } from "./actionCreators";
import {
  GetApis,
  GetDropdownApi,
  GetModalTableApi,
} from "../../services/apis/common";
import Store from "../index";
import Toast from "components/Toast";

export const fetchModalTableData = (apiName, params) => async (dispatch) => {
  try {
    dispatch(fetchingData());
    const response = await GetModalTableApi?.[apiName].data(params);
    const payload = {
      [apiName]: response?.[apiName],
    };
    dispatch(GetModalTableApi?.[apiName].dispatch(payload));
  } catch (error) {
    dispatch(fetchingDataError());
  }
};

export const getDropdownData =
  (apiName, apiKey = "", params, data, callback) =>
  async (dispatch) => {
    try {
      const response = await GetDropdownApi?.[apiName]?.data(params, data);

      const payload =
        apiKey && apiKey === "rows"
          ? {
              [apiName]: response?.rows,
            }
          : apiKey
          ? {
              [apiKey]: response?.[apiKey]?.rows,
            }
          : response?.["data"];
      dispatch(GetDropdownApi?.[apiName]?.dispatch(payload));
    } catch (error) {
      console.log(error);
    }
    if (callback) callback();
  };

export const createData = (innerTab, payload) => async (dispatch) => {
  try {
    const response = await GetApis[innerTab].create.data(payload);
    if (!!response) {
      Toast("success", ``);
      //dispatch(GetApis[innerTab]?.create.dispatch(response));
      return response;
    }
  } catch (error) {
    dispatch(fetchingDataError());
    console.log(error);
  }
};

export const updateData =
  (innerTab, id, payload, apiRequest = "patchRequest") =>
  async (dispatch) => {
    try {
      const response = await GetApis[innerTab].edit.data(
        id,
        payload,
        apiRequest
      );
      if (!!response) {
        Toast("success", ``, "Updated Successfully!");
        dispatch(GetApis[innerTab].edit.dispatch(response.data));
        return response;
      }
    } catch (error) {
      dispatch(fetchingDataError());
    }
  };

export const updateVariantData =
  (innerTab, id, payload) => async (dispatch) => {
    try {
      const response = await GetApis[innerTab].editVariant.data(id, payload);
      if (!!response) {
        Toast("success", ``, "Updated Successfully!");
        dispatch(GetApis[innerTab].edit.dispatch(response.data));
        return response;
      }
    } catch (error) {
      dispatch(fetchingDataError());
    }
  };

export const deleteData = (innerTab, id) => async (dispatch) => {
  try {
    const response = await GetApis[innerTab].delete.data(id);
    if (!!response) {
      Toast("success", ``, "Deleted Successfully!");
      //dispatch(GetApis[innerTab].delete.dispatch(response.data));
      return response;
    }
  } catch (error) {
    dispatch(fetchingDataError());
  }
};

export const updateFilters = (innerTab, payload) => async (dispatch) => {
  try {
    dispatch(GetApis[innerTab].filters.dispatch(payload));
  } catch (e) {
    console.log(e);
  }
};

export const fetchFromReduxOrNetwork = (tabToFetchFor) => async (dispatch) => {
  if (!tabToFetchFor) return;
  let store = Store;
  let reduxState = store.getState()?.[tabToFetchFor];
  if (reduxState?.list?.length !== 0) return reduxState.list;
  else {
    dispatch(fetchDataForInnerTabs(tabToFetchFor));
  }
  return [];
};

export const fetchDataForInnerTabs =
  (innerTab, urlParams, payload, updateReadonlyList) => async (dispatch) => {
    try {
      dispatch(fetchingData());
      let response = await GetApis[innerTab].get.data(urlParams, payload);
      if (innerTab === "aboutparty") {
        let res = {
          success: true,
          party: { ...response },
        };
        response = res;
      }
      dispatch(GetApis[innerTab].get.dispatch(response, updateReadonlyList));
    } catch (error) {
      dispatch(fetchingDataError());
      console.log(error, "here");
    }
  };

export const fetchDataForTable =
  (innerTab, id, urlParams, updateReadonlyList, responseKey) =>
  async (dispatch) => {
    try {
      dispatch(fetchingData());
      const response = await GetApis[innerTab].getFormData.data(id);
      dispatch(
        GetApis[innerTab].getFormData.dispatch(response, updateReadonlyList)
      );
      let resKey = responseKey || innerTab;
      if (innerTab === "importantmessages") resKey = "importantMessages";
      if (innerTab === "division") resKey = "city";
      return response[resKey.slice(0, resKey.length - 1)] || response[resKey];
    } catch (error) {
      dispatch(fetchingDataError());
    }
  };

export const downloadExportedData =
  (innerTab, urlParams) => async (dispatch) => {
    try {
      const response = await GetApis[innerTab].export.data(urlParams);
      return response["userSheetUrl"] || response;
    } catch (error) {
      console.log(error);
    }
  };
