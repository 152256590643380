import { Col, Row } from "antd";
import {
  FaceBookIcon,
  InstagramIcon,
  TwitterIcon,
  WhatsAppIcon,
} from "assets/svgs";
import { ENGLISH } from "constants/language";
import { InputContainer } from "./InputContainer";
import { SocialMediaWrapper } from "./SocialMediaWrapper";
import "./TaskDetails.scss";

export const TaskDetails = ({
  image,
  checkList,
  rewardPoints,
  taskType,
  socialMediaCounts,
  taskDetails,
  totalEngagements
}) => {
  return (
    <div className="task-container">
      <Row className="about-task">
        {image && (
          <Col>
            <img className="task-image" src={image} alt="task" />
          </Col>
        )}
        <Col>
          <p className="field-label mb-0">Title</p>
          <p className="field-input">{taskDetails?.title}</p>
          <p className="field-label mb-0">Description</p>
          <p className="field-input">{taskDetails?.description}</p>
        </Col>
      </Row>
      <Row className="justify-between mt-36">
        <Col>
          <p className="field-label mb-8">Task Type</p>
          <InputContainer
            text={taskType === "newpost" ? "New post" : "Existing post"}
          />
        </Col>
        <Col>
          <p className="field-label mb-8">Reward</p>
          <InputContainer text={`${rewardPoints} points`} />
        </Col>
      </Row>
      <Row className="justify-between mt-26">
        {checkList?.map((list, index) => (
          <Col key={index}>
            <p className="field-label mb-8">CheckList</p>
            <InputContainer
              isCheckEnabled={true}
              text={list?.data?.[ENGLISH]?.title}
            />
          </Col>
        ))}
      </Row>
      <Row className="justify-between mt-36">
        {taskType === "existingpost" && (
          <Col>
            <SocialMediaWrapper
              count={totalEngagements}
              showEngagements={true}
            />
          </Col>
        )}
        {socialMediaCounts?.["instagram"] && (
          <Col>
            <SocialMediaWrapper
              Icon={InstagramIcon}
              count={socialMediaCounts?.instagram}
              stories={true}
              direct={true}
              posts={true}
            />
          </Col>
        )}
        {socialMediaCounts?.["facebook"] && (
          <Col>
            <SocialMediaWrapper
              Icon={FaceBookIcon}
              count={socialMediaCounts?.facebook}
              stories={true}
              direct={true}
              posts={true}
            />
          </Col>
        )}
        {socialMediaCounts?.["twitter"] && (
          <Col>
            <SocialMediaWrapper
              Icon={TwitterIcon}
              count={socialMediaCounts?.twitter}
              tweets={true}
              shares={true}
            />
          </Col>
        )}
        {socialMediaCounts?.["whatsapp"] && (
          <Col>
            <SocialMediaWrapper
              Icon={WhatsAppIcon}
              count={socialMediaCounts?.whatsapp}
              stories={true}
              direct={true}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
