import { avoidCache } from "constants/dummyData";
import "./index.scss";

export const ImageCell = ({ data, className }) => {
  const addDefaultSrc = () => {
    return "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-avatar-vector-isolated-on-white-background-png-image_1694546.jpg";
  };
  return [
    <img
      className={className ? className : "listing-row-image"}
      onError={() => addDefaultSrc()}
      src={
        data
          ? data + avoidCache()
          : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-avatar-vector-isolated-on-white-background-png-image_1694546.jpg"
      }
      alt="img"
    />,
    <div style={{ display: "none" }}>{data}</div>, //for keeping the values in exported excel
  ];
};
