import ReadMore from "../../ReadMore";

export const HTMLCell = ({
  data,
  enableReadMore = false,
  sortedColumn,
  isBold,
  color,
  isArray = false,
  ...props
}) => {
  let text = convertToText(data);

  return (
    <div className={sortedColumn ? "sorted" : ""}>
      {enableReadMore ? (
        <ReadMore className={["listing-row-text", isBold && "bold"].join(" ")}>
          {text || "-"}
        </ReadMore>
      ) : (
        <p
          style={color && { color }}
          className={["listing-row-text", isBold && "bold"].join(" ")}
        >
          {isArray && data?.length > 1
            ? `${text[0]} +${text.length - 1}`
            : text || "-"}
        </p>
      )}
    </div>
  );
};

const convertToText = (data) => {
  let ans = "";

  let remove = false;
  for (let i = 0; i < data?.length; i++) {
    if (data?.[i] === "<") {
      remove = true;
    }
    if (data?.[i] === ">") {
      remove = false;
    }

    if (!remove) {
      if (data?.[i] !== ">") {
        ans += data?.[i];
      }
    }
  }
  return ans;
};

// overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 2; /* number of lines to show */
//   -webkit-box-orient: vertical;
