import { ENGLISH, HINDI, MARATHI } from "constants/language";
import _ from "lodash";
import moment from "moment";

import {
  returnEventColumns,
  returnInfographicsColumns,
  returnPollsColumns,
  returnArticleColumns,
  returnAnnouncementColumns,
  returnMessagesColumns,
  returnVotesColumns,
  returnUsersColumns,
  returnYoutubeVideoColumns,
  returnFacebookLiveColumns,
} from "./columns";

export const INNER_HEADERS = [
  {
    label: "Events",
    singular: "Event",
    title: {
      en: "Events",
      hi: "आयोजन",
      mr: "कार्यक्रम",
    },
  },
  {
    label: "Infographics",
    singular: "Infographics",
    title: {
      en: "Infographics",
      hi: "आलेख जानकारी",
      mr: "व्हिज्युअल माहिती",
    },
  },
  {
    label: "Polls",
    singular: "Poll",
    title: {
      en: "Polls",
      hi: "चुनाव",
      mr: "मतदान",
    },
  },
  {
    label: "Articles",
    singular: "Articles",
    title: {
      en: "Articles",
      hi: "लेख",
      mr: "लेख",
    },
  },
  {
    label: "Announcements",
    singular: "Announcement",
    title: {
      en: "Announcements",
      hi: "घोषणा",
      mr: "घोषणा",
    },
  },
  {
    label: "Important Messages",
    singular: "importantmessages",
    title: {
      en: "Important Messages",
      hi: "महत्वपूर्ण संदेश",
      mr: "महत्वाचे संदेश",
    },
  },
  {
    label: "Votes",
    singular: "Votes",
    title: {
      en: "Votes",
      hi: "वोट",
      mr: "मते",
    },
  },
  {
    label: "Facebook Live",
    singular: "Facebook Live",
    title: {
      en: "Facebook Live",
      hi: "फेसबुक लाइव",
      mr: "फेसबुक लाईव्ह",
    },
  },
  {
    label: "Youtube Video",
    singular: "Youtube Video",
    title: {
      en: "Youtube Video",
      hi: "वीडियो",
      mr: "व्हिडिओ",
    },
  },
];

export const TABS = {
  EVENT: "events",
  INFOGRAPHICS: "infographics",
  POLLS: "polls",
  ARTICLE: "articles",
  ANNOUNCEMENT: "announcements",
  IMPORTANT_MESSAGES: "importantmessages",
  VOTES: "votes",
  YOUTUBE_VIDEO: "youtubevideo",
  FACEBOOK_LIVE: "facebooklive",
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  languageId,
  performColumnAction
) => {
  let args = {
    onSort,
    sortColumn,
    languageId,
    performColumnAction,
  };
  switch (innerTab) {
    case TABS.ANNOUNCEMENT:
      return returnAnnouncementColumns(args);
    case TABS.EVENT:
      return returnEventColumns(args);
    case TABS.INFOGRAPHICS:
      return returnInfographicsColumns(args);
    case TABS.POLLS:
      return returnPollsColumns(args);
    case TABS.ARTICLE:
      return returnArticleColumns(args);
    case TABS.IMPORTANT_MESSAGES:
      return returnMessagesColumns(args);
    case TABS.VOTES:
      return returnVotesColumns(args);
    case TABS.YOUTUBE_VIDEO:
      return returnYoutubeVideoColumns(args);
    case TABS.FACEBOOK_LIVE:
      return returnFacebookLiveColumns(args);
    default:
      return returnAnnouncementColumns(args);
  }
};

export const getSubColumns = () => {
  return returnUsersColumns();
};

export const createPayload = (innerTab, values, tags) => {
  switch (innerTab) {
    case TABS.POLLS: {
      let payload = {
        data: values.data,
        publishedDate: moment(new Date()).toISOString(),
        isPopular: values.isPopular,
        isNotification: values.isNotification,
      };
      if (values.webUrl) {
        payload["webUrl"] = values.webUrl;
      }
      return payload;
    }
    case TABS.INFOGRAPHICS: {
      let payload = {
        data: values.data,
        images: values?.images?.filter((el) => el !== ""),
        publishedDate: moment(new Date()).toISOString(),
        categoryTags: tags,
        isPopular: values.isPopular,
        isNotification: values.isNotification,
        mediaId: values.mediaId,
      };
      if (values.webUrl) {
        payload["webUrl"] = values.webUrl;
      }
      return payload;
    }
    case TABS.ARTICLE: {
      let payload = {
        data: values.data,
        images: values?.images?.filter((el) => el !== ""),
        publishedDate: moment(new Date()).toISOString(),
        categoryTags: tags,
        isPopular: values.isPopular,
        isNotification: values.isNotification,
        mediaId: values.mediaId,
      };
      if (values.webUrl) {
        payload["webUrl"] = values.webUrl;
      } else {
        payload["webUrl"] = "";
      }
      return payload;
    }
    case TABS.ANNOUNCEMENT: {
      let payload = {
        data: values.data,
        publishedDate: moment(new Date()).toISOString(),
        isNotification: values.isNotification,
      };
      if (values.mediaId) {
        payload["mediaId"] = values.mediaId;
      }
      if (values.shortSummary) {
        payload["shortSummary"] = values.shortSummary;
      }
      return payload;
    }
    case TABS.IMPORTANT_MESSAGES: {
      let payload = {
        data: values.data,
        publishedDate: moment(new Date()).toISOString(),
        categoryTags: tags,
        isPopular: values.isPopular,
        backgroundColor: values.backgroundColor,
        isNotification: values.isNotification,
      };
      if (values.webUrl) {
        payload["webUrl"] = values.webUrl;
      }
      return payload;
    }
    case TABS.VOTES: {
      return {
        data: values.data,
        publishedDate: moment(new Date()).toISOString(),
        isPopular: values.isPopular,
        isNotification: values.isNotification,
        choices: values.choices?.map((e) => {
          delete e?.media;
          delete e?.data?.media;
          return e;
        }),
      };
    }
    case TABS.EVENT: {
      let payload = {
        data: values.data,
        images: values?.images?.filter((el) => el !== ""),
        publishedDate: moment(new Date()).toISOString(),
        eventStartDate: moment(values?.eventStartDate).toISOString(),
        eventEndDate: moment(values?.eventEndDate).toISOString(),
        categoryTags: tags,
        location: values?.location,
        latitude: Number(values.latitude),
        longitude: Number(values.longitude),
        isPopular: values.isPopular,
        isNotification: values.isNotification,
        mediaId: values.mediaId,
      };
      if (values.webUrl) {
        payload["webUrl"] = values.webUrl;
      }
      return payload;
    }
    case TABS.FACEBOOK_LIVE: {
      let payload = {
        data: values.data,
        images: values?.images?.filter((el) => el !== ""),
        facebookLiveStartDate: moment(
          values?.facebookLiveStartDate
        ).toISOString(),
        facebookLiveEndDate: moment(values?.facebookLiveEndDate).toISOString(),
        isNotification: values.isNotification,
      };
      if (values.webUrl) {
        payload["webUrl"] = values.webUrl;
      } else {
        payload["webUrl"] = "";
      }
      return payload;
    }
    case TABS.YOUTUBE_VIDEO: {
      let payload = {
        data: values.data,
        images: values?.images?.filter((el) => el !== ""),
        isNotification: values.isNotification,
        mediaId: values.mediaId,
      };
      if (values.webUrl) {
        payload["webUrl"] = values.webUrl;
      } else {
        payload["webUrl"] = "";
      }
      return payload;
    }
    default:
      return null;
  }
};

export const getModalHeader = (tab) => {
  switch (tab) {
    case TABS.ANNOUNCEMENT:
      return "Announcement";
    case TABS.EVENT:
      return "Event";
    case TABS.INFOGRAPHICS:
      return "Infographic";
    case TABS.POLLS:
      return "Poll";
    case TABS.ARTICLE:
      return "Article";
    case TABS.IMPORTANT_MESSAGES:
      return "Important Message";
    case TABS.VOTES:
      return "Vote";
    case TABS.YOUTUBE_VIDEO:
      return "Youtube Video";
    case TABS.FACEBOOK_LIVE:
      return "Facebook Live";
    default:
      return "Content";
  }
};

export const getLocationDropDowns = (array) => {
  return array?.map((e) => {
    return {
      id: e?.id,
      english: e?.data?.[ENGLISH]?.name,
      hindi: e?.data?.[HINDI]?.name,
      marathi: e?.data?.[MARATHI]?.name,
    };
  });
};

export const handleChoices = (curr, prev) => {
  let payload = [];

  for (let i = 0; i < prev.length; i++) {
    let obj = {};
    for (let j = 0; j < curr.length; j++) {
      if (prev[i]?.id === curr[j]?.id) {
        if (prev[i]?.mediaId !== curr[j]?.mediaId) {
          obj = curr[j];
          delete obj?.["choiceVotesCount"];
        } else {
          obj = curr[j];
          delete obj?.["mediaId"];
          delete obj?.["choiceVotesCount"];
        }
      }
    }
    if (_.isEmpty(obj)) {
      obj = {
        action: "delete",
        id: prev[i]?.id,
      };
    }
    payload.push(obj);
  }

  let newChoices = curr
    ?.filter((e) => e?.id === undefined)
    ?.map((e) => {
      return { ...e, action: "create" };
    });

  if (newChoices) {
    return [...payload, ...newChoices];
  } else {
    return [...payload];
  }
};

export const handlePutRequest = (currentValues, prevValues) => {
  if (prevValues.categoryTags === undefined) {
    prevValues.categoryTags = [];
  }
  let payload = {};
  for (let keys in currentValues) {
    for (let currKeys in prevValues) {
      if (keys === currKeys) {
        if (keys !== "choices") {
          if (
            JSON.stringify(currentValues[keys]) !==
            JSON.stringify(prevValues[keys])
          ) {
            payload[keys] = currentValues[keys];
          }
        } else {
          payload[keys] = handleChoices(currentValues[keys], prevValues[keys]);
        }
      }
    }
  }
  if (payload.isNotification) {
    delete payload["isNotification"];
  }
  return payload;
};
