import {
  Col,
  Row,
  Table,
  Space,
  Avatar,
  Button,
  Divider,
  Typography,
  Dropdown,
  Menu,
} from 'antd'
import './ProductDetails.scss'
import {
  MERCHANDISE_PRODUCT_COLORS,
} from "constants/data"
import {COLUMN_ACTIONS} from "constants/dummyData"
import { LeftOutlined, DownOutlined } from "@ant-design/icons";
import { ImageCell } from "components/TableCells/ImageCell";
import { ToggleCell } from 'components/TableCells/ToggleCell';
import { createPayload } from 'views/Merchandise/utils'

const { Title } = Typography;

const getColumns = ({
  sizes,
  colors,
  styles,
  performColumnAction,
}) => {
  return [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (images) => (
        <Avatar.Group>
          {
            images.map((src, index) => (
              <Avatar
                key={index}
                size={48}
                src={src}
              />
            ))
          }
        </Avatar.Group> 
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    ...[
      sizes.length ? ({
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
      }) : null
    ],
    ...[
      styles.length ? ({
        title: 'Style',
        dataIndex: 'style',
        key: 'style',
      }) : null
    ],
    ...[
      colors.length ? ({
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        render: (item, ...rest) => (
          <Space>
            <div
              className="color-badge size-20"
              style={{
                background: `#${item.rgbcode}`
              }}
            />
            <div>
              {item.name}
            </div>
          </Space>
        )
      }) : null
    ],
    {
      title: 'Quantity',
      dataIndex: 'inventoryCount',
      key: 'inventoryCount',
    },
    {
      title: 'Purchase Points',
      key: 'purchasePoints',
      dataIndex: 'purchasePoints',
    },
    {
      title: "Default",
      render: (rowData, data, index) => (
        <ToggleCell
          key={index + Math.random() * 100 + 1}
          status={rowData?.isDefault}
          onToggle={(isDefault) =>
            isDefault !== rowData?.isDefault &&
            performColumnAction &&
            performColumnAction(
              COLUMN_ACTIONS.DEFAULT,
              rowData.id,
              isDefault
            )
          }
        />
      ),
    },
    {
      title: "Status",
      render: (rowData, data, index) => (
        <ToggleCell
          key={index + Math.random() * 100 + 1}
          status={rowData?.isActive}
          onToggle={(isActive) =>
            isActive !== rowData?.isActive &&
            performColumnAction &&
            performColumnAction(
              COLUMN_ACTIONS.TOGGLE_STATUS,
              rowData.id,
              isActive
            )
          }
        />
      ),
    },
  ];
}


export const ProductDetails = ({
  setShowProductDetails,
  productDetail,
  getAttributes,
  setProductDetailModal,
  setActiveProductVariant,
  updateVariantData,
  fetchData,
  getDataFromRedux,
  fetchDataForTable,
  updateData,
}) => {
  const productId = productDetail?.id
  const productTitle = productDetail?.data?.en?.name
  const productDescription = productDetail?.data?.en?.description
  const productCategories = productDetail?.categories?.map(item => ({
    label: item?.data?.en?.name,
    value: item.id
  }))
  const variants = Array.isArray(productDetail?.variants) ? productDetail?.variants : []
  const defaultVariant = variants.find((variant) => variant.isDefault)
  const {
    colors, sizes, styles, attributes
  } = Array.isArray(variants) && getAttributes(variants) 

  const dataSource = variants.map((item) => ({
    key: item?.id,
    image: item.contentMedia.map((item) => item?.media?.link),
    id: item?.id,
    size: item?.data?.size,
    style: item?.data?.style,
    color: item?.data?.color,
    inventoryCount: item?.inventoryCount,
    purchasePoints: item?.pointPrice,
    isActive: item?.isActive,
    isDefault: item?.isDefault, 
  }))

  const performColumnAction = async (actionType, rowId, value) => {
    switch (actionType) {
      case COLUMN_ACTIONS.TOGGLE_STATUS: {
        let request = await updateVariantData(
          "products",
          rowId,
          {
            isActive: value,
          }
        );
        if (request?.status === 200) {
          fetchData(
            "products",
            getDataFromRedux("filters")
          );
          await fetchDataForTable(
            "products",
            productId
          );
        }
        break;
      }
      case COLUMN_ACTIONS.DEFAULT: {
        const updatedVariants = productDetail?.variants?.map((variant) => ({
            ...variant,
            isDefault: (variant.id === rowId) ? value : false
          })
        )
        const payload = createPayload("products", {
          ...productDetail,
          categories: productCategories,
          variants: updatedVariants
        });

        let request = await updateData(
          "products",
          productDetail.id,
          payload
        );
        if (request?.status === 200) {
          fetchData(
            "products",
            getDataFromRedux("filters")
          );
          await fetchDataForTable(
            "products",
            productId
          );
        }
        break;
      }
      default:
        break;
    }
  }

  const columns = getColumns({
    sizes,
    colors,
    styles,
    attributes,
    performColumnAction
  }).filter(item => item)


  const menu = (
    <Menu
      items={
        productDetail?.categories?.map((item) => ({
          key: '1',
          label: (
            <span>
              {item?.data?.en?.name}
            </span>
          ),
        })
      )}
    />
  );  

  return (
    <div className="product-container">
      <Row align="middle" justify="space-between">
        <Col>
          <Space>
            <Space align="center" className=''>
              <span className='cursor-pointer' onClick={() => setShowProductDetails(false)}>
                <LeftOutlined />
              </span>
              <Title level={3} className="mb-0">
                {productTitle}
              </Title>
            </Space>
            <Space align="center" className=''>
              <p className="field-label mb-0 size-20">
                Category:
              </p>
              <p className="mb-0 size-20">
                {
                  productCategories?.length > 1 ? (
                    <Dropdown overlay={menu}>
                      <span>
                        <Space>
                          {productCategories?.[0]?.label}
                          <DownOutlined />
                        </Space>
                      </span>
                    </Dropdown>
                  ) : productCategories?.[0]?.label
                }
              </p>
            </Space>
          </Space>
        </Col>
        <Col>
          <Space align="center" className='m-auto mr-0'>
            <Button
              type="default"
              className={"toggle-form-button"}
              style={{
                margin: 10,
                background: "#FFFFFF",
                color: "#7A869A",
                border: "#E4E8F0",
                paddingLeft: 25,
                paddingRight: 25,
              }}
              >
              Deactivate
            </Button>
            <Button
              type="default"
              className={"toggle-form-button"}
              style={{
                margin: 10,
                background: "#273054",
                color: '#FFFFFF',
                paddingLeft: 25,
                paddingRight: 25,
              }}
              onClick={() => setProductDetailModal(true)}
            >
              EDIT
            </Button>
          </Space>
        </Col>
      </Row>
      {/* Details */}
      <Row gutter={15}>
        <Col span={6}>
          <ImageCell
            data={defaultVariant?.contentMedia?.[0]?.media?.link}
            className="product-image"
          />
        </Col>
        <Col span={18}>
          <p className="field-label mb-0">ID# {productId ? productId : undefined} (Description)</p>
          <div
            dangerouslySetInnerHTML={{ __html: productDescription}}
          />
          {/* Colors */}
          {
            Array.isArray(colors) && !!colors.length && (
              <>
                <Divider />
                <Row justify='space-between' align='middle'>
                  <Col>Colors</Col>
                  <Col>
                    <Space align='center'>
                      {colors.map((item) => (
                        <div
                          key={item.value}
                          className="color-badge size-20"
                          style={{
                            background: `#${MERCHANDISE_PRODUCT_COLORS.find(
                              (color) => color.id === item.value
                            )?.color?.rgbcode}`
                          }}
                        />
                      ))}
                    </Space>
                  </Col>
                </Row>
              </>
            )
          }
          {
            Array.isArray(sizes) && !!sizes.length && (
              <>
                <Divider />
                <Row justify='space-between' align='middle'>
                  <Col>Sizes</Col>
                  <Col>
                    <Space align='center'>
                      {sizes.map((item) => (
                        <div
                          key={item.value}
                          className="size-14 bg-color-white p-5 pl-10 pr-10 br-4"
                        >
                          {item.label}
                        </div>
                      ))}
                    </Space>
                  </Col>
                </Row>
              </>
            )
          }
          {
            Array.isArray(styles) && !!styles.length && (
              <>
                <Divider />
                <Row justify='space-between' align='middle'>
                  <Col>Styles</Col>
                  <Col>
                    <Space align='center'>
                      {styles.map((item) => (
                        <div
                          key={item.value}
                          className="size-14 bg-color-white p-5 pl-10 pr-10 br-4"
                        >
                          {item.label}
                        </div>
                      ))}
                    </Space>
                  </Col>
                </Row>
              </>
            )
          }
        </Col>
      </Row>
      <Divider />
      <Title level={3}>
        {variants.length} Variants
      </Title>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setActiveProductVariant(productDetail?.variants?.[rowIndex]);
            },
          };
        }}
      />
    </div>
  )
}
