import { Button } from "antd";
import { Field, Form } from "formik";
import React, { useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";

const AddCadreCommonForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  points,
  errors,
  touched,
  access,
  submitButtonLabel,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && (errors?.districtId || errors?.data?.en);
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mb-8 field-label">Name</p>
              <Field
                value={values?.data.en.name}
                name="data.en.name"
                type="text"
                component={AntInput}
                suffix={
                  <div>
                    {100 - (values?.data?.[ENGLISH]?.name?.length || 0)} letters
                  </div>
                }
                placeholder={"Enter content title "}
                maxLength = {100}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data?.[HINDI]} />
          ) : (
            <MarathiLanguageForm values={values?.data?.[MARATHI]} />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddCadreCommonForm;
