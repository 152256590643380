import { Layout, Skeleton, Table } from "antd";
import { connect } from "react-redux";
import AppHeader from "components/Header";
import { useCallback, useEffect, useState } from "react";
import {
  fetchCMSLanguage,
  generateFilterUrl,
  getSerializedData,
} from "utils/helpers";
import InnerHeader from "components/InnerHeader";
import {
  INNER_HEADERS,
  getColumns,
  getDropDowns,
  TABS,
  getButtonLabel,
  createPayload,
} from "./utils";
import TableToolbar from "components/TableToolbar";
import {
  CADRE_COMMITTEE_SCHEMA,
  CADRE_COMMON_SCHEMA,
  CADRE_DISTRICT_SCHEMA,
  CADRE_DIVISION_SCHEMA,
  CADRE_POST_SCHEMA,
  CADRE_WARD_SCHEMA,
  getCadreCommonValues,
  getCadreDistrictInitialValues,
  getCommitteeInitialValues,
  getDivisionInitialValues,
  getPostInitialValues,
  getTalukaInitialValues,
  getVidhanSabhaInitialValues,
  getWardInitialValues,
  TALUKA_SCHEMA,
  VIDHAN_SABHA_SCHEMA,
} from "./validate";
import { bindActionCreators } from "redux";
import * as RootActions from "store/root/actions";
import { CustomPagination } from "components/CustomPagination";
import { Formik } from "formik";
import Modal from "components/Modal";
import { COLUMN_ACTIONS } from "constants/dummyData";
import AddDivisionForm from "forms/AddDivisionForm/AddDivisionForm";
import AddDistrictForm from "forms/AddDistrictForm/AddDistrictForm";
import AddAssemblyForm from "forms/AddAssemblyForm/AddAssemblyForm";
import AddTalukaForm from "forms/AddTalukaForm/AddTalukaForm";
import AddCadreCommonForm from "forms/CadreCommonForm/cadreForm";
import AddWardForm from "forms/AddWardForm/AddWardForm";
import "./CadreMaster.scss";
import AddCommitteeForm from "forms/AddCommittee/AddCommittee";
import AddPostForm from "forms/AddPostForm/AddPostForm";
import CadreDetailForm from "forms/CadreDetailForm/CadreDetailForm";
import CadreFullDetailForm from "forms/CadreDetailForm/CadreFullDetailForm";

const { Header, Content } = Layout;

const CadreMaster = (props) => {
  let dropdownData = props?.dropdownData;
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());
  const [innerTab, setInnerTab] = useState(TABS.ORGANIZATION);
  const [isEditing, setIsEditing] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [fullCadreDetails, setFullCadreDetails] = useState(false);

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  const onSearch = (type, value) => {
    const paramsObj = {
      lang: "en",
    };
    if (value) paramsObj.search = value;

    const params = new URLSearchParams(paramsObj);

    switch (type) {
      case "cadreOrganisation":
        props.fetchDropdownData(type, "organisations", params);
        break;
      case "cadreCommittee":
        props.fetchDropdownData(type, "committees", params);
        break;
      case "cadrePost":
        props.fetchDropdownData(type, "posts", params);
        break;
      case "cadreState":
        props.fetchDropdownData(type, "states", params);
        break;
      case "cadreDistrict":
        props.fetchDropdownData(type, "districts", params);
        break;
      case "cadreDivision":
        props.fetchDropdownData(type, "divisions", params);
        break;
      case "cadreTaluka":
        props.fetchDropdownData(type, "taluka", params);
        break;
      case "cadreVidhansabha":
        props.fetchDropdownData(type, "vidhanSabha", params);
        break;
      case "cadreMunicipalcouncil":
        props.fetchDropdownData(type, "municipalCouncil", params);
        break;
      case "cadreNagarpanchayat":
        props.fetchDropdownData(type, "nagarPanchayat", params);
        break;
      case "cadreWard":
        props.fetchDropdownData(type, "wards", params);
        break;
      case "cadrePrabhag":
        props.fetchDropdownData(type, "prabhag", params);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const dropdowns = [
      "cadreOrganisation",
      "cadreCommittee",
      "cadrePost",
      "cadreState",
      "cadreDistrict",
      "cadreDivision",
      "cadreTaluka",
      "cadreVidhansabha",
      "cadreMunicipalcouncil",
      "cadreNagarpanchayat",
      "cadreWard",
      "cadrePrabhag",
    ];
    dropdowns.forEach((type) => onSearch(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.DIVISIONS:
          return props.cadre_divisions?.[key];
        case TABS.DISTRICTS:
          return props.cadre_districts?.[key];
        case TABS.VIDHAN_SABHA:
          return props.cadre_vidhansabha?.[key];
        case TABS.TALUKA:
          return props.cadre_taluka?.[key];
        case TABS.MUNICIPAL_COUNCIL:
          return props.cadre_council?.[key];
        case TABS.NAGAR_PANCHAYAT:
          return props.cadre_panchyat?.[key];
        case TABS.ORGANIZATION:
          return props.cadre_organization?.[key];
        case TABS.COMMITTEE:
          return props.cadre_committee?.[key];
        case TABS.POST:
          return props.cadre_post?.[key];
        case TABS.STATE:
          return props.cadre_state?.[key];
        case TABS.PRABHAG:
          return props.cadre_prabhag?.[key];
        case TABS.WARD:
          return props.cadre_ward?.[key];
        default:
          return null;
      }
    },
    [
      props.cadre_divisions,
      props.cadre_districts,
      props.cadre_vidhansabha,
      props.cadre_taluka,
      props.cadre_council,
      props.cadre_panchyat,
      props.cadre_organization,
      props.cadre_committee,
      props.cadre_post,
      props.cadre_state,
      props.cadre_prabhag,
      props.cadre_ward,
      innerTab,
    ]
  );

  const setInitialValues = (data = {}) => {
    let values =
      innerTab === TABS.DIVISIONS
        ? getDivisionInitialValues(data)
        : innerTab === TABS.DISTRICTS
        ? getCadreDistrictInitialValues(data)
        : innerTab === TABS.VIDHAN_SABHA
        ? getVidhanSabhaInitialValues(data)
        : innerTab === TABS.TALUKA
        ? getTalukaInitialValues(data)
        : innerTab === TABS.NAGAR_PANCHAYAT // same form used in taluka
        ? getTalukaInitialValues(data)
        : innerTab === TABS.MUNICIPAL_COUNCIL // same form used in taluka
        ? getTalukaInitialValues(data)
        : innerTab === TABS.ORGANIZATION
        ? getCommitteeInitialValues(data)
        : innerTab === TABS.COMMITTEE
        ? getCommitteeInitialValues(data)
        : innerTab === TABS.POST
        ? getPostInitialValues(data)
        : innerTab === TABS.STATE
        ? getCadreCommonValues(data) //common form
        : innerTab === TABS.PRABHAG
        ? getTalukaInitialValues(data) //common form
        : innerTab === TABS.WARD
        ? getWardInitialValues(data)
        : null;
    setFormInitialValues(() => values);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    if (query.length && query.length > 1) {
      applyFilters({ search: query });
    } else if (!query.length) {
      applyFilters({ search: query });
    }
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  const openFormModal = () => {
    setFormModalVisible(true);
  };

  return (
    <div className="cadre wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props?.[innerTab]?.count || "0"}
            onclick={setInnerTab}
            showCount
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                {fullCadreDetails ? (
                  <div className="container pl-40 pr-40">
                    <CadreFullDetailForm
                      cadreDetails={props.cadres.formData}
                      setFullCadreDetails={setFullCadreDetails}
                    />
                  </div>
                ) : (
                  <>
                    <TableToolbar
                      showFilter
                      innerTab={innerTab}
                      onSearch={handleSearch}
                      onClickAdd={openFormModal}
                      ctaLabel={getButtonLabel(innerTab)}
                      query={props[innerTab]?.filters?.search || ""}
                      disableModalButton={innerTab === TABS.CADRES}
                    />
                    <div className="container pl-40 pr-40">
                      <Table
                        className={"is_editable"}
                        columns={getColumns(
                          innerTab,
                          handleSort,
                          sortColumn,
                          languageId,
                          async (actionType, rowId, value) => {
                            switch (actionType) {
                              case COLUMN_ACTIONS.TOGGLE_STATUS:
                                if (rowId) {
                                  let request = await props.updateData(
                                    innerTab,
                                    rowId,
                                    {
                                      isActive: value,
                                    }
                                  );
                                  if (request?.status === 200) {
                                    fetchData(
                                      innerTab,
                                      getDataFromRedux("filters")
                                    );
                                  }
                                }
                                break;
                              case COLUMN_ACTIONS.ACTIVE_DATA:
                                const data = await props.fetchDataForTable(
                                  innerTab,
                                  rowId,
                                  null,
                                  null,
                                  "cadreDetails"
                                );
                                setActiveRow(data);
                                if (data) {
                                  setFullCadreDetails(data);
                                }
                                break;
                              default:
                                break;
                            }
                          }
                        ).map((column, index) => {
                          return {
                            ...column,
                            ...(props.loading && {
                              render: () => (
                                <Skeleton
                                  key={index}
                                  title={true}
                                  paragraph={false}
                                />
                              ),
                            }),
                          };
                        })}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: async (event) => {
                              try {
                                let responseKey = innerTab;
                                if (innerTab === TABS.DESIGNATIONS) {
                                  responseKey = "cadreDesignation";
                                }

                                let data = null;
                                if (innerTab === TABS.CADRES) {
                                  data = await props.fetchDataForTable(
                                    innerTab,
                                    record.userId,
                                    null,
                                    null,
                                    "cadreDetails"
                                  );
                                } else {
                                  data = await props.fetchDataForTable(
                                    innerTab,
                                    record.id,
                                    null,
                                    null,
                                    responseKey
                                  );
                                }
                                setInitialValues(data || record);
                                setIsEditing(true);
                                setActiveRow(record);
                                openFormModal(true);
                              } catch {}
                              setFormModalVisible(() => true);
                            },
                          };
                        }}
                        dataSource={getSerializedData(
                          props[innerTab]?.filters?.page,
                          getDataFromRedux()
                        )}
                        rowKey={"id"}
                        pagination={false}
                        onChange={(
                          pagination,
                          filters,
                          sorter,
                          { currentDataSource, action }
                        ) => {
                          if (action === "filter") {
                            applyFilters({
                              approvalStatus: filters.approvalStatus?.[0],
                              page: 0,
                            });
                          }
                        }}
                      />
                      <CustomPagination
                        current={props[innerTab]?.filters?.page + 1}
                        totalCount={props[innerTab]?.count}
                        onChange={handlePageChange}
                      />
                    </div>
                  </>
                )}
                <Modal
                  width={TABS.POLICIES ? "540px" : "500px"}
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing
                        ? innerTab === TABS.CADRES
                          ? "View Cadre - Personal Information"
                          : `Edit ${getButtonLabel(innerTab)}`
                        : `Add ${getButtonLabel(innerTab)}`}
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setInitialValues();
                    setFormModalVisible(false);
                  }}
                  visible={formModalVisible}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(innerTab, values);
                        let request;
                        if (isEditing || values?.id) {
                          request = props.updateData(
                            innerTab,
                            activeRow.id || values?.id,
                            payload
                          );
                        } else request = props.createData(innerTab, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSortColumn(null);
                              setFormModalVisible(false);
                              setIsEditing(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                          });
                        setSubmitting(false);
                      }}
                      validationSchema={
                        innerTab === TABS.DIVISIONS
                          ? CADRE_DIVISION_SCHEMA
                          : innerTab === TABS.DISTRICTS
                          ? CADRE_DISTRICT_SCHEMA
                          : innerTab === TABS.VIDHAN_SABHA
                          ? VIDHAN_SABHA_SCHEMA
                          : innerTab === TABS.TALUKA
                          ? TALUKA_SCHEMA
                          : innerTab === TABS.NAGAR_PANCHAYAT
                          ? TALUKA_SCHEMA
                          : innerTab === TABS.MUNICIPAL_COUNCIL
                          ? TALUKA_SCHEMA
                          : innerTab === TABS.PRABHAG
                          ? TALUKA_SCHEMA
                          : innerTab === TABS.WARD
                          ? CADRE_WARD_SCHEMA
                          : innerTab === TABS.COMMITTEE
                          ? CADRE_COMMITTEE_SCHEMA
                          : innerTab === TABS.POST
                          ? CADRE_POST_SCHEMA
                          : innerTab === TABS.ORGANIZATION
                          ? CADRE_COMMITTEE_SCHEMA
                          : CADRE_COMMON_SCHEMA
                      }
                    >
                      {(rest) =>
                        innerTab === TABS.DIVISIONS ? (
                          <AddDivisionForm
                            showOptionalField={false}
                            state={getDropDowns(dropdownData?.states)}
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Division`
                            } `}
                            disableStateField={false}
                            {...rest}
                          />
                        ) : innerTab === TABS.DISTRICTS ? (
                          <AddDistrictForm
                            showOptionalField={false}
                            showCadreType={true}
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add District`
                            } `}
                            division={getDropDowns(dropdownData?.divisions)}
                            {...rest}
                          />
                        ) : innerTab === TABS.VIDHAN_SABHA ? (
                          <AddAssemblyForm
                            isAssemblyForm={false}
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Vidhan Sabha`
                            } `}
                            fetchMetadata={false}
                            district={getDropDowns(dropdownData?.districts)}
                            {...rest}
                          />
                        ) : innerTab === TABS.TALUKA ? (
                          <AddTalukaForm
                            showOptionalField={false}
                            district={getDropDowns(dropdownData?.districts)}
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Taluka`
                            } `}
                            fetchMetadata={false}
                            {...rest}
                          />
                        ) : innerTab === TABS.MUNICIPAL_COUNCIL ? (
                          <AddTalukaForm
                            showOptionalField={false}
                            district={getDropDowns(dropdownData?.districts)}
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Council`
                            } `}
                            fieldName="Council name"
                            fetchMetadata={false}
                            {...rest}
                          />
                        ) : innerTab === TABS.NAGAR_PANCHAYAT ? (
                          <AddTalukaForm
                            showOptionalField={false}
                            district={getDropDowns(dropdownData?.districts)}
                            fieldName="Panchyat name"
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Panchyat`
                            } `}
                            fetchMetadata={false}
                            {...rest}
                          />
                        ) : innerTab === TABS.ORGANIZATION ? (
                          <AddCommitteeForm
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Organization`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.COMMITTEE ? (
                          <AddCommitteeForm
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Committee`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.POST ? (
                          <AddPostForm
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Post`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.STATE ? (
                          <AddCadreCommonForm
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add State`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.PRABHAG ? (
                          <AddTalukaForm
                            showOptionalField={false}
                            district={getDropDowns(dropdownData?.districts)}
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Prabhag`
                            } `}
                            fieldName="Prabhag name"
                            fetchMetadata={false}
                            {...rest}
                          />
                        ) : innerTab === TABS.WARD ? (
                          <AddWardForm
                            showOptionalField={false}
                            prabhag={getDropDowns(dropdownData?.prabhag)}
                            vidhanSabha={getDropDowns(
                              dropdownData?.vidhanSabha
                            )}
                            submitButtonLabel={`${
                              isEditing ? "Update " : `Add Prabhag`
                            } `}
                            fieldName="Prabhag name"
                            fetchMetadata={false}
                            {...rest}
                          />
                        ) : innerTab === TABS.CADRES ? (
                          <CadreDetailForm {...rest} />
                        ) : null
                      }
                    </Formik>
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

CadreMaster.propTypes = {};

const mapStateToProps = ({
  cadre_divisions,
  cadre_districts,
  cadre_vidhansabha,
  cadre_taluka,
  cadre_council,
  cadre_panchyat,
  cadre_organization,
  cadre_committee,
  cadre_post,
  cadre_state,
  cadre_prabhag,
  cadre_ward,
  dropdownData,
}) => ({
  cadre_divisions,
  cadre_districts,
  cadre_vidhansabha,
  cadre_taluka,
  cadre_council,
  cadre_panchyat,
  cadre_organization,
  cadre_committee,
  cadre_post,
  cadre_state,
  cadre_prabhag,
  cadre_ward,
  dropdownData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CadreMaster);
