import { Button } from "antd";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { Field, Form } from "formik";
import React, { useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";

const AddDesignationForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  taskType,
  points,
  errors,
  touched,
  access,
  organisations,
  committees,
  posts,
  states,
  districts,
  divisions,
  taluka,
  vidhanSabha,
  municipalCouncil,
  nagarPanchayat,
  wards,
  prabhag,
  submitButtonLabel,
  designations,
  onSearch,
  setOpenModal,
  approverId,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <div>
              <p className="mb-8 field-label">Designation title</p>
              <Field
                value={values.title}
                name="data.en.title"
                type="text"
                component={AntInput}
                suffix={
                  <div>
                    {200 - (values?.data?.[ENGLISH]?.title?.length || 0)}
                    &nbsp;letters
                  </div>
                }
                placeholder={"Enter designation title"}
                maxLength={200}
              />
              <p className="mb-8 field-label">Cell/Org you are Belong to?</p>
              <Field
                value={values.organisationId}
                selectOptions={organisations}
                optionValueKey="id"
                optionNameKey="english"
                mode="single"
                name="organisationId"
                component={AntSelect}
                placeholder="Select any Cell/Org"
                showSearch
                onSearch={(value) => onSearch("cadreOrganisation", value)}
              />
              <p className="mb-8 field-label">Committee?</p>
              <Field
                value={values.committeeId}
                selectOptions={committees}
                optionValueKey="id"
                optionNameKey="english"
                mode="single"
                name="committeeId"
                component={AntSelect}
                placeholder="Select any Committee"
                showSearch
                onSearch={(value) => onSearch("cadreCommittee", value)}
              />
              <p className="mb-8 field-label">Posts</p>
              <Field
                value={values.postId}
                selectOptions={posts}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="postId"
                component={AntSelect}
                placeholder="Select any Post"
                showSearch
                onSearch={(value) => onSearch("cadrePost", value)}
              />
              <p className="mb-8 field-label">Approver</p>
              <div onClick={setOpenModal}>
                <Field
                  value={values?.approverName}
                  mode={"single"}
                  name="approverName"
                  component={AntInput}
                  placeholder="Select Approver"
                  style={{ cursor: "pointer", color: "#131415" }}
                  disabled
                />
              </div>
              <p className="mb-8 field-label">State</p>
              <Field
                value={values.stateId}
                selectOptions={states}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="stateId"
                component={AntSelect}
                placeholder="Select any State"
                showSearch
                onSearch={(value) => onSearch("cadreState", value)}
              />
              <p className="mb-8 field-label">Division</p>
              <Field
                value={values.divisionId}
                selectOptions={divisions}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="divisionId"
                component={AntSelect}
                placeholder="Select any Division"
                showSearch
                onSearch={(value) => onSearch("cadreDivision", value)}
              />
              <p className="mb-8 field-label">District</p>
              <Field
                value={values.districtId}
                selectOptions={districts}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="districtId"
                component={AntSelect}
                placeholder="Select any District"
                showSearch
                onSearch={(value) => onSearch("cadreDistrict", value)}
              />
              <p className="mb-8 field-label">Vidhansabha</p>
              <Field
                value={values.vidhansabhaId}
                selectOptions={vidhanSabha}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="vidhansabhaId"
                component={AntSelect}
                placeholder="Select any Vidhansabha"
                showSearch
                onSearch={(value) => onSearch("cadreVidhansabha", value)}
              />
              <p className="mb-8 field-label">Taluka</p>
              <Field
                value={values.talukaId}
                selectOptions={taluka}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="talukaId"
                component={AntSelect}
                placeholder="Select any Taluka"
                showSearch
                onSearch={(value) => onSearch("cadreTaluka", value)}
              />
              <p className="mb-8 field-label">Municipal Council</p>
              <Field
                value={values.municipalcouncilId}
                selectOptions={municipalCouncil}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="municipalcouncilId"
                component={AntSelect}
                placeholder="Select any Municipal Council"
                showSearch
                onSearch={(value) => onSearch("cadreMunicipalcouncil", value)}
              />
              <p className="mb-8 field-label">Nagar Panchayat</p>
              <Field
                value={values.nagarpanchayatId}
                selectOptions={nagarPanchayat}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="nagarpanchayatId"
                component={AntSelect}
                placeholder="Select any Nagar Panchayat"
                showSearch
                onSearch={(value) => onSearch("cadreNagarpanchayat", value)}
              />
              <p className="mb-8 field-label">Prabhag</p>
              <Field
                value={values.prabhagId}
                selectOptions={prabhag}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="prabhagId"
                component={AntSelect}
                placeholder="Select any Prabhag"
                showSearch
                onSearch={(value) => onSearch("cadrePrabhag", value)}
              />
              <p className="mb-8 field-label">Wards</p>
              <Field
                value={values.wardId}
                selectOptions={wards}
                optionValueKey="id"
                optionNameKey="english"
                mode={"single"}
                name="wardId"
                component={AntSelect}
                placeholder="Select any Ward"
                showSearch
                onSearch={(value) => onSearch("cadreWard", value)}
              />
            </div>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data?.[HINDI]} />
          ) : (
            <MarathiLanguageForm values={values?.data?.[MARATHI]} />
          )}
          <Button
            loading={isSubmitting}
            className="submit-btn mt-40 "
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddDesignationForm;
