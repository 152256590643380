import { ReactComponent as NCPSvg } from '../../assets/svgs/Logo.svg';
import "./termsandconditions.scss";

export const TermsandConditions = () => {
  return (
    <div className="privacy-container">
      <div className="header">
        <NCPSvg/>
        <h3>Nationalist Congress Party</h3>
      </div>
      <h1>Terms & Conditions</h1>
      <p>
        This NCP APP is developed, managed and operated by the Nationalist
        Congress Party (“NCP”). These APP Terms apply solely to your access to,
        and use of, the NCP APP (the "App") operated by the NCP which link to
        these app Terms. However app Terms in no manner shall alter any of the
        express terms agreed between you and NCP (including its affiliates,
        agents and permitted assignees) for any products or services as the case
        may be.
      </p>
      <ul>
        <li>
          Reserve the right to change , modify ,alter, expand any of the terms
          and conditions contained in the APP Terms or any policy , guideline
          ,specifications of the APP, at any time and in its sole discretion
          without any prior notice . Any such changes, modification, alteration,
          addition or expansion so made to the APP Terms shall be effective
          immediately upon posting of the revisions on the APPs, and by
          accessing the APP you waive any right you may have to receive specific
          notice of such changes or modifications. Your continued use of the APP
          following the posting of changes, alterations, modifications, addition
          and expansion will confirm your acceptance of such changes or
          modifications. Therefore, it is deemed that you frequently review,
          understand and accept the APP Terms and applicable policies from
          time-to-time. If you do not agree to the amended terms, you must stop
          using the APP.
        </li>
        <br />
        <p>
          If you have any question regarding the use of the APP, please place
          your queries and questions or comments clarifications about the APP or
          APP content to ncpconnect.mumbai@gmail.com
        </p>
      </ul>
      <h2>A. Terms of Privacy</h2>
      <p>
        Refer to the Privacy Policy for an understanding of how we collect, use
        and disclose the identifiable information from our users.
      </p>
      <h2>B. Registration forms, Tools, Features and Facilities</h2>
      <p>
        Various forms are available on the APP to assist you in filling up your
        details and identifiable information for becoming a user of NCP App. NCP
        may currently or in future provide various options to connect to NCP and
        register yourself on the APP for various purposes as and when
        applicable. However, you are solely responsible for properly completing
        the form and submitting it in a timely manner.. Although NCP works hard
        to keep the forms, format, content and the information on APP, accurate,
        up-to-date and complete, including the correct functionality of the
        tools as and when NCP offer, operate as intended, NCP cannot guarantee
        and makes no representation regarding the accuracy or currency of such
        forms or information or the operation of its tools. Therefore, all the
        tools, forms, features and facilities as and where present and available
        on the APP is provided “AS IS,” and you are solely responsible for usage
        of the APP in any manner.
      </p>
      <h2>C. Stay connected option</h2>
      <p>
        If you request and accept to receive updates or other information by
        email or mobile phone or text message, you consent to our communicating
        with you via your emails and mobile device. We do not charge for these
        services. However, your carrier's normal messaging, data and other rates
        and fees may be applicable as per your carrier’s connection and usage
        terms. You should check with your carrier to find out what plans are
        available and how much they cost. At any time, you may contact us to
        unsubscribe from the updates as subscribed by you.
      </p>
      <p>
        You shall connect to NCP official links on social media portals such as
        Facebook, Twitter, Pinterest, YouTube or any other such portals as and
        when available solely on your own responsibility wherein you may be
        bound by the individual terms of usage of such portal and NCP shall not
        be liable to you or anyone for any loss damage or liability arising out
        of your access, usage and exploration of any such portal at any time. It
        is further agreed by you that NCP at no time whatsoever assures, claims
        or represent the safety and security for usage of any such portal. NCP
        shall at no time to be liable to any claims arising out of but not
        limited to any usage as mentioned above.
      </p>
      <h2>D. Intellectual Property Rights</h2>
      <p>
        Unless stated/indicated otherwise on the APP, including all content and
        materials on the APP, including, without limitation, NCP’s party symbol,
        designs, texts, graphics, pictures, information, data, audio-visual
        files, sound files, tools, widgets, software and any files in whatsoever
        format whether used collectively or individually (“APP Property”) are
        the proprietary property of the NCP or its licensors or users and are
        protected by Indian and international copyright laws. You as a user of
        the APP are granted a limited, non-sub licensable, non-transferable
        license to access and use the APP and the APP Property for your
        informational, non-commercial and personal use only. The license so
        granted is subject to these APP Terms.
      </p>
      <ul>
        <li>
          You as a user may use this material only as expressly authorized by
          NCP and shall not copy, transmit or create derivative works of such
          material without express authorization.
        </li>
        <li>
          You acknowledge and agree that he/she shall not upload post,
          reproduce, or distribute any content on or through the services that
          is protected by copyright or other proprietary right of a third party,
          without obtaining the written permission of the owner of such right.
        </li>
        <li>
          Any copyrighted or other proprietary content distributed with the
          consent of the owner must contain the appropriate copyright or other
          proprietary rights notice. The unauthorized submission or distribution
          of copyrighted or other proprietary content is illegal and could
          subject the user to personal liability or criminal prosecution.
        </li>
      </ul>
      <h2>Copyright Complaints</h2>
      <p>
        If you have a reason to believe that any material on the APP including
        the APP Property infringes upon any copyright or proprietary right which
        you own or control you may contact on this email address
        ncpconnect.mumbai@gmail.com
      </p>
      <p>
        Any notice with respect of any such infringement shall be addressed to
        the abovementioned designated person and shall be governed by the Indian
        Laws and acts as amended up to date.
      </p>
      <p>
        You are prohibited from using any Meta tags or any other "hidden text"
        utilizing "NCP" or any other name, APP Property or product or service
        name of the NCP without NCP’s prior written permission. In addition, the
        look and feel of the APP, including but not limited to all page headers,
        custom graphics, button icons and scripts, is the proprietary property
        of NCP and must not be copied, imitated or used, in whole or in part,
        without our prior written permission. All other trademarks, registered
        trademarks, product names and company names or logos mentioned in the
        APPs are the property of their respective owners. Reference to any
        products, services, processes or other information, by trade name,
        trademark, manufacturer, and supplier or otherwise does not constitute
        or imply endorsement, sponsorship or recommendation thereof by us.
      </p>
      <p>
        Except as otherwise provided, you retain ownership of all User
        Submissions you post on the APP. However, if you post User Submissions
        to the APP, unless NCP indicate otherwise, you grant NCP and its
        affiliates a non-exclusive, royalty-free, perpetual, irrevocable and
        fully sub licensable right to use, reproduce, modify, adapt, publish,
        translate, create derivative works from, distribute, perform and display
        such User Submissions throughout the world in any manner, media or
        language including without limitation in advertising, campaigning and
        other communications in support of NCP, without any right of
        compensation or attribution. You grant the NCP and its affiliates and
        sub licenses the right to use the name that you submit in connection
        with such content, as and if they choose. You represent and warrant that
        (i) you own and control all of the rights to the User Submissions that
        you post or you otherwise have the right to post such User Submissions
        to the APP; (ii) the User Submission is accurate and not misleading; and
        (iii) use and posting of the User Submission you supply does not violate
        these APP Terms and will not violate any rights of or cause injury to
        any person or entity.
      </p>
      <h2>E. Third party and hyper links</h2>
      <p>
        The NCP makes no claim or representation regarding, and accepts no
        responsibility for, the quality, content, nature or reliability of
        third-party Web APPs accessible by hyperlink from the APP, or web APP
        linking to the APP. Such APPs are not under the control of the NCP and
        the NCP is not responsible for the contents of any linked APP or any
        link contained in a linked APP, or any review, changes or updates to
        such APPs. The NCP provides these links to you only as a convenience,
        and the inclusion of any link does not imply affiliation, endorsement or
        adoption by the NCP of any APP or any information contained therein.
        When you leave the APP, you are deemed to be aware of going out of the
        purview of the APP Terms You should review the applicable terms and
        policies, including privacy and data gathering practices, of any APP to
        which you navigate from the APP
      </p>
      <h2>Hyper Linking Notice</h2>
      <p>
        You are prohibited from using any of the APP Property or placing/using
        any link to the content on the APP without prior written consent of NCP.
      </p>
      <h2>F. Third Party Content</h2>
      <p>
        NCP may make third party information and other content available on or
        through the APP (the "Third Party Content") as a service to those
        interested in this information and we may provide information regarding
        or access to third party products or services available on or through
        the APP (“Third Party Products and Services”). Your business dealings or
        correspondence with such third parties, and any terms, conditions,
        warranties or representations associated therewith, are solely between
        you and such third party. NCP does not control, endorse or adopt any
        Third Party Content or Third Party Products, and makes no representation
        or warranties of any kind regarding the Third Party Content, including
        without limitation regarding its accuracy or completeness. You
        acknowledge and agree that the NCP is not responsible or liable in any
        manner for any Third Party Content and undertakes no responsibility to
        update or review any Third Party Content. Users use such Third Party
        Content contained therein at their own risk Submissions. You acknowledge
        and agree that any feedback, questions, comments, suggestions, ideas, or
        other information or materials regarding the APP or the NCP that are
        provided by you in the form of email or other submissions to the NCP, or
        any postings on the APP, are non-confidential and shall become the sole
        property of the NCP. The NCP shall own exclusive rights, including all
        intellectual property rights, and shall be entitled to the unrestricted
        use and dissemination of these materials for any purpose without
        acknowledgment or compensation to you.
      </p>
      <h2>G. Interactive Areas</h2>
      <p>
        The APPs may include interactive interface to users (“Interactive
        Areas”) such as surveys, votes, polls, personal information forms,
        forums, or other areas or services in which you or other users may
        create, post, share or store content, messages, materials, data,
        information, text, graphics, audio, video, or other items or materials
        on the APPs ("User Submissions"). You are solely responsible for your
        use of such Interactive Areas and use them at your own risk. By using
        any Interactive Areas, you agree not to post, upload to, transmit,
        distribute, store, create or otherwise publish through the APPs any of
        the following:
      </p>
      <h2>User Submission</h2>
      <ul>
        <li>
          that is unlawful, libellous, defamatory, obscene, pornographic,
          indecent, lewd, suggestive, harassing, threatening, invasive of
          privacy or publicity rights, abusive, inflammatory, fraudulent,
          deceptive or misleading;
        </li>
        <li>
          that would constitute, encourage or provide instructions for a
          criminal offense, violate the rights of any party, or that would
          otherwise create liability or violate any local, state, national or
          international law.
        </li>
        <li>
          that may infringe any patent, trademark, trade secret, copyright or
          other intellectual or proprietary right of any party.
        </li>
        <li>
          that impersonates any person or entity or otherwise misrepresents your
          affiliation with a person or entity;
        </li>
        <li>
          that, in the sole judgment of the NCP, is objectionable or which
          restricts or inhibits any other person from using or enjoying the
          Interactive Areas or the APPs, or which may expose NCP or its users to
          any harm or liability of any type;
        </li>
        <li>
          which violates the terms of any NCP guidelines, principles, policies
          or rules posted on the APP or as and where communicated to you;
        </li>
        <li>Unsolicited promotions, spam’, advertising or solicitations;</li>
        <li>
          Private information of any third party, including, without limitation,
          addresses, phone numbers, email addresses, Aadhar numbers or any other
          such personal details; and
        </li>
        <li>
          Viruses, corrupted data or other harmful, disruptive or destructive
          files;
        </li>
      </ul>
      <h2>H. User Data</h2>
      <p>In consideration of your use of the APP, you agree to:</p>
      <p>
        (a)Provide accurate, current and complete information about you as may
        be prompted by any registration forms on the APP ("Input Data");
      </p>
      <p>
        (b)Maintain the security of your password and identification wherever
        applicable;
      </p>
      <p>
        (c) Maintain and promptly update the Input Data, and any other
        information you provide to NCP, to keep it accurate, current and
        complete; and
      </p>
      <p>
        (d)Accept all risks of unauthorized access to the Registration Data and
        any other information you provide to the NCP.
      </p>
      <h2>I. Disclaimer</h2>
      <p>
        Except as specifically stated in APP Terms, NCP does not make any
        representation or warranty, express or implied, in connection with the
        subject matter of the APP Terms and hereby disclaims any and all implied
        warranties, including all implied warranties of system ability or
        fitness for a particular purpose. NCP shall adopt industry acceptable
        standards for providing and maintaining the APP and shall maintain
        widgets, software, or any other link , uptime of 98% provided however
        that NCP shall not be required to suffer any direct, consequential, or,
        indirect, exemplary, punitive, or other liability under APP Terms for
        uninterrupted access or use of the APP by factors beyond the NCP’s
        reasonable control including, without limitation, the unavailability,
        inoperability or interruption of the internet or other
        telecommunications services or as a result of any scheduled maintenance
        or other service work carried out on APP. The user acknowledges that all
        such computer software and/or hardware as and where applicable is
        provided on “AS IS BASIS".
      </p>
      <p>
        User agrees that NCP at no time shall be responsible or liable for any
        User Submissions posted, stored or uploaded by you or anyone else acting
        on your behalf or any third party, or for any loss or damage thereto,
        NCP at no time shall be liable for any mistakes, defamation, libel,
        omissions, falsehoods, obscenity, pornography or profanity you may
        encounter. You agree that your use of Interactive Areas is at your own
        risk. Implementation of the APP Terms set out here is the sole
        responsibility of NCP, and failure to enforce such rules in some
        instances does not constitute a waiver of NCP’s right to enforce such
        rules in other instances. In addition, these rules do not create any
        private right of action on the part of any third party or any reasonable
        expectation that the APP will not contain any content that is prohibited
        by such rules. By providing Interactive User interface, NCP does not
        attract liability for any statements, representations or User
        Submissions provided by its users in any public forum, blog or other
        Interactive Area. NCP reserves the right, and has absolute discretion,
        to remove, screen or edit any User Submission posted or stored on the
        APP at any time and for any reason without notice, and you are solely
        responsible for creating backup copies of and replacing any User
        Submission you post or store on the APP at your sole cost and expense.
        Any use of the Interactive Areas or other portions of the APPs in
        violation of the foregoing violates these APP Terms and may result in,
        among other things, termination or suspension of your rights to use the
        Interactive Areas and/or the APP.
      </p>
      <h2>J. INDEMNIFICATION AND LIABILITY</h2>
      <p>
        You as user shall be liable towards, and compensate, indemnify and hold
        NCP , its affiliates or any person working under it harmless from and
        against any direct or indirect damages, liabilities, obligations, costs,
        claims of any kind, interest, penalties, legal proceedings and expenses
        (including, without limitation, reasonable attorneys’ fees and expenses)
        actually paid, suffered or incurred by NCP due to the breach of the APP
        Terms, any act or omission, misuse, abuse of the APP access, fraudulent
        acts, wilful misconduct of the user or Any person acting under such
        user. User shall be liable for any claims arising due to fraudulent
        transaction, abuse or misuse of the APP and agrees to indemnify and hold
        harmless NCP against such claims, loss or damage.
      </p>
      <h2>K. GOVERNING LAWS AND DISPUTE RESOLUTION:</h2>
      <p>
        These APP Terms including any dispute of whatsoever nature arising out
        of the use, access, operation, interpretation, or any effect related
        directly or indirectly arising out of the APP and the APP Property shall
        be exclusively governed by the laws of INDIA and user expressly agrees
        to the exclusive jurisdiction of courts of Mumbai.
      </p>
      <h2>L. GENERAL</h2>
      <ul>
        <li>
          No course of dealing, failure by either party to require the strict
          performance of any obligation assumed by the other hereunder, or
          failure by either party to exercise any right or remedy to which it is
          entitled, shall constitute a waiver or cause a diminution of the
          rights or obligations provided under APP Terms. No provision of APP
          Terms shall be deemed to have been waived by any act or knowledge of
          either party, but only by a written instrument signed by a duly
          authorized representative of the party to be bound thereby. Waiver by
          either party of any default shall not constitute a waiver of any other
          or subsequent default.
        </li>
        <li>
          Notwithstanding anything as mentioned or specified in the APP Terms,
          NCP reserve the right to terminate or withdraw your license to use the
          APP at any time without prior notice of the same.
        </li>
        <li>
          If any one or more provisions contained in this agreement or any
          document executed in connection herewith shall be invalid, illegal, or
          unenforceable, the remaining provisions contained herein shall not in
          any way be affected or impaired.
        </li>
        <li>
          User acknowledges that the remedy at law for any breach or violation
          of any provision of APP Terms may not be sufficient and a breach may
          cause continuing and irreparable loss, harm or damage to good will,
          name, reputation and credibility of NCP. User therefore agree that NCP
          shall be entitled to obtain injunctive relief, or any other
          restraining or any other appropriate order against the user in the
          event of any threat or disclosure of confidential Information.
        </li>
        <li>
          Nothing in APP Terms shall be construed as to create the relationship
          of employer-employee, partners, collaborators, joint-venture or
          principal-agent between user and NCP hereto. The parties shall be
          independent entities and neither party shall bind the other by its
          acts, deeds or omissions.
        </li>
        <li>
          User agrees that all notices under these APP Terms from the user shall
          be in writing, sent by facsimile or first-class registered or recorded
          delivery post to the NCP at its registered office or at such other
          address of which NCP shall have given notice as aforesaid, and marked
          for the attention of that NCP designated agent as mentioned above in
          the APP Terms. The date of service shall be deemed to be the day
          following the day on which the notice was transmitted or posted as the
          case may be.
        </li>
      </ul>
      <p>
        In addition to the APP Terms, user agrees that the products and services
        of NCP are subject to the privacy policy at APP and the website
        www.ncp.org.in
      </p>
    </div>
  );
};
