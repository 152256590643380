import { AntInput, RTEditor } from 'components/CreateAntFields/CreateAntField'
import Modal from 'components/Modal'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import { FastField, Formik, Form } from 'formik'
import { getProductDetailInitialValues } from 'views/Merchandise/validate'
import { Button } from 'antd';
import FormMenu from 'components/FormMenu';
import { useState } from 'react';
import { ENGLISH, HINDI } from "constants/language";
import HindiLanguageForm from "./FormLanguages/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguages/MarathiLanguageForm";
import { createPayload } from 'views/Merchandise/utils'
import { PRODUCT_UPDATE_SCHEMA } from 'views/Merchandise/validate'

function UpdateProductForm({
  productDetailModal,
  categories,
  productDetail,
  setProductDetailModal,
  updateData,
  fetchDataForTable,
}) {
  const [submit, setSubmit] = useState(false)
  const [language, setLanguage] = useState("en");
  const productInitialValues = getProductDetailInitialValues(productDetail)
  
  const hindiFormError = (errors) => submit && errors?.data?.hi;
  const englishFormError = (errors) => submit && (errors?.data?.en || errors?.categories);
  const marathiFormError = (errors) => submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div>
      <Modal
        width="540px"
        title={
          <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
            Update Product
          </p>
        }
        destroyOnClose
        onCancel={() => {
          setProductDetailModal(false)
        }}
        visible={productDetailModal}
      >
        <div
          style={{
            width: "85%",
            marginLeft: "40px",
          }}
          className="add-menu"
        >
          <Formik
            enableReinitialize
            initialValues={productInitialValues}
            onSubmit={(values, { setSubmitting, validateForm }) => {
              const payload = createPayload("products", values);
              let request = updateData(
                "products",
                productDetail.id,
                payload
              );
              request
                .then((res) => {
                  if (res?.status === 200) {
                    setProductDetailModal(false);
                    setSubmitting(false);
                    fetchDataForTable("products", productDetail.id);
                  }
                })
                .catch((e) => {
                  alert("ss");
                })
                .finally((e) => {
                  setSubmitting(false);
                });
            }}
            validationSchema={PRODUCT_UPDATE_SCHEMA}
          >
            {({values, isSubmitting, errors, ...rest}) => (
              <div className='form-wrapper'>
                <FormMenu
                  language={language}
                  handleFormLanguage={handleFormLanguage}
                  hindiFormError={hindiFormError(errors)}
                  englishFormError={englishFormError(errors)}
                  marathiFormError={marathiFormError(errors)}
                />
                <Form>
                  <div className='add-form'>
                    {language === ENGLISH ? (
                      <>
                        <p className="mb-8 field-label">Name</p>
                        <FastField
                          value={values.name}
                          name="data[en].name"
                          type="text"
                          suffix={
                            <div>
                              {100 - (values?.data?.[ENGLISH]?.name?.length || 0)}{" "}
                              letters
                            </div>
                          }
                          component={AntInput}
                          placeholder={"Enter Product name"}
                          maxLength={100}
                        />
                        <p className="mt-10 field-label">Category</p>
                        <FastField
                          value={values.categories}
                          filterOption={false}
                          showSearch={false}
                          name="categories"
                          selectOptions={categories}
                          optionValueKey="name"
                          optionNameKey="id"
                          component={MultiSelect}
                          mode={"multiple"}
                          showArrow={true}
                          placeholder={"Select category (can multi-select)"}
                          language="en"
                          disabled={false}
                        />
                        <p className="field-label">Product description</p>
                        <FastField
                          value={values.description}
                          name="data[en].description"
                          type="editor"
                          component={RTEditor}
                          characterLimit={20000}
                          placeholder={"Enter details here"}
                        />
                      </>
                    ) : language === HINDI ? (
                      <HindiLanguageForm values={values?.data} />
                    ) : (
                      <MarathiLanguageForm values={values?.data} />
                    )}
                    <Button
                      loading={isSubmitting}
                      className="submit-btn mt-60"
                      type="primary"
                      htmlType="submit"
                      onClick={() => setSubmit(true)}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  )
}

export default UpdateProductForm