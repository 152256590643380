import { ColumnHeaderWithSorter } from "components/ColumnHeaderWithSorter";
import { DateCell } from "components/TableCells/DateCell";
import { ToggleCell } from "components/TableCells/ToggleCell";
import { TextCell } from "components/TableCells/TextCell";
import { COLUMN_ACTIONS } from "../../constants/dummyData";
import { CADRES_FILTERS } from "./utils";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { ImageCell } from "components/TableCells/ImageCell";
import { TrashIcon } from "assets/svgs";

export const returnCadreColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Name"}
        sortKey={`userDetails.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    align: "left",
    render: (rowData) => <TextCell data={rowData?.userDetails?.name} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Registered on"}
        sortKey={"registeredOn"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    render: (rowData) => <DateCell data={rowData?.updatedAt} />,
  },
  {
    title: "Designation Name",
    key: "designationName",
    render: (rowData, data, index) => (
      <TextCell data={rowData?.designation?.[languageId]?.title} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Created on"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    render: (rowData) => <DateCell data={rowData?.createdAt} />,
  },
  {
    title: "Current status",
    key: "approvalStatus",
    filters: [
      {
        text: CADRES_FILTERS.PENDING,
        value: CADRES_FILTERS.PENDING,
      },
      {
        text: CADRES_FILTERS.APPROVED,
        value: CADRES_FILTERS.APPROVED,
      },
      {
        text: CADRES_FILTERS.REJECTED,
        value: CADRES_FILTERS.REJECTED,
      },
      {
        text: CADRES_FILTERS.REGISTERED,
        value: CADRES_FILTERS.REGISTERED,
      },
    ],
    filterMultiple: false,
    render: (rowData, data, index) => (
      <TextCell data={rowData?.approvalStatus} />
    ),
  },
  {
    title: "Mobile Number",
    key: "mobileNumber",
    render: (rowData, data, index) => (
      <TextCell data={rowData?.userDetails?.phoneNo} />
    ),
  },
  {
    title: "Status",
    align: "center",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    render: (rowData) => (
      <Button
        icon={<EyeOutlined />}
        style={{
          background: "#273054",
          color: "#FFFFFF",
          width: 44,
        }}
        onClick={(e) => {
          e.stopPropagation();
          return (
            performColumnAction &&
            performColumnAction(COLUMN_ACTIONS.ACTIVE_DATA, rowData.userId)
          );
        }}
      />
    ),
  },
];

export const returnDesignationColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.title} />,
  },
  {
    title: "Registered",
    align: "center",
    render: (rowData) => {
      const count =
        rowData?.statusCounts?.find(
          (item) => item?.approvalStatus === CADRES_FILTERS.REGISTERED
        )?.count || "0";
      return <TextCell data={count} />;
    },
  },
  {
    title: "Pending",
    align: "center",
    render: (rowData) => {
      const count =
        rowData?.statusCounts?.find(
          (item) => item?.approvalStatus === CADRES_FILTERS.PENDING
        )?.count || "0";
      return <TextCell data={count} />;
    },
  },
  {
    title: "Rejected",
    align: "center",
    render: (rowData) => {
      const count =
        rowData?.statusCounts?.find(
          (item) => item?.approvalStatus === CADRES_FILTERS.REJECTED
        )?.count || "0";
      return <TextCell data={count} />;
    },
  },
  {
    title: "Approved",
    align: "center",
    render: (rowData) => {
      const count =
        rowData?.statusCounts?.find(
          (item) => item?.approvalStatus === CADRES_FILTERS.APPROVED
        )?.count || "0";
      return <TextCell data={count} />;
    },
  },
  {
    title: "Status",
    align: "center",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnDivisionColumns = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Division name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.name} />,
  },
];

export const returnDistrictColumns = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"District name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "5%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];

export const returnVidhanSabhaColumn = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Vidhan sabha name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];

export const returnTalukaColumns = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Taluka name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];

export const returnCouncilColumns = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Council name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];

export const returnPanchyatColumn = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Panchyat name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];

export const returnCommonCadreForm = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];

export const returnWardsColumn = ({ onSort, sortColumn, languageId }) => [
  {
    title: "S.no.",
    // width: "4%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    // width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
  {
    title: "Type",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.type} />,
  },
];

export const returnSurveyColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
  deleteColumn,
}) => [
  {
    title: "Image",
    width: "10%",
    render: (rowData, data, index) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        className="square-img"
        data={rowData?.media?.link}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.title} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "Status",
    align: "center",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];
