export const ADD_DATA = `ADD_DATA`;
export const SET_DATA = `SET_DATA`;
export const SET_TAG = `SET_TAG`;
export const UPDATE_DATA = `UPDATE_DATA`;
export const FETCHING_DATA = `FETCHING_DATA`;
export const FETCHING_DATA_FAILED = `FETCHING_DATA_FAILED`;
export const UPDATE_FILTERS = `UPDATE_FILTERS`;
export const SET_FORM_DATA = `SET_FORM_DATA`;
export const SET_META_DATA = `SET_META_DATA`;
export const SET_UTILS = `SET_UTILS`;
export const SET_DROPDOWN_DATA = `SET_DROPDOWN_DATA`;
