import { Col, Divider, Row, Tooltip, Typography } from "antd";
import { UserCell } from "components/TableCells/UserCell";
import { connect } from "react-redux";
import * as RootActions from "store/root/actions";
import { bindActionCreators } from "redux";
import { useEffect, useState } from "react";
import "./QuestionsToLeaders.scss";
import moment from "moment";
import { CustomPagination } from "components/CustomPagination";
import { generateFilterUrl } from "utils/helpers";
import { Empty } from "antd";
import { UploadIcon } from "assets/svgs";

const QuestionsToLeaders = (props) => {
  const [questions, setQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  //const [sortOrder, setSortOrder] = useState("ASC");

  let innerTab = "questionsToLeaders";
  let leaderId = props?.id;

  useEffect(() => {
    const filters = {
      leaderId: leaderId,
    };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let { count, list } = props?.[innerTab];
    setTotalQuestions(count);
    setQuestions(list);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props[innerTab], props]);

  const applyFilters = (updates = {}) => {
    const { filters } = props?.[innerTab];
    let apiFilters = {
      questionTypeId: leaderId,
      ...filters,
      ...updates,
    };
    let { urlParams } = generateFilterUrl(apiFilters);
    props.updateFilters(innerTab, apiFilters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  // const handleSort = () => {
  //   let order = sortOrder === "ASC" ? "DESC" : "ASC";
  //   setSortOrder(order);
  //   let sortParams = { query: ["createdAt", order] };
  //   applyFilters(sortParams);
  //   // setSortedData(sortedData);
  // };

  const downloadTableData = async () => {
    const filters = {
      leaderId: leaderId,
      download: true,
    };
    let { urlParams } = generateFilterUrl(filters);
    let response = await props.downloadExportedData(innerTab, urlParams);
    let data = response?.data?.["userSheetUrl"];
    window.open(data, "_self");
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  return (
    <div className="questions-form ml-40 mt-40">
      {questions?.length ? (
        <>
          <Row className="header">
            <Col className="count" span={12}>
              <p>{totalQuestions} Question</p>
            </Col>
            <Col
              className="align-right justify-center sort-wrapper cursor-not-allowed"
              span={12}
            >
              <span>Sort by date</span>
            </Col>
          </Row>
          <div className="questions-table">
            {questions.map((ques, index) => (
              <Typography key={index}>
                <p>Question {index + 1}</p>
                <Row className="questions-cell">
                  <Col span={12}>
                    <UserCell
                      data={{
                        ...ques?.creator,
                        email: ques?.creator?.userType || "",
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <p className="align-right date-wrapper">
                      Sent:{" "}
                      {moment(ques?.createdAt).format("Do MMM, YY hh:mm a")}
                    </p>
                  </Col>
                </Row>
                <p className="question m-0">{ques?.question}</p>
                <Divider />
              </Typography>
            ))}
          </div>
          <div className="pagination mt-20">
            <Tooltip title={"Download data"}>
              <div
                onClick={downloadTableData}
                className={"square-button coming-soon"}
              >
                <UploadIcon />
              </div>
            </Tooltip>
            <CustomPagination
              current={props?.[innerTab]?.["filters"]?.page + 1}
              totalCount={props?.[innerTab]?.count}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="no-data">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};

QuestionsToLeaders.propTypes = {};

const mapStateToProps = ({ questionsToLeaders, leaders }) => ({
  questionsToLeaders,
  leaders,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  downloadExportedData: bindActionCreators(
    RootActions.downloadExportedData,
    dispatch
  ),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsToLeaders);
