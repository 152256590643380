import { Button, Progress, Upload } from "antd";
import { Field, Form } from "formik";
import React, { useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
} from "utils/helpers";
import ImageEditor from "components/ImageEditor";
import { UploadButton } from "components/UploadButtons";

const AddSurveyForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  taskType,
  points,
  errors,
  touched,
  access,
  organisations,
  committees,
  posts,
  states,
  districts,
  divisions,
  taluka,
  vidhanSabha,
  municipalCouncil,
  nagarPanchayat,
  wards,
  prabhag,
  submitButtonLabel,
  designations,
  onSearch,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Image, setImage] = useState(values?.media?.link);
  const [uploadProgress, setUploadProgress] = useState(0);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  const handleProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadProgress(percent);
      },
    };
    return options;
  };

  const handleChange = (info) => {
    let imageValidation = beforeUpload(info.file.originFileObj);
    handleProgress();

    if (imageValidation) {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <div>
              <p className="mt-24 field-label">Cover Image</p>
              {Image ? (
                <ImageEditor
                  image={Image}
                  onDelete={() => {
                    setFieldValue("mediaId", "");
                    setImage("");
                    setUploadProgress(0);
                  }}
                  isEditing={uploadProgress}
                  handleEditing={(status) => {
                    if (status && uploadProgress) {
                      setUploadProgress(0);
                    }
                  }}
                  onSave={async (url) => {
                    let file = dataURLtoFile(url, `image_${Math.random()}.png`);
                    let response = await uploadImage(file, handleProgress());
                    if (response) {
                      setFieldValue("mediaId", response);
                      setImage(url);
                      setLoading(false);
                    }
                  }}
                  width={220}
                />
              ) : (
                <Upload
                  name="mediaId"
                  listType={`picture-card ${
                    hasError("mediaId") ? "error" : ""
                  }`}
                  className="picture-uploader"
                  showUploadList={false}
                  // action={handleUpload}
                  beforeUpload={beforeUpload}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleChange}
                  progress={
                    <Progress percent={uploadProgress} showInfo={true} />
                  }
                >
                  {
                    <UploadButton
                      width="350 : 350"
                      ratio="1 : 1"
                      loading={loading}
                    />
                  }
                </Upload>
              )}
              {uploadProgress === 100 && !hasError("mediaId") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="progress-bar">
                  <Progress percent={uploadProgress} showInfo={true} />
                </div>
              )}
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {hasError("mediaId")}
              </div>
              <p className="mb-8 field-label">Survey title</p>
              <Field
                value={values.title}
                name="data.en.title"
                type="text"
                component={AntInput}
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.title?.length || 0)}
                    &nbsp;letters
                  </div>
                }
                placeholder={"Enter designation title"}
              />
              <p className="mb-8 field-label">Cell/Org you are Belong to?</p>
              <Field
                value={values.organisationId}
                name="organisationId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={organisations}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select any Cell/Org"
              />
              <p className="mb-8 field-label">Committee?</p>
              <Field
                value={values.committeeId}
                name="committeeId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={committees}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Committee"
              />
              <p className="mb-8 field-label">Posts</p>
              <Field
                value={values.postId}
                name="postId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={posts}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Post"
              />
              <p className="mb-8 field-label">State</p>
              <Field
                value={values.stateId}
                name="stateId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={states}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select States"
              />
              <p className="mb-8 field-label">Division</p>
              <Field
                value={values.divisionId}
                name="divisionId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={divisions}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Divisions"
              />
              <p className="mb-8 field-label">District</p>
              <Field
                value={values.districtId}
                name="districtId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={districts}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Districts"
              />
              <p className="mb-8 field-label">Vidhansabha</p>
              <Field
                value={values.vidhansabhaId}
                name="vidhansabhaId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={vidhanSabha}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Vidhan Sabha"
              />
              <p className="mb-8 field-label">Taluka</p>

              <Field
                value={values.talukaId}
                name="talukaId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={taluka}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Taluka"
              />

              <p className="mb-8 field-label">Municipal Council</p>

              <Field
                value={values.municipalcouncilId}
                name="municipalcouncilId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={municipalCouncil}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Municipal Councils"
              />
              <p className="mb-8 field-label">Nagar Panchayat</p>
              <Field
                value={values.nagarpanchayatId}
                name="nagarpanchayatId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={nagarPanchayat}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Nagar Panchayat"
              />
              <p className="mb-8 field-label">Prabhag</p>
              <Field
                value={values.prabhagId}
                name="prabhagId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={prabhag}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select Prabhag"
              />

              <p className="mb-8 field-label">Wards</p>
              <Field
                value={values.wardId}
                name="wardId"
                optionValueKey="name"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                selectOptions={wards}
                width="425px"
                isPural={false}
                showArrow={true}
                className="multi-select"
                placeholder="Select any Ward"
              />
            </div>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data?.[HINDI]} />
          ) : (
            <MarathiLanguageForm values={values?.data?.[MARATHI]} />
          )}
          <Button
            loading={isSubmitting}
            className="submit-btn mt-40 "
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddSurveyForm;
