import axios from "axios";
import API_URL from "./apiUrl";
import { fetchAccessToken } from "../../utils/helpers/index";

class AxiosSingleton {
  static instance = undefined;
  static createInstance(baseUrl, userToken) {
    const token = userToken || fetchAccessToken();
    return axios.create({
      baseURL: API_URL,
      timeout: 15000,
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  static getInstance() {
    if (!AxiosSingleton.instance) {
      let baseUrl = "";
      AxiosSingleton.instance = AxiosSingleton.createInstance(baseUrl);
    }
    return AxiosSingleton.instance;
  }

  static resetInstance() {
    AxiosSingleton.instance = undefined;
  }
}

export default AxiosSingleton;
