import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { Field } from "formik";

const MarathiLanguageForm = ({ values, categoryTags, categoryTagValues }) => {
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={values?.title}
        name="data[mr].title"
        type="text"
        suffix={<div>{50 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Short Summary</p>
      <Field
        value={values?.shortSummary}
        name="data[mr].shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={1000}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
      <p className="mb-8 mt-10 field-label">Category Tags</p>
      <Field
        value={categoryTagValues}
        name="categoryTags"
        optionValueKey="tagName"
        optionNameKey="id"
        disabled={true}
        filterOption={false}
        showSearch={false}
        component={MultiSelect}
        mode={"multiple"}
        selectOptions={categoryTags}
        showArrow={true}
        placeholder={"Select content category tags"}
      />
    </>
  );
};

export default MarathiLanguageForm;
