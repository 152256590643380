import { ReadmoreIcon } from "assets/svgs";
import React from "react";
import { Tooltip } from "antd";
import "./index.scss";

const TEXT_LENGTH_THRESHOLD = 35;

const ReadMore = ({
  className,
  maxLength = TEXT_LENGTH_THRESHOLD,
  children,
}) => {
  const text = children;

  let truncatedText = text?.slice(0, maxLength);

  return (
    <p className={className}>
      {truncatedText === text ? (
        text
      ) : (
        <>
          {truncatedText}{" "}
          <Tooltip title={text}>
            ...
            <ReadmoreIcon />
          </Tooltip>
        </>
      )}
    </p>
  );
};

export default React.memo(ReadMore);
