import { FastField, Form } from "formik";
import { AntInput, AntAutoComplete } from "components/CreateAntFields/CreateAntField";
import { Button } from "antd";
import Date from "components/Date/date";

const CourierPartners = [
  {
    value: 'DTDC',
    label: 'DTDC',
  },
  {
    value: 'FedEx',
    label: 'FedEx',
  },
  {
    value: 'Professional',
    label: 'Professional'
  }
]

function OrderShipForm({
  values,
  isSubmitting,
}) {
  return (
    <div className="form-wrapper">
      <Form>
        <div className="add-form">
          <p className="mb-8 mt-10 field-label">Courier partner</p>
          <FastField
            name="courierId"
            component={AntAutoComplete}
            value={values?.courierId}
            options={CourierPartners}
            placeholder="Select courier partner"
            filterOption={(inputValue, option) =>
              option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
            }
          />
          <p className="mb-8 field-label">Courier tracking id</p>
          <FastField
            value={values?.courierTrackingId}
            name="courierTrackingId"
            type="text"
            component={AntInput}
            placeholder={"Enter courier tracking id"}
            maxLength={50}
          />
          <p className="mb-8 field-label">ETA arrival time</p>
          <FastField
            defaultValue={values?.courierDeliveryEstimatedDate}
            name="courierDeliveryEstimatedDate"
            showTime={true}
            format="DD/MM/YY HH:mm"
            component={Date}
            showNow={false}
            placeholder={"dd/mm/yy hh:mm"}
          />
          <div
            className='absolute-submit-btn'
          >
            <Button
              loading={isSubmitting}
              className="submit-btn"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default OrderShipForm