const DashboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd" opacity=".5">
      <g>
        <g>
          <g transform="translate(-247 -20) translate(247 19) translate(0 1)">
            <circle cx="10" cy="10" r="10" fill="#29292A" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M6 7v6c0 .552.448 1 1 1h1V6H7c-.552 0-1 .448-1 1zm7-1H8.667v2H14V7c0-.552-.448-1-1-1zm-4.333 8H13c.552 0 1-.448 1-1V8.667H8.667V14z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ContentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-452 -20) translate(247 19) translate(205 1)">
            <circle cx="10" cy="10" r="10" fill="#29292A" />
            <path
              fill="#949494"
              fillRule="nonzero"
              d="M11.667 6v2h2l-2-2zM11 6H7.667C7.298 6 7 6.298 7 6.667v6.666c0 .369.298.667.667.667H13c.368 0 .667-.298.667-.667V8.667H11V6zm1 6.667H8.667c-.184 0-.334-.15-.334-.334 0-.184.15-.333.334-.333H12c.184 0 .333.15.333.333 0 .184-.149.334-.333.334zm0-2.334c.184 0 .333.15.333.334 0 .184-.149.333-.333.333H8.667c-.184 0-.334-.15-.334-.333 0-.184.15-.334.334-.334H12z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const TasksandRewardsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-553 -19) translate(247 19) translate(306)">
            <circle cx="11" cy="11" r="11" fill="#29292A" />
            <path
              fill="#949494"
              fillRule="nonzero"
              d="M14.769 12.95l-.962-.412-2.583.923-.224.08-.224-.08-2.583-.923-.962.412c-.144.062-.236.206-.23.363.004.157.104.295.252.347L11 15l3.747-1.34c.148-.052.248-.19.253-.347.005-.157-.087-.3-.231-.363zM7.253 9.327L11 10.667l3.747-1.34c.148-.053.248-.19.253-.347.005-.157-.087-.301-.231-.363L11 7 7.231 8.617c-.144.062-.236.206-.23.363.004.156.104.294.252.347zm7.516 1.457l-.962-.413-2.583.923-.224.08-.224-.08-2.583-.923-.962.413c-.144.062-.236.205-.23.362.004.157.104.295.252.348L11 12.834l3.747-1.34c.148-.053.248-.191.253-.348.005-.157-.087-.3-.231-.362z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const PartyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-783.000000, -19.000000) translate(183.000000, 19.000000) translate(600.000000, 0.000000)">
            <circle cx="11" cy="11" r="11" fill="#29292A" />
            <g>
              <path
                fill="#FFF"
                d="M5.48.87c0 .476-.673.74-1.48.74-.807 0-1.443-.264-1.443-.74 0-.477.654-.863 1.462-.863.807 0 1.461.386 1.461.862"
                transform="translate(7.000000, 6.000000)"
              />
              <path
                fill="#FFF"
                d="M5.37 1.519c.727.264 1.32.674 1.77 1.236.523.653.915 1.474.854 2.66-.044.845-.389 1.536-.815 2.075-.435.55-.991.979-1.667 1.236.12.28.27.53.388.812.289-.013.613.237.375.462h-.88c-.139-.094-.085-.317.053-.375l-.388-.749c-.598.189-1.545.175-2.146 0-.065.035-.086.116-.116.175-.09.175-.192.38-.298.562.05.065.145.116.143.225-.002.07-.042.108-.091.162h-.88c-.23-.224.082-.467.363-.462.149-.26.266-.55.414-.812C1.73 8.4 1.124 7.956.69 7.327c-.43-.623-.739-1.4-.685-2.435C.051 4.004.422 3.3.86 2.755c.453-.566 1.047-.978 1.784-1.236-.007-.057-.062-.096-.091-.137-.073-.106-.15-.283-.13-.475.056-.527.76-.854 1.358-.9.853-.064 1.744.29 1.823.888.036.278-.1.46-.233.624"
                transform="translate(7.000000, 6.000000)"
              />
              <path
                stroke="#949494"
                strokeWidth=".5"
                d="M5.37 1.519c.727.264 1.32.674 1.77 1.236.523.653.915 1.474.854 2.66-.044.845-.389 1.536-.815 2.075-.435.55-.991.979-1.667 1.236.12.28.27.53.388.812.289-.013.613.237.375.462h-.88c-.139-.094-.085-.317.053-.375l-.388-.749c-.598.189-1.545.175-2.146 0-.065.035-.086.116-.116.175-.09.175-.192.38-.298.562.05.065.145.116.143.225-.002.07-.042.108-.091.162h-.88c-.23-.224.082-.467.363-.462.149-.26.266-.55.414-.812C1.73 8.4 1.124 7.956.69 7.327c-.43-.623-.739-1.4-.685-2.435C.051 4.004.422 3.3.86 2.755c.453-.566 1.047-.978 1.784-1.236-.007-.057-.062-.096-.091-.137-.073-.106-.15-.283-.13-.475.056-.527.76-.854 1.358-.9.853-.064 1.744.29 1.823.888.036.278-.1.46-.233.624z"
                transform="translate(7.000000, 6.000000)"
              />
              <path
                fill="#29292A"
                d="M5.37 1.519c.727.264 1.32.674 1.77 1.236.523.653.915 1.474.854 2.66-.044.845-.389 1.536-.815 2.075-.435.55-.991.979-1.667 1.236.12.28.27.53.388.812.289-.013.613.237.375.462h-.88c-.139-.094-.085-.317.053-.375l-.388-.749c-.598.189-1.545.175-2.146 0-.065.035-.086.116-.116.175-.09.175-.192.38-.298.562.05.065.145.116.143.225-.002.07-.042.108-.091.162h-.88c-.23-.224.082-.467.363-.462.149-.26.266-.55.414-.812C1.73 8.4 1.124 7.956.69 7.327c-.43-.623-.739-1.4-.685-2.435C.051 4.004.422 3.3.86 2.755c.453-.566 1.047-.978 1.784-1.236-.007-.057-.062-.096-.091-.137-.073-.106-.15-.283-.13-.475.056-.527.76-.854 1.358-.9.853-.064 1.744.29 1.823.888.036.278-.1.46-.233.624"
                transform="translate(7.000000, 6.000000)"
              />
              <path
                fill="#949494"
                d="M5.409.77c-.2-.435-.93-.655-1.526-.613-.442.032-.894.168-1.137.425-.218.23-.238.693.09.875.294-.104.65-.147 1.021-.175-.007-.134.017-.3-.013-.413C3.576.891 3.4.866 3.366.707c-.04-.192.067-.272.156-.3.134-.041.321-.012.542-.012.197 0 .415-.031.53.025.154.075.162.4-.039.45-.12.03-.23-.023-.374 0-.03.113-.005.278-.013.412.371.028.714.083 1.021.175.202-.115.337-.433.22-.688M2.978 1.744c-.718.2-1.285.586-1.693 1.024C.731 3.363.258 4.226.342 5.366c.078 1.07.535 1.82 1.176 2.399.635.572 1.564.97 2.714.923.538-.021 1.04-.174 1.435-.374.408-.206.74-.47 1.034-.775.573-.59 1.039-1.488.957-2.585-.08-1.074-.545-1.867-1.164-2.411-.587-.517-1.545-1.002-2.714-.924-.272.018-.547.054-.802.125"
                transform="translate(7.000000, 6.000000)"
              />
              <path
                fill="#29292A"
                d="M3.922 1.906c.198-.051.342.06.323.25-.027.268-.454.264-.49.013-.02-.13.024-.226.167-.263M2.63 2.38c.244-.08.324.277.103.313-.183.03-.32-.241-.104-.312M5.435 2.543c.106-.025.21.028.22.138.017.218-.353.26-.35.012.001-.081.034-.128.13-.15M1.414 3.567c.069-.017.13-.009.169.025.057.05.074.25-.052.288-.226.067-.341-.256-.117-.313M6.688 3.73c.095-.014.179.024.194.125.035.227-.285.263-.336.1-.036-.116.008-.205.142-.225M6.3 4.105c-.187.243-.406.456-.594.7.002-.107-.016-.269-.065-.363-.451.217-.885.452-1.331.674.122.48-.672.476-.543 0-.335-.205-.686-.394-1.021-.6-.055.064-.074.163-.103.25-.158-.201-.282-.435-.44-.637.26.004.55.085.827.113-.093.047-.172.109-.232.187.327.209.683.39 1.008.6.091-.106.365-.12.452 0 .448-.213.866-.454 1.318-.662-.015-.11-.151-.17-.167-.238.294-.01.59-.02.892-.024M.756 4.93c.189-.043.327.06.31.249-.029.303-.525.264-.491-.063.007-.073.048-.158.18-.187M7.063 4.93c.054-.014.1-.023.142-.013.234.05.214.458-.051.474-.266.017-.385-.39-.09-.462M1.22 6.265c.172-.059.279.07.234.213-.047.15-.31.133-.337-.025-.018-.106.02-.159.104-.188M6.495 6.428c.137-.032.211.034.219.137.016.21-.352.257-.349.013.001-.081.034-.128.13-.15M2.475 7.452c.125-.03.212.028.22.138.014.22-.354.253-.35.012.002-.076.036-.127.13-.15M5.623 7.355c.259-.04.233.326.026.325-.194-.001-.244-.29-.026-.325M3.987 7.951c.376-.08.382.44.09.463-.28.021-.395-.397-.09-.463"
                transform="translate(7.000000, 6.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ComponentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-671 -19) translate(247 19) translate(424)">
            <circle cx="11" cy="11" r="11" fill="#29292A" />
            <path
              fill="#949494"
              fillRule="nonzero"
              d="M9.4 12H7.6c-.16 0-.312.063-.424.176-.113.112-.176.265-.176.424v1.8c0 .16.063.312.176.424.112.113.265.176.424.176h1.8c.331 0 .6-.269.6-.6v-1.8c0-.331-.269-.6-.6-.6zm0-4H7.6c-.16 0-.312.063-.424.176C7.063 8.288 7 8.44 7 8.6v1.8c0 .16.063.312.176.424.112.113.265.176.424.176h1.8c.331 0 .6-.269.6-.6V8.6c0-.331-.269-.6-.6-.6zm5-1h-1.8c-.16 0-.312.063-.424.176-.113.112-.176.265-.176.424v1.8c0 .16.063.312.176.424.112.113.265.176.424.176h1.8c.331 0 .6-.269.6-.6V7.6c0-.331-.269-.6-.6-.6zm-1.333 5h-1.8c-.16 0-.312.063-.425.176-.112.112-.175.265-.175.424v1.8c0 .16.063.312.175.424.113.113.266.176.425.176h1.8c.331 0 .6-.269.6-.6v-1.8c0-.331-.269-.6-.6-.6z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const TagCloseButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill="#4B68EF"
      d="M7 0C3.134 0 0 3.134 0 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm2.746 8.92c.147.148.205.363.15.564-.053.202-.21.359-.412.413-.201.054-.416-.004-.563-.151L7 7.825l-1.921 1.92c-.228.229-.597.229-.825 0-.228-.227-.228-.596 0-.824L6.175 7l-1.92-1.92c-.148-.148-.206-.363-.152-.564.054-.202.211-.359.413-.413.201-.054.416.004.563.151L7 6.175l1.92-1.92c.148-.15.364-.21.566-.156.203.054.361.212.415.415.054.202-.006.418-.155.565L7.825 7l1.92 1.92z"
    />
  </svg>
);

const ArrowButtonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-957 -794) translate(813 794) translate(144)">
            <rect width="26" height="26" fill="#273054" rx="4" />
            <path
              fill="#FFF"
              d="M10.713 11h4.393c.553 0 1 .448 1 1 0 .291-.126.568-.347.758l-2.186 1.883c-.374.322-.927.323-1.302.003l-2.207-1.883c-.42-.359-.47-.99-.112-1.41.19-.223.468-.351.76-.351z"
              transform="rotate(-90 12.9 13.1)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const ChevronButtonIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-242 -198) translate(201 196) translate(41 2)">
        <rect
          width="19"
          height="19"
          x=".5"
          y=".5"
          fill="#FFF"
          stroke="#E4E8F0"
          rx="2"
        />
        <path
          stroke="#7A869A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M6 9L9.945 12 14 9"
        />
      </g>
    </g>
  </svg>
);

const PanoramaImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="23"
    viewBox="0 0 31 23"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M236.958 104.778c1.427 0 2.584-1.144 2.584-2.556 0-1.411-1.157-2.555-2.584-2.555-1.426 0-2.583 1.144-2.583 2.555 0 1.412 1.157 2.556 2.583 2.556zM244.715 92c-.078 0-.155.007-.231.02-9.252 1.611-18.716 1.611-27.968 0-.076-.013-.153-.02-.23-.02-.341-.001-.668.131-.91.37-.24.237-.376.56-.376.896v20.468c0 .336.136.66.377.897s.568.37.908.369c.078 0 .155-.007.231-.02 9.252-1.611 18.716-1.611 27.968 0 .076.013.153.02.23.02.341.001.668-.131.91-.37.24-.237.376-.56.376-.896V93.266c0-.336-.136-.66-.377-.897s-.568-.37-.908-.369zm-2.59 18.733c-2.468-.338-4.955-.557-7.443-.68l-8.057-9.109-7.75 8.762v-13.44c7.713 1.06 15.537 1.06 23.25 0v14.467z"
              transform="translate(-497 -203) translate(242) translate(40 111)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const CheckBoxTick = ({ ...props }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"B298A6E0-042E-4951-B025-AA4FCF6AFD96"}</title>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke="#4B68EF"
        strokeWidth={2}
        fill="#4B68EF"
        width={16}
        height={16}
        rx={2}
      />
      <path
        d="M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 10-1.414-1.414L7 8.586 5.707 7.293z"
        fill="#FFF"
      />
    </g>
  </svg>
);
const TrashSquareButton = ({ ...props }) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"87154618-7E01-4376-AA66-655A9DBFA702"}</title>
    <g fillRule="nonzero" fill="none">
      <rect fillOpacity={0.3} fill="#000" width={30} height={30} rx={2} />
      <path
        d="M10.534 20.903c.035.617.517 1.098 1.1 1.097h6.732c.583.001 1.065-.48 1.1-1.097l.484-8.236h-9.9l.484 8.236zm9.416-10.57H18.3V9.75c0-.966-.739-1.75-1.65-1.75h-3.3c-.911 0-1.65.784-1.65 1.75v.583h-1.65c-.304 0-.55.261-.55.584 0 .322.246.583.55.583h9.9c.304 0 .55-.261.55-.583 0-.323-.246-.584-.55-.584zm-2.75 0h-4.4V9.75c0-.322.246-.583.55-.583h3.3c.304 0 .55.26.55.583v.583z"
        fill="#FFF"
      />
    </g>
  </svg>
);
const TickSquareButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g>
            <g
              fill="#000"
              fillOpacity=".3"
              transform="translate(-702 -153) translate(282 111) translate(420 42)"
            >
              <rect width="30" height="30" rx="2" />
            </g>
            <path
              fill="#FFF"
              d="M19.95 10.05c-2.002-2.002-5.013-2.6-7.629-1.517C9.705 9.616 8 12.169 8 15c0 2.831 1.705 5.384 4.321 6.467 2.616 1.083 5.627.485 7.629-1.517 2.733-2.734 2.733-7.166 0-9.9zm-1.59 2.996l-3.856 4.499-2.77-2.078c-.168-.125-.256-.33-.23-.537.024-.207.158-.386.35-.467.192-.082.413-.055.58.07l1.896 1.422 3.144-3.668c.135-.158.345-.232.55-.194.205.039.374.183.443.38.07.197.028.415-.107.573z"
              transform="translate(-702 -153) translate(282 111) translate(420 42)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const RadioButtonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#4B68EF" fillRule="nonzero">
        <path
          d="M5.64 5.643c-.954.953-1.239 2.387-.723 3.633.516 1.245 1.73 2.057 3.078 2.057 1.347 0 2.562-.812 3.077-2.057.516-1.246.23-2.68-.722-3.633-1.3-1.302-3.41-1.302-4.71 0zm8.008-3.3C10.526-.78 5.464-.78 2.342 2.343c-3.123 3.124-3.123 8.19 0 11.314 3.122 3.124 8.184 3.124 11.306 0C15.153 12.16 16 10.124 16 8s-.847-4.16-2.352-5.657zm-1.413 9.9c-1.715 1.716-4.294 2.229-6.535 1.3C3.46 12.615 2 10.427 2 8S3.459 3.385 5.7 2.457c2.24-.929 4.82-.416 6.535 1.3 1.134 1.12 1.772 2.649 1.772 4.243 0 1.594-.638 3.123-1.772 4.243z"
          transform="translate(-298 -1004) translate(242) translate(40 383) translate(16 621)"
        />
      </g>
    </g>
  </svg>
);
const ZoomOutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M12.804 2.201C10.458-.149 6.844-.677 3.924.906 1.005 2.49-.525 5.804.165 9.054c.69 3.249 3.434 5.657 6.745 5.918l-.03.028H15V7.503c.003-1.99-.788-3.897-2.196-5.302zM11.48 11.48c-2.196 2.196-5.756 2.196-7.952 0-2.196-2.196-2.196-5.756 0-7.952 2.196-2.196 5.756-2.196 7.952 0 1.063 1.05 1.662 2.481 1.662 3.976 0 1.494-.599 2.926-1.662 3.976zM8.128 6.878H4.379c-.345 0-.625.28-.625.625s.28.625.625.625h6.248c.345 0 .624-.28.624-.625s-.28-.625-.624-.625h-2.5z"
                transform="translate(-654 -335) translate(242) translate(42 111) translate(370 224)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ZoomInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M34.804 2.201c-2.346-2.35-5.96-2.878-8.88-1.295-2.92 1.583-4.45 4.898-3.76 8.148.69 3.249 3.434 5.657 6.745 5.918l-.03.028H37V7.503c.003-1.99-.788-3.897-2.196-5.302zM33.48 11.48c-2.196 2.196-5.756 2.196-7.952 0-2.196-2.196-2.196-5.756 0-7.952 2.196-2.196 5.756-2.196 7.952 0 1.063 1.05 1.662 2.481 1.662 3.976 0 1.494-.599 2.926-1.662 3.976zm-.852-4.601h-2.5v-2.5c0-.344-.28-.624-.624-.624-.345 0-.625.28-.625.625v2.499H26.38c-.345 0-.625.28-.625.625s.28.625.625.625h2.5v2.499c0 .345.279.624.624.624s.625-.28.625-.624v-2.5h2.499c.345 0 .624-.28.624-.624 0-.345-.28-.625-.624-.625z"
                transform="translate(-676 -335) translate(242) translate(42 111) translate(370 224)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const RotateLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M7.672 2.328C11.225 2.674 14 5.608 14 9.178c0 3.49-2.654 6.373-6.094 6.822v-2.326c2.14-.425 3.75-2.276 3.75-4.496 0-2.3-1.729-4.206-3.984-4.538v2.243L2.985 3.442 7.672 0v2.328zM2.704 14.65c.949.71 2.087 1.188 3.328 1.35v-2.326c-.599-.118-1.156-.35-1.648-.668l-1.68 1.644zm-1.326-1.298C.654 12.424.165 11.31 0 10.095h2.375c.122.586.357 1.131.683 1.613l-1.68 1.644zm-.05-8.285C.632 5.983.162 7.074 0 8.26h2.375c.139-.668.426-1.283.825-1.812L1.33 5.068z"
                transform="translate(-699 -334) translate(242) translate(42 111) translate(415 223)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const RotateRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M28.672 2.328C32.225 2.674 35 5.608 35 9.178c0 3.49-2.654 6.373-6.094 6.822v-2.326c2.14-.425 3.75-2.276 3.75-4.496 0-2.3-1.729-4.206-3.984-4.538v2.243l-4.687-3.441L28.672 0v2.328zM23.704 14.65c.949.71 2.087 1.188 3.328 1.35v-2.326c-.599-.118-1.156-.35-1.648-.668l-1.68 1.644zm-1.326-1.298c-.724-.928-1.213-2.042-1.378-3.257h2.375c.122.586.357 1.131.683 1.613l-1.68 1.644zm-.05-8.285c-.697.916-1.167 2.007-1.328 3.193h2.375c.139-.668.426-1.283.825-1.812l-1.871-1.38z"
                transform="translate(-720 -334) translate(242) translate(42 111) translate(415 223) matrix(-1 0 0 1 56 0)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
        <g>
          <path
            d="M90 417h-6v-6c0-1.105-.895-2-2-2s-2 .895-2 2v6h-6c-1.105 0-2 .895-2 2s.895 2 2 2h6v6c0 1.105.895 2 2 2s2-.895 2-2v-6h6c1.105 0 2-.895 2-2s-.895-2-2-2z"
            transform="translate(-314 -409) translate(242)"
          />
        </g>
      </g>
    </g>
  </svg>
);
const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 12 14"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7a869a" fillRule="nonzero">
        <g>
          <path
            d="M24.4 15v3.5H28L24.4 15zm-1.2 0h-6c-.663 0-1.2.522-1.2 1.167v11.666c0 .645.537 1.167 1.2 1.167h3.6v-2.333h-1.551c-.243 0-.462-.142-.555-.36-.093-.218-.041-.47.13-.636L22 22.583l3.176 3.088c.171.167.223.418.13.636-.093.217-.312.36-.554.36H23.2V29h3.6c.663 0 1.2-.522 1.2-1.167v-8.166h-4.8V15z"
            transform="translate(-802 -137) translate(786 122)"
          />
        </g>
      </g>
    </g>
  </svg>
);
const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
        <g>
          <path
            d="M15.583 17.916h1.75v.584c0 .644.523 1.167 1.167 1.167s1.167-.523 1.167-1.167v-2.333c0-.645-.523-1.167-1.167-1.167s-1.167.522-1.167 1.167v.583h-1.75c-.322 0-.583.26-.583.583 0 .322.261.583.583.583zm0 4.668h7.584v-1.167h-7.584c-.322 0-.583.261-.583.583 0 .322.261.584.583.584zM29 17.333c0-.322-.261-.583-.583-.583h-7.584v1.167h7.584c.322 0 .583-.262.583-.584zm-14 9.333c0 .323.261.584.583.584H18.5v-1.167h-2.917c-.322 0-.583.261-.583.583zm13.417-5.249h-1.75v-.584c0-.644-.523-1.166-1.167-1.166s-1.167.522-1.167 1.166v2.334c0 .644.523 1.166 1.167 1.166s1.167-.522 1.167-1.166v-.583h1.75c.322 0 .583-.262.583-.584 0-.322-.261-.583-.583-.583zm0 4.666H22V25.5c0-.644-.522-1.167-1.167-1.167-.644 0-1.166.523-1.166 1.167v2.333c0 .645.522 1.167 1.166 1.167.645 0 1.167-.522 1.167-1.167v-.583h6.417c.322 0 .583-.261.583-.584 0-.322-.261-.583-.583-.583z"
            transform="translate(-745 -137) translate(730 122)"
          />
        </g>
      </g>
    </g>
  </svg>
);

const CloseIcon = ({
  width = 24,
  height = 24,
  color = "#131415",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"close"}</title>
    <path
      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm4.707 15.293a1 1 0 11-1.414 1.414L12 13.414l-3.293 3.293a1 1 0 01-1.414-1.414L10.586 12 7.293 8.707a1 1 0 111.414-1.414L12 10.586l3.293-3.293a1 1 0 111.414 1.414L13.414 12l3.293 3.293z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);
const LogoutIcon = ({ color = "#5d6476", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={color}
      d="M13.967,21.3l1.866,1.867L22.5,16.5,15.833,9.833,13.967,11.7l3.467,3.467H4.5v2.667H17.366ZM25.833,4.5H7.167A2.674,2.674,0,0,0,4.5,7.167V12.5H7.167V7.167H25.833V25.833H7.167V20.5H4.5v5.333A2.674,2.674,0,0,0,7.167,28.5H25.833A2.674,2.674,0,0,0,28.5,25.833V7.167A2.674,2.674,0,0,0,25.833,4.5Z"
      transform="translate(-4.5 -4.5)"
    />
  </svg>
);

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-637.000000, -20.000000) translate(230.000000, 20.000000) translate(407.000000, 0.000000)">
            <circle cx="10" cy="10" r="10" fill="#29292A" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M10 5.5c-2.761 0-5 2.239-5 5s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5zm0 2.083c1.036 0 1.875.84 1.875 1.875 0 1.036-.84 1.875-1.875 1.875-1.036 0-1.875-.84-1.875-1.875.001-1.035.84-1.873 1.875-1.875zm0 7.084c-1.033 0-2.03-.387-2.793-1.084.353-.816 1.467-1.416 2.793-1.416 1.327 0 2.44.6 2.793 1.416-.763.697-1.76 1.083-2.793 1.084z"
              opacity=".5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const RolesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-743.000000, -20.000000) translate(230.000000, 20.000000) translate(513.000000, 0.000000)">
            <circle cx="10" cy="10" r="10" fill="#29292A" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M8.667 10.333v2C8.667 13.806 9.86 15 11.333 15 12.806 15 14 13.806 14 12.333v-2H8.667zm3.666 1c.184 0 .334.15.334.334 0 .184-.15.333-.334.333-.184 0-.333-.15-.333-.333 0-.184.15-.334.333-.334zm-2 0c.184 0 .334.15.334.334 0 .184-.15.333-.334.333-.184 0-.333-.15-.333-.333 0-.184.15-.334.333-.334zm1 2.667c-.662-.001-1.26-.394-1.525-1h3.05c-.264.606-.863.999-1.525 1zM8.525 9.667h2.808V7H6v2.333c0 1.215.823 2.276 2 2.58v-1.246H7c.265-.607.864-1 1.525-1zm1.142-1.334c.184 0 .333.15.333.334C10 8.85 9.85 9 9.667 9c-.184 0-.334-.15-.334-.333 0-.184.15-.334.334-.334zm-2 0c.184 0 .333.15.333.334C8 8.85 7.85 9 7.667 9c-.184 0-.334-.15-.334-.333 0-.184.15-.334.334-.334z"
              opacity=".5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ReadmoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#4B68EF" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M456 9c0 2.761 2.239 5 5 5s5-2.239 5-5-2.239-5-5-5-5 2.239-5 5zm4.583-2.5c0-.11.044-.217.122-.295.078-.078.184-.122.295-.122.11 0 .217.044.295.122.078.078.122.184.122.295v2.917c0 .11-.044.216-.122.294-.078.078-.184.122-.295.122-.11 0-.217-.044-.295-.122-.078-.078-.122-.184-.122-.294V6.5zm.417 5.417c-.345 0-.625-.28-.625-.625 0-.346.28-.625.625-.625s.625.28.625.625-.28.625-.625.625z"
              transform="translate(-584.000000, -340.000000) translate(41.000000, 308.000000) translate(87.000000, 28.000000) translate(461.000000, 9.000000) scale(1, -1) translate(-461.000000, -9.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const UserInfo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g
            fill="#4C68EF"
            transform="translate(-952.000000, -15.000000) translate(952.000000, 15.000000)"
          >
            <circle cx="15" cy="15" r="15" />
          </g>
          <text
            fill="#FFF"
            fontFamily="NeueHaasUnicaPro-Bold, Neue Haas Unica Pro"
            fontSize="12"
            fontWeight="bold"
            letterSpacing=".75"
            transform="translate(-952.000000, -15.000000) translate(952.000000, 15.000000)"
          >
            <tspan x="6.744" y="20">
              PT
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

const CancelCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#4B68EF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M101 4c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm2.746 8.92c.147.148.205.363.15.564-.053.202-.21.359-.412.413-.201.054-.416-.004-.563-.151L101 11.825l-1.921 1.92c-.228.229-.597.229-.825 0-.228-.227-.228-.596 0-.824L100.175 11l-1.92-1.92c-.148-.148-.206-.363-.152-.564.054-.202.211-.359.413-.413.201-.054.416.004.563.151L101 10.175l1.92-1.92c.148-.15.364-.21.566-.156.203.054.361.212.415.415.054.202-.006.418-.155.565L101.825 11l1.92 1.92z"
                    transform="translate(-500.000000, -957.000000) translate(242.000000, 0.000000) translate(40.000000, 411.000000) translate(0.000000, 454.000000) translate(0.000000, 88.000000) translate(124.000000, 0.000000)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const FontSizeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="16"
    viewBox="0 0 19 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M148.25 13.6c0-.884-.709-1.6-1.583-1.6h-11.084c-.874 0-1.583.716-1.583 1.6 0 .884.709 1.6 1.583 1.6h3.959v11.2c0 .884.709 1.6 1.583 1.6s1.583-.716 1.583-1.6V15.2h3.959c.874 0 1.583-.716 1.583-1.6zm3.167 4.8h-7.125v3.2h2.375v4.8c0 .884.709 1.6 1.583 1.6s1.583-.716 1.583-1.6v-4.8h1.584c.874 0 1.583-.716 1.583-1.6 0-.884-.709-1.6-1.583-1.6z"
                transform="translate(-432.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(16.000000, 314.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const TrashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="20"
    viewBox="0 0 11 13"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#D10000" fillRule="nonzero">
        <path
          d="M716.034 826.982c.035.572.517 1.019 1.1 1.018h6.732c.583.001 1.065-.446 1.1-1.018l.484-7.649h-9.9l.484 7.649zm9.416-9.815h-1.65v-.542c0-.897-.739-1.625-1.65-1.625h-3.3c-.911 0-1.65.728-1.65 1.625v.542h-1.65c-.304 0-.55.242-.55.541 0 .3.246.542.55.542h9.9c.304 0 .55-.243.55-.542 0-.299-.246-.541-.55-.541zm-2.75 0h-4.4v-.542c0-.3.246-.542.55-.542h3.3c.304 0 .55.243.55.542v.542z"
          transform="translate(-715.000000, -815.000000)"
        />
      </g>
    </g>
  </svg>
);

const uploadImageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="17"
    viewBox="0 0 14 17"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M42.234 44.375H44.6v4.25c0 .782.627 1.417 1.4 1.417.773 0 1.4-.635 1.4-1.417v-4.25h2.366c.424 0 .807-.26.97-.656.162-.397.072-.853-.228-1.157L46 38l-4.508 4.562c-.3.304-.39.76-.227 1.157.162.397.545.656.969.656zm9.366 7.792H40.4c-.773 0-1.4.634-1.4 1.416 0 .783.627 1.417 1.4 1.417h11.2c.773 0 1.4-.634 1.4-1.417 0-.782-.627-1.416-1.4-1.416z"
                transform="translate(-321.000000, -181.000000) translate(242.000000, 0.000000) translate(40.000000, 111.000000) translate(0.000000, 32.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const DropdownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M0 0H24V24H0z"
                    transform="translate(-709.000000, -524.000000) translate(242.000000, 0.000000) translate(40.000000, 111.000000) translate(0.000000, 370.000000) translate(0.000000, 32.000000) translate(427.000000, 11.000000)"
                  />
                  <path
                    stroke="#7A869A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.95"
                    d="M7 10L11.931 14 17 10"
                    transform="translate(-709.000000, -524.000000) translate(242.000000, 0.000000) translate(40.000000, 111.000000) translate(0.000000, 370.000000) translate(0.000000, 32.000000) translate(427.000000, 11.000000)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const AddButton = () => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>6FB578D0-5F30-4551-8633-8A6E057B8207</title>
    <g
      id="Main-screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.699999988"
    >
      <g
        id="6.3.-Add-leaders-2.2"
        transform="translate(-464.000000, -1316.000000)"
        fill="#7A869A"
        fillRule="nonzero"
      >
        <g id="Group-4" transform="translate(242.000000, 0.000000)">
          <g id="Group-25" transform="translate(40.000000, 584.000000)">
            <g id="Group-24" transform="translate(0.000000, 715.000000)">
              <g id="Group-3" transform="translate(182.000000, 15.000000)">
                <path
                  d="M12.6,7.6 L8.4,7.6 L8.4,3.4 C8.4,2.62680135 7.77319865,2 7,2 C6.22680135,2 5.6,2.62680135 5.6,3.4 L5.6,7.6 L1.4,7.6 C0.62680135,7.6 0,8.22680135 0,9 C0,9.77319865 0.62680135,10.4 1.4,10.4 L5.6,10.4 L5.6,14.6 C5.6,15.3731986 6.22680135,16 7,16 C7.77319865,16 8.4,15.3731986 8.4,14.6 L8.4,10.4 L12.6,10.4 C13.3731986,10.4 14,9.77319865 14,9 C14,8.22680135 13.3731986,7.6 12.6,7.6 Z"
                  id="Shape-Copy-2"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const DashboardNCPLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="30"
    viewBox="0 0 65 30"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <text
            fill="#FFF"
            fontFamily="NeueHaasUnicaPro-Bold, Neue Haas Unica Pro"
            fontSize="20"
            fontWeight="bold"
            letterSpacing="-.273"
            transform="translate(-39.000000, -15.000000) translate(40.000000, 15.000000)"
          >
            <tspan x="22" y="23">
              NCP
            </tspan>
          </text>
          <g>
            <path
              fill="#FFF"
              d="M9.59 1.565c0 .858-1.178 1.334-2.59 1.334s-2.526-.476-2.526-1.334C4.474.708 5.619.012 7.033.012c1.412 0 2.557.696 2.557 1.553"
              transform="translate(-39.000000, -15.000000) translate(40.000000, 15.000000) translate(0.000000, 6.000000)"
            />
            <path
              fill="#FFF"
              d="M9.397 2.734c1.273.476 2.311 1.213 3.1 2.225.912 1.175 1.6 2.652 1.492 4.79-.077 1.519-.68 2.763-1.425 3.733-.762.99-1.735 1.762-2.918 2.226.211.501.474.951.678 1.46.506-.024 1.073.426.657.832H9.442c-.243-.169-.15-.57.091-.674l-.678-1.35c-1.046.34-2.703.316-3.755 0-.113.064-.151.209-.204.316-.156.314-.336.682-.52 1.011.087.117.253.21.249.405-.004.128-.073.195-.16.292H2.929c-.405-.404.142-.84.634-.831.26-.468.465-.991.723-1.461-1.257-.59-2.318-1.388-3.076-2.519C.457 12.067-.083 10.669.01 8.805.09 7.207.739 5.942 1.503 4.96c.794-1.02 1.832-1.76 3.122-2.225-.011-.103-.109-.172-.16-.247-.126-.19-.261-.508-.225-.855C4.337.683 5.57.095 6.615.014c1.492-.116 3.052.52 3.19 1.596.063.502-.174.83-.408 1.124"
              transform="translate(-39.000000, -15.000000) translate(40.000000, 15.000000) translate(0.000000, 6.000000)"
            />
            <path
              stroke="#FFF"
              strokeWidth=".5"
              d="M9.397 2.734c1.273.476 2.311 1.213 3.1 2.225.912 1.175 1.6 2.652 1.492 4.79-.077 1.519-.68 2.763-1.425 3.733-.762.99-1.735 1.762-2.918 2.226.211.501.474.951.678 1.46.506-.024 1.073.426.657.832H9.442c-.243-.169-.15-.57.091-.674l-.678-1.35c-1.046.34-2.703.316-3.755 0-.113.064-.151.209-.204.316-.156.314-.336.682-.52 1.011.087.117.253.21.249.405-.004.128-.073.195-.16.292H2.929c-.405-.404.142-.84.634-.831.26-.468.465-.991.723-1.461-1.257-.59-2.318-1.388-3.076-2.519C.457 12.067-.083 10.669.01 8.805.09 7.207.739 5.942 1.503 4.96c.794-1.02 1.832-1.76 3.122-2.225-.011-.103-.109-.172-.16-.247-.126-.19-.261-.508-.225-.855C4.337.683 5.57.095 6.615.014c1.492-.116 3.052.52 3.19 1.596.063.502-.174.83-.408 1.124z"
              transform="translate(-39.000000, -15.000000) translate(40.000000, 15.000000) translate(0.000000, 6.000000)"
            />
            <path
              fill="#2E3192"
              d="M9.397 2.734c1.273.476 2.311 1.213 3.1 2.225.912 1.175 1.6 2.652 1.492 4.79-.077 1.519-.68 2.763-1.425 3.733-.762.99-1.735 1.762-2.918 2.226.211.501.474.951.678 1.46.506-.024 1.073.426.657.832H9.442c-.243-.169-.15-.57.091-.674l-.678-1.35c-1.046.34-2.703.316-3.755 0-.113.064-.151.209-.204.316-.156.314-.336.682-.52 1.011.087.117.253.21.249.405-.004.128-.073.195-.16.292H2.929c-.405-.404.142-.84.634-.831.26-.468.465-.991.723-1.461-1.257-.59-2.318-1.388-3.076-2.519C.457 12.067-.083 10.669.01 8.805.09 7.207.739 5.942 1.503 4.96c.794-1.02 1.832-1.76 3.122-2.225-.011-.103-.109-.172-.16-.247-.126-.19-.261-.508-.225-.855C4.337.683 5.57.095 6.615.014c1.492-.116 3.052.52 3.19 1.596.063.502-.174.83-.408 1.124"
              transform="translate(-39.000000, -15.000000) translate(40.000000, 15.000000) translate(0.000000, 6.000000)"
            />
            <path
              fill="#FFF"
              d="M9.465 1.385C9.115.603 7.839.208 6.795.283c-.773.057-1.565.302-1.99.765-.38.415-.417 1.247.159 1.574.514-.186 1.137-.264 1.787-.315-.014-.24.029-.538-.023-.743-.47.04-.78-.005-.837-.291-.07-.346.116-.49.272-.54.234-.074.563-.022.95-.022.343 0 .726-.056.928.044.268.136.282.72-.07.81-.211.054-.402-.04-.655 0-.05.204-.008.501-.023.742.65.05 1.251.15 1.788.315.354-.206.59-.779.384-1.237M5.212 3.138c-1.257.36-2.25 1.056-2.963 1.845C1.28 6.054.451 7.607.6 9.659c.136 1.925.935 3.276 2.058 4.317 1.11 1.03 2.737 1.748 4.75 1.663.94-.039 1.818-.313 2.51-.674.714-.371 1.294-.847 1.81-1.394 1.002-1.063 1.818-2.68 1.675-4.654-.142-1.933-.955-3.361-2.037-4.34-1.028-.93-2.704-1.804-4.75-1.662-.475.032-.958.097-1.403.223"
              transform="translate(-39.000000, -15.000000) translate(40.000000, 15.000000) translate(0.000000, 6.000000)"
            />
            <path
              fill="#2E3192"
              d="M6.864 3.43c.347-.091.599.108.565.45-.048.483-.795.476-.859.024-.033-.234.043-.407.294-.473M4.601 4.285c.43-.144.57.497.182.562-.32.056-.56-.434-.182-.562M9.51 4.578c.186-.045.37.05.385.248.03.392-.616.467-.61.02 0-.145.059-.228.226-.268M2.475 6.421c.12-.031.226-.016.295.045.1.09.13.45-.091.518-.395.121-.597-.46-.204-.563M11.704 6.713c.167-.024.312.043.34.225.06.41-.5.474-.589.18-.062-.207.015-.369.249-.405M11.026 7.388c-.328.439-.712.822-1.04 1.26.004-.192-.028-.483-.114-.653-.79.391-1.549.814-2.33 1.214.214.863-1.175.856-.95 0-.586-.37-1.2-.71-1.787-1.079-.096.114-.129.291-.18.45-.276-.364-.493-.785-.77-1.147.454.007.964.151 1.448.202-.164.086-.301.196-.407.337.572.376 1.195.701 1.764 1.08.16-.19.64-.217.792 0 .784-.382 1.515-.817 2.307-1.192-.028-.197-.265-.304-.294-.427.515-.02 1.034-.036 1.561-.045M1.322 8.873c.332-.077.573.11.542.449-.049.546-.917.475-.859-.113.013-.132.086-.284.317-.336M12.36 8.873c.095-.025.177-.04.25-.023.409.091.373.824-.091.854-.466.03-.673-.702-.158-.831M2.137 11.278c.3-.107.486.123.407.382-.082.27-.541.24-.589-.045-.032-.19.035-.285.182-.337M11.365 11.57c.242-.058.37.061.384.248.028.376-.616.462-.61.022.002-.146.06-.23.226-.27M4.33 13.413c.22-.054.372.05.385.248.025.396-.618.457-.61.023.002-.137.062-.23.226-.27M9.84 13.24c.453-.074.409.585.046.584-.34-.002-.428-.522-.046-.585M6.977 14.313c.658-.144.668.792.157.832-.49.039-.691-.715-.157-.832"
              transform="translate(-39.000000, -15.000000) translate(40.000000, 15.000000) translate(0.000000, 6.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M48 306h-6v-6c0-1.105-.895-2-2-2s-2 .895-2 2v6h-6c-1.105 0-2 .895-2 2s.895 2 2 2h6v6c0 1.105.895 2 2 2s2-.895 2-2v-6h6c1.105 0 2-.895 2-2s-.895-2-2-2z"
              transform="translate(-312.000000, -410.000000) translate(242.000000, -3.000000) translate(40.000000, 115.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const EditorIcons = {
  underline: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
  <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
          <g>
              <g>
                  <g>
                      <path d="M99.457 25.712c3.551-.315 6.257-3.26 6.21-6.761v-6.315c0-.903-.747-1.636-1.667-1.636s-1.667.733-1.667 1.636v6.546c0 .924-.4 1.805-1.097 2.425-.699.62-1.631.923-2.568.832-1.68-.21-2.951-1.595-2.988-3.257h-.013v-6.546C95.667 11.733 94.92 11 94 11s-1.667.733-1.667 1.636v6.546c.002 1.813.77 3.544 2.12 4.782 1.35 1.237 3.161 1.87 5.004 1.748zm8.71 1.652H89.833c-.46 0-.833.366-.833.818 0 .452.373.818.833.818h18.334c.46 0 .833-.366.833-.818 0-.452-.373-.818-.833-.818z" transform="translate(-387.000000, -1692.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(16.000000, 314.000000)"/>
                  </g>
              </g>
          </g>
      </g>
  </g>
</svg>`,
  fontSize: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16">
<g fill="none" fillRule="evenodd">
    <g fill="#7A869A" fillRule="nonzero">
        <g>
            <g>
                <g>
                    <path d="M148.25 13.6c0-.884-.709-1.6-1.583-1.6h-11.084c-.874 0-1.583.716-1.583 1.6 0 .884.709 1.6 1.583 1.6h3.959v11.2c0 .884.709 1.6 1.583 1.6s1.583-.716 1.583-1.6V15.2h3.959c.874 0 1.583-.716 1.583-1.6zm3.167 4.8h-7.125v3.2h2.375v4.8c0 .884.709 1.6 1.583 1.6s1.583-.716 1.583-1.6v-4.8h1.584c.874 0 1.583-.716 1.583-1.6 0-.884-.709-1.6-1.583-1.6z" transform="translate(-432.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(16.000000, 314.000000)"/>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>
`,
  strikeTrough: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16">
  <g fill="none" fillRule="evenodd">
      <g fill="#7A869A" fillRule="nonzero">
          <g>
              <g>
                  <g>
                      <path d="M185.917 26.4c0 .884.709 1.6 1.583 1.6s1.583-.716 1.583-1.6v-4h-3.166v4zm10.291-7.2h-7.125v-4h3.959c.874 0 1.583-.716 1.583-1.6 0-.884-.709-1.6-1.583-1.6h-11.084c-.874 0-1.583.716-1.583 1.6 0 .884.709 1.6 1.583 1.6h3.959v4h-7.125c-.438 0-.792.358-.792.8 0 .442.354.8.792.8h17.416c.438 0 .792-.358.792-.8 0-.442-.354-.8-.792-.8z" transform="translate(-476.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(16.000000, 314.000000)"/>
                  </g>
              </g>
          </g>
      </g>
  </g>
</svg>

`,
  textColor: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g fill="none" fillRule="evenodd">
    <g fill="#7A869A" fillRule="nonzero">
        <g>
            <g>
                <g>
                    <path d="M16 24c.736 0 1.333-.597 1.333-1.333v-2h5.334v2c0 .736.597 1.333 1.333 1.333s1.333-.597 1.333-1.333v-5.334c-.001-1.477-.615-2.888-1.695-3.896-1.08-1.009-2.53-1.524-4.003-1.425-2.841.256-5.006 2.657-4.968 5.51v5.145c0 .736.597 1.333 1.333 1.333zm1.333-6.667c.001-.753.32-1.47.879-1.976.558-.506 1.304-.752 2.054-.678 1.39.177 2.425 1.371 2.4 2.773V18h-5.333v-.667zm9.334 8H13.333c-.736 0-1.333.597-1.333 1.334 0 .736.597 1.333 1.333 1.333h13.334c.736 0 1.333-.597 1.333-1.333 0-.737-.597-1.334-1.333-1.334z" transform="translate(-527.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(233.000000, 314.000000)"/>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>
`,
  backgroundColor: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g fill="none" fillRule="evenodd">
    <g fill="#7A869A" fillRule="nonzero">
        <g>
            <g>
                <g>
                    <path d="M66.286 24c.379-.003.739-.165.992-.447.254-.282.378-.657.341-1.034 0-.872-1.135-2.276-1.184-2.336L66.286 20l-.15.183c-.048.06-1.183 1.464-1.183 2.336-.037.377.087.752.34 1.034.254.282.614.444.993.447zm-8.162-.343c.521.52 1.365.52 1.886 0L65.667 18l-6-6-5.657 5.657c-.25.25-.39.589-.39.943 0 .353.14.692.39.942l4.114 4.115zm1.543-8.829L62.838 18l-.666.667h-6.21l-.066-.067 3.77-3.772zm8 10.505H54.333c-.736 0-1.333.597-1.333 1.334 0 .736.597 1.333 1.333 1.333h13.334c.736 0 1.333-.597 1.333-1.333 0-.737-.597-1.334-1.333-1.334z" transform="translate(-568.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(233.000000, 314.000000)"/>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>
`,
  bold: `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16">
<g fill="none" fillRule="evenodd">
    <g fill="#7A869A" fillRule="nonzero">
        <g>
            <g>
                <g>
                    <path d="M23.299 19.56c.649-.904.958-2.002.876-3.106-.247-2.552-2.442-4.49-5.045-4.454h-5.505c-.897 0-1.625.717-1.625 1.6v12.8c0 .883.728 1.6 1.625 1.6h6.29c2.686.04 4.927-2.01 5.083-4.65.046-1.452-.579-2.846-1.7-3.79zM15.25 15.2h4.062c.898 0 1.625.717 1.625 1.6 0 .884-.727 1.6-1.625 1.6H15.25v-3.2zm4.875 9.6H15.25v-3.2h4.875c.897 0 1.625.716 1.625 1.6 0 .883-.728 1.6-1.625 1.6z" transform="translate(-310.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(16.000000, 314.000000)"/>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>
`,
  italic: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
<g fill="none" fillRule="evenodd">
    <g fill="#7A869A" fillRule="nonzero">
        <g>
            <g>
                <g>
                    <path d="M62.444 12h-7.777c-.86 0-1.556.716-1.556 1.6 0 .884.697 1.6 1.556 1.6H57l-3.111 9.6h-2.333c-.86 0-1.556.716-1.556 1.6 0 .884.696 1.6 1.556 1.6h7.777c.86 0 1.556-.716 1.556-1.6 0-.884-.697-1.6-1.556-1.6H57l3.111-9.6h2.333c.86 0 1.556-.716 1.556-1.6 0-.884-.696-1.6-1.556-1.6z" transform="translate(-348.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(16.000000, 314.000000)"/>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>
`,
  alignCenter: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g fill="none" fillRule="evenodd">
    <g fill="#7A869A" fillRule="nonzero">
        <g>
            <g>
                <g>
                    <path d="M56.733 16.666c-.372-.024-.727.161-.921.48-.194.32-.194.72 0 1.04.194.319.549.504.921.48h8.534c.372.024.727-.161.921-.48.194-.32.194-.72 0-1.04-.194-.319-.549-.504-.921-.48h-8.534zM54 14h14c.552 0 1-.448 1-1s-.448-1-1-1H54c-.552 0-1 .448-1 1s.448 1 1 1zm14 7.332H54c-.552 0-1 .448-1 1s.448 1 1 1h14c.552 0 1-.448 1-1s-.448-1-1-1zm-2.733 4.666h-8.534c-.372-.024-.727.161-.921.48-.194.32-.194.72 0 1.04.194.319.549.504.921.48h8.534c.372.024.727-.161.921-.48.194-.32.194-.72 0-1.04-.194-.319-.549-.504-.921-.48z" transform="translate(-657.000000, -1693.000000) translate(242.000000, 0.000000) translate(40.000000, 1367.000000) translate(322.000000, 314.000000)"/>
                </g>
            </g>
        </g>
    </g>
</g>
</svg>
`,
  adMedia: `<svg
xmlns="http://www.w3.org/2000/svg"
width="24"
height="18"
viewBox="0 0 24 18"
>
<g fill="none" fillRule="evenodd" opacity=".8">
  <g fill="#7A869A" fillRule="nonzero">
    <path
      d="M937 678c1.105 0 2-.895 2-2s-.895-2-2-2c-1.104 0-2 .895-2 2s.896 2 2 2zm6.005-10c-.06 0-.12.005-.179.016-7.162 1.26-14.49 1.26-21.652 0-.059-.01-.119-.016-.179-.016-.263-.001-.516.103-.703.289-.187.186-.292.438-.292.702v16.018c0 .264.105.516.292.702.187.186.44.29.703.289.06 0 .12-.005.179-.016 7.162-1.26 14.49-1.26 21.652 0 .06.01.12.016.179.016.264.001.517-.103.703-.289.187-.186.292-.438.292-.702v-16.018c0-.264-.105-.516-.292-.702-.186-.186-.44-.29-.703-.289zM941 682.66c-1.91-.264-3.836-.435-5.762-.531L929 675l-6 6.857V671.34c5.971.83 12.029.83 18 0v11.322z"
      transform="translate(-920.000000, -668.000000)"
    />
  </g>
</g>
</svg>`,
};

const ArrowRight = () => (
  <svg
    width="6px"
    height="11px"
    viewBox="0 0 6 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>F8FA98FE-B82E-4AEA-9270-0B749C5AEE8D</title>
    <g
      id="Main-screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="6.10.-Manifesto"
        transform="translate(-947.000000, -260.000000)"
        stroke="#4C68EF"
        strokeWidth="1.5"
      >
        <polyline
          id="Path-2"
          transform="translate(950.000000, 265.500000) scale(-1, 1) rotate(90.000000) translate(-950.000000, -265.500000) "
          points="946 264 949.944768 267 954 264"
        ></polyline>
      </g>
    </g>
  </svg>
);

const ArrowLeft = () => (
  <svg
    width="6px"
    height="11px"
    viewBox="0 0 6 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>F8FA98FE-B82E-4AEA-9270-0B749C5AEE8D</title>
    <g
      id="Main-screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="6.10.-Manifesto"
        transform="translate(-947.000000, -260.000000)"
        stroke="#4C68EF"
        strokeWidth="1.5"
      >
        <polyline
          id="Path-2"
          transform="translate(950.000000, 265.500000) scale(-1, 1) rotate(270.000000) translate(-950.000000, -265.500000) "
          points="946 264 949.944768 267 954 264"
        ></polyline>
      </g>
    </g>
  </svg>
);


const AddMediaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    viewBox="0 0 24 18"
  >
    <g fill="none" fillRule="evenodd" opacity=".8">
      <g fill="#7A869A" fillRule="nonzero">
        <path
          d="M937 678c1.105 0 2-.895 2-2s-.895-2-2-2c-1.104 0-2 .895-2 2s.896 2 2 2zm6.005-10c-.06 0-.12.005-.179.016-7.162 1.26-14.49 1.26-21.652 0-.059-.01-.119-.016-.179-.016-.263-.001-.516.103-.703.289-.187.186-.292.438-.292.702v16.018c0 .264.105.516.292.702.187.186.44.29.703.289.06 0 .12-.005.179-.016 7.162-1.26 14.49-1.26 21.652 0 .06.01.12.016.179.016.264.001.517-.103.703-.289.187-.186.292-.438.292-.702v-16.018c0-.264-.105-.516-.292-.702-.186-.186-.44-.29-.703-.289zM941 682.66c-1.91-.264-3.836-.435-5.762-.531L929 675l-6 6.857V671.34c5.971.83 12.029.83 18 0v11.322z"
          transform="translate(-920.000000, -668.000000)"
        />
      </g>
    </g>
  </svg>
);

const MasterIcon = () => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#29292A" cx="10" cy="10" r="10" />
      <path
        d="M13 6a.996.996 0 0 0-1 .996v.666h2V7a1 1 0 0 0-1-1zm-2.333 1.333H10a1.333 1.333 0 0 0-2.667 0h-.666A.669.669 0 0 0 6 8v5.333a.669.669 0 0 0 .667.667h4a.669.669 0 0 0 .666-.667V8a.669.669 0 0 0-.666-.667zm-2 .667a.667.667 0 1 1 0-1.333.667.667 0 0 1 0 1.333zM12 12.329l1 1.667 1-1.667V8.333h-2v3.996z"
        fill="#949494"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const CardBackground = () => (
  <svg
    width="115"
    height="104"
    viewBox="0 0 115 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M76.6435 113.094C107.735 89.3687 82.6176 30.4696 39.2818 38.3724C42.1422 40.2815 48.0728 45.215 48.9124 49.676C39.6757 52.7071 14.5796 63.4254 23.3925 98.1406C29.5444 122.374 63.0903 123.436 76.6435 113.094Z"
        fill="#5C6198"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M128.453 108.716C166.485 79.6943 135.761 7.64799 82.752 17.3148C86.2509 19.65 93.5053 25.6847 94.5323 31.1415C83.2338 34.8492 52.5359 47.96 63.3159 90.4242C70.841 120.067 111.875 121.366 128.453 108.716Z"
          fill="#ECEDF5"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="48.1588"
        y="13.8507"
        width="104.956"
        height="120.504"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2.58425" dy="7.73556" />
        <feGaussianBlur stdDeviation="5.15704" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const CardQuotes = () => (
  <svg
    width="34"
    height="28"
    viewBox="0 0 34 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7345 22.7568C11.4226 17.9828 16.5217 6.13114 25.3194 7.72133C24.7387 8.10548 23.5347 9.09819 23.3643 9.99583C25.2395 10.6057 30.3343 12.7625 28.5451 19.7478C27.2962 24.624 20.486 24.8378 17.7345 22.7568Z"
      fill="#34375A"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M7.21617 21.8756C-0.504662 16.036 5.73263 1.53892 16.4941 3.48406C15.7838 3.95396 14.3111 5.16826 14.1026 6.26627C16.3963 7.01231 22.6283 9.65046 20.4399 18.1951C18.9122 24.1597 10.5818 24.4212 7.21617 21.8756Z"
        fill="#7E83C1"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="2.22227"
        y="2.78682"
        width="21.2704"
        height="24.2479"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.518847" dy="1.55654" />
        <feGaussianBlur stdDeviation="1.03769" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const BackgroundQuote = () => (
  <svg
    width="67"
    height="91"
    viewBox="0 0 67 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M80.4534 95.7156C118.485 66.6943 87.761 -5.35201 34.752 4.31477C38.2509 6.65003 45.5053 12.6847 46.5323 18.1415C35.2338 21.8492 4.53592 34.96 15.3159 77.4242C22.841 107.067 63.8749 108.366 80.4534 95.7156Z"
        fill="#ECEDF5"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0.158799"
        y="0.850679"
        width="104.956"
        height="120.504"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2.58425" dy="7.73556" />
        <feGaussianBlur stdDeviation="5.15704" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0 0.133333 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const Map = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
  </svg>
);

const CheckedGreen = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <title>428A51FF-4FF7-4320-85A9-D790A4A64F79</title>
    <g
      id="Main-screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="2.6.-Task-details"
        transform="translate(-52.000000, -642.000000)"
        fill="#00A64C"
        fill-rule="nonzero"
      >
        <path
          d="M70,642 L56,642 C54.9391283,642 53.9217019,642.421403 53.1715524,643.171552 C52.4214028,643.921702 52,644.939128 52,646 L52,660 C52,662.209118 53.7908816,664 56,664 L70,664 C72.2091207,664 74,662.209121 74,660 L74,646 C74,644.939128 73.5786007,643.9217 72.8284506,643.171549 C72.0783004,642.421399 71.0608725,642 70,642 L70,642 Z M68.75928,649.65088 L62.14942,657.3623 L57.39991,653.7998 C57.1131794,653.585757 56.9626771,653.234814 57.0052573,652.879545 C57.0478375,652.524276 57.2770093,652.218838 57.6061999,652.078615 C57.9353905,651.938392 58.3144181,651.984759 58.6001,652.2002 L61.85059,654.6377 L67.24073,648.34912 C67.473267,648.077857 67.8328571,647.951257 68.1840461,648.017008 C68.5352352,648.08276 68.8246692,648.330874 68.9433212,648.667889 C69.0619732,649.004903 68.9918171,649.379617 68.75928,649.65088 L68.75928,649.65088 Z"
          id="check-square"
        ></path>
      </g>
    </g>
  </svg>
);

const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <defs>
      <filter id="yvg4p8kvoa" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.074510 0 0 0 0 0.078431 0 0 0 0 0.082353 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g
            filter="url(#yvg4p8kvoa)"
            transform="translate(-56.000000, -747.000000) translate(40.000000, 700.000000) translate(0.000000, 32.000000)"
          >
            <g>
              <path
                fill="#FFF"
                fill-rule="nonzero"
                d="M8.333 0c2.264 0 2.547.008 3.435.05.888.042 1.492.18 2.024.388.55.211 1.013.498 1.476.96.424.417.752.92.961 1.477.206.53.346 1.136.388 2.023.039.889.05 1.171.05 3.435 0 2.265-.009 2.547-.05 3.435-.042.888-.182 1.492-.388 2.024-.208.556-.536 1.06-.96 1.476-.417.424-.921.752-1.477.961-.531.206-1.136.346-2.024.388-.888.039-1.17.05-3.435.05-2.264 0-2.546-.009-3.435-.05-.887-.042-1.491-.182-2.023-.388-.556-.209-1.06-.537-1.477-.96-.423-.417-.751-.921-.96-1.477C.23 13.26.092 12.656.05 11.768.01 10.88 0 10.598 0 8.333 0 6.07.008 5.787.05 4.898c.042-.888.18-1.491.388-2.023.208-.557.536-1.06.96-1.477.417-.424.92-.752 1.477-.96C3.407.23 4.01.092 4.898.05 5.787.01 6.07 0 8.333 0zm0 4.167c-1.105 0-2.165.439-2.946 1.22-.781.781-1.22 1.841-1.22 2.946 0 1.105.439 2.165 1.22 2.947.781.781 1.841 1.22 2.946 1.22 1.105 0 2.165-.439 2.947-1.22.781-.782 1.22-1.842 1.22-2.947s-.439-2.165-1.22-2.946c-.782-.781-1.842-1.22-2.947-1.22zm5.417-.209c0-.276-.11-.54-.305-.736-.195-.196-.46-.305-.737-.305-.276 0-.54.11-.736.305-.196.195-.305.46-.305.736 0 .277.11.542.305.737.195.195.46.305.736.305.277 0 .542-.11.737-.305.195-.195.305-.46.305-.737zM8.333 5.833c.663 0 1.3.264 1.768.733.469.468.732 1.104.732 1.767 0 .663-.263 1.3-.732 1.768-.469.469-1.105.732-1.768.732s-1.299-.263-1.767-.732c-.47-.469-.733-1.105-.733-1.768s.264-1.299.733-1.767c.468-.47 1.104-.733 1.767-.733z"
                transform="translate(16.000000, 15.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const FaceBookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <defs>
      <filter id="huxix9enya" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.074510 0 0 0 0 0.078431 0 0 0 0 0.082353 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g
            filter="url(#huxix9enya)"
            transform="translate(-540.000000, -747.000000) translate(40.000000, 700.000000) translate(484.000000, 32.000000)"
          >
            <g>
              <path
                fill="#131415"
                fillRule="nonzero"
                d="M10.852 15V9.195h2.041l.305-2.257h-2.346v-1.44c0-.653.19-1.099 1.174-1.099h1.255V2.381c-.608-.062-1.218-.091-1.829-.09-1.809 0-3.047 1.052-3.047 2.984v1.663H6.36v2.257h2.045V15H.875c-.232 0-.455-.088-.619-.244C.092 14.6 0 14.388 0 14.166V.834C0 .613.092.4.256.244.42.088.643 0 .875 0h14c.232 0 .455.088.619.244.164.156.256.368.256.59v13.333c0 .22-.092.433-.256.589-.164.156-.387.244-.619.244h-4.023z"
                transform="translate(16.000000, 15.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="14"
    viewBox="0 0 18 14"
  >
    <defs>
      <filter id="lq19zu1pja" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.074510 0 0 0 0 0.078431 0 0 0 0 0.082353 0 0 0 1.000000 0"
        />
      </filter>
      <filter id="i7qxls2sjb" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.294118 0 0 0 0 0.407843 0 0 0 0 0.937255 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g
            filter="url(#lq19zu1pja)"
            transform="translate(-56.000000, -915.000000) translate(40.000000, 700.000000) translate(0.000000, 199.000000)"
          >
            <g
              filter="url(#i7qxls2sjb)"
              transform="translate(16.000000, 16.000000)"
            >
              <path
                fill="#1974EB"
                fill-rule="nonzero"
                d="M17.835 1.64c-.668.28-1.376.466-2.102.548.765-.436 1.337-1.121 1.61-1.928-.717.406-1.504.691-2.324.845-.55-.56-1.28-.933-2.076-1.058-.796-.126-1.613.003-2.324.365-.71.363-1.276.939-1.608 1.639-.332.7-.412 1.485-.227 2.233-1.455-.07-2.878-.43-4.178-1.057-1.3-.628-2.446-1.508-3.364-2.585-.326.532-.497 1.137-.496 1.753 0 1.208.646 2.275 1.628 2.9-.581-.017-1.15-.166-1.657-.435v.043c0 .805.292 1.585.827 2.207.535.623 1.28 1.05 2.108 1.21-.54.14-1.105.16-1.654.06.234.693.69 1.299 1.302 1.733.613.434 1.353.675 2.117.688-.759.568-1.628.988-2.558 1.235-.93.248-1.9.319-2.859.208 1.673 1.025 3.62 1.569 5.609 1.567 6.731 0 10.412-5.31 10.412-9.916 0-.15-.004-.302-.011-.45.716-.494 1.335-1.105 1.826-1.805z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <filter id="y498drz6la" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.074510 0 0 0 0 0.078431 0 0 0 0 0.082353 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g
            filter="url(#y498drz6la)"
            transform="translate(-541.000000, -914.000000) translate(40.000000, 700.000000) translate(484.000000, 199.000000)"
          >
            <g>
              <path
                fill="#000"
                fill-rule="nonzero"
                d="M8.032 0C3.642 0 .064 3.56.064 7.928c0 1.4.37 2.76 1.062 3.96L0 16l4.221-1.104c1.166.632 2.476.968 3.811.968 4.39 0 7.968-3.56 7.968-7.928 0-2.12-.828-4.112-2.332-5.608C12.931 1.588 12.053 1 11.086.6 10.118.2 9.08-.004 8.032 0zm.008 1.336c1.769 0 3.425.688 4.68 1.936.615.612 1.103 1.34 1.436 2.14.332.8.503 1.658.501 2.524 0 3.632-2.975 6.584-6.625 6.584-1.19 0-2.356-.312-3.369-.92l-.24-.136-2.51.656.668-2.432-.16-.256c-.665-1.049-1.016-2.264-1.014-3.504.008-3.632 2.975-6.592 6.633-6.592zM5.21 4.264c-.129 0-.346.048-.53.248-.177.2-.7.688-.7 1.656 0 .976.715 1.912.804 2.048.112.136 1.415 2.136 3.417 2.984.474.216.844.336 1.134.424.474.152.908.128 1.254.08.386-.056 1.174-.48 1.343-.944.168-.464.168-.856.12-.944-.056-.08-.185-.128-.386-.216-.2-.112-1.182-.592-1.359-.656-.184-.064-.297-.096-.45.096-.128.2-.514.648-.627.776-.12.136-.233.152-.426.056-.21-.104-.852-.312-1.608-.984-.595-.528-.989-1.176-1.11-1.376-.096-.192-.008-.312.089-.4.088-.088.217-.232.297-.352.105-.112.137-.2.201-.328.065-.136.032-.248-.016-.344-.048-.088-.45-1.08-.619-1.472-.16-.384-.321-.336-.45-.344-.113 0-.241-.008-.378-.008z"
                transform="translate(17.000000, 15.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const CompanyLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="506.000000pt"
    height="493.000000pt"
    viewBox="0 0 506.000000 493.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,493.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M1934 4754 c-230 -38 -427 -160 -498 -306 -40 -81 -40 -180 -1 -266
15 -34 36 -69 46 -78 11 -9 19 -23 19 -29 0 -7 -35 -27 -77 -44 -420 -175
-737 -478 -938 -896 -113 -237 -164 -466 -165 -741 0 -385 109 -738 328 -1054
84 -121 296 -334 420 -420 51 -36 143 -93 205 -127 61 -34 118 -66 126 -71 12
-7 5 -29 -40 -122 -127 -265 -119 -254 -166 -262 -114 -19 -186 -105 -148
-177 l16 -31 204 0 c199 0 204 0 219 22 25 36 20 83 -13 120 l-29 32 84 167
c46 92 87 170 91 172 5 3 32 -1 60 -8 243 -63 624 -64 860 -2 l52 14 85 -170
85 -171 -24 -28 c-31 -37 -40 -80 -23 -115 l13 -28 205 -3 c199 -3 206 -2 223
19 40 50 8 122 -74 163 -29 14 -68 26 -86 26 -18 0 -33 4 -33 9 0 8 -35 85
-139 300 l-30 65 118 57 c323 158 587 410 774 740 98 173 152 318 194 519 23
111 26 148 26 330 0 271 -27 432 -110 650 -176 463 -528 827 -985 1021 -75 32
-77 34 -63 54 8 11 29 45 47 75 31 52 33 61 33 150 0 80 -4 103 -24 144 -109
221 -511 360 -867 300z m341 -69 c120 -19 186 -40 268 -83 91 -47 160 -111
193 -178 23 -48 26 -63 22 -128 -5 -80 -35 -147 -83 -184 -24 -19 -25 -19
-120 4 -101 24 -286 54 -335 54 l-30 0 0 101 0 101 83 -6 c101 -8 126 3 146
61 19 54 6 108 -32 137 -26 20 -43 21 -240 23 -130 2 -224 -1 -244 -8 -49 -16
-73 -53 -73 -111 0 -68 29 -94 114 -103 34 -4 71 -5 81 -3 19 3 20 -4 23 -92
l3 -96 -116 -12 c-112 -12 -264 -41 -315 -60 -65 -25 -138 81 -139 200 -2 159
116 282 338 349 157 47 308 58 456 34z m162 -694 c322 -66 625 -229 854 -460
219 -220 375 -523 434 -838 71 -386 27 -720 -138 -1044 -89 -175 -162 -275
-312 -424 -148 -148 -182 -176 -301 -250 -293 -185 -629 -265 -982 -235 -422
35 -773 197 -1054 486 -318 327 -467 703 -468 1176 0 427 163 816 478 1137
270 274 607 433 1007 475 103 11 382 -3 482 -23z"
      />
      <path
        d="M2064 3881 c-32 -14 -64 -60 -64 -93 0 -36 24 -84 49 -98 38 -20 107
-12 140 16 27 22 31 32 31 74 0 67 -29 101 -90 106 -25 2 -55 0 -66 -5z"
      />
      <path
        d="M1476 3649 c-54 -43 -16 -129 58 -129 64 0 89 78 41 125 -30 30 -64
32 -99 4z"
      />
      <path
        d="M2720 3570 c-23 -23 -27 -77 -7 -103 6 -10 28 -20 48 -23 74 -12 126
82 70 128 -31 24 -86 23 -111 -2z"
      />
      <path
        d="M953 3113 c-45 -8 -68 -77 -40 -114 15 -20 51 -39 72 -39 27 0 65 46
65 78 0 52 -44 85 -97 75z"
      />
      <path
        d="M3300 3033 c-32 -12 -50 -37 -50 -70 0 -45 30 -73 78 -73 49 0 76 27
76 77 0 55 -49 86 -104 66z"
      />
      <path
        d="M2798 2853 c-27 -2 -48 -7 -48 -10 0 -3 16 -23 35 -44 19 -22 35 -43
35 -48 0 -6 -64 -42 -142 -81 -79 -40 -147 -76 -153 -80 -5 -4 -72 -40 -148
-80 l-138 -72 -33 16 c-44 21 -109 20 -152 0 -34 -16 -34 -16 -97 24 -34 22
-120 74 -192 117 -151 90 -185 113 -185 125 0 5 21 25 46 45 26 19 43 35 38
35 -6 0 -74 11 -153 26 -79 14 -156 24 -171 22 -27 -3 -27 -3 68 -148 l95
-144 17 52 c10 29 20 55 23 58 3 3 109 -57 235 -133 220 -132 230 -139 225
-165 -14 -71 44 -138 120 -138 88 0 146 59 134 136 -4 24 -1 33 11 37 9 3 58
28 107 54 50 27 158 84 240 128 83 43 169 88 192 101 l41 23 10 -27 c6 -15 14
-51 19 -80 l8 -53 115 137 c63 76 121 146 128 156 13 17 7 18 -135 16 -81 -1
-169 -3 -195 -5z"
      />
      <path
        d="M647 2476 c-50 -18 -66 -41 -67 -94 0 -54 12 -77 50 -97 93 -48 190
28 161 126 -19 63 -77 89 -144 65z"
      />
      <path
        d="M3474 2476 c-63 -28 -82 -92 -44 -154 11 -18 36 -40 55 -48 30 -12
40 -12 69 0 47 19 66 51 66 109 0 40 -5 53 -29 78 -33 32 -67 37 -117 15z"
      />
      <path
        d="M875 1863 c-34 -8 -55 -35 -55 -67 0 -63 54 -99 111 -76 55 23 57
114 3 139 -26 12 -27 12 -59 4z"
      />
      <path
        d="M3208 1774 c-27 -14 -34 -33 -29 -78 5 -57 82 -76 126 -31 32 31 33
74 2 103 -27 25 -60 27 -99 6z"
      />
      <path
        d="M1410 1303 c-59 -23 -49 -121 14 -137 77 -19 135 73 80 128 -15 16
-64 20 -94 9z"
      />
      <path
        d="M2641 1204 c-24 -30 -26 -43 -10 -77 11 -25 47 -47 75 -47 28 0 64
47 64 83 0 43 -26 67 -73 67 -27 0 -41 -7 -56 -26z"
      />
      <path
        d="M2059 1051 c-72 -72 -3 -205 97 -187 66 13 103 99 70 164 -20 39 -44
52 -96 52 -33 0 -48 -6 -71 -29z"
      />
    </g>
  </svg>
);

export {
  AddIcon,
  DashboardIcon,
  ContentIcon,
  CancelCircle,
  TasksandRewardsIcon,
  ComponentIcon,
  ArrowButtonIcon,
  PanoramaImage,
  ChevronButtonIcon,
  FontSizeIcon,
  CheckBoxTick,
  RadioButtonIcon,
  TrashSquareButton,
  TickSquareButton,
  ZoomInIcon,
  ZoomOutIcon,
  RotateLeftIcon,
  RotateRightIcon,
  PlusIcon,
  FilterIcon,
  UploadIcon,
  CloseIcon,
  TagCloseButton,
  LogoutIcon,
  UserIcon,
  TrashIcon,
  RolesIcon,
  ReadmoreIcon,
  UserInfo,
  uploadImageIcon,
  DropdownIcon,
  DashboardNCPLogo,
  PartyIcon,
  AddButton,
  EditorIcons,
  ArrowRight,
  ArrowLeft,
  AddMediaIcon,
  MasterIcon,
  CardBackground,
  CardQuotes,
  BackgroundQuote,
  Map,
  CheckedGreen,
  InstagramIcon,
  FaceBookIcon,
  TwitterIcon,
  WhatsAppIcon,
  CompanyLogo,
};
