import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const MarathiLanguageForm = ({ values, showDescription }) => {
  return (
    <>
      <p className="field-label">Title</p>
      <Field
        value={values.title}
        name="data[mr].title"
        type="text"
        className="suffix-field"
        suffix={<div>{70 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter task title"}
        maxLength={70}
      />
      {showDescription && (
        <>
          <p className="mb-8 field-label">Description (optional)</p>
          <Field
            value={values.description}
            name="data[mr].description"
            type="text"
            component={AntTextArea}
            maxLength={160}
            showCount={{
              formatter: ({ maxLength, count }) => {
                return `${maxLength - count} letters`;
              },
            }}
            placeholder={"Enter short description about the task"}
          />
        </>
      )}
    </>
  );
};

export default MarathiLanguageForm;
