import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const MarathiLanguageForm = ({
  values,
}) => {
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={values?.title}
        name="data[mr].title"
        type="text"
        suffix={<div>{50 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
    </>
  );
};

export default MarathiLanguageForm;
