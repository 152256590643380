import { Button, Upload, Progress, Divider } from "antd";
import { FastField, FieldArray, Form } from "formik";
import React, { useState } from "react";
import { AddButton } from "../../assets/svgs";
import "../index.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
} from "../../utils/helpers";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import "../AddOrganizationForm/AddOrganizationForm.scss";
import { AntCheckBox } from "components/CreateAntFields/CreateAntField";
import { UploadButton } from "components/UploadButtons";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiFormLanguage";
import ImageEditor from "components/ImageEditor";
import { AntToggle } from "components/CreateAntFields/CreateAntField";

const AddVotesForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  city,
  isEditing,
  page,
  handlePage,
  parlimentaryStatus,
  department,
  experience,
  position,
  location,
  section,
  tab,
  pageErrors,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [currentChoiceIndex, setCurrentChoiceIndex] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const arrayErrors = (array, language) => {
    let errors = array?.filter((e) => {
      if (language !== ENGLISH) {
        return e?.data?.[language];
      } else {
        return e?.data?.[language] || e?.mediaId;
      }
    });
    if (errors?.[0]) {
      return true;
    } else {
      return false;
    }
  };

  const hindiFormError =
    submit && (errors?.data?.hi || arrayErrors(errors?.choices, "hi"));
  const englishFormError =
    submit &&
    (errors?.data?.en ||
      arrayErrors(errors?.choices, "en") ||
      errors?.publishedDate);
  const marathiFormError =
    submit && (errors?.data?.mr || arrayErrors(errors?.choices, "mr"));
  const hindiFormValues = values?.data?.hi;
  const marathiFormValues = values?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  const choicePayload = {
    mediaId: "",
    media: { link: "" },
    data: {
      en: {
        choiceTitle: "",
        choiceSubtext: "",
      },
      hi: {
        choiceTitle: "",
        choiceSubtext: "",
      },
      mr: {
        choiceTitle: "",
        choiceSubtext: "",
      },
    },
  };

  const hanldeProgress = (progress) => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        progress(percent);
      },
    };
    return options;
  };

  const handleUpload = (info, index) => {
    const imageValidation = beforeUpload(info.file.originFileObj);

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue(`choices[${index}].media.link`, imageUrl);
        setLoading(false);
      });
    }
  };

  const handleChoiceList = () => {
    const payload = choicePayload;
    const choices = values?.choices;
    setUploadProgress(0);
    setFieldValue("choices", [...choices, payload]);
  };

  const handleDelete = (index) => {
    if (values.choices.length !== 1) {
      let choices = values.choices.filter((e, i) => i !== index);
      setFieldValue("choices", choices);
    } else {
      const payload = choicePayload;
      setUploadProgress(0);
      setFieldValue("choices", [payload]);
    }
  };

  const hasError = (field, index, fieldName) => {
    if (fieldName) {
      return (
        errors[field]?.[index]?.[fieldName] &&
        touched[field]?.[index]?.[fieldName] &&
        errors[field]?.[index]?.[fieldName]
      );
    } else return errors?.[field] && touched?.[field] && errors?.[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mb-8 field-label">Title / Question</p>
              <FastField
                value={values?.data?.[ENGLISH]?.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {40 - (values?.data?.[ENGLISH]?.title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter main title/ question "}
                maxLength={40}
              />
              <Divider
                style={{
                  position: "relative",
                  width: "536px",
                  left: "-40px",
                }}
              />
              <p className="video-label mt-23">Add Choices</p>
              <FieldArray
                name="choices"
                render={(arrayHelpers) => (
                  <div>
                    {values?.choices?.map((items, i) => (
                      <div key={i}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="field-label">Choice {i + 1}</p>
                          <p
                            style={{ color: "#e70000", cursor: "pointer" }}
                            onClick={() => handleDelete(i)}
                            className="field-label"
                          >
                            Delete choice
                          </p>
                        </div>
                        {items?.media?.link ? (
                          <ImageEditor
                            pixelRatio={16 / 9}
                            width={228}
                            onDelete={() => {
                              setFieldValue(`choices[${i}].mediaId`, "");
                              setFieldValue(`choices[${i}].media.link`, "");
                              setIsPreviewMode(false);
                              setFieldValue();
                              setUploadProgress(0);
                            }}
                            isPreviewMode={isPreviewMode}
                            isEditing={uploadProgress}
                            handleEditing={(status) => {
                              if (status && uploadProgress) {
                                setUploadProgress(0);
                              }
                            }}
                            onSave={async (url) => {
                              let file = dataURLtoFile(
                                url,
                                `image_${Math.random()}.png`
                              );
                              let response = await uploadImage(
                                file,
                                hanldeProgress(setUploadProgress)
                              );
                              if (response) {
                                setCurrentChoiceIndex(() => i);
                                setFieldValue(
                                  `choices[${i}].mediaId`,
                                  response
                                );
                                setLoading(false);
                              }
                            }}
                            image={items?.media?.link}
                          />
                        ) : (
                          <Upload
                            name="thumb_mediaId"
                            listType={`picture-card ${
                              hasError("choices", i, "mediaId") ? "error" : ""
                            }`}
                            className="picture-uploader"
                            accept="image/png, image/jpeg, image/jpg"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            customRequest={dummyRequest}
                            onChange={(e) => handleUpload(e, i)}
                            onClick={() => setCurrentChoiceIndex(i)}
                            progress={
                              <Progress
                                percent={uploadProgress}
                                showInfo={true}
                              />
                            }
                          >
                            {<UploadButton loading={loading} />}
                          </Upload>
                        )}
                        {uploadProgress === 100 &&
                          i === currentChoiceIndex &&
                          !hasError("choices", i, "mediaId") && (
                            <div className="success-text">
                              Image uploaded Successfully
                            </div>
                          )}
                        {uploadProgress > 0 &&
                          uploadProgress < 100 &&
                          i === currentChoiceIndex && (
                            <div className="progress-bar">
                              <Progress
                                percent={uploadProgress}
                                showInfo={true}
                              />
                            </div>
                          )}
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {hasError("choices", i, "mediaId") &&
                            `Image is required`}
                        </div>
                        <p className="field-label mb-8">Choice title / name</p>
                        <FastField
                          value={items?.["data"].choiceTitle}
                          name={`choices[${i}].data[en].choiceTitle`}
                          type="text"
                          suffix={
                            <div>
                              {30 -
                                (items?.["data"]?.[ENGLISH].choiceTitle
                                  ?.length || 0)}{" "}
                              letters
                            </div>
                          }
                          component={AntInput}
                          placeholder={"Enter title "}
                          maxLength={30}
                        />
                        <p className="field-label mb-8">Choice subtext</p>
                        <FastField
                          value={items?.["data"].choiceSubtext}
                          name={`choices[${i}].data[en].choiceSubtext`}
                          type="text"
                          suffix={
                            <div>
                              {20 -
                                (items?.["data"]?.[ENGLISH].choiceSubtext
                                  ?.length || 0)}{" "}
                              letters
                            </div>
                          }
                          component={AntInput}
                          placeholder={"Enter title"}
                          maxLength={20}
                        />
                        {items.choiceVotesCount >= 0 ? (
                          <div className="social-count mb-40">
                            {" "}
                            <p className="mb-0 mt-16 field-label">
                              Number of votes
                            </p>
                            <Progress
                              strokeColor="#00a64c"
                              percent={items.choiceVotesCount}
                              format={() => items.choiceVotesCount}
                            />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                )}
              />
              <Button
                disabled={values.choices.length === 4 ? true : false}
                className="mb-21 add-btn"
                type="dashed"
                styles={{ backgroundColor: "white !important" }}
                onClick={handleChoiceList}
              >
                <span>
                  <AddButton style={{ width: "14px", height: "14px" }} /> Add
                  Choice
                </span>
              </Button>
              <div className="mt-28 checkbox-container">
                <FastField
                  name="isPopular"
                  type="checkbox"
                  component={AntCheckBox}
                />
                <p>
                  {values.isPopular ? "Marked as popular" : "Mark as popular"}
                </p>
              </div>
              {!isEditing && (
                <div>
                  <p className="mt-20 field-label">Send notification</p>
                  <FastField
                    defaultChecked={values.isNotification}
                    name="isNotification"
                    type="switch"
                    component={AntToggle}
                  />
                </div>
              )}
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm
              values={hindiFormValues}
              choices={values.choices}
            />
          ) : (
            <MarathiLanguageForm
              values={marathiFormValues}
              choices={values.choices}
            />
          )}
          <Button
            style={{ backgroundColor: "none" }}
            loading={isSubmitting}
            className={
              language === ENGLISH || values.choices.length > 1
                ? "submit-btn mt-40"
                : "absolute-submit-btn submit-btn"
            }
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddVotesForm;
