import {
  RotateLeftIcon,
  RotateRightIcon,
  TickSquareButton,
  TrashSquareButton,
  ZoomInIcon,
  ZoomOutIcon,
} from "assets/svgs";
import { CORS_PARAM } from "constants/dummyData";
import { useEffect, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import "./index.scss";

const ImageEditor = ({
  image,
  width,
  onSave,
  onDelete,
  isEditing,
  handleEditing,
  renderMultipleImage = false,
  initalZoom = 1,
  initialRotation = 0,
  radius = 0,
}) => {
  var editor = "";
  const [zoom, setZoom] = useState(initalZoom);
  const [picture, setPicture] = useState(image);
  const [rotate, setRotate] = useState(initialRotation);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  let step = 10;

  useEffect(() => {
    if (renderMultipleImage) {
      setPicture(() => image);
      setZoom(1);
      setRotate(0);
    }
  }, [image, renderMultipleImage]);

  const handleZoom = (value) => {
    if ((value > 0 && zoom < 4) || (value < 0 && zoom > 0.5)) {
      setZoom(() => zoom + value);
    }
    isEditing && handleEditing(true);
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handlePosition = (value) => {
    if (zoom >= 1) {
      setPosition(() => value);
    } else if (
      value?.x > 0.1 &&
      value?.x < 0.9 &&
      value?.y > 0.1 &&
      value?.y < 0.9
    ) {
      setPosition(() => value);
    }
    isEditing && handleEditing(true);
  };

  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor?.getImage();
      let croppedImg;
      try {
        croppedImg = canvasScaled.toDataURL();
        onSave(croppedImg);
      } catch (err) {
        try {
          const canvasScaled = editor?.getImageScaledToCanvas();
          const croppedImg = canvasScaled.toDataURL(null, 1.0);
          onSave(croppedImg);
        } catch (err) {}
        console.log(err);
      }
    }
  };

  return (
    <div className="image-editor-container">
      <AvatarEditor
        className="image-editor"
        ref={setEditorRef}
        image={picture.length < 1000 ? picture + CORS_PARAM : picture}
        width={width || 360}
        position={position}
        height={220}
        border = {0}
        borderRadius = {radius}
        color={[0, 0, 0]} // RGBA
        rotate={rotate}
        scale={zoom}
        disableHiDPIScaling={true}
        crossOrigin="anonymous"
        onPositionChange={handlePosition}
      />
      <div className={"action-toolbar"}>
        <div onClick={onDelete}>
          <TrashSquareButton />
        </div>
        <div onClick={handleSave}>
          <TickSquareButton />
        </div>
      </div>
      <div className={"action-toolbar bottom"}>
        <div onClick={() => handleZoom(0.2)}>
          <ZoomInIcon />
        </div>
        <div onClick={() => handleZoom(-0.2)}>
          <ZoomOutIcon />
        </div>
        <div
          onClick={() => {
            setRotate(() => rotate - step);
            isEditing && handleEditing(true);
          }}
        >
          <RotateLeftIcon />
        </div>
        <div
          onClick={() => {
            setRotate(() => rotate + step);
            isEditing && handleEditing(true);
          }}
        >
          <RotateRightIcon />
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;
