import {
  Col,
  Row,
  Space,
  Divider,
  Typography,
  List,
  Timeline,
} from 'antd'
import {COLUMN_ACTIONS} from "constants/dummyData"
import { ImageCell } from "components/TableCells/ImageCell";
import moment from 'moment';
import { ENGLISH } from 'constants/language';

const Status = ({status}) => {
  return (
    <>
      {
        status?.createdAt && (
          <div>
            <Space split={<Divider type="vertical" />}>
              <span>
                {moment(status?.createdAt).format("DD MMM")}
              </span>
              <span>
                {moment(status?.createdAt).format("h:mm A")}
              </span>
            </Space>
          </div>
        )
      }
    </>
  )
}

const Ring = () => <div className='width-20 height-20 completed-ring'/>

export const OrderDetails = ({
  values
}) => {
  const latestStatus = values?.latestStatus?.status
  const data = [
    {
      label: "Order Date",
      value: values?.createdAt ? moment(values?.createdAt).format('Do MMM, YYYY') : undefined
    },
    {
      label: "Total Cost",
      value: values?.orderMeta?.variant?.pointPrice
    },
    {
      label: "Status",
      value: latestStatus 
    }
  ]

  const shippingInfo = [
    {
      label: "Contact Name",
      value: values?.orderMeta?.billingAddress?.contactName,
    },
    {
      label: "Contact Number",
      value: values?.orderMeta?.billingAddress?.contactNo,
    },
    {
      label: "Address",
      value: values?.orderMeta?.billingAddress?.addressLine1,
    },
    {
      label: "Locality",
      value: values?.orderMeta?.billingAddress?.landmark,
    },
    {
      label: "City",
      value: values?.orderMeta?.billingAddress?.city,
    },
    {
      label: "State",
      value: values?.orderMeta?.billingAddress?.state,
    },
    {
      label: "Zip",
      value: values?.orderMeta?.billingAddress?.zipcode,
    },
  ]

  const status = (key) => values?.orderStatus?.find((item) => item?.status === key)
  const size = values?.orderMeta?.variant?.data?.size
  const color = values?.orderMeta?.variant?.data?.color
  const style = values?.orderMeta?.variant?.data?.style

  const pending_for_approval = status(COLUMN_ACTIONS.PENDING_FOR_APPROVAL)
  const accepted = status(COLUMN_ACTIONS.ACCEPTED)
  const rejected = status(COLUMN_ACTIONS.REJECTED)
  const shipped = status(COLUMN_ACTIONS.SHIPPED)
  const delivered = status(COLUMN_ACTIONS.DELIVERED)

  return (
    <div className="product-container">
      <Row align='middle' className='mt-8'>
        <Col span={6}>
          <ImageCell
            data={values?.orderMeta?.variant?.contentMedia?.[0]?.media?.link}
          />
        </Col>
        <Col span={18}>
          <Typography className='size-18 weight-700'>
            {values?.orderMeta?.product?.data?.[ENGLISH]?.name}
          </Typography>
          <p
            dangerouslySetInnerHTML={{
              __html: values?.orderMeta?.product?.data?.[ENGLISH]?.description
            }}
          />
          <Space>
            {
              color && (
                <Space>
                  <span>
                    Colour:
                  </span>
                  <div
                    className="color-badge size-20"
                    style={{
                      background: `#${color?.rgbcode}`
                    }}
                  />
                  <span>
                    {color?.name}
                  </span>
                </Space>
              )
            }
            {
              size && (
                <Space>
                  <span>Size:</span>
                  <span>{size}</span>
                </Space>
              )
            }
            {
              style && (
                <Space>
                  <span>Style:</span>
                  <span>{style}</span>
                </Space>
              )
            }
          </Space>
        </Col>
      </Row>
      <Divider/>
      <Typography className='weight-700 mt-20 size-18'>
        Order Info
      </Typography>
      <List
        split={false}
        size="small"
        gutter={0}
        dataSource={data}
        renderItem={item => (
          <List.Item
            style={{
              padding: 0,
              justifyContent: 'flex-start'
            }}
          >
            <Space>
              <span>{item.label}:</span>
              <span>{item.value}</span>
            </Space>
          </List.Item>
        )}
      />
      <Typography className='weight-700 mt-20 size-18'>
        Shipping Info.
      </Typography>
      <List
        className='mt-8'
        split={false}
        size="small"
        gutter={0}
        dataSource={shippingInfo}
        renderItem={item => (
          <List.Item
            style={{
              padding: 0,
              justifyContent: 'flex-start'
            }}
          >
            <Space>
              <span>{item.label}:</span>
              <span>{item.value}</span>
            </Space>
          </List.Item>
        )}
      />
      <Typography className='weight-700 mt-20 size-18'>
        Tracking & Carrier
      </Typography>
      <Timeline className='mt-8 mb-40'>
        <Timeline.Item
          dot={latestStatus && latestStatus === pending_for_approval?.status && <Ring />}
        >
          <Typography>Order Placed</Typography>
          <Status status={pending_for_approval}/>
        </Timeline.Item>
        {
          rejected ? (
            <Timeline.Item
              dot={latestStatus && latestStatus === accepted?.status && <Ring />}
            >
              <Typography>Order Rejected</Typography>
              <Status status={rejected}/>
              <Space>
                <Typography>Comments:</Typography>
                <Typography>{values?.orderMeta?.comments}</Typography>
              </Space>
            </Timeline.Item>
          ) : accepted && (
            <>
              <Timeline.Item
                dot={latestStatus && latestStatus === accepted?.status && <Ring />}
              >
                <Typography>Order Accepted</Typography>
                <Status status={accepted}/>
              </Timeline.Item>
              <Timeline.Item
                dot={latestStatus && latestStatus === shipped?.status && <Ring />}
              >
                <Typography>Order Shipped</Typography>
                <Status status={shipped}/>
                {
                  shipped && (
                    <>
                      <div className='weight-600 mt-8 size-16'>Shipping Details</div>
                      <div>
                        <Space>
                          <Typography>Courier:</Typography>
                          <Typography>{values?.orderMeta?.courierId}</Typography>
                        </Space>
                      </div>
                      <div>
                        <Space>
                          <Typography>Courier Tracking ID:</Typography>
                          <Typography>
                            {values?.orderMeta?.courierTrackingId}
                          </Typography>
                        </Space>
                      </div>
                      <div>
                        <Space>
                          <Typography>Courier Delivery ETA:</Typography>
                          {
                            values?.orderMeta?.courierDeliveryEstimatedDate ? (
                              <Typography>
                                {moment(values?.orderMeta?.courierDeliveryEstimatedDate).format('Do MMM, YYYY')}
                              </Typography>
                            ) : null
                          }
                        </Space>
                      </div>
                    </>
                  )
                }
              </Timeline.Item>
              <Timeline.Item
                dot={latestStatus && latestStatus === delivered?.status && <Ring />}
              >
                <Typography>Order Delivery</Typography>
                <Status status={delivered}/>
              </Timeline.Item>   
            </>
          )
        }
      </Timeline>
    </div>
  )
}
