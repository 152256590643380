import "./index.scss"


export const HTMLConatiner = ({ heading, html }) => {
  return (
    <>
      <h1 className = "container-heading">{heading}</h1>
      <div className = "html-container" dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
};
