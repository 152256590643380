/* eslint-disable no-useless-escape */
import { capitalizeTitle } from "utils/helpers";
import * as Yup from "yup";

export const Task_SCHEMA = Yup.lazy((value) => {
  if (value?.taskType === "newpost") {
    return Yup.object().shape({
      taskType: Yup.string().required("Task type is required"),
      mediaId: Yup.string().required("Image is required"),
      deepLinkMediaId: Yup.string().required("Image is required"),
      data: Yup.object().shape({
        en: Yup.object().shape({
          title: Yup.string().required("Title is required"),
          // description: Yup.string().required("Description is required"),
        }),
        hi: Yup.object().shape({
          title: Yup.string().required("Title is required"),
          // description: Yup.string().required("Description is required"),
        }),
        mr: Yup.object().shape({
          title: Yup.string().required("Title is required"),
          // description: Yup.string().required("Description is required"),
        }),
      }),
      socialMedia: Yup.lazy((value) => {
        return Yup.array().of(
          Yup.object().shape({
            platform: Yup.string()
              .required("Platform is required")
              .test("platform", "Platform should be unique", function (val) {
                if (val && value.length) {
                  let count = 0;
                  for (let i = 0; i < value.length; i++) {
                    if (value[i]?.platform === val) count++;
                  }
                  if (count > 1) return false;
                  else return true;
                } else return true;
              }),
            points: Yup.string()
              .required("points is required")
              .test("points", "Please enter a valid integer", function (value) {
                const { points } = this.parent;
                const str = points?.toString();
                if (
                  str?.includes("-") ||
                  str?.includes("+") ||
                  str?.includes(".")
                ) {
                  return false;
                } else if (isNaN(+points)) {
                  return false;
                } else if (+points <= 0.99) {
                  return false;
                } else {
                  return true;
                }
              }),
          })
        );
      }),
    });
  } else {
    return Yup.object().shape({
      taskType: Yup.string().required("Task type is required"),
      data: Yup.object().shape({
        en: Yup.object().shape({
          title: Yup.string().required("Title is required"),
        }),
        hi: Yup.object().shape({
          title: Yup.string().required("Title is required"),
        }),
        mr: Yup.object().shape({
          title: Yup.string().required("Title is required"),
        }),
      }),
      totalPoints: Yup.string()
        .required("points is required")
        .test("totalPoints", "Please enter a valid integer", function (value) {
          const { totalPoints } = this.parent;
          const str = totalPoints?.toString();
          if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
            return false;
          } else if (isNaN(+totalPoints)) {
            return false;
          } else if (+totalPoints <= 0.99) {
            return false;
          } else {
            return true;
          }
        }),
      webUrl: Yup.string()
        .matches(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
          "Enter a valid url!"
        )
        .required("Web url is required"),
    });
  }
});

export const Category_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      tagName: Yup.string().required("Name is required"),
      description: Yup.string(),
    }),
    hi: Yup.object().shape({
      tagName: Yup.string().required("Name is required"),
      description: Yup.string(),
    }),
    mr: Yup.object().shape({
      tagName: Yup.string().required("Name is required"),
      description: Yup.string(),
    }),
  }),
});

export const Reward_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
  }),
  categoryType: Yup.string().required("Category is required"),
  rewardPoints: Yup.string()
    .required("Reward points is required")
    .test("rewardPoints", "Please enter a valid integer", function (value) {
      const { rewardPoints } = this.parent;
      const str = rewardPoints?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+rewardPoints)) {
        return false;
      } else {
        return true;
      }
    })
    .test(
      "rewardPoints",
      "Points must be greator then or equal to 10",
      function (value) {
        const { rewardPoints } = this.parent;
        if (+rewardPoints < 10) {
          return false;
        } else {
          return true;
        }
      }
    ),
});

export const TROPHY_SCHEMA = Yup.object().shape({
  mediaId: Yup.string().required("Image is required"),
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Trophy name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Trophy name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Trophy name is required"),
    }),
  }),
  trophyPoints: Yup.string()
    .required("Points is required")
    .test("trophyPoints", "Please enter a valid integer", function (value) {
      const { trophyPoints } = this.parent;
      const str = trophyPoints?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+trophyPoints)) {
        return false;
      } else {
        return true;
      }
    })
    .test(
      "trophyPoints",
      "Points must be greator then or equal to 10",
      function (value) {
        const { trophyPoints } = this.parent;
        if (+trophyPoints < 10) {
          return false;
        } else {
          return true;
        }
      }
    ),
});

export const getTaskInitialValues = (values = {}) => ({
  mediaId: values.mediaId || "",
  deepLinkMediaId: values.deepLinkMediaId || "",
  data: values.data || {
    en: {
      title: "",
      description: "",
    },
    hi: {
      title: "",
      description: "",
    },
    mr: {
      title: "",
      description: "",
    },
  },
  deepLinkImage: {
    type: values?.deepLinkImage?.type || "",
    link: values?.deepLinkImage?.link || "",
  },
  isNotification: false,
  image: values?.image || undefined,
  taskType: values.taskType || undefined,
  totalPoints: values.totalPoints || 0,
  webUrl: values.webUrl || "",
  socialMedia: values?.socialMediaCounts
    ? getSocialCount(values?.socialMediaCounts)
    : [{ platform: undefined, points: "" }],
});

const getSocialCount = (values) => {
  let social = [];
  for (const key in values) {
    social.push({
      platform: capitalizeTitle(key),
      points: values[key]?.points,
    });
  }
  return social;
};

export const getCategoryInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      tagName: "",
      description: "",
    },
    hi: {
      tagName: "",
      description: "",
    },
    mr: {
      tagName: "",
      description: "",
    },
  },
});

export const getRewardInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
    },
    hi: {
      title: "",
    },
    mr: {
      title: "",
    },
  },
  categoryType: values.categoryType || undefined,
  rewardPoints: values.rewardPoints || "",
});

export const getTrophiesInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  mediaId: values.mediaId || "",
  media: values.media || undefined,
  trophyPoints: values.trophyPoints || "",
});
