import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";


const HindiLanguageForm = ({values}) => {
    
return (
    <>
    <p className="mb-10 mt-30 field-label">Name</p>
        <Field
          value={values?.name}
          name= {`data[hi].name`}
          type="text"
          suffix={<div>{20 - (values?.name?.length || 0)} letters</div>}
          component={AntInput}
          placeholder={"Enter category name"}
          maxLength={20}
        />
    </>
)}

export default HindiLanguageForm