import { Menu } from "antd";
import React, { useState } from "react";
import "./InnerHeader.scss";

const InnerHeader = ({
  data,
  onclick,
  activeCount = 0,
  showCount = true,
  languageId,
  ...props
}) => {
  const [current, setCurrent] = useState(data[0]?.label?.toLowerCase());

  if (data?.length === 0) return null;
  return (
    <div className="inner-header-component">
      <div className="inner-header">
        <Menu defaultSelectedKeys={current} mode="horizontal">
          {data.map(({ label, title }, index) => (
            <Menu.Item
              onClick={(e) => {
                onclick(
                  label
                    ?.toLowerCase()
                    .split(" ")
                    .map((item) => {
                      return item.replace("&", "_");
                    })
                    .join("")
                );
                setCurrent(label?.toLowerCase());
              }}
              key={[label?.toLowerCase()]}
            >
              {title?.[languageId] || label}
              {showCount && label?.toLowerCase() === current && (
                <div className={"count-container"}>
                  <span className={"count"}>{activeCount}</span>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default InnerHeader;
