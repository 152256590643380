import { Button, Upload, Progress } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import "./AddAdminForm.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
} from "../../utils/helpers";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import Date from "components/Date/date";
import { UploadButton } from "components/UploadButtons";
import ImageEditor from "components/ImageEditor";

const AddAdminForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  gender,
  userType,
  isEditing,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [Image, setImage] = useState(values?.image);
  const [uploadprogress, setUploadprogress] = useState(0);

  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const handleChange = (info) => {
    let imageValidation = beforeUpload(info.file.originFileObj);
    hanldeProgress();

    if (imageValidation) {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <Form>
      <div className="add-form">
        <p className="mb-10 mt-20 field-label">Image</p>
        {Image ? (
          <ImageEditor
            image={Image}
            onDelete={() => {
              setImage("");
              setFieldValue("mediaId", "");
              setUploadprogress(0);
            }}
            isEditing={uploadprogress}
            handleEditing={(status) => {
              if (status && uploadprogress) {
                setUploadprogress(0);
              }
            }}
            onSave={async (url) => {
              let file = dataURLtoFile(url, `image_${Math.random()}.png`);
              let response = await uploadImage(file, hanldeProgress());
              if (response) {
                setFieldValue("mediaId", response);
                setImage(url);
                setLoading(false);
              }
            }}
            width={200}
          />
        ) : (
          <Upload
            name="mediaId"
            listType={`picture-card ${hasError("mediaId") ? "error" : ""}`}
            className="picture-uploader"
            showUploadList={false}
            // action={handleUpload}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            accept="image/png, image/jpeg, image/jpg"
            progress={<Progress percent={uploadprogress} showInfo={true} />}
          >
            {<UploadButton loading={loading} />}
          </Upload>
        )}
        {uploadprogress === 100 && (
          <div className="success-text">Image uploaded Successfully</div>
        )}
        {uploadprogress > 0 && uploadprogress < 100 && (
          <div className="progress-bar">
            <Progress percent={uploadprogress} showInfo={true} />
          </div>
        )}
        <div className="ant-form-item-explain ant-form-item-explain-error">
          {hasError("mediaId")}
        </div>
        <p className="mb-10 mt-12 field-label">User type</p>
        <Field
          value={values.roleId}
          selectOptions={userType}
          optionValueKey="id"
          optionNameKey="name"
          filterOption={true}
          showSearch={true}
          mode={"single"}
          name="roleId"
          component={AntSelect}
          placeholder={"Select type"}
        />
        <p className="mb-8 field-label">Name</p>
        <Field
          value={values.name}
          name="name"
          type="text"
          component={AntInput}
          placeholder={"Enter admin name"}
        />
        <p className="mb-8 field-label">Email ID</p>
        <Field
          value={values.email}
          name="email"
          type="email"
          component={AntInput}
          placeholder={"Enter admin’s email id"}
        />
        {!isEditing && (
          <>
            <p className="mb-8 field-label">Password</p>
            <Field
              value={values.password}
              name="password"
              type="text"
              component={AntInput}
              placeholder={"Enter password"}
            />
          </>
        )}
        <p className="mb-8 field-label">Phone number</p>
        <Field
          value={values.phoneNo}
          name="phoneNo"
          type="text"
          component={AntInput}
          placeholder={"Enter number here"}
          maxLength={10}
          style={{ width: "100%" }}
        />
        <p className="mb-8 field-label">Gender</p>
        <Field
          value={values.gender}
          selectOptions={gender}
          optionValueKey="name"
          optionNameKey="name"
          filterOption={true}
          showSearch={true}
          mode={"single"}
          name="gender"
          component={AntSelect}
          placeholder={"Select Gender"}
        />
        <p className="mb-8 field-label">Date of birth</p>
        <FastField
          defaultValue={values.dob}
          name="dob"
          format="DD/MM/YY"
          component={Date}
          placeholder={"dd/mm/yy"}
        />
        <p className="mb-8 field-label">Location</p>
        <Field
          value={values.location}
          name="location"
          type="text"
          component={AntInput}
          placeholder={"First line of address"}
        />
        <Button
          loading={isSubmitting}
          className="mt-40 submit-btn"
          type="primary"
          htmlType="submit"
        >
          {submitButtonLabel}
        </Button>
      </div>
    </Form>
  );
};

export default AddAdminForm;
