import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Row } from "antd";
import "antd/dist/antd.css";
import { Field, Form, Formik } from "formik";
import "./Login.scss";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import { Link, useHistory } from "react-router-dom";
import * as AuthSuppliers from "../../store/auth/actions";
import LoginImage from "../../assets/images/LoginImage.png";
import {
  CREATE_PASS_SCHEMA,
  CREATE_PASS_VALUES,
  LOGIN_INIT_VALUES,
  LOGIN_SCHEMA,
  RESET_PASS_SCHEMA,
  RESET_PASS_VALUES,
} from "./validate";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { storage } from "services/config/storage";

const FORM_MODES = {
  LOGIN: "login",
  RESET_PASS: "resetPassword",
  CREATE_PASS: "createPassword",
};

const Login = (props) => {
  const [persistUser, setPersistUser] = useState(true);
  const [formTitle, setFormTitle] = useState("Hello, Welcome back");
  const [formText, setFormText] = useState("Please login to your account");
  const [formMode, setFormMode] = useState(FORM_MODES.LOGIN);
  const history = useHistory();

  useEffect(() => {
    if (history?.location?.pathname === "/forgot-password") {
      setFormMode(FORM_MODES.RESET_PASS);
      setFormTitle("Reset your password");
      setFormText("Enter your registered email below to get the OTP");
    } else if (history?.location?.pathname === "/reset-password") {
      setFormMode(FORM_MODES.CREATE_PASS);
      setFormTitle("Create new password");
      setFormText("Enter the new password you want to create");
    } else {
      setFormMode(FORM_MODES.LOGIN);
      setFormTitle("Hello, Welcome back");
      setFormText("Please login to your account");
    }
  }, [history.location.pathname]);

  const submitHandler = async (values, { setSubmitting }) => {
    let data = {},
      result = {};
    if (formMode === FORM_MODES.LOGIN) {
      data = {
        email: values.email,
        password: values.password,
      };
      setSubmitting(true);
      result = await props.login(data);
      handlePage(result);
    }
    if (formMode === FORM_MODES.RESET_PASS) {
      result = await props.forgotpassword(values);
      if (result.status === 200) {
        let userData = result?.config?.data;
        storage.set.userDetails(userData);
        history.replace("/reset-password");
      }
    }
    if (formMode === FORM_MODES.CREATE_PASS) {
      let userData = storage.fetch.userDetails();
      let data = {
        ...userData,
        otp: values.otp,
        newPassword: values.newPassword,
      };
      result = await props.createpassword(data);
      if (result.status === 200) {
        storage.destroy.userDetails();
        history.replace("/login");
      }
    }
    setSubmitting(false);
  };

  const handlePage = (result) => {
    if (result.status === 200) {
      if (persistUser) {
        storage.set.rememberUser(persistUser, result?.data?.token); //is remember me is selected? save user token in LS
      } else {
        storage.set.removeToken();
        storage.set.authToken(result?.data?.token);
      }
      storage.set.permissions(result?.data?.permission);
      storage.set.language(result?.data?.languageId);

      let innerTab = result?.data?.permission
        ?.filter((item) => item !== "Dashboard")?.[0]
        .toLowerCase()
        ?.replace(/ /g, "");
      let route = formMode === FORM_MODES.LOGIN ? `/${innerTab}` : "/login";
      history.replace(route);
    }
  };

  return (
    <div className="flex flex-row login-form-container">
      <img
        className="flex justify-center items-center login-banner"
        src={LoginImage}
        alt={"login banner"}
      />
      <Formik
        enableReinitialize
        initialValues={
          formMode === FORM_MODES.LOGIN
            ? LOGIN_INIT_VALUES
            : formMode === FORM_MODES.RESET_PASS
            ? RESET_PASS_VALUES
            : CREATE_PASS_VALUES
        }
        onSubmit={submitHandler}
        validationSchema={
          formMode === FORM_MODES.LOGIN
            ? LOGIN_SCHEMA
            : formMode === FORM_MODES.RESET_PASS
            ? RESET_PASS_SCHEMA
            : CREATE_PASS_SCHEMA
        }
      >
        {({ isSubmitting, values, errors }) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, x: 400 }}
            className="login-form"
          >
            <p
              className={
                formMode === FORM_MODES.CREATE_PASS ? "create-logo" : "logo"
              }
            >
              NCP
            </p>
            <p className={"title"}>{formTitle}</p>

            <p className="sub-title">{formText}</p>
            <div
              className={
                history.location.pathname === "/forgot-password"
                  ? "reset-form-container"
                  : "form-container"
              }
            >
              <Form>
                <>
                  {formMode === FORM_MODES.RESET_PASS && (
                    <>
                      <p className="field-label">Email</p>
                      <Field
                        value={values.email}
                        name="email"
                        type="text"
                        component={AntInput}
                        placeholder={"Enter your registered email"}
                      />
                      <p
                        className="sub-title mt-0 w-290px reset-sub-title"
                        style={{
                          fontSize: 12,
                          opacity: 0.4,
                        }}
                      ></p>
                    </>
                  )}
                  {formMode === FORM_MODES.CREATE_PASS && (
                    <>
                      <p className="field-label">New password</p>
                      <Field
                        value={values.newPassword}
                        name="newPassword"
                        type="password"
                        component={AntInput}
                        placeholder={"Enter your new password"}
                      />
                      <p className="field-label">Confirm password</p>
                      <Field
                        value={values.confirmPassword}
                        name="confirmPassword"
                        type="text"
                        component={AntInput}
                        placeholder={"Confirm your password"}
                      />
                      <p className="field-label">OTP</p>
                      <Field
                        value={values.otp}
                        name="otp"
                        type="text"
                        component={AntInput}
                        placeholder={"Enter your OTP"}
                        maxLength={4}
                      />
                      <Row
                        style={{
                          justifyContent: "space-between",
                          marginTop: "-8px",
                        }}
                      >
                        <Col span={11}></Col>
                        <Col
                          span={11}
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <Link
                            to={"/forgot-password"}
                            style={{ color: "#4b68ef" }}
                          >
                            resend otp?
                          </Link>
                        </Col>
                      </Row>
                    </>
                  )}
                  {formMode === FORM_MODES.LOGIN && (
                    <div style={{ marginTop: "-8px" }}>
                      <p className="field-label">Email ID</p>
                      <Field
                        value={values.email}
                        name="email"
                        type="text"
                        component={AntInput}
                        placeholder={"Enter your email ID"}
                      />
                      <p className="field-label">Password</p>
                      <Field
                        value={values.password}
                        name="password"
                        type="password"
                        component={AntInput}
                        placeholder={"Enter password"}
                      />
                      <Row
                        style={{
                          justifyContent: "space-between",
                          marginTop: "-8px",
                        }}
                      >
                        <Col span={11}>
                          <Checkbox
                            checked={persistUser}
                            onChange={({ target }) => {
                              setPersistUser(target.checked);
                              storage.set.rememberUser(target.checked);
                            }}
                          >
                            <p className={"field-label"}>Remember me</p>
                          </Checkbox>
                        </Col>
                        <Col
                          span={11}
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <Link
                            to={"/forgot-password"}
                            style={{ color: "#4b68ef" }}
                          >
                            Forgot Password?
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  )}
                </>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ant-btn-block bg-color-primary size-14 weight-700"
                  disabled={isSubmitting}
                >
                  {formMode === FORM_MODES.LOGIN
                    ? "Login"
                    : formMode === FORM_MODES.RESET_PASS
                    ? "Send OTP"
                    : "Reset password"}
                </Button>
              </Form>
              <Row justify="space-between" className="privacy-policy-container">
                {formMode === FORM_MODES.LOGIN ? (
                  <>
                    <Col span={11}>
                      <Link
                        to={"/privacy-policy"}
                        target="_blank"
                        style={{ color: "#4b68ef" }}
                      >
                        Privacy policy
                      </Link>
                    </Col>
                    <Col
                      span={11}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Link
                        to={"/terms-and-condition"}
                        target="_blank"
                        style={{ color: "#4b68ef" }}
                      >
                        Terms and Conditions
                      </Link>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </motion.div>
        )}
      </Formik>
    </div>
  );
};

function mapStateToProps({ auth }) {
  return {
    loading: auth?.loading,
    error: auth?.error,
    data: auth?.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: bindActionCreators(AuthSuppliers.login, dispatch),
    forgotpassword: bindActionCreators(AuthSuppliers.forgotPassword, dispatch),
    createpassword: bindActionCreators(AuthSuppliers.createPassword, dispatch),
  };
}

Login.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
