import { object, string } from "yup";

export const LOGIN_SCHEMA = object().shape({
  email: string().email("Invalid email").required("Email is required"),
  password: string().required("Password is required"),
});

export const RESET_PASS_SCHEMA = object().shape({
  email: string().email("Invalid email").required("Email is required"),
});

export const CREATE_PASS_SCHEMA = object().shape({
  newPassword: string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,15}$/,
      "Password should contain atleast one upper-case one lower-case letter and one number"
    )
    .required("Password is required"),
  confirmPassword: string()
    .test("confirmPassword", "Password does not match", function (value) {
      const { newPassword } = this.parent;
      if (newPassword === value) {
        return true;
      } else {
        return false;
      }
    })
    .required("Password is required"),
  otp: string()
    .required("OTP is required")
    .test("Otp", "Please entaer a valid integer", function (value) {
      const { otp } = this.parent;
      const str = otp?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+otp)) {
        return false;
      } else {
        return true;
      }
    }),
});

export const LOGIN_INIT_VALUES = {
  email: process.env.NODE_ENV === "development" ? "admin@ncp.com" : "",
  password: process.env.NODE_ENV === "development" ? "ncp@2021" : "",
};

export const RESET_PASS_VALUES = {
  email: "",
  countryCode: "91",
  userType: "admin",
};

export const CREATE_PASS_VALUES = {
  otp: "",
  newPassword: "",
  confirmPassword: "",
};
