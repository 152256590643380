import { TextCell } from "../../components/TableCells/TextCell";
import { ToggleCell } from "../../components/TableCells/ToggleCell";
import { COLUMN_ACTIONS } from "../../constants/dummyData";
import { ImageCell } from "components/TableCells/ImageCell";
import { ColumnHeaderWithSorter } from "../../components/ColumnHeaderWithSorter";
import { DateCell } from "components/TableCells/DateCell";

export const returnEventColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "10%",
    render: (rowData, data, index) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        className="square-img"
        data={rowData?.contentMedia?.[0]?.media?.link}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: "Category",
    width: "15%",
    render: (rowData) => <TextCell data={"Event"} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnInfographicsColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "10%",
    render: (rowData) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        className="square-img"
        data={rowData?.contentMedia?.[0]?.media?.link}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: "Category",
    width: "10%",
    render: (rowData) => <TextCell data={"Infographics"} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnPollsColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "S.no",
    width: "5%",
    render: (rowData) => <TextCell data={`${rowData.sNo}.`} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: "Category",
    width: "15%",
    render: (rowData) => <TextCell data={"Poll"} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnArticleColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "15%",
    render: (rowData) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        className="square-img"
        data={rowData?.contentMedia?.[0]?.media?.link}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: "Category",
    width: "15%",
    render: (rowData) => <TextCell data={"Article"} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnMessagesColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "S.no",
    width: "3%",
    render: (rowData) => <TextCell data={`${rowData.sNo}.`} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: "Category",
    width: "15%",
    render: (rowData) => <TextCell data={"Important message"} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnVotesColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "15%",
    render: (rowData) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        className="square-img"
        data={rowData?.choices?.[0]?.media?.link}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: "Category",
    width: "15%",
    render: (rowData) => <TextCell data={"Vote"} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnAnnouncementColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "S.no",
    width: "3%",
    render: (rowData) => <TextCell data={`${rowData.sNo}.`} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: "Category",
    width: "15%",
    render: (rowData) => <TextCell data={"Announcement"} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnUsersColumns = () => [
  {
    title: "Image",
    width: "15%",
    render: (rowData) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        data={rowData?.media?.link}
      />
    ),
  },
  {
    title: "Username",
    width: "25%",
    render: (rowData) => <TextCell data={rowData?.name} />,
  },
  {
    title: "UserId",
    width: "15%",
    render: (rowData) => <TextCell data={rowData?.username} />,
  },
];

export const returnFacebookLiveColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "10%",
    render: (rowData, data, index) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        className="square-img"
        data={rowData?.contentMedia?.[0]?.media?.link}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnYoutubeVideoColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "15%",
    render: (rowData) => (
      <ImageCell
        sortedColumn={true}
        isBold={true}
        data={rowData?.contentMedia?.[0]?.media?.link}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell data={rowData?.["data"]?.[languageId].title} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Published on"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData, data, index) => (
      <ToggleCell
        key={index + Math.random() * 100 + 1}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];
