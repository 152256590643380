import { Button, Col, Row } from "antd";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { FastField, Field, Form } from "formik";
import React, { useEffect, useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import Date from "components/Date/date";
import "../index.scss";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";

const AddTalukaForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  taskType,
  points,
  errors,
  touched,
  access,
  district,
  division,
  submitButtonLabel,
  getMetaData,
  fetchMetadata = true,
  showOptionalField = true,
  fieldName = "Taluka name",
  ...props
}) => {
  useEffect(() => {
    fetchMetadata && getMetaData("district", values.divisionId || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && (errors?.districtId || errors?.data?.en);
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              {showOptionalField && (
                <Row
                  className="mt-32"
                  style={{ justifyContent: "space-between" }}
                >
                  <Col span={11}>
                    <p className="mb-8 field-label">Date added</p>
                    <Field
                      defaultValue={values.createdAt}
                      disabled={true}
                      name="createdAt"
                      format="DD/MM/YY"
                      component={Date}
                      type={"datePicker"}
                      placeholder={"dd/mm/yy"}
                    />
                  </Col>
                  <Col span={11}>
                    <p className="mb-8 field-label">Taluka code</p>
                    <FastField
                      value={values.talukaCode}
                      name="talukaCode"
                      type="text"
                      component={AntInput}
                      placeholder={"123456"}
                    />
                  </Col>
                </Row>
              )}
              <p className="mb-8 field-label">{fieldName}</p>
              <Field
                value={values?.taluka}
                name="data.en.name"
                type="text"
                component={AntInput}
                suffix={
                  <div>
                    {100 - (values?.data?.[ENGLISH]?.name?.length || 0)} letters
                  </div>
                }
                placeholder={"Enter content title "}
                maxLength = {100}
              />
              <p className="mb-8 field-label">District</p>
              <Field
                value={values.districtId}
                selectOptions={district}
                optionValueKey="id"
                optionNameKey="english"
                showSearch={true}
                autoComplete="off"
                mode={"single"}
                name="districtId"
                component={AntSelect}
                placeholder={"Maharastra"}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm
              values={values?.data?.[HINDI]}
              district={district}
              fieldName={fieldName}
            />
          ) : (
            <MarathiLanguageForm
              values={values?.data?.[MARATHI]}
              district={district}
              fieldName={fieldName}
            />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddTalukaForm;
