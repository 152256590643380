import { ColumnHeaderWithSorter } from "components/ColumnHeaderWithSorter";
import { TextCell } from "../../components/TableCells/TextCell";
import "./master.scss";

export const returnDivisionColumns = ({
  onSort,
  sortColumn,
  fetchModal,
  languageId,
}) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: "Division code",
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData.divisionCode} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Division name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.name} />,
  },
  {
    title: "No. of districts",
    width: "15%",
    render: (rowData) => {
      return (
        <div
          style={{ cursor: "pointer", color: "#4c68ef", fontWeight: "bold" }}
          className="table-rows"
          onClick={(e) => {
            e.stopPropagation();
            rowData.districtsCount &&
              fetchModal({
                name: "districts",
                divisionId: rowData?.id,
                count: rowData.districtsCount,
              });
          }}
        >
          {rowData.districtsCount}
        </div>
      );
    },
  },
];

export const returnDistrictColumns = ({
  onSort,
  sortColumn,
  fetchModal = "",
  languageId,
}) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: "District code",
    width: "5%",
    align: "left",
    render: (rowData) => <TextCell data={rowData.districtCode} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"District name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "5%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
  {
    title: "No. of Talukas",
    width: "5%",
    render: (rowData) => {
      return (
        <div
          style={{ cursor: "pointer", color: "#4c68ef", fontWeight: "bold" }}
          onClick={(e) => {
            e.stopPropagation();
            rowData.talukasCount &&
              fetchModal({
                name: "talukas",
                districtId: rowData.id,
                count: rowData.talukasCount,
              });
          }}
        >
          {rowData.talukasCount || "-"}
        </div>
      );
    },
  },
  {
    title: "No. of assembly",
    width: "5%",
    render: (rowData) => {
      return (
        <div
          style={{ cursor: "pointer", color: "#4c68ef", fontWeight: "bold" }}
          onClick={(e) => {
            e.stopPropagation();
            rowData.assemblyCount &&
              fetchModal({
                districtId: rowData?.id,
                name: "assembly",
                count: rowData.assemblyCount,
              });
          }}
        >
          {rowData.assemblyCount || "-"}
        </div>
      );
    },
  },
];

export const returnTalukaColumns = ({
  onSort,
  sortColumn,
  fetchModal,
  languageId,
}) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: "Taluka code",
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData.talukaCode} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Taluka name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
  {
    title: "No. of villages",
    width: "5%",
    render: (rowData) => {
      return (
        <div
          style={{ cursor: "pointer", color: "#4c68ef", fontWeight: "bold" }}
          onClick={(e) => {
            e.stopPropagation();
            rowData.villagesCount &&
              fetchModal({
                name: "village",
                talukaId: rowData?.id,
                count: rowData?.villagesCount,
              });
          }}
        >
          {rowData.villagesCount || "-"}
        </div>
      );
    },
  },
];

export const returnAssemblyColumns = ({
  onSort,
  sortColumn,
  fetchModal,
  languageId,
  performColumnAction,
}) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: "Assembly code",
    width: "4%",
    align: "left",
    render: (rowData) => <TextCell data={rowData.assemblyCode} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Assembly name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];

export const returnVillageColumns = ({
  onSort,
  sortColumn,
  fetchModal,
  languageId,
  performColumnAction,
}) => [
  {
    title: "S.no.",
    width: "2%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: "Village code",
    width: "4%",
    align: "left",
    render: (rowData) => <TextCell data={rowData.villageCode} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Village name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    align: "left",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId].name} />,
  },
];
