import { Button, Col, Row } from "antd";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { FastField, Field, Form } from "formik";
import React, { useEffect, useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import Date from "components/Date/date";
import "../index.scss";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";

const AddVillageForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  taskType,
  points,
  errors,
  touched,
  access,
  state,
  division,
  district,
  taluka,
  submitButtonLabel,
  getMetaData,
  ...props
}) => {

  
  useEffect(() => {
    getMetaData("taluka");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && (errors?.talukaId || errors?.data?.en);
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <Row
                className="mt-32"
                style={{ justifyContent: "space-between" }}
              >
                <Col span={11}>
                  <p className="mb-8 field-label">Date added</p>
                  <Field
                    defaultValue={values.createdAt}
                    disabled={true}
                    name="createdAt"
                    format="DD/MM/YY"
                    component={Date}
                    type={"datePicker"}
                    placeholder={"dd/mm/yy"}
                  />
                </Col>
                <Col span={11}>
                  <p className="mb-8 field-label">Village code</p>
                  <FastField
                    value={values.villageCode}
                    name="villageCode"
                    type="text"
                    component={AntInput}
                    placeholder={"123456"}
                  />
                </Col>
              </Row>
              <p className="mb-8 field-label">Village name</p>
              <Field
                value={values.village}
                name="data.en.name"
                type="text"
                component={AntInput}
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH].name?.length || 0)} letters
                  </div>
                }
                placeholder={"Enter content title "}
              />
              <p className="mb-8 field-label">Taluka</p>
              <Field
                value={values.talukaId}
                selectOptions={taluka}
                optionValueKey="id"
                optionNameKey="english"
                showSearch={true}
                autoComplete="off"
                mode={"single"}
                name="talukaId"
                component={AntSelect}
                placeholder={"Maharastra"}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data?.[HINDI]} taluka={taluka} />
          ) : (
            <MarathiLanguageForm
              values={values?.data?.[HINDI]}
              taluka={taluka}
            />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddVillageForm;
