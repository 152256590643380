import * as actionTypes from "./actionTypes";
import { initialDataState } from "./constants";

export const schema = {
  ...initialDataState,
};

const initialState = schema;

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload, updateReadonlyList } = action;
  switch (type) {
    case actionTypes.FETCHING_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCHING_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SET_UTILS:
      return setUtils(state, payload);
    case actionTypes.ADD_DATA:
      return appendData(state, payload);
    case actionTypes.UPDATE_DATA:
      return editData(state, payload);
    case actionTypes.SET_DATA:
      return setData(state, payload, updateReadonlyList);
    case actionTypes.SET_FORM_DATA:
      return setFormData(state, payload, updateReadonlyList);
    case actionTypes.UPDATE_FILTERS:
      return setFilters(state, payload);
    case actionTypes.SET_META_DATA:
      return setMetaData(state, payload);
    case actionTypes.SET_DROPDOWN_DATA:
      return setDropDownData(state, payload);
    default:
      return state;
  }
}

const appendData = (state, payload) => {
  return {
    ...state,
    loading: false,
    list: [payload.data, ...state.list],
  };
};

const setData = (state, payload, updateReadonlyList) => {
  if (!payload) {
    return {
      ...initialDataState,
    };
  }
  return {
    ...state,
    loading: false,
    list: payload.list?.rows || payload.list,
    count: payload.list.count,
    activeCount: payload.activeCount,
    tag: payload.tags,
    page: payload.page?.page,
    // filters: payload.filters.metaData,
    utils: payload.utils,
    readonlyList: updateReadonlyList
      ? state.filters?.id__in?.length === 0 //just a category ID check such that on mount of Categories, it doesn't replace the reasonly data
        ? payload?.data
        : state?.readonlyList
      : state?.readonlyList,
    // metadata: payload?.metadata,
  };
};

const setFormData = (state, payload, updateReadonlyList) => {
  if (!payload) {
    return {
      ...initialDataState,
    };
  }
  return {
    ...state,
    loading: false,
    formData: payload,
    readonlyList: updateReadonlyList
      ? state.filters?.id__in?.length === 0 //just a category ID check such that on mount of Categories, it doesn't replace the reasonly data
        ? payload?.data
        : state?.readonlyList
      : state?.readonlyList,
    metadata: payload?.metadata,
  };
};

const setFilters = (state, payload) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
  };
};
const editData = (state, payload) => {
  return {
    ...state,
    loading: false,
    list: state?.list?.map((item) => {
      return item.id === payload.id ? payload : item;
    }),
  };
};

const setMetaData = (state, payload) => {
  return {
    // ...state,
    // metaData: { ...state.metaData, ...payload },
    ...payload,
  };
};

const setUtils = (state, payload) => {
  return {
    ...state,
    metaData: { ...state.metaData, ...payload },
  };
};

const setDropDownData = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};
