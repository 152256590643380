import { Layout, Skeleton, Table } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as RootActions from "../../store/root/actions";
import { GENDER } from "../../constants/data";
import Modal from "../../components/Modal";
import AppHeader from "../../components/Header";
import "../common.scss";
import {
  getUserInitialValues,
  ADMIN_PUT_SCHEMA,
  ADMIN_POST_SCHEMA,
} from "./validate";
import TableToolbar from "../../components/TableToolbar";
import InnerHeader from "../../components/InnerHeader";
import {
  createPayload,
  getColumns,
  getDropDown,
  INNER_HEADERS,
  TABS,
} from "./utils";
import { CustomPagination } from "components/CustomPagination";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCMSLanguage, generateFilterUrl } from "utils/helpers";
import AddAdminForm from "forms/AddAdminForm/AddAdminForm";

const { Header, Content } = Layout;

const Users = (props) => {
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [innerTab, setInnerTab] = useState(TABS.CITIZENS);
  const [sortColumn, setSortColumn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());

  const dropdownData = props?.dropdownData;

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  useEffect(() => {
    if (formModalVisible && !isEditing) {
      //reset formData when Add modal opens
      setInitialValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModalVisible]);

  useEffect(() => {
    props.fetchDropdownData("roles", "roles");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.CITIZENS:
          return props.citizens?.[key];
        case TABS.VOLUNTEERS:
          return props.volunteers?.[key];
        case TABS.ADMINS:
          return props.admins?.[key];
        default:
          return null;
      }
    },
    [props.citizens, props.admins, props.volunteers, innerTab]
  );

  const setInitialValues = () => {
    let values = getUserInitialValues();
    setFormInitialValues(values);
  };
  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    if (query.length && query.length > 1) {
      applyFilters({ search: query });
    } else if (!query.length) {
      applyFilters({ search: query });
    }
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  const openModal = () => {
    setFormModalVisible(true);
  };

  const downloadTableData = async () => {
    let data = await props.downloadExportedData(innerTab);
    window.open(data, "_self");
  };

  return (
    <div className="wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props[innerTab]?.count}
            onclick={setInnerTab}
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                <TableToolbar
                  disableModalButton={innerTab === TABS.ADMINS ? false : true}
                  innerTab={innerTab}
                  ctaLabel={"Admin"}
                  onClickAdd={openModal}
                  showFilter={true}
                  onSearch={handleSearch}
                  dataAvailabilty={true}
                  exportData={downloadTableData}
                  query={props[innerTab]?.filters?.search || ""}
                />
                <div className="container pl-40 pr-40">
                  <Table
                    rowKey={"id"}
                    className={"is_editable"}
                    columns={getColumns(
                      innerTab,
                      handleSort,
                      sortColumn,
                      async (rowId, value) => {
                        if (rowId) {
                          // eslint-disable-next-line default-case
                          let request = await props.updateData(
                            innerTab,
                            rowId,
                            {
                              isActive: value,
                            }
                          );
                          if (request?.status === 200) {
                            fetchData(innerTab, getDataFromRedux("filters"));
                          }
                        }
                      }
                    ).map((column) => {
                      return {
                        ...column,
                        ...(props.loading && {
                          render: () => (
                            <Skeleton
                              key={column.dataIndex}
                              title={true}
                              paragraph={false}
                            />
                          ),
                        }),
                      };
                    })}
                    onRow={(record, rowIndex) => {
                      if (innerTab === TABS.ADMINS) {
                        return {
                          onClick: async (event) => {
                            try {
                              const data = await props.fetchDataForTable(
                                innerTab,
                                record.id
                              );
                              let values = data
                                ? data
                                : innerTab === TABS.ADMINS
                                ? getUserInitialValues(record)
                                : null;
                              setIsEditing(true);
                              setFormInitialValues(() => values);
                              openModal();
                              setActiveRow(record);
                            } catch {}
                            setFormModalVisible(() => true);
                          },
                        };
                      }
                    }}
                    dataSource={getDataFromRedux()}
                    pagination={false}
                  />
                  <CustomPagination
                    current={props[innerTab]?.filters?.page + 1}
                    totalCount={props[innerTab]?.count}
                    onChange={handlePageChange}
                  />
                </div>
                <Modal
                  width="500px"
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing ? "Edit User" : "New User"}
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setFormModalVisible(false);
                  }}
                  visible={formModalVisible}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(values);
                        let request;
                        if (isEditing)
                          request = props.updateData(
                            innerTab,
                            activeRow.id,
                            payload
                          );
                        else request = props.createData(innerTab, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSortColumn(null);
                              setFormModalVisible(false);
                              setIsEditing(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                          });
                        setSubmitting(false);
                      }}
                      validationSchema={
                        isEditing ? ADMIN_PUT_SCHEMA : ADMIN_POST_SCHEMA
                      }
                    >
                      {(rest) => (
                        <AddAdminForm
                          gender={GENDER}
                          isEditing={isEditing}
                          userType={getDropDown(dropdownData?.["roles"])}
                          submitButtonLabel={`${
                            isEditing ? "Update " : "Add User"
                          } `}
                          {...rest}
                        />
                      )}
                    </Formik>
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

Users.propTypes = {};

const mapStateToProps = ({
  admins,
  citizens,
  volunteers,
  metaData,
  roles,
  dropdownData,
}) => ({
  admins,
  citizens,
  volunteers,
  metaData,
  roles,
  dropdownData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
  downloadExportedData: bindActionCreators(
    RootActions.downloadExportedData,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
