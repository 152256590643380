const ROOT_KEY = "@NCP";
const LS_KEY = {
  auth_token: ROOT_KEY + ":auth",
  remember_user: ROOT_KEY + ":remember_user",
  permissions: ROOT_KEY + ":permissions",
  language: ROOT_KEY + ":language",
  userdetails: ROOT_KEY + ":userdetails",
};

// eslint-disable-next-line no-unused-vars
let storageType = checkStorage();

function checkStorage() {
  window.localStorage.setItem("ex-fg", "check");
  if (window.localStorage.getItem("ex-fg")) return "localStorage";
  else return "sessionStorage";
}

const set = {
  authToken: (data) => {
    sessionStorage.setItem(
      LS_KEY.auth_token,
      JSON.stringify({
        auth_token: data,
      })
    );
  },

  permissions: (data) => {
    localStorage.setItem(
      LS_KEY.permissions,
      JSON.stringify({
        permission: data,
      })
    );
  },

  language: (data) => {
    localStorage.setItem(
      LS_KEY.language,
      JSON.stringify({
        languageId: data,
      })
    );
  },

  userDetails: (data) => {
    sessionStorage.setItem(LS_KEY.userdetails, data);
  },

  removeToken: (data) => {
    localStorage.removeItem(LS_KEY.auth_token);
  },

  rememberUser: (data, token) => {
    localStorage.setItem(
      LS_KEY.remember_user,
      JSON.stringify({ remember_user: data })
    );
    localStorage.setItem(
      LS_KEY.auth_token,
      JSON.stringify({
        auth_token: token,
      })
    );
  },
};

const fetch = {
  authToken: () => {
    let data = localStorage.getItem(LS_KEY.auth_token);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.auth_token;
      } catch (err) {
        console.log(err);
      }
    }
  },
  permissions: () => {
    let data = localStorage.getItem(LS_KEY.permissions);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.permission;
      } catch (err) {
        console.log(err);
      }
    }
  },
  language: () => {
    let data = localStorage.getItem(LS_KEY.language);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.languageId;
      } catch (err) {
        console.log(err);
      }
    }
  },

  userDetails: () => {
    let data = sessionStorage.getItem(LS_KEY.userdetails);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded;
      } catch (err) {
        console.log(err);
      }
    }
  },

  sessionToken: () => {
    let data = sessionStorage.getItem(LS_KEY.auth_token);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.auth_token;
      } catch (err) {
        console.log(err);
      }
    }
  },
  rememberUser: () => {
    const data = localStorage.getItem(LS_KEY.remember_user);
    if (data) {
      try {
        const decoded = JSON.parse(data);
        return decoded.remember_user;
      } catch (err) {
        console.log(err);
      }
    }
  },
};

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY.auth_token);
    sessionStorage.removeItem(LS_KEY.auth_token);
  },
  permissions: () => {
    localStorage.removeItem(LS_KEY.permissions);
  },
  userDetails: () => {
    sessionStorage.removeItem(LS_KEY.userdetails);
  },
};

export const storage = {
  set,
  fetch,
  destroy,
};
