import { Layout, Skeleton, Table } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { USER_ACCESS } from "../../constants/data";
import Modal from "../../components/Modal";
import AppHeader from "../../components/Header";
import "../common.scss";
import TableToolbar from "../../components/TableToolbar";
import InnerHeader from "../../components/InnerHeader";
import {
  createPayload,
  getButtonLabel,
  getColumns,
  getDropDowns,
  INNER_HEADERS,
  TABS,
} from "./utils";
import { CustomPagination } from "components/CustomPagination";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RootActions from "../../store/root/actions";
import {
  capitalizeTitle,
  fetchCMSLanguage,
  generateFilterUrl,
  getSerializedData,
} from "utils/helpers";
import AddDivisionForm from "forms/AddDivisionForm/AddDivisionForm";
import AddDistrictForm from "forms/AddDistrictForm/AddDistrictForm";
import AddTalukaForm from "forms/AddTalukaForm/AddTalukaForm";
import AddAssemblyForm from "forms/AddAssemblyForm/AddAssemblyForm";
import AddVillageForm from "forms/AddVillageForm/AddVillageForm";
import {
  getAssemblyInitialValues,
  getDistrictInitialValues,
  getDivisionInitialValues,
  getPolicyInitialValues,
  getTalukaInitialValues,
  getValidationSchema,
  getVillageInitialValues,
  getWelcomeVideoInitialValues,
} from "./validate";
import "./master.scss";
import { AddPolicyForm } from "forms/AddPolicyForm/AddPolicyForm";
import { HTMLConatiner } from "components/HTMLContainer";
import WelcomeVideoForm from "forms/WelcomeVideoForm/WelcomeVideoForm";
import { VideoContainer } from "components/VideosContainer";

const { Header, Content } = Layout;

const Master = (props) => {
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [tableModalVisible, setTableModalVisible] = useState(false);
  const [tableHeading, setTableheading] = useState("");
  const [innerTab, setInnerTab] = useState(TABS.DIVISIONS);
  const [sortColumn, setSortColumn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  //const [updateFormInitialValues, setUpdateFormInitialValues] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [tableCount, setTableCount] = useState(0);
  const [tableParams, setTableParams] = useState({});
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());

  const metaData = props?.["utils"]?.metaData;
  let dropdownData = props?.dropdownData;

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  useEffect(() => {
    // api to get all states
    props.fetchDropdownData(
      "state",
      "rows",
      generateFilterUrl({ countryId: "59fefd61-1a80-4e17-b2cf-7e0df354173c" })
        .urlParams
    );

    // api to get all divisions
    props.fetchDropdownData(
      "division",
      "rows",
      generateFilterUrl({
        countryId: "59fefd61-1a80-4e17-b2cf-7e0df354173c",
        stateId: "8b096998-d741-4375-bcc3-4522d242eda1",
        isDivision: true,
      }).urlParams
    );

    // api to get all districts
    props.fetchDropdownData("district", "rows");

    // api to get all taluka
    props.fetchDropdownData("taluka", "rows");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.DIVISIONS:
          return props.division?.[key];
        case TABS.DISTRICTS:
          return props.district?.[key];
        case TABS.TALUKAS:
          return props.taluka?.[key];
        case TABS.ASSEMBLY:
          return props.assembly?.[key];
        case TABS.VILLAGES:
          return props.village?.[key];
        case TABS.POLICIES:
          let data = props.policy?.[key];
          if (data?.[0]?.id && !isEditing) setIsEditing(true);
          return data;
        case TABS.WELCOMEVIDEO:
          let videoData = props.welcomevideo?.[key];
          if (videoData?.[0]?.id && !isEditing) setIsEditing(true);
          return videoData;
        default:
          return null;
      }
    },
    [
      props.division,
      props.district,
      props.taluka,
      props.assembly,
      props.village,
      props.policy,
      props.welcomevideo,
      isEditing,
      innerTab,
    ]
  );

  const setInitialValues = (data = {}) => {
    let values =
      innerTab === TABS.DIVISIONS
        ? getDivisionInitialValues(data)
        : innerTab === TABS.DISTRICTS
        ? getDistrictInitialValues(data)
        : innerTab === TABS.TALUKAS
        ? getTalukaInitialValues(data)
        : innerTab === TABS.ASSEMBLY
        ? getAssemblyInitialValues(data)
        : innerTab === TABS.VILLAGES
        ? getVillageInitialValues(data)
        : innerTab === TABS.POLICIES
        ? getPolicyInitialValues(getDataFromRedux()?.[0])
        : innerTab === TABS.WELCOMEVIDEO
        ? getWelcomeVideoInitialValues(getDataFromRedux()?.[0])
        : null;
    setFormInitialValues(() => values);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    if (query.length && query.length > 1) {
      applyFilters({ search: query });
    } else if (!query.length) {
      applyFilters({ search: query });
    }
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  const openFormModal = (editing) => {
    innerTab === TABS.POLICIES && setInitialValues();
    innerTab === TABS.WELCOMEVIDEO && setInitialValues();
    setFormModalVisible(true);
  };

  const getSerialNumber = (number) => {
    if (number < 10) {
      number = `0${number}`;
    }
    return number;
  };

  const fetchModal = (data) => {
    const param = {
      ...data,
      page: pageNumber || 0,
      pageSize: 10,
    };

    delete param?.["name"];
    delete param?.["count"];

    setTableParams(param);
    const { urlParams } = generateFilterUrl(param);

    props.fetchModalTableData(data?.name, urlParams);
    setTableheading(data?.name);
    data.count && setTableCount(data.count);
    setTableModalVisible(true);
  };

  const getModalTableData = () => {
    let data = metaData?.[tableHeading]?.rows?.map((e, i) => {
      return {
        ...e,
        sNo: getSerialNumber(pageNumber * 10 + (i + 1)),
      };
    });
    return data;
  };

  const handleTablePageChange = (page) => {
    let params = {
      ...tableParams,
      page: page - 1,
    };
    params["pageSize"] = 10;
    const { urlParams } = generateFilterUrl(params);

    props.fetchModalTableData(tableHeading, urlParams);
    setPageNumber(page - 1);
  };

  const sortTableModal = (sortOrder, sortKey) => {
    const params = {
      ...tableParams,
      order: [sortKey, sortOrder],
    };
    setPageNumber(0);
    setSortColumn(sortKey);
    setTableParams(params);
    const { urlParams } = generateFilterUrl(params);

    props.fetchModalTableData(tableHeading, urlParams);
  };

  return (
    <div className="wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props?.[innerTab]?.count || "0"}
            onclick={setInnerTab}
            showCount={
              innerTab === TABS.POLICIES
                ? false
                : innerTab === TABS.WELCOMEVIDEO
                ? false
                : true
            }
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                <TableToolbar
                  innerTab={innerTab}
                  onSearch={handleSearch}
                  ctaLabel={getButtonLabel(innerTab)}
                  onClickAdd={openFormModal}
                  showFilter={
                    innerTab === TABS.POLICIES
                      ? false
                      : innerTab === TABS.WELCOMEVIDEO
                      ? false
                      : true
                  }
                  isEditMode={
                    (innerTab === TABS.POLICIES ||
                      innerTab === TABS.WELCOMEVIDEO) &&
                    getDataFromRedux()?.[0]
                  }
                  query={props[innerTab]?.filters?.search || ""}
                />
                {innerTab === TABS.POLICIES ? (
                  <div className="policy-container pl-40 pr-40 pt-20">
                    <HTMLConatiner
                      heading={"Policy"}
                      html={getDataFromRedux()?.[0]?.policyData?.[languageId]}
                    />
                  </div>
                ) : innerTab === TABS.WELCOMEVIDEO ? (
                  <div className="policy-container pl-40 pr-40 pt-20">
                    <VideoContainer
                      style={{ border: "1px solid black" }}
                      heading={"Welcome video"}
                      videos={getDataFromRedux()?.[0]?.welcome_video}
                    />
                  </div>
                ) : (
                  <div className="container pl-40 pr-40">
                    <Table
                      className={"is_editable"}
                      columns={getColumns(
                        innerTab,
                        handleSort,
                        sortColumn,
                        fetchModal,
                        languageId,
                        async (actionType, rowId, value) => {
                          switch (actionType) {
                          }
                        }
                      ).map((column) => {
                        return {
                          ...column,
                          ...(props.loading && {
                            render: () => (
                              <Skeleton
                                key={column.dataIndex}
                                title={true}
                                paragraph={false}
                              />
                            ),
                          }),
                        };
                      })}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: async (event) => {
                            try {
                              const data = await props.fetchDataForTable(
                                innerTab,
                                record.id
                              );
                              await setInitialValues(data || record);
                              setIsEditing(true);
                              setActiveRow(record);
                              openFormModal(true);
                            } catch {}
                          },
                        };
                      }}
                      dataSource={getSerializedData(
                        getDataFromRedux("filters")?.page,
                        getDataFromRedux()
                      )}
                      pagination={false}
                    />
                    <CustomPagination
                      current={props[innerTab]?.filters?.page + 1}
                      totalCount={props[innerTab]?.count}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
                <Modal
                  width={TABS.POLICIES ? "540px" : "500px"}
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing
                        ? `Edit ${getButtonLabel(innerTab)}`
                        : `Add ${getButtonLabel(innerTab)}`}
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setInitialValues();
                    setFormModalVisible(false);
                  }}
                  visible={formModalVisible}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(innerTab, values);
                        let request;
                        if (isEditing || values?.id) {
                          request = props.updateData(
                            innerTab,
                            activeRow.id || values?.id,
                            payload
                          );
                        } else request = props.createData(innerTab, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSortColumn(null);
                              setFormModalVisible(false);
                              setIsEditing(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                          });
                        setSubmitting(false);
                      }}
                      validationSchema={getValidationSchema(innerTab)}
                    >
                      {(rest) =>
                        innerTab === TABS.DIVISIONS ? (
                          <AddDivisionForm
                            state={getDropDowns(dropdownData?.["state"])}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.DISTRICTS ? (
                          <AddDistrictForm
                            division={getDropDowns(dropdownData?.["division"])}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.TALUKAS ? (
                          <AddTalukaForm
                            district={getDropDowns(dropdownData?.["district"])}
                            getMetaData={() => {}}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.ASSEMBLY ? (
                          <AddAssemblyForm
                            district={getDropDowns(dropdownData?.["district"])}
                            getMetaData={() => {}}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.VILLAGES ? (
                          <AddVillageForm
                            taluka={getDropDowns(dropdownData?.["taluka"])}
                            getMetaData={() => {}}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.POLICIES ? (
                          <AddPolicyForm
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.WELCOMEVIDEO ? (
                          <WelcomeVideoForm
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : (
                          <AddDivisionForm
                            access={USER_ACCESS}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        )
                      }
                    </Formik>
                  </div>
                </Modal>
                <Modal
                  width="900px"
                  className="tablemodal"
                  title={capitalizeTitle(tableHeading)}
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setTableModalVisible(false);
                    setPageNumber(0);
                  }}
                  footer={false}
                  visible={tableModalVisible}
                >
                  <Table
                    className={"is_editable"}
                    scroll={
                      getModalTableData()?.length > 5
                        ? { y: window.innerHeight - 390 }
                        : false
                    }
                    columns={getColumns(
                      tableHeading,
                      sortTableModal,
                      sortColumn,
                      fetchModal,
                      languageId,
                      () => {}
                    ).map((column) => {
                      return {
                        ...column,
                        ...(props.loading && {
                          render: () => (
                            <Skeleton
                              key={column.dataIndex}
                              title={true}
                              paragraph={false}
                            />
                          ),
                        }),
                      };
                    })}
                    dataSource={getModalTableData()}
                    pagination={false}
                  />
                  <div className="table-pagination">
                    <CustomPagination
                      current={pageNumber + 1}
                      totalCount={tableCount}
                      onChange={handleTablePageChange}
                    />
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

Master.propTypes = {};

const mapStateToProps = ({
  division,
  district,
  taluka,
  assembly,
  village,
  policy,
  welcomevideo,
  utils,
  dropdownData,
}) => ({
  division,
  district,
  taluka,
  assembly,
  village,
  policy,
  welcomevideo,
  utils,
  dropdownData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  fetchModalTableData: bindActionCreators(
    RootActions.fetchModalTableData,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Master);
