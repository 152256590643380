import { TextCell } from "../../components/TableCells/TextCell";
import { ColumnHeaderWithSorter } from "../../components/ColumnHeaderWithSorter";
import { DateCell } from "components/TableCells/DateCell";
import { UserCell } from "components/TableCells/UserCell";
import "../../styles/scss/_variables.scss";
import { ToggleCell } from "components/TableCells/ToggleCell";

export const returnCitizensColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"User details"}
        sortKey={"name"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "18%",
    render: (rowData) => (
      <UserCell
        sortedColumn={true}
        data={{
          image: rowData?.media?.link,
          email: rowData?.email,
          name: rowData?.name,
        }}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Registered on"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "Contact",
    width: "12%",
    render: (rowData) => <TextCell data={rowData.phoneNo} />,
  },
  {
    title: "User Id",
    width: "12%",
    render: (rowData) => (
      <TextCell isBold={true} color="#4c68ef" data={rowData.username} />
    ),
  },
  {
    title: "Gender",
    width: "12%",
    render: (rowData) => (
      <TextCell
        data={
          rowData.gender === "M"
            ? "Male"
            : rowData.gender === "F"
            ? "Female"
            : rowData.gender === "U"
            ? "Unknown"
            : "Others"
        }
      />
    ),
  },
  {
    width: "2%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        key={rowData?.isActive}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(rowData.id, isActive)
        }
      />
    ),
  },
];

export const returnVolunteersColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"User Details"}
        sortKey={"name"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "18%",
    render: (rowData) => (
      <UserCell
        sortedColumn={true}
        data={{
          image: rowData?.media?.link,
          email: rowData?.email,
          name: rowData?.name,
        }}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"RegisteredOn"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "Contact",
    width: "12%",
    render: (rowData) => <TextCell data={rowData.phoneNo} />,
  },
  {
    title: "User Id",
    width: "12%",
    render: (rowData) => (
      <TextCell isBold={true} color="#4c68ef" data={rowData.username} />
    ),
  },
  {
    title: "Total Tasks",
    width: "12%",
    render: (rowData) => (
      <TextCell isBold={true} color="#4c68ef" data={rowData.totalTask} />
    ),
  },
  {
    title: "Total Points",
    width: "12%",
    render: (rowData) => (
      <TextCell
        data={
          rowData.totalPoint?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " " +
          "pts"
        }
      />
    ),
  },
  {
    width: "2%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        key={rowData?.isActive}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(rowData.id, isActive)
        }
      />
    ),
  },
];

export const returnAdminsColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"User Details"}
        sortKey={"name"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => (
      <UserCell
        sortedColumn={true}
        data={{
          image: rowData?.media?.link,
          email: rowData?.email,
          name: rowData?.name,
        }}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"RegisteredOn"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "Contact",
    width: "12%",
    render: (rowData) => <TextCell data={rowData?.phoneNo} />,
  },
  {
    title: "Role",
    width: "12%",
    render: (rowData) => <TextCell data={rowData?.role?.displayName} />,
  },
  {
    title: "Access",
    width: "12%",
    render: (rowData) => (
      <TextCell
        isBold={true}
        color="#4c68ef"
        isArray={true}
        data={rowData.role?.access}
      />
    ),
  },
  {
    width: "2%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        key={rowData?.isActive}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(rowData.id, isActive)
        }
      />
    ),
  },
];
