import { Button, Progress } from "antd";
import { FastField, Form } from "formik";
import React, { useState } from "react";
import {
  AntInput,
  AntTextArea,
} from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import "./AddPollForm.scss";
import { AntCheckBox } from "components/CreateAntFields/CreateAntField";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import { ENGLISH } from "constants/language";
import { AntToggle } from "components/CreateAntFields/CreateAntField";

const AddPollForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  defaultCategory,
  tab,
  isEditing,
  formModalVisible,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError =
    submit && (errors?.data?.en || errors?.publishedDate);
  const marathiFormError = submit && errors?.data?.mr;
  const hindiFormValues = values?.data?.hi;
  const marathiFormValues = values?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  const getPollsPercentage = (value) => {
    let totalCount = values?.yesCount + values?.noCount || 0;
    return `${Math.floor((value / totalCount) * 100) || 0}%/ ${value}`;
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === "en" ? (
            <>
              <p className="mb-8 field-label">Title</p>
              <FastField
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content name"}
                maxLength={50}
              />
              <p className="mb-8 field-label">Short Summary</p>
              <FastField
                value={values.shortSummary}
                name="data[en].shortSummary"
                type="text"
                component={AntTextArea}
                preventNextLineOnEnter={true}
                maxLength={300}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short summary about the content"}
              />
              {/* <p className="mb-8 mt-10 field-label">Web URL</p>
              <FastField
                value={values.webUrl}
                defaultValue={values.webUrl}
                name="webUrl"
                type="text"
                autoComplete={"off"}
                component={AntInput}
                placeholder={"Enter web url"}
              /> */}
              {isEditing ? (
                <div className="poll-count">
                  {" "}
                  <p className="mb-0 mt-16 field-label">Poll results</p>
                  <Progress
                    strokeColor="#00a64c"
                    percent={values?.yesCount}
                    format={() =>
                      `${getPollsPercentage(
                        values?.yesCount || 0
                      )}\u00A0\u00A0\u00A0\u00A0 Yes`
                    }
                  />
                  <Progress
                    className="mt-10"
                    strokeColor="#576bbb"
                    percent={values?.noCount}
                    format={() =>
                      `${getPollsPercentage(
                        values?.noCount || 0
                      )}\u00A0\u00A0\u00A0\u00A0 No`
                    }
                  />
                </div>
              ) : (
                ""
              )}
              <div className="mt-28 checkbox-container">
                <FastField
                  name="isPopular"
                  type="checkbox"
                  component={AntCheckBox}
                />
                <p>
                  {values.isPopular ? "Marked as popular" : "Mark as popular"}
                </p>
              </div>
              {!isEditing && (
                <div>
                  <p className="mt-20 field-label">Send notification</p>
                  <FastField
                    defaultChecked={values.isNotification}
                    name="isNotification"
                    type="switch"
                    component={AntToggle}
                  />
                </div>
              )}
            </>
          ) : language === "hi" ? (
            <HindiLanguageForm
              values={hindiFormValues}
              categoryTags={categoryTags}
              categoryTagsValue={values.hindiCategoryTags}
            />
          ) : (
            <MarathiLanguageForm
              values={marathiFormValues}
              categoryTags={categoryTags}
              categoryTagsValue={values.marathiCategoryTags}
            />
          )}
          <Button
            loading={isSubmitting}
            className={"absolute-submit-btn submit-btn"}
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddPollForm;
