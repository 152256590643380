import { Input } from "antd";
import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./index.scss";
import GoogleMapReact from "google-map-react";
import { Map } from "assets/svgs";
import get from "lodash/get";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address || "",
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: {
        lat: props.lat || 19.7515,
        lng: props.lng || 75.7139,
      },
    };
  }

  handleChange = (address) => {
    this.setState({ address });
    this.props.handlemapCenter("", address);
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.props.handlemapCenter(latLng, address);
        this.setState({ mapCenter: latLng });
      })
      .catch((error) => console.error("Error", error));
  };

  getErrors = () => {
    let error =
      this.props.form.isSubmitting || this.props.form.touched?.location
        ? get(this.props.form.errors, this.props?.field?.name)
        : false;
    return error;
  };

  render() {
    return (
      <div className="googleMaps mt-10 mb-24">
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          debounce={800}
          searchOptions={{
            componentRestrictions: { country: ["in"] },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <Input
                {...getInputProps({
                  placeholder: this.props.placeholder,
                  className: this.getErrors()
                    ? "location-search-input-error"
                    : "location-search-input",
                })}
                allowClear
              />
              <p className="explain-error">{this.getErrors()}</p>
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item-active"
                    : "suggestion-item";
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <div className="map mt-10">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY,
            }}
            defaultCenter={this.state.mapCenter}
            defaultZoom={this.props.zoom || 11}
            center={this.state.mapCenter}
          >
            <AnyReactComponent
              lat={this.state.mapCenter.lat}
              lng={this.state.mapCenter.lng}
              text={<Map />}
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default GoogleMaps;
