import { AntInput } from "components/CreateAntFields/CreateAntField";
import { FastField } from "formik";

const MarathiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Organization name</p>
      <FastField
        value={values?.name}
        name="data[mr].name"
        type="text"
        suffix={<div>{50 - (values?.name?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter organization name "}
        maxLength={50}
      />
      <p className="mb-8 field-label">Description (optional)</p>
      <FastField
        value={values?.description}
        name="data[mr].description"
        type="text"
        suffix={<div>{50 - (values?.description?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter details here"}
      />
    </>
  );
};

export default MarathiLanguageForm;
