import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const HindiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Timeline description</p>
      <Field
        value={values?.description}
        name="data[hi].description"
        type="editor"
        component={RTEditor}
        characterLimit={20000}
        placeholder={"Enter details here"}
      />
    </>
  );
};

export default HindiLanguageForm;
