import { Button, Progress } from "antd";
import { FastField, Form } from "formik";
import React, { useState } from "react";
import {
  AntInput,
  AntTextArea,
} from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import { ENGLISH } from "constants/language";
import { AntToggle } from "components/CreateAntFields/CreateAntField";

const AddAnnouncementForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  defaultCategory,
  tab,
  isEditing,
  formModalVisible,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError =
    submit && (errors?.publishedDate || errors?.data?.en);
  const marathiFormError = submit && errors?.data?.mr;
  const hindiFormValues = values?.data?.hi;
  const marathiFormValues = values?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === "en" ? (
            <>
              <p className="mb-8 field-label">Title</p>
              <FastField
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {160 - (values?.data?.[ENGLISH]?.title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content name"}
                maxLength={160}
              />
              <p className="mb-8 field-label">Short Summary (optional)</p>
              <FastField
                value={values.shortSummary}
                name="data[en].shortSummary"
                type="text"
                component={AntTextArea}
                maxLength={500}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short summary about the content"}
              />
              {isEditing ? (
                <div className="social-count">
                  {" "}
                  <p className="mb-6 mt-22 field-label">Number of shares</p>
                  <Progress
                    strokeColor="maroon"
                    percent={values?.sharesCount || 0}
                    format={(percent) => values?.sharesCount || 0}
                  />
                </div>
              ) : (
                ""
              )}
              {!isEditing && (
                <div>
                  <p className="mt-20 field-label">Send notification</p>
                  <FastField
                    defaultChecked={values.isNotification}
                    name="isNotification"
                    type="switch"
                    component={AntToggle}
                  />
                </div>
              )}
            </>
          ) : language === "hi" ? (
            <HindiLanguageForm values={hindiFormValues} />
          ) : (
            <MarathiLanguageForm values={marathiFormValues} />
          )}
          <Button
            loading={isSubmitting}
            className={"absolute-submit-btn submit-btn"}
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAnnouncementForm;
