import { Col, Progress, Row } from "antd";

export const SocialMediaWrapper = ({
  Icon,
  count = 0,
  posts,
  direct,
  stories,
  shares,
  tweets,
  showEngagements = false,
}) => {
  let totalCount =
    count?.stories ||
    0 + count?.direct ||
    0 + count?.posts ||
    0 + count?.shares ||
    0 + count?.tweets ||
    0;

  return (
    <div className="social-wrapper">
      {showEngagements ? (
        <>
          <Row>
            <Col>
              <p className="field-input mb-0">Total engagements</p>
            </Col>
          </Row>
          <p className="field-input absolute-right mb-0">{count || "0"}</p>
        </>
      ) : (
        <>
          <Row>
            <Col className="social-icon mr-10">
              <Icon />
            </Col>
            <Col>
              <p className="field-input mb-0">
                {totalCount || "0"} total engagements
              </p>
            </Col>
          </Row>
          <p className="field-input absolute-right mb-0">
            {count?.points || "0"} Points
          </p>
        </>
      )}

      {/* {stories && (
        <>
          <Progress
            strokeColor="#00a64c"
            percent={count?.stories || 0}
            format={() => "Stories"}
          />
          <span className="progress-count">{count?.stories || "0"}</span>
        </>
      )}

      {direct && (
        <>
          <Progress
            strokeColor="#00a64c"
            percent={count?.direct || 0}
            format={() => "Direct"}
          />
          <span className="progress-count">{count?.direct || "0"}</span>
        </>
      )}

      {posts && (
        <>
          <Progress
            strokeColor="#00a64c"
            percent={count?.posts || 0}
            format={() => "Posts"}
          />
          <span className="progress-count">{count?.posts || "0"}</span>
        </>
      )}

      {tweets && (
        <>
          <Progress
            strokeColor="#00a64c"
            percent={count?.direct || 0}
            format={() => "Tweets"}
          />
          <span className="progress-count">{count?.Tweets || "0"}</span>
        </>
      )}

      {shares && (
        <>
          <Progress
            strokeColor="#00a64c"
            percent={count?.posts || 0}
            format={() => "Shares"}
          />
          <span className="progress-count">{count?.shares || "0"}</span>
        </>
      )} */}

      <>
        <Progress
          strokeColor="#00a64c"
          percent={showEngagements ? count : totalCount || 0}
          format={() => "count"}
        />
        <span className="progress-count">
          {showEngagements ? count : totalCount || "0"}
        </span>
      </>
    </div>
  );
};
