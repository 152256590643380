export const COLUMN_ACTIONS = {
  EDIT: "edit",
  TOGGLE_STATUS: "toggleStatus",
  ACTIVE_DATA: 'activeData',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  PENDING_FOR_APPROVAL: 'pending_for_approval',
  DEFAULT: 'default'
};

export const CORS_PARAM = "?not-from-cache-please";
export const avoidCache = () => {
  let uid = new Date().getTime().toString(36) + Math.random();
  return `?v=${uid}`;
};
