import "./index.scss";
import ReadMore from "../../ReadMore";

export const TextCell = ({
  data,
  enableReadMore = false,
  sortedColumn,
  isBold,
  color,
  isArray = false,
  ...props
}) => {
  return (
    <div className={sortedColumn ? "sorted" : ""}>
      {enableReadMore ? (
        <ReadMore className={["listing-row-text", isBold && "bold"].join(" ")}>
          {data || "-"}
        </ReadMore>
      ) : (
        <p
          style={color && { color }}
          className={["listing-row-text", isBold && "bold"].join(" ")}
        >
          {isArray && data?.length > 1
            ? `${data[0]} +${data.length - 1}`
            : data || "-"}
        </p>
      )}
    </div>
  );
};

// overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 2; /* number of lines to show */
//   -webkit-box-orient: vertical;
