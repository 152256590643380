import { FastField } from "formik";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Divider, Progress, Upload, Switch } from "antd";
import React, { useCallback, useState } from "react";
import { PlusIcon } from "../../assets/svgs";
import "../index.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadMultipleImage,
} from "../../utils/helpers";
import ImageEditor from "components/ImageEditor";
import {
  UploadImages,
} from "components/UploadButtons";
import {cloneDeep} from 'lodash'

let imageParams = {
  maxHeight: 350,
  maxWidth: 350,
  fileSize: 50,
};

function Variants({
  values,
  index,
  setFieldValue,
  errors,
  touched,
  formValue
}) {
  const [activeImage, setActiveImage] = useState(values?.imageList?.[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [loading, setLoading] = useState(false);
  
  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const addToImageList = useCallback(
    async (url) => {
      const images = cloneDeep(values.imageList);
      const mediaId = cloneDeep(values.images);
      const formData = new FormData();
      const file = dataURLtoFile(url, `image_${Math.random()}.png`);
      formData.append("file", file);
      const response = await uploadMultipleImage(
        file,
        hanldeProgress(setUploadprogress)
      );
      images[activeImageIndex] = url;
      mediaId[activeImageIndex] = response?.data?.id;
      setLoading(false);
      setFieldValue(`variants[${index}].images`, mediaId);
      setFieldValue(`variants[${index}].imageList`, images);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImageIndex, values, index]
  );
  
  const deleteImageFromList = useCallback(() => {
    let imageList = values.imageList;
    let mediaId = values.images;
    imageList[activeImageIndex] = "";
    mediaId[activeImageIndex] = "";
    setFieldValue(`variants[${index}].images`, mediaId);
    setFieldValue(`variants[${index}].imageList`, imageList);

    let lastValidImage =
      imageList?.filter((item) => item)?.slice(-1)?.[0] || [];
    setActiveImage(lastValidImage);
    let lastIndex = 0;
    for (let i = 0; i < imageList?.length; i++) {
      if (imageList[i]) {
        lastIndex = i;
      }
    }
    setActiveImageIndex(lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const hasError = (field) => {
    return errors?.variants?.[index]?.[field] && touched?.variants?.[index]?.[field];
  };

  const handleChange = async (info) => {
    hanldeProgress();
    getBase64(info.file.originFileObj, (imageUrl) => {
      // setFieldValue(`variants[${index}]images[${activeImageIndex}]`, imageUrl);
      setActiveImage(imageUrl);
      setLoading(false);
    });
  };

  const handleDefaultStatus = (value, index) => {
    const updatedVariants = formValue?.variants?.map((variant, idx) => ({
      ...variant,
      isDefault: (idx === index) ? value : false
    }))
    setFieldValue('variants', updatedVariants)
  }

  return (
    <>
      <Divider />
      <p className="field-label mb-8">
        {
          [
            values?.data?.size,
            values?.data?.style,
            values?.data?.color?.name
          ].filter((item) => item).join(' / ')
        }
      </p>
      <p className="mb-10 mt-10 field-label">Cover Image</p>
      {activeImage && activeImage.length ? (
        <ImageEditor
          image={activeImage}
          renderMultipleImage={true}
          onDelete={() => {
            setActiveImage("");
            deleteImageFromList();
            setUploadprogress(0);
          }}
          isEditing={uploadprogress}
          handleEditing={(status) => {
            if (status && uploadprogress) {
              setUploadprogress(0);
            }
          }}
          onSave={async (url) => {
            addToImageList(url);
          }}
          width={220}
        />
      ) : (
        <Upload
          listType={`picture-card ${hasError("images") ? "error" : ""}`}
          className="picture-uploader"
          showUploadList={false}
          onChange={handleChange}
          // beforeUpload={(file) => beforeUpload(file, imageParams)}
          customRequest={dummyRequest}
          accept="image/png, image/jpeg, image/jpg"
          progress={
            <Progress percent={uploadprogress} showInfo={true} />
          }
        >
          {
            <UploadImages
              width="350 : 350"
              ratio="1 : 1"
              loading={loading}
            />
          }
        </Upload>
      )}
      {uploadprogress === 100 && !hasError("images") && (
        <div className="success-text">Image uploaded Successfully</div>
      )}
      {uploadprogress > 0 && uploadprogress < 100 && (
        <div className="progress-bar">
          <Progress percent={uploadprogress} showInfo={true} />
        </div>
      )}
      {hasError("images") && (
        <div className="ant-form-item-explain ant-form-item-explain-error">
          {hasError("images")}
        </div>
      )}
      <div className="mt-6 mb-18 upload-list">
        {values.imageList?.map((item, idx) =>
          item ? (
            <div
              key={idx}
              className={[
                "uploaded-image-container",
                idx === activeImageIndex ? "selected" : "",
              ].join(" ")}
            >
              <img
                onClick={() => {
                  setActiveImageIndex(idx);
                  setActiveImage(item);
                }}
                alt={"upload-pic"}
                className={["uploaded-image-container-item"].join(" ")}
                src={item}
              />
            </div>
          ) : (
            <div key={idx} className={"upload-button-small"}>
              <input
                onChange={async (e) => {
                  const file = e.target.files[0];
                  let isValidImage = await beforeUpload(
                    file,
                    imageParams
                  );
                  if (isValidImage) {
                    setUploadprogress(0);
                    getBase64(file, (imageUrl) => {
                      setActiveImage(imageUrl);
                      setActiveImageIndex(idx);
                    });
                  } else {
                    setActiveImage("");
                    setUploadprogress(0);
                  }
                }}
                type="file"
                id={`file${index}_${idx}`}
                accept="image/png, image/jpeg, image/jpg"
              />
              <label
                onClick={() => {
                  setActiveImageIndex(idx)
                }}
                htmlFor={`file${index}_${idx}`}
              >
                <PlusIcon />
              </label>
            </div>
          )
        )}
      </div>

      <p className="mt-20 field-label">Set Default</p>
      <Switch
        defaultChecked={values.isDefault}
        checked={values.isDefault}
        // checked={active}
        name={`variants.${index}.isDefault`}
        type="switch"
        onChange={(val) => {
          handleDefaultStatus(val, index)
        }}
      />
      <p className="mt-20 field-label">Point Price</p>
      <FastField
        value={values.name}
        name={`variants.${[index]}.pointPrice`}
        type="text"
        component={AntInput}
        placeholder={"Enter Points"}
        maxLength={100}
      />
    </>
  )
}

export default Variants