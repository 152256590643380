import { combineReducers } from "redux";
import commonReducer from "./root/reducer";
import auth from "./auth/reducer";

function createNamedWrapperReducer(reducerFunction, reducerName) {
  return (state, action) => {
    const { name } = action;
    const isInitializationCall = state === undefined;
    if (name !== reducerName && !isInitializationCall) {
      return state;
    }

    return reducerFunction(state, action);
  };
}

export default combineReducers({
  auth,
  tasks: createNamedWrapperReducer(commonReducer, "tasks"),
  rewards: createNamedWrapperReducer(commonReducer, "rewards"),
  categoryTags: createNamedWrapperReducer(commonReducer, "categoryTags"),
  trophies: createNamedWrapperReducer(commonReducer, "trophies"),
  events: createNamedWrapperReducer(commonReducer, "events"),
  polls: createNamedWrapperReducer(commonReducer, "polls"),
  infographics: createNamedWrapperReducer(commonReducer, "infographics"),
  articles: createNamedWrapperReducer(commonReducer, "articles"),
  announcements: createNamedWrapperReducer(commonReducer, "announcements"),
  importantmessages: createNamedWrapperReducer(
    commonReducer,
    "importantMessages"
  ),
  votes: createNamedWrapperReducer(commonReducer, "votes"),
  youtubevideo: createNamedWrapperReducer(commonReducer, "youtubevideo"),
  facebooklive: createNamedWrapperReducer(commonReducer, "facebookLive"),
  //categoryTags: createNamedWrapperReducer(commonReducer, "tag"),
  leaders: createNamedWrapperReducer(commonReducer, "leaders"),
  aboutparty: createNamedWrapperReducer(commonReducer, "party"),
  manifestos: createNamedWrapperReducer(commonReducer, "manifesto"),
  timelines: createNamedWrapperReducer(commonReducer, "timeline"),
  magazine: createNamedWrapperReducer(commonReducer, "magazine"),
  frontalorganisations: createNamedWrapperReducer(
    commonReducer,
    "organisations"
  ),
  schemes: createNamedWrapperReducer(commonReducer, "schemes"),
  citizens: createNamedWrapperReducer(commonReducer, "citizens"),
  admins: createNamedWrapperReducer(commonReducer, "admins"),
  volunteers: createNamedWrapperReducer(commonReducer, "volunteers"),
  roles: createNamedWrapperReducer(commonReducer, "roles"),
  division: createNamedWrapperReducer(commonReducer, "divisions"),
  district: createNamedWrapperReducer(commonReducer, "districts"),
  taluka: createNamedWrapperReducer(commonReducer, "talukas"),
  assembly: createNamedWrapperReducer(commonReducer, "assembly"),
  village: createNamedWrapperReducer(commonReducer, "village"),
  questionsToLeaders: createNamedWrapperReducer(commonReducer, "data"),
  policy: createNamedWrapperReducer(commonReducer, "config"),
  utils: createNamedWrapperReducer(commonReducer, "utils"),
  organisationalstructure: createNamedWrapperReducer(
    commonReducer,
    "organisations"
  ),
  mla_mlc: createNamedWrapperReducer(commonReducer, "mlaNmlc"),
  organisation_members: createNamedWrapperReducer(commonReducer, "members"),
  welcomevideo: createNamedWrapperReducer(commonReducer, "config"),
  dropdownData: createNamedWrapperReducer(commonReducer, "dropdown"),
  category: createNamedWrapperReducer(commonReducer, "category"),
  products: createNamedWrapperReducer(commonReducer, "products"),
  orders: createNamedWrapperReducer(commonReducer, "orders"),
  digital_submissions: createNamedWrapperReducer(
    commonReducer,
    "digital_submissions"
  ),
  cadre_digital_work: createNamedWrapperReducer(
    commonReducer,
    "cadre_digital_work"
  ),
  cadreExperiences: createNamedWrapperReducer(
    commonReducer,
    "cadreExperiences"
  ),
  cadres: createNamedWrapperReducer(commonReducer, "cadres"),
  cadre_designations: createNamedWrapperReducer(
    commonReducer,
    "cadre_designations"
  ),
  cadre_divisions: createNamedWrapperReducer(commonReducer, "cadre_divisions"),
  cadre_districts: createNamedWrapperReducer(commonReducer, "cadre_districts"),
  cadre_vidhansabha: createNamedWrapperReducer(
    commonReducer,
    "cadre_vidhansabha"
  ),
  cadre_taluka: createNamedWrapperReducer(commonReducer, "cadre_taluka"),
  cadre_council: createNamedWrapperReducer(commonReducer, "cadre_council"),
  cadre_panchyat: createNamedWrapperReducer(commonReducer, "cadre_panchyat"),
  cadre_organization: createNamedWrapperReducer(
    commonReducer,
    "cadre_organization"
  ),
  cadre_committee: createNamedWrapperReducer(commonReducer, "cadre_committee"),
  cadre_post: createNamedWrapperReducer(commonReducer, "cadre_post"),
  cadre_state: createNamedWrapperReducer(commonReducer, "cadre_state"),
  cadre_prabhag: createNamedWrapperReducer(commonReducer, "cadre_prabhag"),
  cadre_ward: createNamedWrapperReducer(commonReducer, "cadre_ward"),
  survey: createNamedWrapperReducer(commonReducer, "survey"),
});
