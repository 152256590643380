import {
  returnCitizensColumns,
  returnVolunteersColumns,
  returnAdminsColumns,
} from "./columns";

export const INNER_HEADERS = [
  {
    label: "Citizens",
    singular: "Citizen",
    title: {
      en: "Citizens",
      hi: "नागरिक",
      mr: "नागरिक",
    },
  },
  {
    label: "Volunteers",
    singular: "Volunteer",
    title: {
      en: "Volunteers",
      hi: "स्वयंसेवक",
      mr: "स्वयंसेवक",
    },
  },
  {
    label: "Admins",
    singular: "Admin",
    title: {
      en: "Admins",
      hi: "व्यवस्थापक",
      mr: "प्रशासन",
    },
  },
];

export const TABS = {
  CITIZENS: "citizens",
  VOLUNTEERS: "volunteers",
  ADMINS: "admins",
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  performColumnAction
) => {
  let args = {
    onSort,
    sortColumn,
    performColumnAction,
  };
  switch (innerTab) {
    case TABS.CITIZENS:
      return returnCitizensColumns(args);
    case TABS.VOLUNTEERS:
      return returnVolunteersColumns(args);
    case TABS.ADMINS:
      return returnAdminsColumns(args);
    default:
      return returnCitizensColumns(args);
  }
};

export const createPayload = (values) => {
  const data = {
    email: values.email,
    phoneNo: values.phoneNo,
    dob: values.dob,
    roleId: values.roleId,
    userType: "Admin",
    name: values.name,
    location: values.location,
    gender: values.gender,
    countryCode: "001",
    languageId: "en",
  };
  if (values.password) {
    data["password"] = values.password;
  }
  if (values.mediaId !== "") {
    data["mediaId"] = values.mediaId;
  }
  return data;
};

export const METADATA = {
  admins: ["roles"],
};

export const getDropDown = (array) => {
  let data = array?.map((e) => {
    return {
      name: e?.displayName,
      id: e?.id,
    };
  });
  return data;
};

export const getLocationDropdown = (array) => {
  let data = array?.map((e) => {
    return {
      id: e?.id,
      name: e?.data?.en?.name,
    };
  });

  return data?.filter((e) => e.name !== undefined);
};
