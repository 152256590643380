import { Button, Row, Col } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useState } from "react";
import { RTEditor } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import Time from "components/Time/time";
import Date from "components/Date/date";
import FormMenu from "components/FormMenu";
import { HINDI, ENGLISH } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";

const AddManifestoForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  city,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <Row style={{ justifyContent: "space-between" }}>
                <Col span={11}>
                  <p className="mb-7 mt-25 field-label">Date</p>
                  <Field
                    defaultValue={values?.date}
                    name="date"
                    format="DD/MM/YY"
                    component={Date}
                    placeholder={"dd/mm/yy"}
                  />
                </Col>
                <Col span={11}>
                  <p className="mb-7 mt-25 field-label">Time</p>
                  <Field
                    defaultValue={values?.time}
                    name="time"
                    format="hh:mm aa"
                    component={Time}
                    placeholder={"13:00 pm"}
                  />
                </Col>
              </Row>
              <p className="mb-8 field-label">Description</p>
              <FastField
                value={values?.description}
                name="data[en].description"
                type="editor"
                component={RTEditor}
                characterLimit={20000}
                placeholder={"Enter details here"}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data} />
          ) : (
            <MarathiLanguageForm values={values?.data} />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn  submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddManifestoForm;
