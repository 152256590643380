import { TimePicker } from "antd";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import get from "lodash/get";

const dateFormat = "hh:mm a";
function Time(props) {
  const touched = get(props.form?.touched, props.field?.name);
  // const submitted = submitCount > 0;
  const hasError = get(props.form.errors, props.field.name);
  const submittedError = hasError && props.submitted;
  const touchedError = hasError && touched;

  const onOk = (val) => {
    props.form.setFieldValue(props.field.name, val);
  };

  const onChange = (val) => {
    props.form.setFieldValue(props.field.name, val);
  };

  const findError = () => {
    if (
      (props.hasFeedback && props.submitted) ||
      (props.hasFeedback && touched)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getDisabledHours = () => {
    let hours = [];
    if (
      props.disabledDateValue &&
      moment(props.disabledDateValue).format("L") ===
        moment(props.defaultValue).format("L")
    ) {
      if (props.disabledTimeValue && props.disabledTimeOption) {
        let hour = new Date(
          moment(props?.disabledTimeValue).toISOString()
        ).getHours();
        if (props.disabledTimeOption === "greater") {
          for (let i = 0; i <= hour; i++) {
            hours.push(i);
          }
        } else {
          for (let i = 24; i >= hour; i--) {
            hours.push(i);
          }
        }
      }
    }
    return hours;
  };

  return (
    <>
      <FormItem
        hasFeedback={findError()}
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? "error" : "success"}
      >
        <TimePicker
          format={dateFormat}
          onOk={onOk}
          onChange={onChange}
          suffixIcon={null}
          disabled={props.disabled}
          disabledHours={getDisabledHours}
          defaultValue={
            props.defaultValue === undefined
              ? undefined
              : props.defaultValue !== "Invalid date"
              ? moment(props.defaultValue)
              : undefined
          }
          placeholder={props.placeholder}
        />
      </FormItem>
    </>
  );
}
export default Time;
