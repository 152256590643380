import { ENGLISH, HINDI, MARATHI } from "constants/language";
import { extractKeysFromArray } from "utils/helpers";
import {
  returnCadreColumns,
  returnDesignationColumns,
  returnSurveyColumns,
} from "./columns";

export const INNER_HEADERS = [
  {
    label: "cadres",
    singular: "Cadre",
    title: {
      en: "Cadre",
      hi: "काडर",
      mr: "संवर्ग",
    },
  },
  {
    label: "cadre_designations",
    singular: "Designation",
    title: {
      en: "Designations",
      hi: "पद",
      mr: "पदनाम",
    },
  },
  {
    label: "digital_submissions",
    singular: "Digital Submission",
    title: {
      en: "Digital Submission",
      hi: "डिजिटल सबमिशन",
      mr: "डिजिटल सबमिशन",
    },
  },
  {
    label: "survey",
    singular: "survey",
    title: {
      en: "Survey",
      hi: "सर्वेक्षण",
      mr: "सर्वेक्षण",
    },
  },
];

export const TABS = {
  CADRES: "cadres",
  DESIGNATIONS: "cadre_designations",
  DIGITAL_SUBMISSIONS: "digital_submissions",
  CADRE_EXPERIENCES: "cadreExperiences",
  CADRE_DIGITAL_WORK: "cadre_digital_work",
  SURVEY: "survey",
};

export const CADRES_FILTERS = {
  REGISTERED: "registered",
  APPROVED: "approved",
  REJECTED: "rejected",
  PENDING: "pending",
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
  deleteColumn
) => {
  let args = {
    onSort,
    sortColumn,
    languageId,
    performColumnAction,
    deleteColumn,
  };

  switch (innerTab) {
    case TABS.CADRES:
      return returnCadreColumns(args);
    case TABS.DESIGNATIONS:
      return returnDesignationColumns(args);
    case TABS.DIGITAL_SUBMISSIONS:
      return returnDesignationColumns(args);
    case TABS.SURVEY:
      return returnSurveyColumns(args);
    default:
      return returnDesignationColumns(args);
  }
};

export const getButtonLabel = (innerTab) => {
  let label = innerTab;
  if (innerTab === TABS.VIDHAN_SABHA) {
    return "Vidhan Sabha";
  }

  if (innerTab.includes("_")) {
    label = innerTab.split("_")?.[1];
  }
  label = label.charAt(0).toUpperCase() + label.slice(1);
  return label;
};

export const createPayload = (innerTab, values) => {
  switch (innerTab) {
    case TABS.DESIGNATIONS: {
      const payload = {
        data: values.data,
        organisationId: values.organisationId,
        committeeId: values.committeeId,
        postId: values.postId,
        stateId: values.stateId,
        divisionId: values.divisionId,
        districtId: values.districtId,
        vidhansabhaId: values.vidhansabhaId,
        talukaId: values.talukaId,
        municipalcouncilId: values.municipalcouncilId,
        nagarpanchayatId: values.nagarpanchayatId,
        prabhagId: values.prabhagId,
        wardId: values.wardId,
        approverId: values.approverId,
      };
      return payload;
    }
    case TABS.CADRES: {
      const payload = {
        approvalStatus: values?.approvalStatus,
      };
      return payload;
    }
    case TABS.SURVEY: {
      const payload = {
        data: values.data,
        mediaId: values.mediaId,
        organisationId: extractKeysFromArray(values.organisationId),
        committeeId: extractKeysFromArray(values.committeeId),
        postId: extractKeysFromArray(values.postId),
        stateId: extractKeysFromArray(values.stateId),
        divisionId: extractKeysFromArray(values.divisionId),
        districtId: extractKeysFromArray(values.districtId),
        vidhansabhaId: extractKeysFromArray(values.vidhansabhaId),
        talukaId: extractKeysFromArray(values.talukaId),
        municipalcouncilId: extractKeysFromArray(values.municipalcouncilId),
        nagarpanchayatId: extractKeysFromArray(values.nagarpanchayatId),
        prabhagId: extractKeysFromArray(values.prabhagId),
        wardId: extractKeysFromArray(values.wardId),
      };

      for (let key in payload) {
        if ((!payload[key] || !payload[key].length) && key !== "data") {
          delete payload[key];
        }
      }

      return payload;
    }
    default: {
      return null;
    }
  }
};

export const handlePutRequest = (currentValues, prevValues) => {
  let payload = {};

  for (let keys in currentValues) {
    for (let currKeys in prevValues) {
      if (keys === currKeys) {
        if (
          JSON.stringify(currentValues[keys]) !==
          JSON.stringify(prevValues[keys])
        ) {
          payload[keys] = currentValues[keys];
        }
      }
    }
  }
  return payload;
};

export const getDropDowns = (array) => {
  if (Array.isArray(array)) {
    let validArray = array?.filter((item) => item?.data !== null);
    return validArray?.map((item) => {
      return {
        id: item.id,
        english: item.data?.[ENGLISH]?.name || item.data?.[ENGLISH]?.title,
        hindi: item?.data?.[HINDI]?.name || item.data?.[HINDI]?.title,
        marathi: item?.data?.[MARATHI]?.name || item.data?.[MARATHI]?.title,
      };
    });
  }
};
