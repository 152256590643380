import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const MarathiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={values?.title}
        name="videoData.mr.title"
        type="text"
        component={AntInput}
        suffix={<div>{50 - (values?.title?.length || 0)} letters</div>}
        placeholder={"Enter video title "}
      />
    </>
  );
};

export default MarathiLanguageForm;
