import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const HindiLanguageForm = ({ values, district, fieldName }) => {
  return (
    <>
      <p className="mb-8 field-label">{fieldName}</p>
      <Field
        value={values.taluka}
        name="data.hi.name"
        type="text"
        component={AntInput}
        suffix={<div>{100 - (values?.name?.length || 0)} letters</div>}
        placeholder={"Enter content title "}
        maxLength = {100}
      />
      <p className="mb-8 field-label">District</p>
      <Field
        value={values.districtId}
        selectOptions={district}
        optionValueKey="id"
        optionNameKey="hindi"
        showSearch={true}
        autoComplete="off"
        mode={"single"}
        disabled={true}
        name="districtId"
        component={AntSelect}
        placeholder={"Maharastra"}
      />
    </>
  );
};

export default HindiLanguageForm;
