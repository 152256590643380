import { Button, Row, Upload, Col, Progress } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { RTEditor } from "../../components/CreateAntFields/CreateAntField";
import { AddButton, PlusIcon, TrashSquareButton } from "../../assets/svgs";
import "../index.scss";
import {
  beforeUpload,
  beforeVideoUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
  uploadMultipleImage,
  uploadVideo,
} from "../../utils/helpers";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import "../AddOrganizationForm/AddOrganizationForm.scss";
import "./AboutPartyForm.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RootActions from "../../store/root/actions";
import Date from "components/Date/date";
import {
  UploadButton,
  UploadImages,
  UploadVideoButton,
} from "components/UploadButtons";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import HindiLanguageForm from "./Formlanguage/HindiLanguageForm";
import MarathiLanguageForm from "./Formlanguage/MarathiLanguageForm";
import { leadersVideosSchema } from "views/Party/validate";
import ImageEditor from "components/ImageEditor";

const AboutPartyForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  city,
  isEditing,
  page,
  handlePage,
  parlimentaryStatus,
  department,
  experience,
  position,
  location,
  section,
  tab,
  pageErrors,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [activeImage, setActiveImage] = useState(values?.imageList?.[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [leaderImageProgress, setLeaderImageProgress] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [thumbnailUploadprogress, setThumbnailUploadprogress] = useState(0);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [activityUploadProgress, setActivityUploadProgress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const languageErrors = (array, language) => {
    let errors = array?.filter((e) => e?.data?.[language]);
    if (errors?.[0]) {
      return true;
    } else {
      return false;
    }
  };

  const findArrayErrors = (array, mediaName) => {
    let errors = array?.filter((e) => e?.[mediaName] !== undefined);
    if (errors?.[0]) {
      return true;
    } else {
      return false;
    }
  };

  const hindiFormError =
    submit &&
    (errors?.data?.hi ||
      languageErrors(errors?.activities, "hi") ||
      languageErrors(errors?.videos, "hi"));
  const englishFormError =
    submit &&
    (errors?.data?.en ||
      languageErrors(errors?.activities, "en") ||
      findArrayErrors(errors?.activities, "mediaId") ||
      findArrayErrors(errors?.videos, "mediaId") ||
      findArrayErrors(errors?.videos, "thumbnailId") ||
      findArrayErrors(errors?.videos, "date") ||
      findArrayErrors(errors?.activities, "url") ||
      errors?.imagesMediaId ||
      languageErrors(errors?.videos, "en"));
  const marathiFormError =
    submit &&
    (errors?.data?.mr ||
      languageErrors(errors?.activities, "mr") ||
      languageErrors(errors?.videos, "mr"));

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    props.fetchFromReduxOrNetwork(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (let i = 0; i < values?.videos?.length; i++) {
      let currentVideoData = values?.videos?.[i]?.data;
      let englishValue = currentVideoData?.[ENGLISH];
      if (englishValue?.location) {
        getVideosMultilanguageValues(
          city,
          englishValue.location,
          "location",
          i
        );
      }
      if (englishValue?.section) {
        getVideosMultilanguageValues(
          section,
          englishValue.section,
          "section",
          i
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.videos, section, city]);

  const getVideosMultilanguageValues = (
    dropdownArray,
    value,
    fieldName,
    index
  ) => {
    const data = dropdownArray?.filter((e) => e.english === value);
    let hindiVideoData = values?.videos?.[index]?.data?.[HINDI];
    let marathiVideoData = values?.videos?.[index]?.data?.[MARATHI];

    hindiVideoData[fieldName] = data?.[0].hindi;
    marathiVideoData[fieldName] = data?.[0].marathi;
  };

  const hanldeProgress = (progress) => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        progress(percent);
      },
    };
    return options;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback((info) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue("image", imageUrl);
        setActiveImage(imageUrl);
        setLoading(false);
      });
    }
    // }
  });

  const handleVideoChange = useCallback((info, index) => {
    const videoValidation = beforeVideoUpload(info);
    if (videoValidation) {
      getBase64(info, async (videoUrl) => {
        let response = await uploadVideo(
          info,
          hanldeProgress(setUploadprogress)
        );
        hanldeProgress();
        setFieldValue(`videos[${index}].mediaId`, response);
        setFieldValue(`videos[${index}].link`, videoUrl);
        setLoading(true);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = useCallback((info, index) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (imageValidation) {
      getBase64(info.file.originFileObj, async (imageUrl) => {
        setFieldValue(`activities[${index}].link`, imageUrl);
        setLoading(true);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleThumbnail = (info, index) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        values.videos[index].thumbnaillink = imageUrl;
        setLoading(false);
      });
    }
    // }
  };

  const addToImageList = useCallback(
    async (url) => {
      let images = values.imageList;
      let mediaId = values.imagesMediaId;
      let formData = new FormData();
      let file = dataURLtoFile(url, `image_${Math.random()}.png`);
      formData.append("file", file);
      let response = await uploadMultipleImage(
        file,
        hanldeProgress(setLeaderImageProgress)
      );
      images[activeImageIndex] = url;
      mediaId[activeImageIndex] = response?.data?.id;
      setFieldValue("");
      setLoading(false);
      setFieldValue("imagesMediaId", mediaId);
      setFieldValue("imageList", images);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImageIndex]
  );

  const deleteImageFromList = useCallback(() => {
    let imageList = values.imageList;
    let mediaId = values.imagesMediaId;
    imageList[activeImageIndex] = "";
    mediaId[activeImageIndex] = "";
    setFieldValue("imagesMediaId", mediaId);
    setFieldValue("imageList", imageList);

    let lastValidImage =
      imageList?.filter((item) => item)?.slice(-1)?.[0] || [];
    setActiveImage(lastValidImage);
    let lastIndex = 0;
    for (let i = 0; i < imageList?.length; i++) {
      if (imageList[i]) {
        lastIndex = i;
      }
    }
    setActiveImageIndex(lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const handleDelete = (index, field) => {
    let videos = values?.videos?.filter((e, i) => i !== index);
    setFieldValue("videos", videos);
  };

  const deleteVideo = (index) => {
    let list = values.videos;
    list[index].link = "";
    list[index].mediaId = "";
    setUploadprogress(0);
    setFieldValue("videos", list);
  };

  const handleVideoList = (field) => {
    const payload = leadersVideosSchema;
    payload.thumbnaillink = "";
    const videos = values?.videos;
    setUploadprogress(0);
    setFieldValue("videos", [...videos, payload]);
  };

  const handleActivitiesList = (field) => {
    const payload = {
      mediaId: "",
      url: "",
      data: {
        en: {
          title: "",
        },
        hi: {
          title: "",
        },
        mr: {
          title: "",
        },
      },
    };
    const activities = values?.activities;
    setActivityUploadProgress(0);
    setFieldValue("activities", [...activities, payload]);
  };

  const deleteActiviList = (index) => {
    let activities = values?.activities?.filter((e, i) => i !== index);
    setFieldValue("activities", activities);
  };

  const hasError = (field, index, fieldName) => {
    if (fieldName) {
      return (
        errors[field]?.[index]?.[fieldName] &&
        touched[field]?.[index]?.[fieldName] &&
        errors[field]?.[index]?.[fieldName]
      );
    } else return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mt-24 field-label">Images</p>
              {activeImage && activeImage.length ? (
                <ImageEditor
                  image={activeImage}
                  renderMultipleImage={true}
                  onDelete={() => {
                    setActiveImage("");
                    deleteImageFromList();
                    setLeaderImageProgress(0);
                  }}
                  isEditing={leaderImageProgress}
                  handleEditing={(status) => {
                    if (status && leaderImageProgress) {
                      setLeaderImageProgress(0);
                    }
                  }}
                  onSave={async (url) => {
                    addToImageList(url);
                  }}
                  width={380}
                />
              ) : (
                <Upload
                  listType={`picture-card ${
                    hasError("imagesMediaId") ? "error" : ""
                  }`}
                  className="picture-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, image/jpg"
                  progress={
                    <Progress percent={leaderImageProgress} showInfo={true} />
                  }
                >
                  {<UploadImages loading={loading} />}
                </Upload>
              )}
              {leaderImageProgress === 100 && !hasError("imagesMediaId") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {leaderImageProgress > 0 && leaderImageProgress < 100 && (
                <div className="progress-bar">
                  <Progress percent={leaderImageProgress} showInfo={true} />
                </div>
              )}
              {hasError("imagesMediaId") && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {hasError("imagesMediaId")}
                </div>
              )}
              <div className="upload-list">
                {values.imageList?.map((item, index) =>
                  item ? (
                    <div
                      key={index}
                      className={[
                        "uploaded-image-container",
                        index === activeImageIndex ? "selected" : "",
                      ].join(" ")}
                    >
                      <img
                        onClick={() => {
                          setLeaderImageProgress(0);
                          setActiveImageIndex(index);
                          setActiveImage(item);
                        }}
                        alt={"upload-pic"}
                        className={["uploaded-image-container-item"].join(" ")}
                        src={item}
                      />
                    </div>
                  ) : (
                    <div key={index} className={"upload-button-small"}>
                      <input
                        onChange={(e) => {
                          setLeaderImageProgress(0);
                          const file = e.target.files[0];
                          getBase64(file, (imageUrl) => {
                            setActiveImage(imageUrl);
                          });
                        }}
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <label
                        onClick={() => setActiveImageIndex(index)}
                        htmlFor="file"
                      >
                        <PlusIcon />
                      </label>
                    </div>
                  )
                )}
              </div>
              <p
                className={
                  isEditing ? "field-label mt-25" : "field-label mt-29"
                }
              >
                About party heading{" "}
              </p>
              <FastField
                value={values.heading}
                name="data[en].heading"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH].heading?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title "}
                maxLength={50}
              />
              <p className="field-label">Party bio 1</p>
              <FastField
                value={values.bio1}
                name="data[en].bio1"
                type="editor"
                component={RTEditor}
                placeholder={"Enter details here"}
                characterLimit={20000}
              />
              <p className="field-label">Party bio 2</p>
              <FastField
                value={values.bio2}
                name="data[en].bio2"
                type="editor"
                component={RTEditor}
                placeholder={"Enter details here"}
                characterLimit={20000}
              />
              <p className="video-label mt-23">Videos</p>
              {values?.videos?.map((items, i) => (
                <div key={i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="field-label">Video {i + 1}</p>
                    <p
                      style={{ color: "#e70000", cursor: "pointer" }}
                      onClick={() => handleDelete(i)}
                      className="field-label"
                    >
                      Delete Video
                    </p>
                  </div>
                  {items.link ? (
                    <div className="video-container">
                      <div
                        onClick={() => deleteVideo(i)}
                        className="video-trash-icon"
                      >
                        <TrashSquareButton />
                      </div>
                      <video
                        width="460"
                        height="115"
                        src={items.link}
                        controls
                      />
                    </div>
                  ) : (
                    <Upload
                      name={`mediaId`}
                      listType={`video-card ${
                        hasError("videos", i, "mediaId") ? "error" : ""
                      }`}
                      className="picture-uploader"
                      accept="video/avi, video/mp4, video/mpeg4"
                      showUploadList={false}
                      action={(e) => handleVideoChange(e, i)}
                      onClick={() => setCurrentVideoIndex(i)}
                      customRequest={dummyRequest}
                      progress={
                        <Progress percent={uploadprogress} showInfo={true} />
                      }
                    >
                      {<UploadVideoButton loading={loading} />}
                    </Upload>
                  )}
                  {uploadprogress === 100 &&
                    i === currentVideoIndex &&
                    !hasError("videos", i, "mediaId") && (
                      <div className="success-text">
                        Video uploaded Successfully
                      </div>
                    )}
                  {uploadprogress > 0 &&
                    uploadprogress < 100 &&
                    i === currentVideoIndex && (
                      <div className="progress-bar">
                        <Progress percent={uploadprogress} showInfo={true} />
                      </div>
                    )}
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    {hasError("videos", i, "mediaId") &&
                      uploadprogress < 80 &&
                      `Video is required`}
                  </div>
                  <p className="field-label">Thumbnail {i + 1}</p>
                  {items?.thumbnaillink ? (
                    <ImageEditor
                      image={items?.thumbnaillink}
                      onDelete={() => {
                        setFieldValue(`videos[${i}].thumbnaillink`, "");
                        setFieldValue(`videos[${i}].thumbnailId`, "");
                        setThumbnailUploadprogress(0);
                        setUploadprogress(0);
                      }}
                      isEditing={thumbnailUploadprogress}
                      handleEditing={(status) => {
                        if (status && thumbnailUploadprogress) {
                          setThumbnailUploadprogress(0);
                        }
                      }}
                      onSave={async (url) => {
                        let file = dataURLtoFile(
                          url,
                          `image_${Math.random()}.png`
                        );
                        currentVideoIndex !== i && setCurrentVideoIndex(i);
                        setFieldValue(`videos[${i}].thumbnaillink`, url);
                        let response = await uploadImage(
                          file,
                          hanldeProgress(setThumbnailUploadprogress)
                        );
                        if (response) {
                          setFieldValue(`videos[${i}].thumbnailId`, response);
                          setLoading(false);
                        }
                      }}
                      width={380}
                    />
                  ) : (
                    <Upload
                      name="thumbnailId"
                      listType={`picture-card ${
                        hasError("videos", i, "thumbnailId") ? "error" : ""
                      }`}
                      className="picture-uploader"
                      accept="image/png, image/jpeg, image/jpg"
                      showUploadList={false}
                      customRequest={dummyRequest}
                      // action={handleUpload}
                      beforeUpload={beforeUpload}
                      onChange={(e) => handleThumbnail(e, i)}
                      onClick={() => {
                        setCurrentVideoIndex(i);
                      }}
                      progress={
                        <Progress
                          percent={thumbnailUploadprogress}
                          showInfo={true}
                        />
                      }
                    >
                      {<UploadButton loading={loading} />}
                    </Upload>
                  )}
                  {thumbnailUploadprogress === 100 &&
                    i === currentVideoIndex && (
                      <div className="success-text">
                        Image uploaded Successfully
                      </div>
                    )}
                  {thumbnailUploadprogress > 0 &&
                    !hasError("videos", i, "thumbnailId") &&
                    thumbnailUploadprogress < 100 &&
                    i === currentVideoIndex && (
                      <div className="progress-bar">
                        <Progress
                          percent={thumbnailUploadprogress}
                          showInfo={true}
                        />
                      </div>
                    )}
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    {hasError("videos", i, "thumbnailId") &&
                      `Thumbnail is required`}
                  </div>
                  <p className="field-label">Video title</p>
                  <FastField
                    value={items?.title}
                    name={`videos.[${i}].data[en].title`}
                    type="text"
                    suffix={
                      <div>
                        {50 -
                          (values?.videos?.[i]?.data?.[ENGLISH]?.title
                            ?.length || 0)}{" "}
                        letters
                      </div>
                    }
                    component={AntInput}
                    placeholder={"Enter content title "}
                    shortSummary
                    maxLength={50}
                  />
                  <Row style={{ justifyContent: "space-between" }}>
                    <Col span={11}>
                      <p className="field-label">Location</p>
                      <Field
                        value={items?.location}
                        selectOptions={city}
                        optionValueKey="english"
                        optionNameKey="english"
                        filterOption={false}
                        showSearch={true}
                        mode={"single"}
                        name={`videos.[${i}].data[en].location`}
                        component={AntSelect}
                        placeholder={"Mumbai"}
                      />
                    </Col>
                    <Col span={11}>
                      <p className="field-label">Date of video</p>
                      <Field
                        defaultValue={items?.date}
                        name={`videos[${i}].date`}
                        format="DD/MM/YY"
                        component={Date}
                        type={"datePicker"}
                        placeholder={"dd/mm/yy"}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
              <Button
                className="mb-31 add-btn"
                type="dashed"
                onClick={handleVideoList}
                style={{ background: "transparent !important" }}
              >
                <span>
                  <AddButton style={{ width: "14px", height: "14px" }} /> Add
                  Video
                </span>
              </Button>
              {values?.activities?.map((items, i) => (
                <div key={i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="field-label">Activity {i + 1}</p>
                    <p
                      style={{ color: "#e70000", cursor: "pointer" }}
                      onClick={() => deleteActiviList(i)}
                      className="field-label"
                    >
                      Delete Activity
                    </p>
                  </div>
                  {items?.link ? (
                    <ImageEditor
                      image={items?.link}
                      onDelete={() => {
                        setFieldValue(`activities[${i}].link`, "");
                        setFieldValue(`activities[${i}].mediaId`, "");
                        setActivityUploadProgress(0);
                      }}
                      isEditing={activityUploadProgress}
                      handleEditing={(status) => {
                        if (status && activityUploadProgress) {
                          setActivityUploadProgress(0);
                        }
                      }}
                      onSave={async (url) => {
                        let file = dataURLtoFile(
                          url,
                          `image_${Math.random()}.png`
                        );
                        currentActivityIndex !== i &&
                          setCurrentActivityIndex(i);
                        let response = await uploadImage(
                          file,
                          hanldeProgress(setActivityUploadProgress)
                        );
                        if (response) {
                          setFieldValue(`activities[${i}].mediaId`, response);
                          setLoading(false);
                        }
                      }}
                      width={380}
                    />
                  ) : (
                    <Upload
                      name={`mediaId`}
                      listType={`picture-card ${
                        hasError("activities", i, "mediaId") ? "error" : ""
                      }`}
                      className="picture-uploader"
                      showUploadList={false}
                      customRequest={dummyRequest}
                      onChange={(e) => handleImageChange(e, i)}
                      onClick={() => {
                        setCurrentActivityIndex(i);
                        setActivityUploadProgress(0);
                      }}
                      progress={
                        <Progress percent={uploadprogress} showInfo={true} />
                      }
                    >
                      {<UploadButton loading={loading} />}
                    </Upload>
                  )}
                  {activityUploadProgress === 100 &&
                    !hasError("activities", i, "mediaId") &&
                    i === currentActivityIndex && (
                      <div className="success-text">
                        Image uploaded Successfully
                      </div>
                    )}
                  {activityUploadProgress > 0 &&
                    activityUploadProgress < 100 &&
                    i === currentActivityIndex && (
                      <div className="progress-bar">
                        <Progress
                          percent={activityUploadProgress}
                          showInfo={true}
                        />
                      </div>
                    )}
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    {hasError("activities", i, "mediaId") &&
                      `Image is required`}
                  </div>
                  <p className="field-label">Activity title</p>
                  <FastField
                    value={items?.title}
                    name={`activities[${i}].data[en].title`}
                    type="text"
                    suffix={
                      <div>
                        {50 - (items?.data?.[ENGLISH]?.title?.length || 0)}{" "}
                        letters
                      </div>
                    }
                    component={AntInput}
                    placeholder={"Enter title "}
                    shortSummary
                    maxLength={50}
                  />
                  <p className="field-label">Web URL</p>
                  <FastField
                    value={items?.url}
                    name={`activities[${i}].url`}
                    type="text"
                    component={AntInput}
                    placeholder={"Enter URL  "}
                    shortSummary
                  />
                </div>
              ))}
              <Button
                className="mb-31 add-btn"
                type="dashed"
                onClick={() => handleActivitiesList("activities")}
                style={{ background: "transparent !important" }}
              >
                <span>
                  <AddButton style={{ width: "14px", height: "14px" }} /> Add
                  Activity
                </span>
              </Button>
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm
              values={values?.data?.hi}
              videos={values?.videos}
              activities={values?.activities}
              city={city}
              section={section}
            />
          ) : (
            <MarathiLanguageForm
              values={values?.data?.mr}
              videos={values?.videos}
              activities={values?.activities}
              city={city}
              section={section}
            />
          )}

          <Button
            loading={isSubmitting}
            className="mt-40 submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = ({ leaders }) => ({
  leaders: leaders?.list,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFromReduxOrNetwork: bindActionCreators(
    RootActions.fetchFromReduxOrNetwork,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutPartyForm);
