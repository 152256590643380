import { Api } from "../config/request";
import * as RootActionCreators from "../../store/root/actionCreators";

export const GetApis = {
  common: {
    createImage: (payload) => Api.postRequest("media/v1/", payload),
  },

  tasks: {
    get: {
      data: (params = "") => Api.getRequest(`task/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "tasks"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`task/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "tasks"),
    },
    create: {
      data: (payload) => Api.postRequest("task/v1", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "tasks"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`task/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "tasks"),
    },
    filters: {
      dispatch: (payload) => RootActionCreators.updateFilters(payload, "tasks"),
    },
  },
  rewards: {
    get: {
      data: (params = "") => Api.getRequest(`reward/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "rewards"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`reward/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "rewards"),
    },
    create: {
      data: (payload) => Api.postRequest("reward/v1", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "rewards"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`reward/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "rewards"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "reward"),
    },
  },
  categoriestags: {
    get: {
      data: (params = "") => Api.getRequest(`categoryTag/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "categoryTags"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`categoryTag/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "categoryTags"),
    },
    create: {
      data: (payload) => Api.postRequest("categoryTag/v1", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "categoryTags"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`categoryTag/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "categoryTags"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "categoryTags"),
    },
  },
  trophies: {
    get: {
      data: (params = "") => Api.getRequest(`trophy/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "trophies"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`trophy/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "trophies"),
    },
    create: {
      data: (payload) => Api.postRequest("trophy/v1", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "trophies"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`trophy/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "trophies"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "trophies"),
    },
  },
  events: {
    get: {
      data: (params = "") => Api.getRequest(`event/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "events"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`event/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "events"),
    },
    create: {
      data: (payload) => Api.postRequest("event/v1", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "events"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`event/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "events"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "events"),
    },
  },
  polls: {
    get: {
      data: (params = "") => Api.getRequest(`poll/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "polls"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`poll/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "polls"),
    },
    create: {
      data: (payload) => Api.postRequest("poll/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "polls"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`poll/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "polls"),
    },
    filters: {
      dispatch: (payload) => RootActionCreators.updateFilters(payload, "polls"),
    },
  },
  infographics: {
    get: {
      data: (params = "") => Api.getRequest(`infographic/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "infographics"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`infographic/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "infographics"),
    },
    create: {
      data: (payload) => Api.postRequest("infographic/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "infographics"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`infographic/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "infographics"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "infographics"),
    },
  },
  articles: {
    get: {
      data: (params = "") => Api.getRequest(`article/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "articles"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`article/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "articles"),
    },
    create: {
      data: (payload) => Api.postRequest("article/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "articles"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`article/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "articles"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "articles"),
    },
  },
  announcements: {
    get: {
      data: (params = "") => Api.getRequest(`announcement/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "announcements"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`announcement/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "announcements"),
    },
    create: {
      data: (payload) => Api.postRequest("announcement/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "announcements"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`announcement/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "announcements"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "announcements"),
    },
  },
  votes: {
    get: {
      data: (params = "") => Api.getRequest(`vote/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "votes"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`vote/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "votes"),
    },
    create: {
      data: (payload) => Api.postRequest("vote/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "votes"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`vote/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "votes"),
    },
    filters: {
      dispatch: (payload) => RootActionCreators.updateFilters(payload, "votes"),
    },
  },
  youtubevideo: {
    get: {
      data: (params = "") => Api.getRequest(`youtube-video/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "youtubevideo"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`youtube-video/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "youtubevideo"),
    },
    create: {
      data: (payload) => Api.postRequest("youtube-video/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "youtubevideo"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`youtube-video/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "youtubevideo"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "youtubevideo"),
    },
  },
  facebooklive: {
    get: {
      data: (params = "") => Api.getRequest(`facebook-live/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "facebookLive"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`facebook-live/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "facebookLive"),
    },
    create: {
      data: (payload) => Api.postRequest("facebook-live/v1", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "facebookLive"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`facebook-live/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "facebookLive"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "facebookLive"),
    },
  },
  importantmessages: {
    get: {
      data: (params = "") => Api.getRequest(`importantmessage/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "importantMessages"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`importantmessage/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "importantMessages"),
    },
    create: {
      data: (payload) => Api.postRequest("importantmessage/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "importantMessages"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`importantmessage/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "importantMessages"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "importantMessages"),
    },
  },
  leaders: {
    get: {
      data: (params = "") => Api.getRequest(`party/v1/leader/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "leaders"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`party/v1/leader/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "leaders"),
    },
    create: {
      data: (payload) => Api.postRequest("party/v1/leader/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "leaders"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`party/v1/leader/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "leaders"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`party/v1/leader/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "leaders"),
    },
  },
  aboutparty: {
    get: {
      data: (params = "") => Api.getRequest(`party/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "party"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`party/v1/timeline/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "party"),
    },
    create: {
      data: (payload) => Api.postRequest("party/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "party"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`party/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "party"),
    },
    filters: {
      dispatch: (payload) => RootActionCreators.updateFilters(payload, "party"),
    },
  },
  manifesto: {
    get: {
      data: (params = "") => Api.getRequest(`party/v1/manifesto/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "manifesto"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`party/v1/manifesto/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "manifesto"),
    },
    create: {
      data: (payload) => Api.postRequest("party/v1/manifestoCreate/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "manifesto"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`party/v1/manifesto/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "manifesto"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`party/v1/manifesto/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "manifesto"),
    },
  },

  timeline: {
    get: {
      data: (params = "") => Api.getRequest(`party/v1/timeline/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "timeline"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`party/v1/timeline/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "timeline"),
    },
    create: {
      data: (payload) => Api.postRequest("party/v1/timelineCreate/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "timeline"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`party/v1/timeline/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "timeline"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`party/v1/timeline/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "timeline"),
    },
  },

  magazine: {
    get: {
      data: (params = "") => Api.getRequest(`magazine/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "magazine"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`magazine/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, "magazine"),
    },
    create: {
      data: (payload) => Api.postRequest("magazine/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "magazine"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`magazine/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "magazine"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`magazine/v1/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "magazine"),
    },
  },

  frontalorganisations: {
    get: {
      data: (params = "") =>
        Api.getRequest(`party/v1/frontalOrganisation/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "organisations"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`party/v1/frontalOrganisation/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "organisations"),
    },
    create: {
      data: (payload) =>
        Api.postRequest("party/v1/frontalOrganisationCreate/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "organisations"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`party/v1/frontalOrganisation/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "organisations"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`party/v1/frontalOrganisation/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "organisations"),
    },
  },
  organisationalstructure: {
    get: {
      data: (params = "") => Api.getRequest(`/organisation/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "organisations"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`/organisation/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "organisations"),
    },
    create: {
      data: (payload) => Api.postRequest("/organisation/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "organisations"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`/organisation/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "organisations"),
    },
    delete: {
      data: (id, payload) => Api.deleteRequest(`/organisation/v1/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "organisations"),
    },
  },
  organizationalmembers: {
    get: {
      data: (params = "") =>
        Api.getRequest(`/organisation-member/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "members"),
    },
    getFormData: {
      data: (params) => Api.getRequest(`/organisation-member/v1/?=${params}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "members"),
    },
    create: {
      data: (payload) => Api.postRequest("/organisation-member/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "members"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`/organisation-member/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "members"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`/organisation-member/v1/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "members"),
    },
  },
  mla_mlc: {
    get: {
      data: (params = "") => Api.getRequest(`/mla-mlc/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "mlaNmlc"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`/mla-mlc/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "mlaNmlc"),
    },
    create: {
      data: (payload) => Api.postRequest("/mla-mlc/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "mlaNmlc"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`/mla-mlc/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "mlaNmlc"),
    },
    delete: {
      data: (id, payload) => Api.deleteRequest(`/mla-mlc/v1/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "mlaNmlc"),
    },
  },
  mlaNmlcmembers: {
    get: {
      data: (params = "") => Api.getRequest(`/mla-mlc-member/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "members"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`/mla-mlc-member/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "members"),
    },
    create: {
      data: (payload) => Api.postRequest("/mla-mlc-member/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "members"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`/mla-mlc-member/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "members"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`/mla-mlc-member/v1/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "members"),
    },
  },
  schemes: {
    get: {
      data: (params = "") => Api.getRequest(`/scheme/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "schemes"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`/scheme/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "schemes"),
    },
    create: {
      data: (payload) => Api.postRequest("/scheme/v1", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "schemes"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`/scheme/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "schemes"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`/scheme/v1/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "schemes"),
    },
  },
  roles: {
    get: {
      data: (params = "") => Api.getRequest(`user/v1/role/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "roles"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`user/v1/role/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "roles"),
    },
    create: {
      data: (payload) => Api.postRequest("user/v1/roleV2/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "roles"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`user/v1/role/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "roles"),
    },
    filters: {
      dispatch: (payload) => RootActionCreators.updateFilters(payload, "roles"),
    },
  },
  category: {
    get: {
      data: (params = "") =>
        Api.getRequest(`merchandise/category/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "categoryMerchandises",
          "category"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`merchandise/category/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "category"),
    },
    create: {
      data: (payload) => Api.postRequest("merchandise/category/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "category"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`merchandise/category/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "category"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "category"),
    },
  },
  products: {
    get: {
      data: (params = "") =>
        Api.getRequest(`merchandise/product/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "products"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`merchandise/product/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "products"),
    },
    create: {
      data: (payload) => Api.postRequest("merchandise/product/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "products"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`merchandise/product/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "products"),
    },
    editVariant: {
      data: (id, payload) =>
        Api.putRequest(`merchandise/product/v1/variants/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "products"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "products"),
    },
  },
  orders: {
    get: {
      data: (params = "") => Api.getRequest(`merchandise/order/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "orders"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`merchandise/order/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "orders"),
    },
    create: {
      data: (payload) => Api.postRequest("merchandise/order/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "orders"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`merchandise/order/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "orders"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "orders"),
    },
  },
  digital_submissions: {
    get: {
      data: (params = "") =>
        Api.getRequest(`cadre-digi-submission/v1?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "cadreDigiSubmissions",
          "digital_submissions"
        ),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "digital_submissions"),
    },
  },
  survey: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-surveys/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "survey"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-surveys/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "survey"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-surveys/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "survey"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-surveys/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "survey"),
    },
    delete: {
      data: (id) => Api.deleteRequest(`cadre-surveys/v1/${id}`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "survey"),
    },
  },
  cadre_digital_work: {
    get: {
      data: (params = "") =>
        Api.getRequest(`cadre-digi-submission/v1?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "cadreDigiSubmissions",
          "cadre_digital_work"
        ),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_digital_work"),
    },
  },
  cadreExperiences: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-experiences/v1?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "cadreExperiences"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadreExperiences"),
    },
  },
  cadres: {
    get: {
      data: (params = "") => Api.getRequest(`cadre/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "cadres"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "cadres"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "cadres"),
    },
    edit: {
      data: (id, payload, apiRequest) =>
        Api[apiRequest](`cadre/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "cadres"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadres"),
    },
    export: {
      data: (payload) => Api.getRequest(`cadre/v1/download-excel/cadre`),
    },
  },
  cadre_designations: {
    get: {
      data: (params = "", payload = {}) =>
        Api.postRequest(`cadre-designation/v1/getAll?${params}`, payload),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload?.data,
          "designations",
          "cadre_designations"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-designation/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_designations"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-designation/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_designations"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`cadre-designation/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_designations"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_designations"),
    },
  },
  cadre_divisions: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-division/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "divisions",
          "cadre_divisions"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-division/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_divisions"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-division/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_divisions"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-division/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_divisions"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_divisions"),
    },
  },
  cadre_districts: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-district/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "districts",
          "cadre_districts"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-district/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_districts"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-district/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_districts"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-district/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_districts"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_districts"),
    },
  },
  cadre_vidhansabha: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-vidhansabha/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "vidhanSabha",
          "cadre_vidhansabha"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-vidhansabha/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_vidhansabha"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-vidhansabha/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_vidhansabha"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`cadre-vidhansabha/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_vidhansabha"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_vidhansabha"),
    },
  },
  cadre_taluka: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-taluka/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "taluka", "cadre_taluka"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-taluka/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_taluka"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-taluka/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_taluka"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-taluka/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_taluka"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_taluka"),
    },
  },
  cadre_council: {
    get: {
      data: (params = "") =>
        Api.getRequest(`cadre-municipalcouncil/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "municipalCouncil",
          "cadre_council"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-municipalcouncil/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_council"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-municipalcouncil/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_council"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`cadre-municipalcouncil/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_council"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_council"),
    },
  },
  cadre_panchyat: {
    get: {
      data: (params = "") =>
        Api.getRequest(`cadre-nagarpanchayat/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "nagarPanchayat",
          "cadre_panchyat"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-nagarpanchayat/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_panchyat"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-nagarpanchayat/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_panchyat"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`cadre-nagarpanchayat/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_panchyat"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_panchyat"),
    },
  },
  cadre_organization: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-organisation/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "organisations",
          "cadre_organization"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-organisation/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_organization"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-organisation/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_organization"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`cadre-organisation/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_organization"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_organization"),
    },
  },
  cadre_committee: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-committee/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(
          payload,
          "committees",
          "cadre_committee"
        ),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-committee/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_committee"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-committee/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_committee"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`cadre-committee/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_committee"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_committee"),
    },
  },
  cadre_post: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-post/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "posts", "cadre_post"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-post/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_post"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-post/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "cadre_post"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-post/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_post"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_post"),
    },
  },
  cadre_state: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-state/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "states", "cadre_state"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-state/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_state"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-state/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "cadre_state"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-state/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_state"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_state"),
    },
  },
  cadre_prabhag: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-prabhag/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "prabhag", "cadre_prabhag"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-prabhag/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_prabhag"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-prabhag/v1/", payload),
      dispatch: (payload) =>
        RootActionCreators.addData(payload, "cadre_prabhag"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-prabhag/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_prabhag"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_prabhag"),
    },
  },
  cadre_ward: {
    get: {
      data: (params = "") => Api.getRequest(`cadre-ward/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "wards", "cadre_ward"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`cadre-ward/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "cadre_ward"),
    },
    create: {
      data: (payload) => Api.postRequest("cadre-ward/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "cadre_ward"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`cadre-ward/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "cadre_ward"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "cadre_ward"),
    },
  },

  admins: {
    get: {
      data: (params = "") =>
        Api.getRequest(`user/v1/?userType=Admin&${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "users", "admins"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`user/v1/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "admins"),
    },
    create: {
      data: (payload) => Api.postRequest("user/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "admins"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`user/v1/user/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "admins"),
    },
    export: {
      data: (payload) =>
        Api.getRequest(`user/v1/?userType=Admin&download=ture`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "admins"),
    },
  },
  citizens: {
    get: {
      data: (params = "") =>
        Api.getRequest(`user/v1/?userType=Citizen&${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "users", "citizens"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`user/v1/user/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "citizens"),
    },
    create: {
      data: (payload) => Api.postRequest("user/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "citizens"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`user/v1/user/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "citizens"),
    },
    export: {
      data: (payload) =>
        Api.getRequest(`user/v1/?userType=Citizen&download=ture`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "citizens"),
    },
  },
  volunteers: {
    get: {
      data: (params = "") =>
        Api.getRequest(`user/v1/?userType=Voluntary&${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "users", "volunteers"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`user/v1/user/${id}`),
      dispatch: (payload, tags) =>
        RootActionCreators.setFormData(payload, tags, "volunteers"),
    },
    create: {
      data: (payload) => Api.postRequest("user/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "volunteers"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`user/v1/user/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "volunteers"),
    },
    export: {
      data: (payload) =>
        Api.getRequest(`user/v1/?userType=Voluntary&download=ture`),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "volunteers"),
    },
  },
  division: {
    get: {
      data: (params = "") => Api.getRequest(`division/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "divisions"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`division/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "divisions"),
    },
    create: {
      data: (payload) => Api.postRequest("division/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "divisions"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`division/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "divisions"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "divisions"),
    },
  },
  district: {
    get: {
      data: (params = "") => Api.getRequest(`district/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "districts"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`district/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "districts"),
    },
    create: {
      data: (payload) => Api.postRequest("district/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "districts"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`district/v1/${id}`, payload),
      dispatch: (payload) =>
        RootActionCreators.updateData(payload, "districts"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "districts"),
    },
  },
  policy: {
    get: {
      data: (params = "") => Api.getRequest(`config/v1/app-config/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "config"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`config/v1/app-config/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "config"),
    },
    create: {
      data: (payload) => Api.postRequest("config/v1/app-config/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "config"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`config/v1/app-config/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "config"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "config"),
    },
  },
  welcomevideo: {
    get: {
      data: (params = "") => Api.getRequest(`config/v1/app-config/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "config"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`config/v1/app-config/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "config"),
    },
    create: {
      data: (payload) => Api.postRequest("config/v1/app-config/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "config"),
    },
    edit: {
      data: (id, payload) =>
        Api.putRequest(`config/v1/app-config/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "config"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "config"),
    },
  },
  taluka: {
    get: {
      data: (params = "") => Api.getRequest(`taluka/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "talukas"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`taluka/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "talukas"),
    },
    create: {
      data: (payload) => Api.postRequest("taluka/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "talukas"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`taluka/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "talukas"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "talukas"),
    },
  },
  assembly: {
    get: {
      data: (params = "") => Api.getRequest(`assembly/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "assembly"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`assembly/v1/${id}`),
      dispatch: (payload) =>
        RootActionCreators.setFormData(payload, "assembly"),
    },
    create: {
      data: (payload) => Api.postRequest("assembly/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "assembly"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`assembly/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "assembly"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "assembly"),
    },
  },
  village: {
    get: {
      data: (params = "") => Api.getRequest(`village/v1/?${params}`),
      dispatch: (payload) =>
        RootActionCreators.setTableData(payload, "village"),
    },
    getFormData: {
      data: (id) => Api.getRequest(`village/v1/${id}`),
      dispatch: (payload) => RootActionCreators.setFormData(payload, "village"),
    },
    create: {
      data: (payload) => Api.postRequest("village/v1/", payload),
      dispatch: (payload) => RootActionCreators.addData(payload, "village"),
    },
    edit: {
      data: (id, payload) => Api.putRequest(`village/v1/${id}`, payload),
      dispatch: (payload) => RootActionCreators.updateData(payload, "village"),
    },
    filters: {
      dispatch: (payload) =>
        RootActionCreators.updateFilters(payload, "village"),
    },
  },
  questionsToLeaders: {
    get: {
      data: (params = "") =>
        Api.getRequest(`questions/v1/messageLeader/?${params}`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "data"),
    },
    export: {
      data: (params) => Api.getRequest(`questions/v1/messageLeader/?${params}`),
    },
  },

  metadata: {
    roles: {
      data: (params = "") => Api.getRequest(`user/v1/role`),
      dispatch: (payload) => RootActionCreators.setTableData(payload, "roles"),
    },
    categoryTags: {
      data: (params = "") =>
        Api.getRequest(`categoryTag/v1/?${params}&pageSize=1000`),
      dispatch: (payload) =>
        RootActionCreators.setUtils(payload, "categoryTags"),
    },
    rewards: {
      data: (params = "") => Api.getRequest(`reward/v1/?${params}`),
      dispatch: (payload) => RootActionCreators.setUtils(payload, "rewards"),
    },
    state: {
      data: (countryId = "59fefd61-1a80-4e17-b2cf-7e0df354173c") =>
        Api.getRequest(`location/v1/state/?countryId=${countryId}`),
      dispatch: (payload) =>
        RootActionCreators.setUtils(payload, "rows", "state"),
    },

    utils: {
      data: (params = "") => Api.getRequest(`party/v1/config`),
      dispatch: (payload) => RootActionCreators.setUtils(payload),
    },
  },
};

export const GetDropdownApi = {
  categoryTags: {
    data: (params = "") => Api.getRequest(`categoryTag/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  utils: {
    data: (params = "") => Api.getRequest(`party/v1/config`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  roles: {
    data: (params = "") => Api.getRequest(`user/v1/role`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  state: {
    data: (params) =>
      Api.getRequest(
        `location/v1/state/?${params}
        }`
      ),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },

  district: {
    data: (params = "") => Api.getRequest(`location/v1/district/?${params}`),
    tableData: (params = "") => Api.getRequest(`district/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },

  division: {
    data: (params = "") => Api.getRequest(`location/v1/city/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  taluka: {
    data: (params = "") => Api.getRequest(`location/v1/taluka/?${params}`),
    tableData: (params = "") => Api.getRequest(`taluka/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },

  cadreDesignation: {
    data: async (params = "", payload) => {
      let request = await Api.postRequest(
        `cadre-designation/v1/getAll?pageSize=10&page=0`,
        payload
      );
      return request?.data;
    },
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreOrganisation: {
    data: (params = "") => Api.getRequest(`cadre-organisation/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreCommittee: {
    data: (params = "") => Api.getRequest(`cadre-committee/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadrePost: {
    data: (params = "") => Api.getRequest(`cadre-post/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreState: {
    data: (params = "") => Api.getRequest(`cadre-state/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreDistrict: {
    data: (params = "") => Api.getRequest(`cadre-district/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreDivision: {
    data: (params = "") => Api.getRequest(`cadre-division/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreTaluka: {
    data: (params = "") => Api.getRequest(`cadre-taluka/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreVidhansabha: {
    data: (params = "") => Api.getRequest(`cadre-vidhansabha/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreMunicipalcouncil: {
    data: (params = "") =>
      Api.getRequest(`cadre-municipalcouncil/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreNagarpanchayat: {
    data: (params = "") => Api.getRequest(`cadre-nagarpanchayat/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadreWard: {
    data: (params = "") => Api.getRequest(`cadre-ward/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
  cadrePrabhag: {
    data: (params = "") => Api.getRequest(`cadre-prabhag/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setDropDownData(payload),
  },
};

export const GetModalTableApi = {
  districts: {
    data: (params = "") => Api.getRequest(`district/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setUtils(payload),
  },
  talukas: {
    data: (params = "") => Api.getRequest(`taluka/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setUtils(payload),
  },
  assembly: {
    data: (params = "") => Api.getRequest(`assembly/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setUtils(payload),
  },
  village: {
    data: (params = "") => Api.getRequest(`village/v1/?${params}`),
    dispatch: (payload) => RootActionCreators.setUtils(payload),
  },
};
