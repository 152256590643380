import { Layout, Skeleton, Table } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as RootActions from "../../store/root/actions";
import {
  fetchCMSLanguage,
  generateFilterUrl,
  getSerializedData,
} from "../../utils/helpers";
import { connect } from "react-redux";
import { YEARS } from "../../constants/data";
import Modal from "../../components/Modal";
import { Modal as AntdModal } from "antd";
import AppHeader from "../../components/Header";
import "../common.scss";
import {
  getAboutPartyInitialValues,
  getLeaderInitialValues,
  getManifestoInitialValues,
  getMembersInitialValues,
  getMlAMLCMembers,
  getOrganizationalStructureInitialValues,
  getOrganizationInitialValues,
  getSchemesInitialValues,
  getTimelineInitialValues,
  getMagazineInitialValues,
  LEADERS_SCHEMA,
  MANIFESTO_SCHEMA,
  MEMBERS_SCHEMA,
  MLA__MLC_SCHEMA,
  ORGANIZATIONALSTRUCTURE_SCHEMA,
  ORGANIZATION_SCHEMA,
  PARTY_BIO_SCHEMA,
  SCHEMES_SCHEMA,
  TIMELINE_SCHEMA,
  MAGAZINE_SCHEMA,
} from "./validate";
import TableToolbar from "../../components/TableToolbar";
import InnerHeader from "../../components/InnerHeader";
import {
  getColumns,
  INNER_HEADERS,
  TABS,
  createPayload,
  getPropField,
  buttonField,
  getDropDowns,
  getSubColumns,
} from "./utils";
import { CustomPagination } from "components/CustomPagination";
import AddOrganizationForm from "forms/AddOrganizationForm/AddOrganizationForm";
import AddManifestoForm from "forms/AddManifestoForm/AddManifestoForm";
import AddTimelineForm from "forms/AddTimelineForm/AddTimelineForm";
import AddMagazineForm from "forms/AddMagazineForm/AddMagazineForm";
import AddLeaderForm from "forms/AddLeaderForm/AddLeaderForm";
import AboutPartyForm from "forms/AboutPartyForm/AboutPartyForm";
import AddSchemesForm from "forms/AddSchemesForm/AddSchemesForm";
import { bindActionCreators } from "redux";
import { AboutParty } from "./AboutParty";
import { COLUMN_ACTIONS } from "constants/dummyData";
import AddOrganizationStructureForm from "forms/AddOrganizationStructureForm/AddOrganizationStructureForm";
import AddMembersForm from "forms/AddMembersForm/AddMembersForm";

const { Header, Content } = Layout;

const Party = (props) => {
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [innerTab, setInnerTab] = useState(TABS.LEADERS);
  const [sortColumn, setSortColumn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(0);
  const [leaderFormPage, setLeaderFormPage] = useState(1);
  // const [leadersPageErrors, setLeadersPageErrors] = useState([]);
  const [showMemberForm, setShowMemberForm] = useState(false);
  const [subTableVisible, setSubTableVisible] = useState(false);
  const [subTableHeading, setSubTableHeading] = useState(false);
  const [subInnerHeading, setSubInnerHeading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [subTableData, setSubTableData] = useState(false);
  const [subTableFormModal, setSubTableFormModal] = useState(false);
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());
  const [modalTablePage, setModalTablePage] = useState(0);
  const [tableFilters, setTableFilters] = useState({});

  const dropdownData = props?.dropdownData;

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  useEffect(() => {
    switch (innerTab) {
      case TABS.ORGANIZATIONALSTRUCTURE:
        return setSubInnerHeading(TABS.ORGANIZATIONALMEMBERS);
      case TABS.MLAandMLC:
        return setSubInnerHeading(TABS.MLA_MLCMEMBERS);
      default:
        return false;
    }
  }, [innerTab]);

  useEffect(() => {
    props.fetchDropdownData("utils");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    if (innerTab === "magazine" && (filters?.order?.length < 2)) {
      filters.order = ['publishedDate', 'DESC']
    }
    let { urlParams } = generateFilterUrl({
      ...filters,
      isAdmin: true,
    });
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.LEADERS:
          return props.leaders?.[key];
        case TABS.MANIFESTO:
          return props.manifestos?.[key];
        case TABS.TIMELINE:
          return props.timelines?.[key];
        case TABS.MAGAZINE:
          return props.magazine?.[key];
        case TABS.ORGANIZATION:
          return props.frontalorganisations?.[key];
        case TABS.SCHEMES:
          return props.schemes?.[key];
        case TABS.ABOUT_PARTY:
          return props.aboutparty?.[key];
        case TABS.ORGANIZATIONALSTRUCTURE:
          return props.organisationalstructure?.[key];
        case TABS.MLAandMLC:
          return props.mla_mlc?.[key];
        default:
          return null;
      }
    },
    [
      props.leaders,
      props.manifestos,
      props.timelines,
      props.magazine,
      props.frontalorganisations,
      props.schemes,
      props.aboutparty,
      props.organisationalstructure,
      props.mla_mlc,
      innerTab,
    ]
  );

  const setInitialValues = async (data = {}) => {
    let values =
      innerTab === TABS.LEADERS
        ? await getLeaderInitialValues(data)
        : innerTab === TABS.TIMELINE
        ? await getTimelineInitialValues(data)
        : innerTab === TABS.MAGAZINE
        ? await getMagazineInitialValues(data)
        : innerTab === TABS.MANIFESTO
        ? await getManifestoInitialValues(data)
        : innerTab === TABS.ORGANIZATION
        ? await getOrganizationInitialValues(data)
        : innerTab === TABS.SCHEMES
        ? await getSchemesInitialValues(data)
        : innerTab === TABS.ABOUT_PARTY
        ? await getAboutPartyInitialValues(props.aboutparty?.["list"])
        : innerTab === TABS.ORGANIZATIONALSTRUCTURE
        ? await getOrganizationalStructureInitialValues(data)
        : innerTab === TABS.MLAandMLC
        ? await getOrganizationalStructureInitialValues(data)
        : {};
    setFormInitialValues(values);
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    if (innerTab === TABS.TIMELINE) {
      sortOrder = sortOrder.toLowerCase();
      if (sortKey === "year") {
        sortParams = {
          year: sortOrder,
          month: sortOrder,
          date: sortOrder,
        };
        applyFilters(sortParams);
      } else {
        sortParams = {
          date: sortOrder,
        };
        applyFilters(sortParams, "deleteParams");
      }
    } else {
      applyFilters(sortParams);
    }

    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    applyFilters({ search: query });
  };

  const applyFilters = (updates = {}, deleteParams) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
      isAdmin: true,
    };
    if (deleteParams) {
      delete apiFilters["year"];
      delete apiFilters["month"];
    }
    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  const openModal = async () => {
    // eslint-disable-next-line array-callback-return
    if (innerTab === TABS.ABOUT_PARTY) {
      await setInitialValues();
    }
    setFormModalVisible(true);
  };

  const closeModal = () => {
    setIsEditing(false);
    setInitialValues({});
    setFormModalVisible(false);
    setLeaderFormPage(1);
    setShowMemberForm(() => false);
  };

  const closeSubTableForm = () => {
    setInitialValues({});
    setSubTableFormModal(false);
  };

  const handlePageChange = (page) => {
    setPageNumber(+page - 1);
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleOrganizationMemberForm = async (
    formData,
    innerHeading,
    editing = false
  ) => {
    setShowMemberForm(true);

    const data =
      subInnerHeading === TABS.MLA_MLCMEMBERS
        ? await getMlAMLCMembers(formData)
        : await getMembersInitialValues(formData);
    setFormInitialValues(data);
    setSubTableFormModal(true);
    setIsEditing(editing);
  };

  const handleSubTable = async (payload) => {
    const { params, heading, data } = payload;
    const filters = generateFilterUrl(params);
    await props.fetchDataForInnerTabs(subInnerHeading, filters.urlParams);
    setSubTableData(data);
    setTableFilters(params);
    setSubTableVisible(true);
    setSubTableHeading(heading);
  };

  const handleModalTablePage = async (page) => {
    setModalTablePage(+page - 1);
    let filterUpdates = {
      ...tableFilters,
      page: page - 1,
    };
    props.updateFilters(subInnerHeading, filterUpdates);
    //applyFilters(filterUpdates);

    const params = generateFilterUrl(filterUpdates);
    await props.fetchDataForInnerTabs(subInnerHeading, params.urlParams);
  };

  const closeSubTable = () => {
    setSubTableVisible(false);
    setSubTableData({});
    setModalTablePage(0);
    return false;
  };

  return (
    <div className="wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={
              props[getPropField(innerTab)]?.activeCount ||
              props[getPropField(innerTab)]?.count ||
              "0"
            }
            showCount={innerTab === TABS.ABOUT_PARTY ? false : true}
            onclick={setInnerTab}
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                <TableToolbar
                  innerTab={innerTab}
                  onSearch={handleSearch}
                  ctaLabel={buttonField(innerTab)}
                  isEditMode={
                    innerTab === TABS.ABOUT_PARTY && props.aboutparty?.["list"]
                      ? true
                      : false
                  }
                  onClickAdd={openModal}
                  query={props[innerTab]?.filters?.search || ""}
                  showFilter={innerTab === TABS.ABOUT_PARTY ? false : true}
                />
                <div
                  className={
                    innerTab === TABS.ABOUT_PARTY
                      ? "container"
                      : "container pl-40 pr-40"
                  }
                >
                  {innerTab === TABS.ABOUT_PARTY ? (
                    <AboutParty
                      isDataPresent={getDataFromRedux() ? true : false}
                      data={getDataFromRedux()}
                      languageId={languageId}
                    />
                  ) : (
                    <Table
                      className={"is_editable"}
                      rowKey={"id"}
                      columns={getColumns(
                        innerTab,
                        handleSort,
                        sortColumn,
                        handleOrganizationMemberForm,
                        handleSubTable,
                        languageId,
                        async (actionType, rowId, value) => {
                          switch (actionType) {
                            case COLUMN_ACTIONS.TOGGLE_STATUS: {
                              if (rowId) {
                                let request = await props.updateData(
                                  innerTab,
                                  rowId,
                                  {
                                    isActive: value,
                                  }
                                );
                                if (request?.status === 200) {
                                  fetchData(
                                    innerTab,
                                    getDataFromRedux("filters")
                                  );
                                }
                              }
                            }
                            // eslint-disable-next-line no-fallthrough
                            default: {
                              return null;
                            }
                          }
                        },
                        (rowId) => {
                          AntdModal.confirm({
                            className: "confirm-modal",
                            style: { width: "600px !importnat" },
                            title: `Are you sure you want to delete this ${buttonField(
                              innerTab
                            )}?`,
                            centered: true,
                            okText: "Yes",
                            cancelText: "No",
                            content: <div></div>,
                            cancelButtonProps: {
                              type: "link",
                              style: { background: "#273054" },
                            },
                            okButtonProps: {
                              type: "link",
                              style: {
                                background: "#CD0000",
                              },
                            },
                            onOk: async () => {
                              let request = await props.deleteData(
                                innerTab,
                                rowId
                              );
                              if (request?.status === 200) {
                                fetchData(
                                  innerTab,
                                  getDataFromRedux("filters")
                                );
                              }
                            },
                          });
                        }
                      ).map((column) => {
                        return {
                          ...column,
                          ...(props.loading && {
                            render: () => (
                              <Skeleton
                                key={column.dataIndex}
                                title={true}
                                paragraph={false}
                              />
                            ),
                          }),
                        };
                      })}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: async (event) => {
                            try {
                              const data = await props.fetchDataForTable(
                                innerTab,
                                record.id
                              );
                              data
                                ? await setInitialValues(data)
                                : await setInitialValues(record);
                              setIsEditing(true);
                              setActiveRow(record);
                              openModal();
                            } catch {}
                            setFormModalVisible(() => true);
                          },
                        };
                      }}
                      dataSource={getSerializedData(
                        getDataFromRedux("filters")?.page,
                        getDataFromRedux()
                      )}
                      pagination={false}
                    />
                  )}
                  {innerTab !== TABS.ABOUT_PARTY && (
                    <CustomPagination
                      current={props[getPropField(innerTab)]?.filters?.page + 1}
                      totalCount={props[getPropField(innerTab)]?.count}
                      onChange={handlePageChange}
                    />
                  )}
                </div>
                <Modal
                  width="538px"
                  isEditing={isEditing}
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing
                        ? `${buttonField(innerTab, showMemberForm)} Details`
                        : `Add ${buttonField(innerTab, showMemberForm)}`}
                    </p>
                  }
                  destroyOnClose
                  page={innerTab === TABS.LEADERS ? leaderFormPage : undefined}
                  onCancel={closeModal}
                  visible={formModalVisible}
                >
                  <div
                    style={
                      innerTab !== TABS.LEADERS
                        ? {
                            width: "85%",
                            marginLeft: "40px",
                          }
                        : {}
                    }
                  >
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(innerTab, values);
                        let id = payload?.id;
                        delete payload["id"];
                        let request;
                        if (isEditing || id)
                          if (innerTab === TABS.ABOUT_PARTY) {
                            request = props.updateData(innerTab, id, payload);
                          } else {
                            request = props.updateData(
                              innerTab,
                              activeRow.id,
                              payload
                            );
                          }
                        else request = props.createData(innerTab, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSortColumn(null);
                              setFormModalVisible(false);
                              setIsEditing(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                            setInitialValues({});
                            setLeaderFormPage(1);
                          });
                      }}
                      validationSchema={
                        innerTab === TABS.TIMELINE
                          ? TIMELINE_SCHEMA
                          : innerTab === TABS.MAGAZINE
                          ? MAGAZINE_SCHEMA
                          : innerTab === TABS.MANIFESTO
                          ? MANIFESTO_SCHEMA
                          : innerTab === TABS.SCHEMES
                          ? SCHEMES_SCHEMA
                          : innerTab === TABS.ORGANIZATION
                          ? ORGANIZATION_SCHEMA
                          : innerTab === TABS.LEADERS
                          ? LEADERS_SCHEMA
                          : innerTab === TABS.ABOUT_PARTY
                          ? PARTY_BIO_SCHEMA
                          : innerTab === TABS.ORGANIZATIONALSTRUCTURE
                          ? ORGANIZATIONALSTRUCTURE_SCHEMA
                          : innerTab === TABS.MLAandMLC
                          ? MLA__MLC_SCHEMA
                          : null
                      }
                    >
                      {(rest) =>
                        innerTab === TABS.LEADERS ? (
                          <AddLeaderForm
                            isEditing={isEditing}
                            page={leaderFormPage}
                            publicRepresentative={getDropDowns(
                              dropdownData?.publicRepresentative
                            )}
                            department={getDropDowns(dropdownData?.department)}
                            city={getDropDowns(dropdownData?.location)}
                            section={getDropDowns(
                              dropdownData?.leaderVideoType
                            )}
                            position={getDropDowns(
                              dropdownData?.positionInParty
                            )}
                            submitButtonLabel={`${
                              isEditing ? "Edit Leader " : "Add Leader"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.TIMELINE ? (
                          <AddTimelineForm
                            years={YEARS}
                            submitButtonLabel={`${
                              isEditing ? "Edit Timeline " : "Add Timeline"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.MAGAZINE ? (
                          <AddMagazineForm
                            years={YEARS}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Edit Magazine " : "Add Magazine"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.MANIFESTO ? (
                          <AddManifestoForm
                            submitButtonLabel={`${
                              isEditing ? "Edit Manifesto " : "Add Manifesto"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.ORGANIZATION ? (
                          <AddOrganizationForm
                            years={YEARS}
                            submitButtonLabel={`${
                              isEditing
                                ? "Edit Organization "
                                : "Add Organization"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.ABOUT_PARTY ? (
                          <AboutPartyForm
                            isEditing={isEditing}
                            city={getDropDowns(dropdownData?.location)}
                            section={getDropDowns(
                              dropdownData?.leaderVideoType
                            )}
                            {...rest}
                          />
                        ) : innerTab === TABS.SCHEMES ? (
                          <AddSchemesForm
                            department={getDropDowns(dropdownData?.department)}
                            submitButtonLabel={`${
                              isEditing ? "Edit Schemes" : "Add Schemes"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.ORGANIZATIONALSTRUCTURE ? (
                          <AddOrganizationStructureForm
                            submitButtonLabel={`${
                              isEditing
                                ? "Edit Organization"
                                : "Add Organization"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.MLAandMLC ? (
                          <AddOrganizationStructureForm
                            innerTab={innerTab}
                            representativeTypes={getDropDowns(
                              dropdownData?.representativeType
                            )}
                            submitButtonLabel={`${
                              isEditing
                                ? "Edit Organization"
                                : "Add Organization"
                            } `}
                            {...rest}
                          />
                        ) : null
                      }
                    </Formik>
                  </div>
                </Modal>
                <Modal
                  width="1100px"
                  className="tablemodal"
                  isEditing={isEditing}
                  title={
                    <p className="flex items-center color-black m-0 mb-12 weight-700 size-26">
                      {subTableHeading}
                    </p>
                  }
                  destroyOnClose
                  onCancel={closeSubTable}
                  visible={subTableVisible}
                  footer={false}
                >
                  <Table
                    className={""}
                    scroll={{
                      y: window.innerHeight - 400,
                    }}
                    columns={getSubColumns(
                      subInnerHeading,
                      handleOrganizationMemberForm,
                      (rowId) => {
                        AntdModal.confirm({
                          className: "confirm-modal",
                          style: { width: "600px !importnat" },
                          title:
                            "Are you sure you want to delete this member from the organisation?",
                          centered: true,
                          okText: "Yes",
                          cancelText: "No",
                          content: <div></div>,
                          cancelButtonProps: {
                            type: "link",
                            style: { background: "#273054" },
                          },
                          okButtonProps: {
                            type: "link",
                            style: {
                              background: "#CD0000",
                            },
                          },
                          onOk: async () => {
                            let request = await props.deleteData(
                              subInnerHeading,
                              rowId
                            );
                            if (request?.status === 200) {
                              let params = generateFilterUrl({
                                ...tableFilters,
                                page: 0,
                              });
                              setModalTablePage(0);
                              props.fetchDataForInnerTabs(
                                subInnerHeading,
                                params.urlParams
                              );
                              fetchData(innerTab, getDataFromRedux("filters"));
                            }
                          },
                        });
                      },
                      languageId
                    ).map((column) => {
                      return {
                        ...column,
                        ...(props.loading && {
                          render: () => (
                            <Skeleton
                              key={column.dataIndex}
                              title={true}
                              paragraph={false}
                            />
                          ),
                        }),
                      };
                    })}
                    dataSource={props.organisation_members?.["list"]}
                    pagination={false}
                  />
                  <div className="table-pagination">
                    <CustomPagination
                      current={modalTablePage + 1}
                      totalCount={props.organisation_members?.["count"]}
                      onChange={handleModalTablePage}
                    />
                  </div>
                </Modal>
                <Modal
                  width="538px"
                  isEditing={isEditing}
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing ? `Member Details` : `Add Member`}
                    </p>
                  }
                  destroyOnClose
                  onCancel={closeSubTableForm}
                  visible={subTableFormModal}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(subInnerHeading, values);
                        let id = payload?.id;
                        delete payload["id"];
                        let request;
                        if (isEditing || id) {
                          request = props.updateData(
                            subInnerHeading,
                            id,
                            payload
                          );
                        } else
                          request = props.createData(subInnerHeading, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSubTableFormModal(false);
                              setFormModalVisible(false);
                              setSubTableVisible(false);
                              if (!isEditing)
                                fetchData(
                                  innerTab,
                                  getDataFromRedux("filters")
                                );
                              if (isEditing) {
                                let filters = generateFilterUrl(tableFilters);
                                props.fetchDataForInnerTabs(
                                  subInnerHeading,
                                  filters?.urlParams
                                );
                              }
                              setIsEditing(false);
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                            setInitialValues({});
                          });
                      }}
                      validationSchema={MEMBERS_SCHEMA}
                    >
                      {(rest) =>
                        subInnerHeading === TABS.MLA_MLCMEMBERS ? (
                          <AddMembersForm
                            {...rest}
                            submitButtonLabel={`${
                              isEditing ? "Edit Member" : "Add Member"
                            } `}
                          />
                        ) : (
                          <AddMembersForm
                            {...rest}
                            submitButtonLabel={`${
                              isEditing ? "Edit Member" : "Add Member"
                            } `}
                          />
                        )
                      }
                    </Formik>
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

Party.propTypes = {};

const mapStateToProps = ({
  leaders,
  manifestos,
  timelines,
  magazine,
  frontalorganisations,
  partyachievements,
  aboutparty,
  utils,
  schemes,
  organisationalstructure,
  mla_mlc,
  organisation_members,
  dropdownData,
}) => ({
  leaders,
  manifestos,
  timelines,
  magazine,
  frontalorganisations,
  partyachievements,
  aboutparty,
  utils,
  schemes,
  organisationalstructure,
  mla_mlc,
  organisation_members,
  dropdownData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
  deleteData: bindActionCreators(RootActions.deleteData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Party);
