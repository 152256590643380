import { Button, Col, Row } from "antd";
import { FastField, Form } from "formik";
import React, { useState } from "react";
import "../index.scss";
import FormMenu from "components/FormMenu";
import { HINDI, ENGLISH, MARATHI } from "constants/language";
import HindiLanguageForm from "./Formlanguage/HindiLanguageForm";
import MarathiLanguageForm from "./Formlanguage/MarathiLanguageForm";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AddButton, TrashIcon } from "assets/svgs";

const AddMembersForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  errors,
  touched,
  submitButtonLabel,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  // useEffect(() => {}, [values]);

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  const addContactNumber = () => {
    setFieldValue("phoneNumbers", [...values.phoneNumbers, ""]);
  };

  const deleteContactNumber = (index) => {
    let number = values?.phoneNumbers?.filter((item, i) => i !== index);
    setFieldValue("phoneNumbers", number);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mb-8 field-label">Leader name</p>
              <FastField
                value={values.name}
                name="data[en].name"
                type="text"
                suffix={
                  <div>
                    {60 - (values?.data?.[ENGLISH]?.name?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter leader name "}
                maxLength={60}
              />
              <p className="mb-8 field-label">Organization</p>
              <FastField
                value={values.organization}
                name="data[en].organization"
                type="text"
                disabled
                component={AntInput}
                placeholder={"Enter organization"}
              />
              <p className="mb-8 field-label">Position in organization</p>
              <FastField
                value={values.partyPosition}
                name="data[en].partyPosition"
                type="text"
                suffix={
                  <div>
                    {100 -
                      (values?.data?.[ENGLISH]?.partyPosition?.length ||
                        0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter position"}
                maxLength={100}
              />
              <p className="mt-8 field-label">Order </p>
              <FastField
                value={values.order}
                name="order"
                type="text"
                component={AntInput}
                placeholder={"Enter order "}
                maxLength={4}
              />
              <p className="mb-8 field-label">Add contact number</p>
              {values.phoneNumbers?.map((item, i) => (
                <Row
                  key={i}
                  className="social-media"
                  justify="start"
                  align="top"
                >
                  <Col span={22}>
                    <FastField
                      value={values.phoneNumbers}
                      name={`phoneNumbers[${i}]`}
                      type="text"
                      component={AntInput}
                      placeholder={"Add number here"}
                    />
                  </Col>
                  <Col
                    className="social-trash-icon"
                    style={{ position: "relative", top: "12px" }}
                    offset={1}
                    onClick={() => deleteContactNumber(i)}
                  >
                    <TrashIcon />
                  </Col>
                </Row>
              ))}
              <Button
                className="add-btn mb-54"
                type="dashed"
                onClick={addContactNumber}
                style={{ background: "transparent !important" }}
              >
                <span>
                  <AddButton /> Add Contact number
                </span>
              </Button>
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data?.[HINDI]} />
          ) : (
            <MarathiLanguageForm values={values?.data?.[MARATHI]} />
          )}
          <Button
            loading={isSubmitting}
            className={
              language === ENGLISH && values.phoneNumbers?.length > 1
                ? "submit-btn"
                : "absolute-submit-btn submit-btn"
            }
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddMembersForm;
