import { AntInput } from "components/CreateAntFields/CreateAntField";
import { FastField } from "formik";

const MarathiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Leader name</p>
      <FastField
        value={values?.name}
        name="data[mr].name"
        type="text"
        suffix={<div>{60 - (values?.name?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter leader name "}
        maxLength={60}
      />
      <p className="mb-8 field-label">Position in organization</p>
      <FastField
        value={values?.partyPosition}
        name="data[mr].partyPosition"
        type="text"
        suffix={<div>{100 - (values?.partyPosition?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter position"}
        maxLength={100}
      />
    </>
  );
};

export default MarathiLanguageForm;
