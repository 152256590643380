import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { PanoramaImage } from "assets/svgs";
import { imagesUrl } from "constants/imagesUrl";

export const UploadButton = ({
  loading,
  width,
  ratio,
  maxSize,
  maxResolution,
}) => {
  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="flex flex-col justify-center items-center">
          <PanoramaImage />
          <p className="mt-5 weight-700 size-14">
            Drag your image here, or{" "}
            <span className="browse-btn mt-5 weight-700 size-14">browse</span>
          </p>
          <p className="mt-10 color-black weight-500 size-11 upload-instructions">
            Supports: JPG,PNG
            <br />
            Ratio: {ratio || "16: 9"}
            <br />
          </p>
          {maxSize ? (
            <p className="mt-10 color-black weight-500 size-11 upload-instructions">
              Maximum Image size: {maxSize}
            </p>
          ) : null}
          {maxResolution ? (
            <p className="mt-10 color-black weight-500 size-11 upload-instructions">
              Maximum Image resolution: {maxResolution}
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};

export const UploadImages = ({ loading, ratio, width }) => {
  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="flex flex-col justify-center items-center">
          <PanoramaImage />
          <p className="mt-5 weight-700 size-14">
            Drag your image here, or{" "}
            <span className="browse-btn mt-5 weight-700 size-14">browse</span>
          </p>
          <p className="mt-10 color-black weight-500 size-11 upload-instructions">
            Supports: JPG,PNG
            <br />
            Ratio: {ratio || "16:9"}
            <br />
            (Please note: 1st image is set as cover picture)
          </p>
        </div>
      )}
    </div>
  );
};

export const UploadVideoButton = ({ loading }) => {
  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="video-container justify-center align-center">
          <p className="upload-video-placeholder">
            <span className="mr-8">{imagesUrl.icons.UploadIcon} </span>
            {}
            <span> Upload Video</span>
          </p>
          <p className="mt-10 weight-700 size-14">
            Drag your video here, or{" "}
            <span className="browse-btn mt-5 weight-700 size-14">browse</span>
          </p>
          <p className="color-grey weight-500 size-11 upload-instructions">
            Supports: .mp4,.mov & .avi
          </p>
        </div>
      )}
    </div>
  );
};

export const UploadActivityImage = ({ loading }) => {
  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="flex flex-col justify-start mt-50 items-center align-center">
          <span>{imagesUrl.icons.UploadIcon} </span>
          <span className="mt-5 color-grey weight-700 size-14">
            {" "}
            Upload Image
          </span>
          <p className="color-grey mt-5 weight-700 size-14 upload-instructions">
            Drag your image here,
            {<br />}
            or{" "}
            <span className="browse-btn mt-5 weight-700 size-14">browse</span>
          </p>
        </div>
      )}
    </div>
  );
};

export const UploadAuthorityLogo = ({ loading }) => {
  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="flex flex-col justify-center items-center">
          <PanoramaImage />
          <p className="mt-5 weight-700 size-14">
            Drag your image here, or{" "}
            <span className="mt-5 weight-700 size-14 browse-btn">browse</span>
          </p>
          <p className="mt-10 color-black weight-500 size-11 upload-instructions">
            Supports: JPG,PNG
            <br />
            Image Ratio: 1:1
            <br />
          </p>
        </div>
      )}
    </div>
  );
};

export const UploadPDFButton = ({ loading }) => {
  return (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <Button type="primary" className="submit-btn gap-3">
          Click to Upload
        </Button>
      )}
    </div>
  );
};
