import { Button } from "antd";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI } from "constants/language";
import { FastField, Form } from "formik";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import { useState } from "react";

export const AddPolicyForm = ({
  values,
  setFieldValue,
  errors,
  isSubmitting,
  touched,
  submitButtonLabel,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mt-25 field-label">Policy</p>
              <FastField
                value={values?.policy}
                name="policyData.en"
                type="editor"
                component={RTEditor}
                placeholder={"Enter details here"}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.hi} />
          ) : (
            <MarathiLanguageForm values={values?.mr} />
          )}
          <Button
            loading={isSubmitting}
            className="mt-40 submit-btn absolute-submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};
