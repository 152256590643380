import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const MarathiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={values?.title}
        name="data[mr].title"
        type="text"
        suffix={<div>{50 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Short Summary</p>
      <Field
        value={values?.shortSummary}
        name="data[mr].shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={300}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
    </>
  );
};

export default MarathiLanguageForm;
