import { AntInput } from "components/CreateAntFields/CreateAntField";
import { FastField } from "formik";
import { HINDI } from "constants/language";

const HindiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Title </p>
      <FastField
        value={values.title}
        name="data[hi].title"
        type="text"
        suffix={
          <div>{100 - (values?.[HINDI]?.title?.length || 0)} letters</div>
        }
        component={AntInput}
        placeholder={"Enter content title "}
        maxLength={100}
      />
    </>
  );
};

export default HindiLanguageForm;
