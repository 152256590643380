import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { HINDI } from "constants/language";
import { FastField, Field } from "formik";

const HindiLanguageForm = ({
  values,
  videos = [],
  activities = [],
  city = [],
}) => {
  return (
    <>
      <p className={"field-label mt-29"}>About party heading </p>
      <FastField
        value={values.heading}
        name="data[hi].heading"
        type="text"
        suffix={<div>{50 - (values?.heading?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content title "}
        maxLength={50}
      />
      <p className="field-label">Party bio 1</p>
      <FastField
        value={values.bio1}
        name="data[hi].bio1"
        type="editor"
        component={RTEditor}
        characterLimit={20000}
        placeholder={"Enter details here"}
      />
      <p className="field-label">Party bio 2</p>
      <FastField
        value={values.bio2}
        name="data[hi].bio2"
        type="editor"
        component={RTEditor}
        characterLimit={20000}
        placeholder={"Enter details here"}
      />
      {videos?.map((items, i) => (
        <div key={i}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="field-label">Video {i + 1}</p>
          </div>
          <p className="field-label">Video title</p>
          <FastField
            value={items?.title}
            name={`videos[${i}].data.[hi].title`}
            type="text"
            suffix={
              <div>
                {50 - (videos?.[i]?.data?.[HINDI]?.title?.length || 0)} letters
              </div>
            }
            component={AntInput}
            placeholder={"Enter content title "}
            shortSummary
            maxLength={50}
          />
          <p className="field-label">Location</p>
          <Field
            value={items?.location}
            selectOptions={city}
            optionValueKey="id"
            optionNameKey="hindi"
            disabled={true}
            filterOption={false}
            showSearch={true}
            mode={"single"}
            name={`videos.[${i}].data[hi].location`}
            component={AntSelect}
            placeholder={"Mumbai"}
          />
        </div>
      ))}
      {activities?.map((items, i) => (
        <div key={i}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="field-label">Activity {i + 1}</p>
          </div>
          <p className="field-label">Activity title</p>
          <FastField
            value={items?.title}
            name={`activities[${i}].data[hi].title`}
            type="text"
            suffix={
              <div>
                {50 - (items?.data?.[HINDI]?.title?.length || 0)} letters
              </div>
            }
            component={AntInput}
            placeholder={"Enter title "}
            shortSummary
            maxLength={50}
          />
        </div>
      ))}
    </>
  );
};

export default HindiLanguageForm;
