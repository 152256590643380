import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { HINDI } from "constants/language";
import { FastField, Field } from "formik";

const HindiLanguageForm = ({
  values,
  videos = [],
  achievements = [],
  publicRepresentative = [],
  city = [],
  section = [],
  showAllFields,
}) => {
  return (
    <>
      <p className={"field-label mt-25"}>Leader Name </p>
      <FastField
        value={values.name}
        name="data[hi].name"
        type="text"
        suffix={<div>{50 - (values?.name?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content title "}
        maxLength={50}
      />
      <p className="mt-10 field-label">Public Representative</p>
      <Field
        value={values.publicRepresentative}
        selectOptions={publicRepresentative}
        disabled={true}
        optionValueKey="english"
        optionNameKey="hindi"
        filterOption={false}
        showSearch={false}
        mode={"single"}
        name="data[hi]publicRepresentative"
        component={AntSelect}
        placeholder={"Select staus (e.g. MP/ MLA)"}
      />
      <p className="mt-10 field-label">Constituency</p>
      <Field
        value={values.constituency}
        suffix={<div>{50 - (values?.constituency?.length || 0)} letters</div>}
        type="text"
        component={AntInput}
        name="data[hi]constituency"
        placeholder={"Enter constituency"}
        maxLength={50}
      />
      <p className="mt-10 field-label">Position in party</p>
      <Field
        value={values.partyPosition}
        suffix={<div>{100 - (values?.partyPosition?.length || 0)} letters</div>}
        type="text"
        component={AntInput}
        name="data[hi]partyPosition"
        placeholder={"Enter position"}
        maxLength={100}
      />
      <p className="field-label">About the leaders</p>
      <FastField
        value={values.about}
        name="data[hi]about"
        type="editor"
        component={RTEditor}
        characterLimit={20000}
        placeholder={"Enter details here"}
      />
      {showAllFields && (
        <>
          <p className="field-label">Success story summary</p>
          <FastField
            value={values.successStorySummary}
            name="data[hi].successStorySummary"
            type="editor"
            component={RTEditor}
            characterLimit={20000}
            placeholder={"Enter short summary about the content"}
          />
          {achievements?.map((items, i) => (
            <div key={i}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="field-label">Achievement {i + 1}</p>
              </div>
              <p className=" field-label">Awarding authority </p>
              <FastField
                value={items?.authority}
                name={`achievements[${i}].data.[hi].authority`}
                type="text"
                suffix={
                  <div>
                    {50 -
                      (achievements?.[i]?.data?.[HINDI].authority.length ||
                        0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title "}
                maxLength={50}
              />
              <p className="mt-16 field-label">Award name</p>
              <FastField
                value={items?.name}
                name={`achievements[${i}].data.[hi].name`}
                type="text"
                suffix={
                  <div>
                    {50 - (achievements?.[i]?.data?.[HINDI].name.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title "}
                maxLength={50}
              />
            </div>
          ))}
          {videos?.map((items, i) => (
            <div key={i}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="field-label">Video {i + 1}</p>
              </div>
              <p className="field-label">Video title</p>
              <FastField
                value={items?.title}
                name={`videos[${i}].data.[hi].title`}
                type="text"
                suffix={
                  <div>
                    {50 - (videos?.[i]?.data?.[HINDI]?.title?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title "}
                shortSummary
                maxLength={50}
              />
              <p className="field-label">Location</p>
              <Field
                value={items?.location}
                selectOptions={city}
                optionValueKey="id"
                optionNameKey="hindi"
                disabled
                filterOption={false}
                showSearch={true}
                mode={"single"}
                name={`videos.[${i}].data[hi].location`}
                component={AntSelect}
                placeholder={"Mumbai"}
              />
              <p className="field-label">Section</p>
              <Field
                value={items?.section}
                selectOptions={section}
                disabled
                optionValueKey="id"
                optionNameKey="hindi"
                filterOption={false}
                showSearch={false}
                mode={"single"}
                name={`videos.[${i}].data[hi].section`}
                component={AntSelect}
                placeholder={"Select a section for the video"}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default HindiLanguageForm;
