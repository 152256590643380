import { TextCell } from "../../components/TableCells/TextCell";
import { ColumnHeaderWithSorter } from "../../components/ColumnHeaderWithSorter";
import { DateCell } from "components/TableCells/DateCell";
import { UserCell } from "components/TableCells/UserCell";
import "../../styles/scss/_variables.scss";
import { ImageCell } from "components/TableCells/ImageCell";
import { HTMLCell } from "components/TableCells/HTMLCell";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import { ToggleCell } from "components/TableCells/ToggleCell";
import { COLUMN_ACTIONS } from "constants/dummyData";
import { Button } from "antd";
import { TABS } from "./utils";
import { TrashIcon } from "assets/svgs";

export const returnLeadersColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Leader name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    render: (rowData) => (
      <UserCell
        sortedColumn={true}
        data={{
          name: rowData?.data?.[languageId]?.name,
          image: rowData?.images?.[0].link,
          email: "",
        }}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Public Representative"}
        sortKey={`data.${languageId}.publicRepresentative`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    render: (rowData) => (
      <TextCell data={rowData?.data?.[languageId]?.publicRepresentative} />
    ),
  },
  {
    title: "Constituency",
    width: "12%",
    render: (rowData) => (
      <TextCell
        isBold={true}
        color="#4c68ef"
        data={rowData?.data?.[languageId]?.constituency}
      />
    ),
  },
  {
    title: "Position in party",
    width: "12%",
    render: (rowData) => (
      <TextCell data={rowData?.data?.[languageId]?.partyPosition} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Order"}
        sortKey={`order`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "8%",
    render: (rowData) => (
      <TextCell enableReadMore={true} data={rowData?.order || "-"} />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];

export const returnAboutColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Role"}
        sortKey={"role"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.role} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"CreatedOn"}
        sortKey={"createdOn"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "12%",
    render: (rowData) => <DateCell data={rowData.createdOn} />,
  },
  {
    title: "Created by",
    width: "12%",
    render: (rowData) => <UserCell data={rowData.userDetails} />,
  },
  {
    title: "Access",
    width: "15%",
    render: (rowData) => (
      <TextCell
        isBold={true}
        isArray={true}
        color="#4c68ef"
        data={rowData.access}
      />
    ),
  },
  {
    title: "No. of users",
    width: "12%",
    render: (rowData) => <TextCell data={`${rowData.users} users`} />,
  },
];

export const returnTimelineColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: "",
    width: "6%",
    render: (rowData) => <ImageCell data={rowData?.coverImageId?.[0].link} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Year"}
        sortKey={"year"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "3%",
    render: (rowData) => (
      <TextCell isBold={true} color="#273054" data={rowData.year} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Date"}
        sortKey={"date"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "4%",
    render: (rowData) => <DateCell data={rowData.date} />,
  },
  {
    title: "Title",
    width: "24%",
    render: (rowData) => (
      <HTMLCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.title}
      />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];


export const returnMagazineColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: "",
    width: "6%",
    render: (rowData) => (
      <ImageCell data={rowData?.contentMedia?.find((item) => item?.mediaType === "image" && item?.media?.link)?.media?.link} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Year"}
        sortKey={"year"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "3%",
    render: (rowData) => (
      <TextCell isBold={true} color="#273054" data={rowData.year} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Date"}
        sortKey={"publishedDate"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "4%",
    render: (rowData) => <DateCell data={rowData.publishedDate} />,
  },
  {
    title: "Title",
    width: "24%",
    render: (rowData) => (
      <HTMLCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.title}
      />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];

export const returnManifestoColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: "S.no",
    width: "1%",
    render: (rowData) => (
      <TextCell sortedColumn={true} data={`${rowData.sNo}.`} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Date added"}
        sortKey={"date"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "3%",
    render: (rowData) => <DateCell data={rowData.date} />,
  },
  {
    title: "Description",
    width: "15%",
    render: (rowData) => (
      <HTMLCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.description}
      />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];

export const returnOrganizationColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: "S.no",
    width: "1%",
    render: (rowData) => <TextCell data={`${rowData.sNo}.`} />,
  },
  {
    title: "",
    width: "3%",
    render: (rowData) => <ImageCell data={rowData.images?.[0]?.link} />,
  },
  {
    title: "Name",
    width: "8%",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.name} />,
  },
  {
    title: "President",
    width: "8%",
    render: (rowData) => (
      <TextCell data={rowData?.data?.[languageId]?.president} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Order"}
        sortKey={`order`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "8%",
    render: (rowData) => (
      <TextCell enableReadMore={true} data={rowData?.order || "-"} />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];

export const returnSchemesColumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: "Image",
    width: "3%",
    render: (rowData) => <ImageCell data={rowData.media?.link} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "6%",
    render: (rowData) => (
      <TextCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.title}
      />
    ),
  },
  {
    title: "Department",
    width: "6%",
    render: (rowData) => (
      <TextCell data={rowData?.data?.[languageId]?.department} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Date"}
        sortKey={"dateAdded"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "2%",
    render: (rowData) => <DateCell data={rowData.dateAdded} />,
  },
  {
    title: "Scheme details",
    width: "18%",
    render: (rowData) => (
      <HTMLCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.schemeDetails}
      />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];

export const returnOrganizationalStructureColumn = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Organisation name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "18%",
    render: (rowData) => (
      <TextCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.name}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Order"}
        sortKey={`order`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "10%",
    render: (rowData) => (
      <TextCell enableReadMore={true} data={rowData?.order || "-"} />
    ),
  },
  {
    title: "Members",
    width: "6%",
    render: (rowData) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleSubTable({
              params: { organisationalStructureId: rowData?.id, pageSize: 10 },
              heading: "Members list",
              data: rowData,
            });
          }}
        >
          <TextCell
            isBold={true}
            color="#4c68ef"
            data={rowData?.members?.length}
          />
        </div>
      );
    },
  },
  {
    title: "",
    width: "20%",
    render: (rowData) => {
      return (
        <Button
          style={{
            cursor: "pointer",
            background: "#273054",
            color: "white",
            fontFamily: "NeueHaasUnicaRegular",
            fontSize: "14px",
          }}
          className="submit-btn"
          onClick={(e) => {
            const data = {
              organisationalStructureId: rowData?.id,
              data: {
                en: {
                  organization: rowData?.data?.[ENGLISH]?.name,
                  name: "",
                  partyPosition: "",
                },
                hi: {
                  organization: rowData?.data?.[HINDI]?.name,
                  name: "",
                  partyPosition: "",
                },
                mr: {
                  organization: rowData?.data?.[MARATHI]?.name,
                  name: "",
                  partyPosition: "",
                },
              },
            };
            e.stopPropagation();
            handleOrganizationMemberForm(data, TABS.ORGANIZATIONALMEMBERS);
          }}
        >
          Add Member
        </Button>
      );
    },
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];

export const returnMLA_MLCcolumns = ({
  onSort,
  sortColumn,
  performColumnAction,
  languageId,
  handleOrganizationMemberForm,
  handleSubTable,
  deleteColumn,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Organisation name"}
        sortKey={`data.${languageId}.name`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "18%",
    render: (rowData) => (
      <TextCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.name}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Order"}
        sortKey={`order`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "10%",
    render: (rowData) => <TextCell data={rowData?.order || "-"} />,
  },
  {
    title: "Members",
    width: "6%",
    render: (rowData) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleSubTable({
              params: { mlaNMlcId: rowData?.id, pageSize: 10 },
              heading: "Members list",
              data: rowData,
            });
          }}
        >
          <TextCell
            isBold={true}
            color="#4c68ef"
            data={rowData?.members?.length}
          />
        </div>
      );
    },
  },
  {
    title: "",
    width: "20%",
    render: (rowData) => {
      return (
        <Button
          style={{
            cursor: "pointer",
            background: "#273054",
            color: "white",
            fontFamily: "NeueHaasUnicaRegular",
            fontSize: "14px",
          }}
          className="submit-btn"
          onClick={(e) => {
            const data = {
              mlaNMlcId: rowData?.id,
              data: {
                en: {
                  organization: rowData?.data?.[ENGLISH]?.name,
                  name: "",
                  partyPosition: "",
                },
                hi: {
                  organization: rowData?.data?.[HINDI]?.name,
                  name: "",
                  partyPosition: "",
                },
                mr: {
                  organization: rowData?.data?.[MARATHI]?.name,
                  name: "",
                  partyPosition: "",
                },
              },
            };
            e.stopPropagation();
            handleOrganizationMemberForm(data, TABS.MLA_MLCMEMBERS);
          }}
        >
          Add Member
        </Button>
      );
    },
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];

export const returnOrganizationalMembersColumn = (
  handleOrganizationMemberForm,
  deleteColumn,
  languageId
) => [
  {
    title: "Member name",
    width: "15%",
    render: (rowData) => (
      <TextCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.name}
      />
    ),
  },
  {
    title: "Position",
    width: "15%",
    render: (rowData) => (
      <TextCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.partyPosition}
      />
    ),
  },
  {
    title: "Contact Number",
    width: "15%",
    render: (rowData) => (
      <TextCell
        isArray={true}
        data={rowData?.phoneNumbers || rowData?.phoneNumber}
      />
    ),
  },
  {
    title: "Order",
    width: "10%",
    render: (rowData) => <TextCell data={rowData?.order || "-"} />,
  },
  {
    title: "",
    width: "18%",
    render: (rowData) => {
      return (
        <Button
          style={{
            cursor: "pointer",
            background: "#273054",
            color: "white",
            fontFamily: "NeueHaasUnicaRegular",
            fontSize: "14px",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            handleOrganizationMemberForm(
              rowData,
              TABS.ORGANIZATIONALMEMBERS,
              true
            );
          }}
        >
          Edit Member
        </Button>
      );
    },
  },
  {
    title: "",
    width: "8%",
    render: (rowData) => {
      return (
        <div
          style={{
            cursor: "pointer",
          }}
          className="submit-btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteColumn(rowData?.id);
          }}
        >
          <TrashIcon />
        </div>
      );
    },
  },
];
