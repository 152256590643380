import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Contents from "views/Content";
import Login from "views/Login";
import Party from "views/Party";
import Roles from "views/Roles/Roles";
import TasksandRewards from "views/Tasks&Rewards";
import Users from "views/Users/Users";
import Master from "views/Master";
import Merchandise from "views/Merchandise";
import Cadre from "views/Cadre";
import { Privacy } from "views/PrivacyPolicy/PrivacyPolicy";
import { TermsandConditions } from "views/TermsandConditions/TermsandConditions";
import { Facebook } from "views/Facebook/facebook"
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import CadreMaster from "views/CadreMaster/CadreMaster";

const AppRouter = () => {

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={Login} />
      <Route path="/reset-password" component={Login} />
      <ProtectedRoute path="/cadremaster" component={CadreMaster} />
      <ProtectedRoute path="/tasks&rewards" component={TasksandRewards} />
      <ProtectedRoute path="/content" component={Contents} />
      <ProtectedRoute path="/users" component={Users} />
      <ProtectedRoute path="/roles" component={Roles} />
      <ProtectedRoute path="/party" component={Party} />
      <ProtectedRoute path="/master" component={Master} />
      <ProtectedRoute path="/merchandise" component={Merchandise} />
      <ProtectedRoute path="/cadre" component={Cadre} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/terms-and-condition" component={TermsandConditions} />
      <Route path="/facebook" component={Facebook} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default AppRouter;
