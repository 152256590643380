import { Layout, Skeleton, Table } from "antd";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import * as RootActions from "../../store/root/actions";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import {
  fetchCMSLanguage,
  generateFilterUrl,
  getSerializedData,
} from "../../utils/helpers";
import { contentCategory } from "../../constants/data";
import Modal from "../../components/Modal";
import AppHeader from "../../components/Header";
import "../common.scss";
import {
  EVENT_SCHEMA,
  POLL_SCHEMA,
  getEventInitialValues,
  getPollInitialValues,
  getInfographicsInitialValues,
  getArticleInitialValues,
  getYoutubeVideoInitialValues,
  getAnnouncementInitialValues,
  getimportantmessagesInitialValues,
  getFacebookLiveInitialValues,
  ARTICLE_SCHEMA,
  getVotesInitialValues,
  VOTES_SCHEMA,
  ANNOUNCEMENT_SCHEMA,
  IMPORTANT_MESSAGE_SCHEMA,
  INFOGRAPHICS_SCHEMA,
  YOUTUBE_VIDEO_SCHEMA,
  FACEBOOK_LIVE_SCHEMA,
} from "./validate";
import TableToolbar from "../../components/TableToolbar";
import InnerHeader from "../../components/InnerHeader";
import {
  createPayload,
  getColumns,
  getModalHeader,
  getSubColumns,
  handlePutRequest,
  INNER_HEADERS,
  TABS,
} from "./utils";
import { CustomPagination } from "components/CustomPagination";
import AddEventForm from "forms/AddEventForm/AddEventForm";
import AddPollForm from "forms/AddPollForm/AddPollForm";
import { COLUMN_ACTIONS } from "constants/dummyData";
import AddVotesForm from "forms/AddVotesForm/AddVotesForm";
import AddAnnouncementForm from "forms/AddAnnouncementForm/AddAnnouncement";
import AddImportantMessageForm from "forms/AddImportantMessageForm/AddImportantMessageForm";
import AddInfographicForm from "forms/AddInfographicsForm/AddInfographicsForm";
import AddArticleForm from "forms/AddArticleForm/AddArticleForm";
import AddYoutubeVideoForm from "forms/AddYoutubeVideoForm/AddYoutubeVideoForm";
import AddFacebookLiveForm from "forms/AddFacebookLiveForm/AddFacebookLiveForm"

const { Header, Content } = Layout;

const Contents = (props) => {
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [innerTab, setInnerTab] = useState(TABS.EVENT);
  const [sortColumn, setSortColumn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [updateFormInitialValues, setUpdateFormInitialValues] = useState({});
  const [showPeopleAttendinglist, setShowPeopleAttendinglist] = useState(false);
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());
  const [users, setUsers] = useState(false);

  const dropdownData = props?.dropdownData;

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  useEffect(() => {
    if (formModalVisible && !isEditing) {
      //reset formData when Add modal opens
      setInitialValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModalVisible]);

  useEffect(() => {
    const params = {
      isActive: true,
      pageSize: 1000,
    };
    props.fetchDropdownData(
      "categoryTags",
      "categoryTags",
      generateFilterUrl(params)?.urlParams
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.EVENT:
          return props.events?.[key];
        case TABS.POLLS:
          return props.polls?.[key];
        case TABS.INFOGRAPHICS:
          return props.infographics?.[key];
        case TABS.ARTICLE:
          return props.articles?.[key];
        case TABS.ANNOUNCEMENT:
          return props.announcements?.[key];
        case TABS.IMPORTANT_MESSAGES:
          return props.importantmessages?.[key];
        case TABS.VOTES:
          return props.votes?.[key];
        case TABS.YOUTUBE_VIDEO:
          return props.youtubevideo?.[key];
        case TABS.FACEBOOK_LIVE:
          return props.facebooklive?.[key];
        default:
          return null;
      }
    },
    [
      props.infographics,
      props.events,
      props.polls,
      props.articles,
      props.announcements,
      props.importantmessages,
      props.votes,
      props.youtubevideo,
      props.facebooklive,
      innerTab,
    ]
  );

  const setInitialValues = (data = {}) => {
    let values =
      innerTab === TABS.EVENT
        ? getEventInitialValues(data)
        : innerTab === TABS.INFOGRAPHICS
        ? getInfographicsInitialValues(data)
        : innerTab === TABS.POLLS
        ? getPollInitialValues(data)
        : innerTab === TABS.ARTICLE
        ? getArticleInitialValues(data)
        : innerTab === TABS.ANNOUNCEMENT
        ? getAnnouncementInitialValues(data)
        : innerTab === TABS.IMPORTANT_MESSAGES
        ? getimportantmessagesInitialValues(data)
        : innerTab === TABS.VOTES
        ? getVotesInitialValues(data)
        : innerTab === TABS.YOUTUBE_VIDEO
        ? getYoutubeVideoInitialValues(data)
        : innerTab === TABS.FACEBOOK_LIVE
        ? getFacebookLiveInitialValues(data)
        : null;
    setUpdateFormInitialValues(values);
    setFormInitialValues(() => values);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleModalClose = () => {
    setIsEditing(false);
    setFormModalVisible(false);
  };

  const handleSearch = (query) => {
    if (query.length && query.length > 1) {
      applyFilters({ search: query });
    } else if (!query.length) {
      applyFilters({ search: query });
    }
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  const openAttendingPeopleList = (users) => {
    setUsers(users);
    setShowPeopleAttendinglist(true);
  };

  return (
    <div className="wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props[innerTab]?.activeCount}
            onclick={setInnerTab}
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                <TableToolbar
                  innerTab={innerTab}
                  ctaLabel="Content"
                  onSearch={handleSearch}
                  onClickAdd={() => setFormModalVisible(true)}
                  showFilter={true}
                  query={props[innerTab]?.filters?.search || ""}
                />
                <div className="container pl-40 pr-40">
                  <Table
                    className={"is_editable"}
                    rowKey={"id"}
                    columns={getColumns(
                      innerTab,
                      handleSort,
                      sortColumn,
                      languageId,
                      async (actionType, rowId, value) => {
                        // eslint-disable-next-line default-case
                        switch (actionType) {
                          case COLUMN_ACTIONS.TOGGLE_STATUS:
                            if (rowId) {
                              let request = await props.updateData(
                                innerTab,
                                rowId,
                                {
                                  isActive: value,
                                }
                              );
                              if (request?.status === 200) {
                                fetchData(
                                  innerTab,
                                  getDataFromRedux("filters")
                                );
                              }
                            }
                        }
                      }
                    ).map((column, index) => {
                      return {
                        ...column,
                        ...(props.loading && {
                          render: () => (
                            <Skeleton
                              key={index}
                              dataIndex={index}
                              title={true}
                              paragraph={false}
                            />
                          ),
                        }),
                      };
                    })}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: async (event) => {
                          try {
                            const data = await props.fetchDataForTable(
                              innerTab,
                              record.id
                            );
                            setIsEditing(true);
                            setInitialValues(data || record);
                            setActiveRow(record);
                          } catch {}
                          setFormModalVisible(() => true);
                        },
                      };
                    }}
                    dataSource={getSerializedData(
                      props[innerTab]?.filters?.page,
                      getDataFromRedux()
                    )}
                    pagination={false}
                  />
                  <CustomPagination
                    current={props[innerTab]?.filters?.page + 1}
                    totalCount={props[innerTab]?.count}
                    onChange={handlePageChange}
                    // pageSize={10}
                  />
                </div>
                <Modal
                  width={"538px"}
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing
                        ? `${getModalHeader(innerTab)} Details`
                        : `Add ${getModalHeader(innerTab)}`}
                    </p>
                  }
                  destroyOnClose
                  onCancel={handleModalClose}
                  visible={formModalVisible}
                >
                  <div style={{ width: "85%", marginLeft: "40px" }}>
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        let tags;
                        if (values.categoryTags) {
                          tags = values?.categoryTags.map((e) => e.value);
                        }
                        const country = props[innerTab]?.metaData?.["country"];
                        const state = props[innerTab]?.metaData?.["state"];
                        const city = props[innerTab]?.metaData?.["city"];
                        const payload = createPayload(
                          innerTab,
                          values,
                          tags,
                          country,
                          state,
                          city
                        );
                        let request;
                        if (isEditing) {
                          let updatePayload = handlePutRequest(
                            payload,
                            updateFormInitialValues
                          );
                          request = props.updateData(
                            innerTab,
                            activeRow.id,
                            updatePayload
                          );
                        } else request = props.createData(innerTab, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSortColumn(null);
                              setFormModalVisible(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                              setIsEditing(false);
                              setSubmitting(false);
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                          });
                      }}
                      validationSchema={
                        innerTab === TABS.EVENT
                          ? EVENT_SCHEMA
                          : innerTab === TABS.ARTICLE
                          ? ARTICLE_SCHEMA
                          : innerTab === TABS.IMPORTANT_MESSAGES
                          ? IMPORTANT_MESSAGE_SCHEMA
                          : innerTab === TABS.INFOGRAPHICS
                          ? INFOGRAPHICS_SCHEMA
                          : innerTab === TABS.POLLS
                          ? POLL_SCHEMA
                          : innerTab === TABS.ANNOUNCEMENT
                          ? ANNOUNCEMENT_SCHEMA
                          : innerTab === TABS.VOTES
                          ? VOTES_SCHEMA
                          : innerTab === TABS.YOUTUBE_VIDEO
                          ? YOUTUBE_VIDEO_SCHEMA
                          : innerTab === TABS.FACEBOOK_LIVE
                          ? FACEBOOK_LIVE_SCHEMA
                          : null
                      }
                    >
                      {(rest) =>
                        formModalVisible && innerTab === TABS.EVENT ? (
                          <AddEventForm
                            contentCategory={contentCategory}
                            categoryTags={dropdownData?.["categoryTags"]}
                            isEditing={isEditing}
                            openAttendingPeopleList={openAttendingPeopleList}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } ${getModalHeader(innerTab)}`}
                            {...rest}
                          />
                        ) : innerTab === TABS.INFOGRAPHICS ? (
                          <AddInfographicForm
                            contentCategory={contentCategory}
                            categoryTags={dropdownData?.["categoryTags"]}
                            tab={innerTab}
                            formModalVisible={formModalVisible}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } ${getModalHeader(innerTab)}`}
                            {...rest}
                          />
                        ) : innerTab === TABS.POLLS ? (
                          <AddPollForm
                            contentCategory={contentCategory}
                            categoryTags={dropdownData?.["categoryTags"]}
                            tab={innerTab}
                            formModalVisible={formModalVisible}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } ${getModalHeader(innerTab)}`}
                            {...rest}
                          />
                        ) : innerTab === TABS.ANNOUNCEMENT ? (
                          <AddAnnouncementForm
                            contentCategory={contentCategory}
                            tab={innerTab}
                            formModalVisible={formModalVisible}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } ${getModalHeader(innerTab)}`}
                            {...rest}
                          />
                        ) : innerTab === TABS.ARTICLE ? (
                          <AddArticleForm
                            contentCategory={contentCategory}
                            categoryTags={dropdownData?.["categoryTags"]}
                            tab={innerTab}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } ${getModalHeader(innerTab)}`}
                            {...rest}
                          />
                        ) : innerTab === TABS.IMPORTANT_MESSAGES ? (
                          <AddImportantMessageForm
                            contentCategory={contentCategory}
                            categoryTags={dropdownData?.["categoryTags"]}
                            tab={innerTab}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } Message`}
                            {...rest}
                          />
                        ) : innerTab === TABS.YOUTUBE_VIDEO ? (
                          <AddYoutubeVideoForm
                            tab={innerTab}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } Message`}
                            {...rest}
                          />
                        ) : innerTab === TABS.FACEBOOK_LIVE ? (
                          <AddFacebookLiveForm
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } ${getModalHeader(innerTab)}`}
                            {...rest}
                          />
                        ) : (
                          <AddVotesForm
                            contentCategory={contentCategory}
                            categoryTags={dropdownData?.["categoryTags"]}
                            tab={innerTab}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } ${getModalHeader(innerTab)}`}
                            {...rest}
                          />
                        )
                      }
                    </Formik>
                  </div>
                </Modal>
              </Content>
            </Layout>
            <Modal
              width="1000px"
              className="tablemodal"
              isEditing={isEditing}
              title={
                <p className="flex items-center color-black m-0 mb-12 weight-700 size-26">
                  People attending
                </p>
              }
              destroyOnClose
              onCancel={() => setShowPeopleAttendinglist(false)}
              visible={showPeopleAttendinglist}
              footer={false}
            >
              <Table
                className={"is_editable"}
                scroll={{
                  y: window.innerHeight - 330,
                }}
                columns={getSubColumns().map((column) => {
                  return {
                    ...column,
                    ...(props.loading && {
                      render: () => (
                        <Skeleton
                          key={column.dataIndex}
                          title={true}
                          paragraph={false}
                        />
                      ),
                    }),
                  };
                })}
                dataSource={users}
                pagination={false}
              />
            </Modal>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

Contents.propTypes = {};

const mapStateToProps = ({
  events,
  polls,
  infographics,
  articles,
  announcements,
  importantmessages,
  metaData,
  votes,
  dropdownData,
  youtubevideo,
  facebooklive,
}) => ({
  events,
  polls,
  infographics,
  articles,
  announcements,
  importantmessages,
  votes,
  metaData,
  dropdownData,
  youtubevideo,
  facebooklive,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
