import * as Yup from "yup";

export const ADMIN_POST_SCHEMA = Yup.object().shape({
  mediaId: Yup.string(),
  name: Yup.string().required("Name is required"),
  phoneNo: Yup.number()
    .typeError("You must specify a number")
    .min(1000000000, "Number is not valid")
    .max(9999999999, "Number is not valid")
    .required("Phone number is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string()
    .email("Please enter a valid mail")
    .required("Email is required"),

  roleId: Yup.string().required("Role is required"),
  password: Yup.string().required("Password is required"),
  dob: Yup.date().typeError("Date is required").required("Date is required"),
  location: Yup.string(),
});

export const ADMIN_PUT_SCHEMA = Yup.object().shape({
  mediaId: Yup.string(),
  name: Yup.string().required("Name is required"),
  phoneNo: Yup.number()
    .typeError("You must specify a number")
    .min(1000000000, "Number is not valid")
    .max(9999999999, "Number is not valid")
    .required("Phone number is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string()
    .email("Please enter a valid mail")
    .required("Email is required"),
  roleId: Yup.string().required("Role is required"),
  dob: Yup.date().typeError("Date is required").required("Date is required"),
  location: Yup.string(),
});

export const getUserInitialValues = (values = {}) => ({
  mediaId: values.mediaId || "",
  image: values?.media?.link || "",
  password: values.password || "",
  name: values.name || "",
  email: values.email || "",
  phoneNo: values.phoneNo || "",
  gender: values.gender || undefined,
  roleId: values.roleId || undefined,
  dob: values.dob || undefined,
  location: values.location || "",
});
