import { DatePicker } from "antd";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import get from "lodash/get";

const dateFormat = "DD/MM/YY";

function Date(props) {
  const onOk = (val) => {
    props.form.setFieldValue(props?.field?.name, val);
  };

  const touched = get(props?.form?.touched, props?.field?.name);
  const hasError = get(props?.form?.errors, props?.field?.name);
  const submittedError = hasError && props?.submitted;
  const touchedError = hasError && touched;

  const onChange = (val) => {
    props.form.setFieldValue(props?.field?.name, val);
  };

  const disabledDate = (current) => {
    if (props.disabledDateValue && props.disabledDateOption) {
      if (props.disabledDateOption === "greater") {
        return current && current <= moment(props.disabledDateValue);
      } else {
        return current && current >= moment(props.disabledDateValue);
      }
    }
  };

  return (
    <>
      <FormItem
        hasFeedback={
          (props?.hasFeedback && props?.submitted) ||
          (props?.hasFeedback && touched)
            ? true
            : false
        }
        help={submittedError || touchedError ? hasError : false}
        validateStatus={submittedError || touchedError ? "error" : "success"}
      >
        <DatePicker
          format={props?.format ? props.format : dateFormat}
          onOk={onOk}
          onChange={onChange}
          disabledDateValue={props?.disabledDateValue}
          disabledDateOption={props?.disabledDateOption}
          disabledDate={disabledDate}
          disabled={props?.disabled}
          suffixIcon={null}
          showNow={props?.showNow ? true : false}
          showTime={
            props?.showTime
              ? {
                  format: "hh:mm",
                }
              : false
          }
          defaultValue={
            props?.defaultValue === undefined
              ? undefined
              : props?.defaultValue !== "Invalid date" || undefined
              ? moment(props?.defaultValue)
              : undefined
          }
          placeholder={props?.placeholder}
        />
      </FormItem>
    </>
  );
}
export default Date;
