export const PeopleList = ({ users, peopleAttending = 0 }) => {
  const addDefaultSrc = () => {
    return "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-avatar-vector-isolated-on-white-background-png-image_1694546.jpg";
  };

  return (
    <>
      <p className="people-list-heading mb-8">People attending</p>
      <div className="people-list mb-16">
        <div className="user-images-container">
          {users
            ?.filter((item, i) => i < 5)
            .map((Item, i) => (
              <div className="user-image" key={Item?.id}>
                <img
                  onError={addDefaultSrc}
                  style={{
                    position: "absolute",
                    right: `${i * 12}px`,
                    zIndex: `${i}`,
                  }}
                  src={
                    Item?.media?.link ||
                    "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-avatar-vector-isolated-on-white-background-png-image_1694546.jpg"
                  }
                  alt="user-img"
                />
              </div>
            ))}
        </div>
        {peopleAttending > 4 && (
          <p className={"mb-0 people-count"}>+ {peopleAttending}</p>
        )}
      </div>
    </>
  );
};
