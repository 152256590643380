import { Button, Row, Upload, Col, Progress } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useCallback, useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import "./AddFacebookLiveForm.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadMultipleImage,
} from "../../utils/helpers";
import Date from "components/Date/date";
import { moment } from "moment";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import { ENGLISH } from "constants/language";
import ImageEditor from "components/ImageEditor";
import { AntToggle } from "components/CreateAntFields/CreateAntField";
import { PlusIcon } from "assets/svgs";
import { UploadImages } from "components/UploadButtons";

const AddFacebookLiveForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  handleForm,
  isEditing,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [activeImage, setActiveImage] = useState(values?.imageList?.[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError =
    submit &&
    (errors?.data?.en ||
      errors?.facebookLiveEndDate ||
      errors?.facebookLiveStartDate ||
      errors?.webUrl ||
      errors?.images?.[0]);

  const marathiFormError = submit && errors?.data?.mr;

  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  const handleChange = async (info) => {
    let imageValidation = await beforeUpload(info.file.originFileObj);

    hanldeProgress();

    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue("image", imageUrl);
        setActiveImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  const addToImageList = useCallback(
    async (url) => {
      let images = values.imageList;
      let mediaId = values.images;
      let formData = new FormData();
      let file = dataURLtoFile(url, `image_${Math.random()}.png`);
      formData.append("file", file);
      let response = await uploadMultipleImage(
        file,
        hanldeProgress(setUploadprogress)
      );
      images[activeImageIndex] = url;
      mediaId[activeImageIndex] = response?.data?.id;
      setFieldValue("");
      setLoading(false);
      setFieldValue("images", mediaId);
      setFieldValue("imageList", images);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImageIndex]
  );

  const deleteImageFromList = useCallback(() => {
    let imageList = values.imageList;
    let mediaId = values.images;
    imageList[activeImageIndex] = "";
    mediaId[activeImageIndex] = "";
    setFieldValue("images", mediaId);
    setFieldValue("imageList", imageList);

    let lastValidImage =
      imageList?.filter((item) => item)?.slice(-1)?.[0] || [];
    setActiveImage(lastValidImage);
    let lastIndex = 0;
    for (let i = 0; i < imageList?.length; i++) {
      if (imageList[i]) {
        lastIndex = i;
      }
    }
    setActiveImageIndex(lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === "en" ? (
            <>
              <p className="mb-10 mt-10 field-label">Cover Image</p>
              {activeImage && activeImage.length ? (
                <ImageEditor
                  image={activeImage}
                  renderMultipleImage={true}
                  onDelete={() => {
                    setActiveImage("");
                    deleteImageFromList();
                    setUploadprogress(0);
                  }}
                  isEditing={uploadprogress}
                  handleEditing={(status) => {
                    if (status && uploadprogress) {
                      setUploadprogress(0);
                    }
                  }}
                  onSave={async (url) => {
                    addToImageList(url);
                  }}
                  width={220}
                />
              ) : (
                <Upload
                  listType={`picture-card ${hasError("images") ? "error" : ""}`}
                  className="picture-uploader"
                  showUploadList={false}
                  onChange={handleChange}
                  beforeUpload={(file) => beforeUpload(file)}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, image/jpg"
                  progress={
                    <Progress percent={uploadprogress} showInfo={true} />
                  }
                >
                  {
                    <UploadImages
                      width="350 : 350"
                      ratio="1 : 1"
                      loading={loading}
                    />
                  }
                </Upload>
              )}
              {uploadprogress === 100 && !hasError("images") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadprogress > 0 && uploadprogress < 100 && (
                <div className="progress-bar">
                  <Progress percent={uploadprogress} showInfo={true} />
                </div>
              )}
              {hasError("images") && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {hasError("images")}
                </div>
              )}
              <div className="mt-6 mb-18 upload-list">
                {values.imageList?.map((item, index) =>
                  item ? (
                    <div
                      key={index}
                      className={[
                        "uploaded-image-container",
                        index === activeImageIndex ? "selected" : "",
                      ].join(" ")}
                    >
                      <img
                        onClick={() => {
                          setActiveImageIndex(index);
                          setActiveImage(item);
                        }}
                        alt={"upload-pic"}
                        className={["uploaded-image-container-item"].join(" ")}
                        src={item}
                      />
                    </div>
                  ) : (
                    <div key={index} className={"upload-button-small"}>
                      <input
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          let isValidImage = await beforeUpload(file);
                          if (isValidImage) {
                            setUploadprogress(0);
                            getBase64(file, (imageUrl) => {
                              setActiveImage(imageUrl);
                              setActiveImageIndex(index);
                            });
                          } else {
                            setActiveImage("");
                            setUploadprogress(0);
                          }
                        }}
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <label
                        onClick={() => setActiveImageIndex(index)}
                        htmlFor="file"
                      >
                        <PlusIcon />
                      </label>
                    </div>
                  )
                )}
              </div>
              <Row style={{ justifyContent: "space-between" }}>
                <Col span={11}>
                  <p className="mb-8 field-label">Facebook Live start date</p>
                  <Field
                    defaultValue={values.facebookLiveStartDate}
                    name="facebookLiveStartDate"
                    showTime={true}
                    format="DD/MM/YY HH:mm"
                    disabledDateValue={values.facebookLiveEndDate}
                    disabledDateOption={"lesser"}
                    disabledDate={disabledDate}
                    component={Date}
                    showNow={false}
                    placeholder={"dd/mm/yy hh:mm"}
                  />
                </Col>
                <Col span={11}>
                  <p className="mb-8 field-label">Facebook Live end date</p>
                  <Field
                    defaultValue={values.facebookLiveEndDate}
                    name="facebookLiveEndDate"
                    showTime={true}
                    format="DD/MM/YY HH:mm"
                    disabledDateValue={values.facebookLiveStartDate}
                    disabledDateOption={"greater"}
                    component={Date}
                    showNow={false}
                    type={"datePicker"}
                    placeholder={"dd/mm/yy hh:mm"}
                  />
                </Col>
              </Row>
              <p className="mb-8 field-label">Title</p>
              <Field
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title"}
                maxLength={50}
              />
              <p className="mb-8 mt-10 field-label">Web URL</p>
              <FastField
                value={values.webUrl}
                name="webUrl"
                type="text"
                component={AntInput}
                autoComplete={"off"}
                placeholder={"Enter web url"}
              />
              <p className="mb-8 field-label">Organizer Name</p>
              <Field
                value={values.organiser}
                name="data[en].organizer"
                type="text"
                suffix={
                  <div>
                    {20 - (values?.data?.[ENGLISH]?.organizer?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter organizer name"}
                maxLength={20}
              />
              {!isEditing && (
                <div>
                  <p className="mt-20 field-label">Send notification</p>
                  <FastField
                    defaultChecked={values.isNotification}
                    name="isNotification"
                    type="switch"
                    component={AntToggle}
                  />
                </div>
              )}
            </>
          ) : language === "hi" ? (
            <HindiLanguageForm values={values} />
          ) : (
            <MarathiLanguageForm values={values} />
          )}
          <Button
            loading={isSubmitting}
            className="mt-40 submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddFacebookLiveForm;
