import { CheckedGreen } from "assets/svgs";

export const InputContainer = ({ isCheckEnabled = false, label, text }) => {
  return (
    <div className="input-container">
      <p className="field-label">{label}</p>
      {isCheckEnabled && <span className="float-left">{<CheckedGreen />}</span>}
      <p className="field-input mb-0">{text}</p>
    </div>
  );
};
