import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { MARATHI } from "constants/language";
import { Field } from "formik";

const MarathiLanguageForm = ({
  values = {},
  categoryTags = [],
  location = {},
  categoryTagsValue,
}) => {
  let marathiFieldValues;
  if (values.data) marathiFieldValues = values.data;
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={marathiFieldValues?.title}
        name="data[mr].title"
        type="text"
        suffix={
          <div>
            {50 - (values?.data?.[MARATHI]?.title?.length || 0)} letters
          </div>
        }
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Short Summary</p>
      <Field
        value={marathiFieldValues?.shortSummary}
        name="data[mr].shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={400}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
      <p className="mb-8 mt-10 field-label">Category Tags</p>
      <Field
        value={categoryTagsValue}
        name="categoryTags"
        optionValueKey="tagName"
        optionNameKey="id"
        disabled={true}
        filterOption={false}
        showSearch={false}
        component={MultiSelect}
        mode={"multiple"}
        selectOptions={categoryTags}
        showArrow={true}
        placeholder={"Select content category tags"}
      />
      <p className="mb-8 mt-10 field-label">Location</p>
      <Field
        value={values?.location?.data?.[MARATHI]?.name}
        name="location.data[mr].name"
        type="text"
        component={AntInput}
        suffix={
          <div>
            {200 - (values?.location?.data?.[MARATHI]?.name?.length || 0)}{" "}
            letters
          </div>
        }
        maxLength={200}
        placeholder={"First line of address"}
      />
      <p className="mb-8 field-label">Organizer Name</p>
      <Field
        value={values.organizer}
        name="data[mr].organizer"
        type="text"
        suffix={
          <div>
            {20 - (values?.data?.[MARATHI]?.organizer?.length || 0)} letters
          </div>
        }
        component={AntInput}
        placeholder={"Enter organizer name"}
        maxLength={20}
      />
    </>
  );
};

export default MarathiLanguageForm;
