import { Button } from "antd";
import FormMenu from "components/FormMenu";
import { ENGLISH } from "constants/language";
import { Field, Form } from "formik";
import React, { useState } from "react";
import {
  AntInput,
  AntTextArea,
} from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import HindiLanguageForm from "./FormLanguages/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguages/MarathiLanguageForm";

const AddCategoryForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  isValidating,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const { en } = values?.data;

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === "en" ? (
            <>
              <p className="mb-10 mt-30 field-label">Name</p>
              <Field
                value={en?.tagName}
                name={`data[en].tagName`}
                type="text"
                suffix={
                  <div>
                    {20 - (values?.data?.[ENGLISH]?.tagName?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter category name"}
                maxLength={20}
              />
              <p className="mb-8 field-label">Description (optional)</p>
              <Field
                value={values.description}
                name={`data[en].description`}
                type="text"
                component={AntTextArea}
                maxLength={40}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short description about the category"}
              />
            </>
          ) : language === "hi" ? (
            <HindiLanguageForm values={values?.data?.hi} />
          ) : (
            <MarathiLanguageForm values={values?.data?.mr} />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            onClick={() => setSubmit(true)}
            htmlType="submit"
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddCategoryForm;
