import { Layout, Skeleton, Table } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import AppHeader from "../../components/Header";
import "../common.scss";
import {
  Merchandise_Category_SCHEMA,
  PRODUCT_SCHEMA,
  getMerchandiseCategoryInitialValues,
  getProductInitialValues,
  ORDER_REJECT_SCHEMA,
  ORDER_SHIP_SCHEMA,
  getOrderRejectInitialValues,
  getOrderShipInitialValues,
} from "./validate";
import TableToolbar from "../../components/TableToolbar";
import InnerHeader from "../../components/InnerHeader";
import {
  createPayload,
  handlePutRequest,
  getColumns,
  INNER_HEADERS,
  TABS,
  buttonField,
  getAttributes,
} from "./utils";
import { COLUMN_ACTIONS } from "../../constants/dummyData";
import { getSerializedData } from "utils/helpers";
import { CustomPagination } from "components/CustomPagination";
import AddMerchandiseCategoryForm from "forms/AddMerchandiseCategoryForm/AddMerchandiseCategoryForm";
import AddProductForm from "forms/AddProductForm/AddProductForm"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RootActions from "../../store/root/actions";
import { fetchCMSLanguage, generateFilterUrl } from "utils/helpers";
import { ProductDetails } from './ProductDetails/ProductDetails'
import UpdateProductForm from 'forms/AddProductForm/UpdateProductForm';
import UpdateProductVariantForm from 'forms/AddProductForm/UpdateProductVariantForm';
import OrderRejectForm from "forms/OrderForms/OrderRejectForm/OrderRejectForm";
import OrderShipForm from "forms/OrderForms/OrderShipForm/OrderShipForm";
import { OrderDetails } from "./OrderDetails/OrderDetails";

const { Header, Content } = Layout;

const Merchandise = (props) => {
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [innerTab, setInnerTab] = useState(TABS.CATEGORY);
  const [sortColumn, setSortColumn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [updateFormInitialValues, setUpdateFormInitialValues] = useState({});
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());
  const [productFormPage, setProductFormPage] = useState(1);
  const [showProductDetails, setShowProductDetails] = useState(false);

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  useEffect(() => {
    if (formModalVisible && !isEditing) {
      //reset formData when Add modal opens
      setInitialValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModalVisible]);

  useEffect(() => {
    props.fetchDropdownData("utils");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.CATEGORY:
          return props.category?.[key];
        case TABS.PRODUCTS:
          return props.products?.[key];
        case TABS.ORDERS:
          return props.orders?.[key];
        default:
          return null;
      }
    },
    [
      props.category,
      props.products,
      props.orders,
      innerTab,
    ]
  );
  const setInitialValues = async (data = {}) => {
    let values =
      innerTab === TABS.CATEGORY
        ? await getMerchandiseCategoryInitialValues(data)
        : innerTab === TABS.PRODUCTS
        ? await getProductInitialValues(data)
        : {};
    setUpdateFormInitialValues(values);
    setFormInitialValues(values);
  };

  const openModal = () => {
    setFormModalVisible(true);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { [sortKey]: sortOrder };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    if (query.length && query.length > 1) {
      applyFilters({ search: query });
    } else if (!query.length) {
      applyFilters({ search: query });
    }
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  useEffect(() => {
    const params = {
      isActive: true,
      pageSize: 1000,
    };
    props.fetchDropdownData(
      "categoryTags",
      "categoryTags",
      generateFilterUrl(params)?.urlParams
    );
    let apiFilters = {
      ...getDataFromRedux("filters")
    };
    fetchData('category', { filters: apiFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [productDetailModal, setProductDetailModal] = useState(false);
  const [activeProductVariant, setActiveProductVariant] = useState(false);
  const [actionModal, setActionModal] = useState(false)

  return (
    <div className="wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props[innerTab]?.count}
            onclick={(...props) => {
              setShowProductDetails(false)
              setInnerTab(...props)
            }}
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                  {
                    showProductDetails ? (
                      <div className="container pl-40 pr-40">
                        <ProductDetails
                          setShowProductDetails={setShowProductDetails}
                          productDetail={props?.products?.formData}
                          getAttributes={getAttributes}
                          setProductDetailModal={setProductDetailModal}
                          setActiveProductVariant={setActiveProductVariant}
                          updateVariantData={props.updateVariantData}
                          fetchData={fetchData}
                          updateData={props.updateData}
                          getDataFromRedux={getDataFromRedux}
                          fetchDataForTable={props.fetchDataForTable}
                        />
                        <UpdateProductForm
                          productDetail={props?.products?.formData}
                          categories={props.category.list}
                          productDetailModal={productDetailModal}
                          setProductDetailModal={setProductDetailModal}
                          updateData={props.updateData}
                          fetchDataForTable={props.fetchDataForTable}
                          />
                        <UpdateProductVariantForm
                          updateVariantData={props.updateVariantData}
                          productDetail={props?.products?.formData}
                          activeProductVariant={activeProductVariant}
                          setActiveProductVariant={setActiveProductVariant}
                          fetchDataForTable={props.fetchDataForTable}
                        />
                      </div>
                    ) : (
                      <>
                      <TableToolbar
                        innerTab={innerTab}
                        ctaLabel={buttonField(innerTab)}
                        onClickAdd={openModal}
                        showFilter={true}
                        onSearch={handleSearch}
                        query={props[innerTab]?.filters?.search || ""}
                        disableModalButton={innerTab === TABS.ORDERS}
                      />
                      <div className="container pl-40 pr-40">
                        <Table
                          className={"is_editable"}
                          rowKey={"id"}
                          scroll={innerTab === TABS.ORDERS && ({
                            x: 2000,
                          })}
                          columns={getColumns(
                            innerTab,
                            handleSort,
                            sortColumn,
                            languageId,
                            props[innerTab].filters,
                            async (actionType, rowId, value) => {
                              // eslint-disable-next-line default-case
                              switch (actionType) {
                                case COLUMN_ACTIONS.TOGGLE_STATUS:
                                  if (rowId) {
                                    let request = await props.updateData(
                                      innerTab,
                                      rowId,
                                      {
                                        isActive: value,
                                      }
                                    );
                                    if (request?.status === 200) {
                                      fetchData(
                                        innerTab,
                                        getDataFromRedux("filters")
                                      );
                                    }
                                  }
                                  break;
                                case COLUMN_ACTIONS.ACTIVE_DATA: 
                                  const data = await props.fetchDataForTable(
                                    innerTab,
                                    rowId
                                  );
                                  if (data) setActiveRow(data); 
                                  if (rowId) {
                                    setShowProductDetails(true)
                                  }
                                  break;
                                case COLUMN_ACTIONS.REJECTED: 
                                  setActionModal({
                                    type: COLUMN_ACTIONS.REJECTED,
                                    id: rowId
                                  })
                                  break;
                                case COLUMN_ACTIONS.SHIPPED: 
                                  setActionModal({
                                    type: COLUMN_ACTIONS.SHIPPED,
                                    id: rowId
                                  })
                                  break;
                                case (COLUMN_ACTIONS.ACCEPTED): {
                                  const payload = createPayload(actionType);
                                  const request = await props.updateData(
                                    innerTab,
                                    rowId,
                                    payload
                                  );
                                  if (request?.status === 200) {
                                    fetchData(
                                      innerTab,
                                      getDataFromRedux("filters")
                                    );
                                  }
                                  break;
                                }
                                case (COLUMN_ACTIONS.DELIVERED): {
                                  const payload = createPayload(actionType);
                                  const request = await props.updateData(
                                    innerTab,
                                    rowId,
                                    payload
                                  );
                                  if (request?.status === 200) {
                                    fetchData(
                                      innerTab,
                                      getDataFromRedux("filters")
                                    );
                                  }
                                  break;
                                }
                                default:
                                  break;                                 
                              }
                            }
                          ).map((column, index) => {
                            return {
                              ...column,
                              ...(props.loading && {
                                render: () => (
                                  <Skeleton
                                    key={index}
                                    dataIndex={index}
                                    title={true}
                                    paragraph={false}
                                  />
                                ),
                              }),
                            };
                          })}
                          onRow={(record, rowIndex) => {
                            if (innerTab === TABS.CATEGORY || innerTab === TABS.ORDERS) {
                              return {
                                onClick: async (event) => {
                                  const data = await props.fetchDataForTable(
                                    innerTab,
                                    record.id
                                  );
                                  setIsEditing(true);
                                  openModal();
                                  setFormInitialValues(data || record);
                                  setActiveRow(record);
                                },
                              };
                            }
                          }}
                          dataSource={getSerializedData(
                            props[innerTab]?.filters?.page,
                            getDataFromRedux()
                          )}
                          pagination={false}
                          onChange={(pagination, filters, sorter, { currentDataSource, action}) => {
                            if (action === 'filter') {
                              applyFilters({orderStatus: filters.latestStatus?.[0], page: 0})
                            }
                          }}
                        />
                        <CustomPagination
                          current={props[innerTab]?.filters?.page + 1}
                          totalCount={props[innerTab]?.count}
                          onChange={handlePageChange}
                        />
                      </div>
                      </>
                    )
                  }
                <Modal
                  width="540px"
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {actionModal?.type === COLUMN_ACTIONS.REJECTED && "Reason"}
                      {actionModal?.type === COLUMN_ACTIONS.SHIPPED && "SHIP"}
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setActionModal(false);
                  }}
                  visible={actionModal?.type}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={
                        actionModal?.type === COLUMN_ACTIONS.REJECTED
                          ? getOrderRejectInitialValues()
                          : actionModal?.type === COLUMN_ACTIONS.SHIPPED
                          ? getOrderShipInitialValues()
                          : null
                      }
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        let payload;
                        switch (actionModal?.type) {
                          case COLUMN_ACTIONS.ACCEPTED:
                            payload = createPayload(COLUMN_ACTIONS.ACCEPTED, values);
                            break;
                          case COLUMN_ACTIONS.REJECTED:
                            payload = createPayload(COLUMN_ACTIONS.REJECTED, values);
                            break;
                          case COLUMN_ACTIONS.SHIPPED:
                            payload = createPayload(COLUMN_ACTIONS.SHIPPED, values);
                            break;
                          case COLUMN_ACTIONS.DELIVERED:
                            payload = createPayload(COLUMN_ACTIONS.DELIVERED, values);
                            break;
                          default:
                            break;
                        }
                        const request = props.updateData(
                          innerTab,
                          actionModal?.id,
                          payload
                        );
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setActionModal(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                              setSubmitting(false);
                            }
                          })
                          .catch(() => {
                            alert("ss");
                          })
                          .finally(() => {
                            setSubmitting(false);
                          });
                      }}
                      validationSchema={
                        actionModal?.type === COLUMN_ACTIONS.REJECTED
                          ? ORDER_REJECT_SCHEMA
                          : actionModal?.type === COLUMN_ACTIONS.SHIPPED
                          ? ORDER_SHIP_SCHEMA
                          : null
                      }
                    >
                      {(rest) => (
                        actionModal?.type === COLUMN_ACTIONS.REJECTED ? (
                          <OrderRejectForm
                            {...rest}
                          />
                          ) : actionModal?.type === COLUMN_ACTIONS.SHIPPED ? (
                          <OrderShipForm
                            {...rest}
                          />
                        ) : null
                      )}
                    </Formik>
                  </div>
                </Modal>
                <Modal
                  width="540px"
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                        {
                          innerTab === TABS.ORDERS
                            ? ""
                            : isEditing
                              ? `Edit ${buttonField(innerTab)}`
                              : `Add ${buttonField(innerTab)}`
                        }
                      </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setFormModalVisible(false);
                    setProductFormPage(1);
                  }}
                  visible={formModalVisible}
                  page={innerTab === TABS.PRODUCTS ? productFormPage : undefined}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    { innerTab === TABS.ORDERS ? (
                      <OrderDetails
                        values={formInitialValues}
                      />
                    ) : (
                      <Formik
                        enableReinitialize
                        initialValues={formInitialValues}
                        onSubmit={(values, { setSubmitting, validateForm }) => {
                          const payload = createPayload(innerTab, values);
                          let request;
                          if (isEditing) {
                            let updatePayload = handlePutRequest(
                              payload,
                              updateFormInitialValues
                            );
                            request = props.updateData(
                              innerTab,
                              activeRow.id,
                              updatePayload
                            );
                          } else request = props.createData(innerTab, payload);
                          request
                            .then((res) => {
                              if (res?.status === 200) {
                                setIsEditing(false);
                                setSortColumn(null);
                                setFormModalVisible(false);
                                fetchData(innerTab, getDataFromRedux("filters"));
                                setSubmitting(false);
                              }
                            })
                            .catch((e) => {
                              alert("ss");
                            })
                            .finally((e) => {
                              setSubmitting(false);
                              setProductFormPage(1);
                            });
                        }}
                        validationSchema={
                          innerTab === TABS.CATEGORY
                            ? Merchandise_Category_SCHEMA
                            : innerTab === TABS.PRODUCTS
                            ? PRODUCT_SCHEMA
                            : null
                        }
                      >
                        {(rest) => (
                          innerTab === TABS.CATEGORY ? (
                            <AddMerchandiseCategoryForm
                              submitButtonLabel={`${
                                isEditing ? "Update" : "Add"
                              } ${buttonField(innerTab)}`}
                              isEditing={isEditing}
                              {...rest}
                            />
                          ) : innerTab === TABS.PRODUCTS ? (
                            <AddProductForm
                              page={productFormPage}
                              isEditing={isEditing}
                              submitButtonLabel={`${
                                isEditing ? "Update" : "Add"
                              } ${buttonField(innerTab)}`}
                              categories={props.category.list}
                              setProductFormPage={setProductFormPage}
                              getAttributes={getAttributes}
                              {...rest}
                            />
                          ) : null
                        )}
                      </Formik>
                    )}
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

Merchandise.propTypes = {};

const mapStateToProps = ({
  category,
  products,
  orders,
  dropdownData,
}) => ({
  category,
  products,
  orders,
  dropdownData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
  updateVariantData: bindActionCreators(RootActions.updateVariantData, dispatch),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Merchandise);
