import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";


const MarathiLanguageForm = ({values}) => {


return (
    <>
    <p className="mb-10 mt-30 field-label">Name</p>
        <Field
          value={values?.tagName}
          name= {`data[mr].tagName`}
          type="text"
          suffix={<div>{20 - (values?.tagName?.length || 0)} letters</div>}
          component={AntInput}
          placeholder={"Enter category name"}
          maxLength={20}
        />
        <p className="mb-8 field-label">Description (optional)</p>
        <Field
          value={values?.description}
          name= {`data[mr].description`}
          type="text"
          component={AntTextArea}
          maxLength={40}
          showCount={{
            formatter: ({ maxLength, count }) => {
              return `${maxLength - count} letters`;
            },
          }}
          placeholder={"Enter short description about the category"}
        />
    </>
)}

export default MarathiLanguageForm