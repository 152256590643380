import { Layout, Skeleton, Table } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { points } from "../../constants/data";
import Modal from "../../components/Modal";
import * as RootActions from "../../store/root/actions";
import AppHeader from "../../components/Header";
import "../common.scss";
import {
  Task_SCHEMA,
  Category_SCHEMA,
  Reward_SCHEMA,
  getCategoryInitialValues,
  getRewardInitialValues,
  getTaskInitialValues,
  getTrophiesInitialValues,
  TROPHY_SCHEMA,
} from "./validate";
import TableToolbar from "../../components/TableToolbar";
import InnerHeader from "../../components/InnerHeader";
import { getColumns, getTagname, INNER_HEADERS, TABS } from "./utils";
import {
  fetchCMSLanguage,
  generateFilterUrl,
  getActiveTabLabel,
  getSerializedData,
} from "../../utils/helpers";
import { COLUMN_ACTIONS } from "../../constants/dummyData";
import { CustomPagination } from "components/CustomPagination";
import AddCategoryForm from "forms/AddCategoryForm/AddCategoryForm";
import AddRewardForm from "forms/AddRewardForm/AddRewardForm";
import AddTaskForm from "forms/AddTaskForm/AddTaskForm";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createPayload, handlePutRequest } from "./utils";
import AddTrophyForm from "forms/AddTrophyForm/AddTrophyForm";
import { TaskDetails } from "./TaskDetails/TaskDetails";
import { ENGLISH } from "constants/language";
import { getDropDowns } from "./utils";

const { Header, Content } = Layout;
const TasksandRewards = (props) => {
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [innerTab, setInnerTab] = useState(TABS.TASKS);
  const [sortColumn, setSortColumn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [updateFormInitialValues, setUpdateFormInitialValues] = useState({});
  const [showTaskDetails, setShowTAskDetails] = useState(false);
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());

  const dropdownData = props?.dropdownData;

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  useEffect(() => {
    if (formModalVisible && !isEditing) {
      //reset formData when Add modal opens
      setInitialValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModalVisible]);

  useEffect(() => {
    props.fetchDropdownData("utils");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.TASKS:
          return props.tasks?.[key];
        case TABS.REWARDS:
          return props.rewards?.[key];
        case TABS.CATEGORIES:
          return props.categoryTags?.[key];
        case TABS.TROPHIES:
          return props.trophies?.[key];
        default:
          return null;
      }
    },
    [props.tasks, props.rewards, props.categoryTags, props.trophies, innerTab]
  );

  const setInitialValues = (data = {}) => {
    let values =
      innerTab === TABS.TASKS
        ? getTaskInitialValues(data)
        : innerTab === TABS.REWARDS
        ? getRewardInitialValues(data)
        : innerTab === TABS.CATEGORIES
        ? getCategoryInitialValues(data)
        : innerTab === TABS.TROPHIES
        ? getTrophiesInitialValues(data)
        : getRewardInitialValues;
    setFormInitialValues(values);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    applyFilters({ search: query });
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };
    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  const openModal = () => {
    setFormModalVisible(true);
  };

  return (
    <div className="wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props[getTagname(innerTab)]?.activeCount}
            onclick={setInnerTab}
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                <TableToolbar
                  innerTab={innerTab}
                  onSearch={handleSearch}
                  ctaLabel={getActiveTabLabel(INNER_HEADERS, innerTab)}
                  onClickAdd={openModal}
                  showFilter={true}
                  showSearch={showTaskDetails ? false : true}
                  showBackButton={showTaskDetails}
                  backButtonName="Task Details"
                  query={props[innerTab]?.filters?.search || ""}
                  handleTable={(e) => {
                    setShowTAskDetails(() => false);
                    setIsEditing(false);
                    setFormInitialValues({});
                  }}
                />
                {showTaskDetails ? (
                  <div className="container pl-40 pr-40">
                    <TaskDetails
                      image={updateFormInitialValues?.image?.link}
                      checkList={updateFormInitialValues?.checkList}
                      rewardPoints={
                        updateFormInitialValues?.rewardPoints ||
                        updateFormInitialValues?.totalPoints
                      }
                      taskType={updateFormInitialValues?.taskType}
                      socialMediaCounts={
                        updateFormInitialValues.socialMediaCounts
                      }
                      totalEngagements={
                        updateFormInitialValues?.totalEngagements
                      }
                      taskDetails={updateFormInitialValues?.data?.[ENGLISH]}
                    />
                  </div>
                ) : (
                  <div className="container pl-40 pr-40">
                    <Table
                      className={"is_editable"}
                      rowKey={"id"}
                      columns={getColumns(
                        innerTab,
                        handleSort,
                        sortColumn,
                        languageId,
                        async (actionType, rowId, value) => {
                          if (rowId) {
                            // eslint-disable-next-line default-case
                            switch (actionType) {
                              case COLUMN_ACTIONS.TOGGLE_STATUS:
                                let request = await props.updateData(
                                  innerTab,
                                  rowId,
                                  {
                                    isActive: value,
                                  }
                                );
                                if (request?.status === 200) {
                                  fetchData(
                                    innerTab,
                                    getDataFromRedux("filters")
                                  );
                                }
                            }
                          }
                        }
                      ).map((column, index) => {
                        return {
                          ...column,
                          ...(props.loading && {
                            render: (e) => (
                              <Skeleton
                                key={index}
                                title={true}
                                paragraph={false}
                              />
                            ),
                          }),
                        };
                      })}
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: async (event) => {
                            const data = await props.fetchDataForTable(
                              innerTab,
                              record.id
                            );
                            setInitialValues(data || record);
                            setIsEditing(true);
                            setUpdateFormInitialValues(data || record);
                            setActiveRow(record);
                            if (innerTab === TABS.TASKS) {
                              setShowTAskDetails(() => true);
                            } else {
                              setFormModalVisible(() => true);
                            }
                          },
                        };
                      }}
                      dataSource={getSerializedData(
                        getDataFromRedux("filters")?.page,
                        getDataFromRedux()
                      )}
                      pagination={false}
                    />
                    <CustomPagination
                      current={props[getTagname(innerTab)]?.filters?.page + 1}
                      totalCount={props[getTagname(innerTab)].count}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
                <Modal
                  width="538px"
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing ? "Edit" : "New"}{" "}
                      {getActiveTabLabel(INNER_HEADERS, innerTab)}
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setFormModalVisible(false);
                    setShowTAskDetails(false);
                  }}
                  visible={formModalVisible}
                >
                  <div style={{ width: "85%", marginLeft: "40px" }}>
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(innerTab, values);
                        let request;
                        if (isEditing) {
                          let updatePayload = handlePutRequest(
                            payload,
                            updateFormInitialValues
                          );
                          request = props.updateData(
                            innerTab,
                            activeRow.id,
                            updatePayload
                          );
                        } else request = props.createData(innerTab, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSortColumn(null);
                              setFormModalVisible(false);
                              setIsEditing(false);
                              setShowTAskDetails(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                          });
                        setSubmitting(false);
                      }}
                      validationSchema={
                        innerTab === TABS.TASKS
                          ? Task_SCHEMA
                          : innerTab === TABS.CATEGORIES
                          ? Category_SCHEMA
                          : innerTab === TABS.REWARDS
                          ? Reward_SCHEMA
                          : TROPHY_SCHEMA
                      }
                    >
                      {(rest) =>
                        innerTab === TABS.CATEGORIES ? (
                          <AddCategoryForm
                            points={points}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Continue"
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.REWARDS ? (
                          <AddRewardForm
                            category={dropdownData?.categoryType}
                            points={points}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } Reward`}
                            {...rest}
                          />
                        ) : innerTab === TABS.TROPHIES ? (
                          <AddTrophyForm
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } Reward`}
                            isEditing={isEditing}
                            {...rest}
                          />
                        ) : (
                          <AddTaskForm
                            // socialMedia={socialMedia}
                            taskType={getDropDowns(dropdownData?.taskType)}
                            socialmedia={getDropDowns(
                              dropdownData?.socialmedia
                            )}
                            isEditing={isEditing}
                            submitButtonLabel={`${
                              isEditing ? "Update" : "Add"
                            } Task`}
                            {...rest}
                          />
                        )
                      }
                    </Formik>
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

TasksandRewards.propTypes = {};

const mapStateToProps = ({
  rewards,
  categoryTags,
  tasks,
  trophies,
  dropdownData,
}) => ({
  rewards,
  categoryTags,
  tasks,
  trophies,
  dropdownData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksandRewards);
