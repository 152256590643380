import { Button } from "antd";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { Field, Form } from "formik";
import React from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";

const AddRoleForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  taskType,
  points,
  errors,
  touched,
  access,
  submitButtonLabel,
  ...props
}) => {
  return (
    <Form>
      <div className="add-form">
        <p className="mb-8 mt-42 field-label">Role name</p>
        <Field
          value={values.displayName}
          name="displayName"
          type="text"
          component={AntInput}
          suffix={<div>{50 - (values?.displayName?.length || 0)} letters</div>}
          placeholder={"Enter admin name"}
          maxLength={50}
        />
        <p className="mb-8 mt-10 field-label">Access</p>
        <Field
          value={values.access}
          name="access"
          optionValueKey="name"
          optionNameKey="id"
          filterOption={false}
          showSearch={false}
          component={MultiSelect}
          selectOptions={access}
          width="425px"
          isPural={false}
          showArrow={true}
          className="multi-select"
          placeholder={"Select access"}
        />
        <Button
          loading={isSubmitting}
          className="absolute-submit-btn submit-btn"
          type="primary"
          htmlType="submit"
        >
          {submitButtonLabel}
        </Button>
      </div>
    </Form>
  );
};

export default AddRoleForm;
