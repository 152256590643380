import { Button } from "antd";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI } from "constants/language";
import { Field, Form } from "formik";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import React, { useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";

const AddRewardForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  taskType,
  points,
  errors,
  touched,
  submitButtonLabel,
  category,
  ...props
}) => {
  const [language, setLanguage] = useState(ENGLISH);
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mb-8 mt-22 field-label">Title</p>
              <Field
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {40 - (values?.data?.[ENGLISH].title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter title for the reward"}
                maxLength={40}
              />
              <p className="mb-8 mt-10 field-label">Category</p>
              <Field
                name="categoryType"
                component={AntSelect}
                optionValueKey="name"
                optionNameKey="name"
                mode="single"
                value={values.categoryType}
                selectOptions={category}
                placeholder={"Select a category for the reward"}
              />
              <p className="mb-8 mt-10 field-label">Reward Points</p>
              <Field
                value={values.rewardPoints}
                name="rewardPoints"
                type="text"
                component={AntInput}
                placeholder={"Enter reward points"}
                maxLength={4}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data} />
          ) : (
            <MarathiLanguageForm values={values?.data} />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddRewardForm;
