import { TABS } from "./utils";
import * as Yup from "yup";

export const DESIGNATION_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Designation title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Designation title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Designation title is required"),
    }),
  }),
  organisationId: Yup.string().required("Cell/Org is required"),
  committeeId: Yup.string().required("Committee is required"),
  postId: Yup.string().required("Post is required"),
  approverId: Yup.string().required("Approver is required"),
});

export const CADRE_DIVISION_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Division name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Division name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Division name is required"),
    }),
  }),
  stateId: Yup.string().required("State is required"),
});

export const CADRE_DISTRICT_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("District name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("District name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("District name is required"),
    }),
  }),
  divisionId: Yup.string().required("Division is required"),
  type: Yup.string().required("Type is required"),
});

export const VIDHAN_SABHA_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Assembly name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Assembly name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Assembly name is required"),
    }),
  }),
  districtId: Yup.string().required("District is required"),
});

export const TALUKA_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
  }),
  districtId: Yup.string().required("District is required"),
});

export const CADRE_COMMON_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
  }),
});

export const CADRE_COMMITTEE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
  }),
  order: Yup.string()
    .required("Order is required")
    .matches(/^[0-9]*$/, "Please enter a valid integer")
});

export const CADRE_POST_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
  }),
  order: Yup.string()
    .required("Order is required")
    .matches(/^[0-9]*$/, "Please enter a valid integer"),
  maxApproveLimit: Yup.string()
    .matches(/^[0-9]*$/, "Please enter a valid integer")
});

export const CADRE_WARD_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Ward name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Ward name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Ward name is required"),
    }),
  }),
  type: Yup.string().required("Type is required"),
  prabhagId: Yup.string().when("type", {
    is: (type) => type === "city",
    then: Yup.string().required('Prabhag is required')
  }),
  vidhansabhaId: Yup.string().when("type", {
    is: (type) => type === "mumbai",
    then: Yup.string().required('Vidhan Sabha is required')
  }) 
});

export const getDesignationInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      title: "",
    },
    hi: {
      title: "",
    },
    mr: {
      title: "",
    },
  },
  organisationId: values?.organisationId || undefined,
  committeeId: values?.committeeId || undefined,
  postId: values?.postId || undefined,
  stateId: values?.stateId || undefined,
  divisionId: values?.divisionId || undefined,
  districtId: values?.districtId || undefined,
  vidhansabhaId: values?.vidhansabhaId || undefined,
  talukaId: values?.talukaId || undefined,
  municipalCouncilId: values?.municipalCouncilId || undefined,
  nagarPanchayatId: values?.nagarPanchayatId || undefined,
  prabhagId: values?.prabhagId || undefined,
  wardId: values?.wardId || undefined,
  approverId: values?.approverId || undefined,
});

export const getDivisionInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  divisionCode: values.divisionCode || "",
  stateId: values.stateId,
});

export const getCadreDistrictInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  type: values.type || undefined,
  divisionId: values.divisionId || undefined,
});

export const getVidhanSabhaInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  districtId: values.districtId || undefined,
});

export const getTalukaInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  districtId: values.districtId || undefined,
});

export const getWardInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  vidhansabhaId: values.vidhansabhaId || undefined,
  type: values.type || undefined,
  prabhagId: values.prabhagId || undefined,
});

export const getCadreCommonValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
});

export const getCommitteeInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  order: values?.order,
});

export const getPostInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  order: values?.order,
  maxApproveLimit: values?.maxApproveLimit,
});

export const getValidationSchema = (innerTab) => {
  switch (innerTab) {
    case TABS.DESIGNATION: {
      return DESIGNATION_SCHEMA;
    }
    case TABS.DIVISIONS: {
      return CADRE_DIVISION_SCHEMA;
    }
    default: {
      return null;
    }
  }
};
