import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { FastField, Field } from "formik";

const HindiLanguageForm = ({ values, state }) => {
  return (
    <>
      <p className="mb-8 field-label">Division name</p>
      <Field
        value={values.division}
        name="data.hi.name"
        type="text"
        component={AntInput}
        suffix={<div>{100 - (values?.name?.length || 0)} letters</div>}
        placeholder={"Enter content title "}
        maxLength={100}
      />
      <p className="mb-8 field-label">State</p>
      <FastField
        value={values.stateId}
        selectOptions={state}
        optionValueKey="id"
        optionNameKey="hindi"
        filterOption={true}
        showSearch={true}
        disabled={true}
        autoComplete="off"
        mode={"single"}
        name="stateId"
        component={AntSelect}
        placeholder={"Maharastra"}
      />
    </>
  );
};

export default HindiLanguageForm;
