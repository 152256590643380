import { Modal as AntdModal } from "antd";
import React from "react";
import { imagesUrl } from "../../constants/imagesUrl";

import "./Modal.scss";

const Modal = (props) => {
  const {
    className,
    children,
    page,
    backdropClosable = false,
    isEditing,
  } = props;
  return (
    <AntdModal
      footer={true}
      keyboard={false}
      maskClosable={backdropClosable}
      className={`ub-modal ${className}`}
      closeIcon={imagesUrl.icons.cancelBlackBackground}
      {...props}
    >
      {!isEditing && page && <div className="page-num">{page}/2</div>}
      {children}
    </AntdModal>
  );
};

Modal.propTypes = {};

export default Modal;
