import { Button, Space } from "antd";
import { ImageCell } from "components/TableCells/ImageCell";
import { ENGLISH } from "constants/language";
import { Form } from "formik";
import moment from "moment";
import "./CadreDetailForm.scss";

function CadreDetailForm({ values, isSubmitting, setFieldValue }) {
  const data = [
    {
      label: "Name",
      value: values?.userDetails?.name,
    },
    {
      label: "Party Position",
      value: values?.cadre?.designation?.[ENGLISH]?.title,
    },
    {
      label: "Date of birth",
      value: values?.userDetails?.dob
        ? moment(values?.userDetails?.dob).format("Do MMM, YYYY")
        : null,
    },
    {
      label: "Mobile",
      value: values?.userDetails?.phoneNo,
    },
    {
      label: "Address",
      value: values?.cadre?.address,
    },
    {
      label: "WhatsApp Number",
      value: values?.cadre?.whatsappNo,
    },
    {
      label: "Gender",
      value: values?.userDetails?.gender,
    },
    {
      label: "Blood Group",
      value: values?.cadre?.bloodGroup,
    },
    {
      label: "Aadhar",
      value: values?.cadre?.aadharNumber,
    },
    {
      label: "Division",
      value: values?.address?.divisionData?.data?.[ENGLISH]?.name,
    },
    {
      label: "District",
      value: values?.address?.districtData?.data?.[ENGLISH]?.name,
    },
    {
      label: "Assembly",
      value: values?.address?.assemblyData?.data?.[ENGLISH]?.name,
    },
    {
      label: "Taluka",
      value: values?.address?.tehsilData?.data?.[ENGLISH]?.name,
    },
    {
      label: "M. Corp/ M. Council/ N. Panchayat/ Village",
      value: values?.address?.villageData?.data?.[ENGLISH]?.name,
    },
  ];

  return (
    <div className="cadreDetailForm">
      <Form>
        <ImageCell
          data={values?.userDetails?.media?.link}
          className="width-200 m-auto mt-40 mb-40"
        />
        {data.map(({ label, value }) => (
          <div key={label}>
            <Space>
              <div>{label}:</div>
              <div>{value}</div>
            </Space>
          </div>
        ))}
        <div className="mt-60 ">
          {["pending", "approved"].indexOf(values?.cadre?.approvalStatus) >
            -1 && (
            <Button
              loading={isSubmitting}
              className="mr-10 submit-btn"
              type="danger"
              htmlType="submit"
              onClick={() => {
                setFieldValue("id", values?.cadre?.id);
                setFieldValue("approvalStatus", "rejected");
              }}
            >
              Reject
            </Button>
          )}
          {["pending", "rejected"].indexOf(values?.cadre?.approvalStatus) >
            -1 && (
            <Button
              loading={isSubmitting}
              className="submit-btn"
              type="primary"
              htmlType="submit"
              onClick={() => {
                setFieldValue("id", values?.cadre?.id);
                setFieldValue("approvalStatus", "approved");
              }}
            >
              Accept
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}

export default CadreDetailForm;
