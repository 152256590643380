import { Card, Divider, Radio, Space, Tag } from "antd";
import { ImageCell } from "components/TableCells/ImageCell";
import { ENGLISH } from "constants/language";
import moment from "moment";
import { LeftOutlined } from "@ant-design/icons";
import "./CadreDetailForm.scss";
import React, { useCallback, useState } from "react";
import { CustomPagination } from "components/CustomPagination";
import { generateFilterUrl } from "utils/helpers";
import DigitalSubmissionCard from "components/DigitalSubmissionCard/DigitalSubmissionCard";
import { useEffect } from "react";

function CadreFullDetailForm({
  setFullCadreDetails,
  cadreDetails,
  cadreExperiences,
  cadreDigitalWork,
  fetchDataForInnerTabs,
  updateFilters,
  onClickDescriptionReadMore,
}) {
  const userInfo = [
    {
      label: "Position",
      value: cadreDetails?.cadre?.designation?.[ENGLISH]?.title || "_",
    },
    {
      label: "Date of birth",
      value: cadreDetails?.userDetails?.dob
        ? moment(cadreDetails?.userDetails?.dob).format("Do MMM, YYYY")
        : "_",
    },
    {
      label: "Mobile",
      value: cadreDetails?.userDetails?.phoneNo || "_",
    },
    {
      label: "WhatsApp Number",
      value: cadreDetails?.cadre?.whatsappNo || "_",
    },
    {
      label: "Gender",
      value: cadreDetails?.userDetails?.gender || "_",
    },
    {
      label: "Blood Group",
      value: cadreDetails?.cadre?.bloodGroup || "_",
    },
    {
      label: "Aadhar",
      value: cadreDetails?.cadre?.aadharNumber || "_",
    },
  ];

  const additionalInfo = [
    {
      label: "Blood Group",
      value: cadreDetails?.cadre?.bloodGroup || "_",
    },
    {
      label: "WhatsApp Number",
      value: cadreDetails?.cadre?.whatsappNo || "_",
    },
    {
      label: "Address",
      value: cadreDetails?.cadre?.address || "_",
    },
  ];

  const votingInfo = [
    {
      label: "Voter ID",
      value: cadreDetails?.cadre?.voterId || "_",
    },
    {
      label: "Division",
      value: cadreDetails?.address?.divisionData?.data?.[ENGLISH]?.name || "_",
    },
    {
      label: "District",
      value: cadreDetails?.address?.districtData?.data?.[ENGLISH]?.name || "_",
    },
    {
      label: "Assembly",
      value: cadreDetails?.address?.assemblyData?.data?.[ENGLISH]?.name || "_",
    },
    {
      label: "Taluka",
      value: cadreDetails?.address?.tehsilData?.data?.[ENGLISH]?.name || "_",
    },
    {
      label: "M. Corp/ M. Council/ N. Panchayat/ Village",
      value: cadreDetails?.address?.villageData?.data?.[ENGLISH]?.name || "_",
    },
  ];

  const cadreExperiencesList = cadreExperiences.list;
  const cadreDigitalWorkList = cadreDigitalWork.list;
  const [mode, setMode] = useState("info");

  const handleModeChange = async (e) => {
    setMode(e.target.value);
  };

  useEffect(() => {
    if (mode === "cadreExperiences") {
      fetchData("cadreExperiences", getDataFromRedux("filters"));
    }
    if (mode === "cadre_digital_work") {
      fetchData("cadre_digital_work", getDataFromRedux("filters"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (mode) {
        case "cadre_digital_work":
          return cadreDigitalWork?.[key];
        case "cadreExperiences":
          return cadreExperiences?.[key];
        default:
          return null;
      }
    },
    [cadreExperiences, cadreDigitalWork, mode]
  );

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl({
      ...filters,
      userId: cadreDetails?.cadre?.userId,
    });
    fetchDataForInnerTabs(innerTab, urlParams);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
        userId: cadreDetails?.cadre?.userId,
      };
      applyFilters(filterUpdates);
    }
  };

  const refreshDigitalWorkPage = () => {
    applyFilters();
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    updateFilters(mode, apiFilters);
    fetchData(mode, { filters: apiFilters }, apiFilters.page);
  };

  return (
    <div className="cadreFullDetailForm mb-40">
      <div>
        <Space align="center" className="">
          <span
            className="cursor-pointer"
            onClick={() => setFullCadreDetails(false)}
          >
            <LeftOutlined />
          </span>
          <span className="mb-0 size-26 weight-900">Cadre Details</span>
        </Space>
      </div>
      <div className="flex mt-24 grid-gap-40 items-center">
        <div className="width-200 height-200 flex-shrink-0">
          <ImageCell
            data={cadreDetails?.userDetails?.media?.link}
            className="user-image br-15"
          />
        </div>
        <div className="width-full">
          <div className="size-20">{cadreDetails?.userDetails?.name}</div>
          <div className="width-full column-count-2">
            {userInfo.map(({ label, value }) => (
              <div className="flex-1" key={label}>
                <Space>
                  <span className="color-default size-14">{label}:</span>
                  <span className="size-14 weight-600">{value}</span>
                </Space>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Radio.Group
        value={mode}
        size="large"
        onChange={handleModeChange}
        className="mt-24 mb-24 width-full flex"
        buttonStyle="solid"
      >
        <Radio.Button value="info" className="flex-1">
          Info.
        </Radio.Button>
        <Radio.Button value="cadreExperiences" className="flex-1">
          Experience
        </Radio.Button>
        <Radio.Button value="cadre_digital_work" className="flex-1">
          Digital Work
        </Radio.Button>
      </Radio.Group>
      {mode === "info" && (
        <>
          <div>
            <h3 className="size-16 weight-900">Additional Info & Address</h3>
            {additionalInfo.map(({ label, value }) => (
              <div className="flex-1" key={label}>
                <Space>
                  <span className="color-default size-14">{label}:</span>
                  <span className="size-14 weight-600">{value}</span>
                </Space>
              </div>
            ))}
          </div>
          <Divider />
          <div className="mb-24">
            <h3 className="size-16 weight-900">Voting Info</h3>
            {votingInfo.map(({ label, value }) => (
              <div className="flex-1" key={label}>
                <Space>
                  <span className="color-default size-14">{label}:</span>
                  <span className="size-14 weight-600">{value}</span>
                </Space>
              </div>
            ))}
          </div>
        </>
      )}
      {mode === "cadreExperiences" && (
        <>
          {cadreExperiencesList?.length ? (
            <React.Fragment>
              <div className="flex flex-wrap grid-gap-15">
                {cadreExperiencesList?.map((item) => (
                  <Card
                    style={{
                      flexGrow: 0,
                      flexShrink: 1,
                      flexBasis: 400,
                    }}
                    key={item?.id}
                  >
                    <div>
                      <div className="flex grid-gap-15">
                        <ImageCell
                          className="width-100 height-100 flex-shrink-0 user-image"
                          data={item?.contentMedia?.[0]?.media?.link}
                        />
                        <div>
                          <div className="size-16 weight-900">
                            {item?.cadreDesignation?.data?.en?.title}
                          </div>
                          <div className="size-14 color-default">
                            {moment(item?.startDate).format("Do MMM, YYYY")}
                            &nbsp;-&nbsp;
                            {item?.endDate
                              ? moment(item?.endDate).format("Do MMM, YYYY")
                              : "Present"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="size-14 color-default mt-10">
                      {item?.description}
                    </p>
                  </Card>
                ))}
              </div>
              <div>
                <CustomPagination
                  current={cadreExperiences?.filters?.page + 1}
                  totalCount={cadreExperiences?.count}
                  onChange={handlePageChange}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className="flex items-center justify-center border">
              <p>No submissions</p>
            </div>
          )}
        </>
      )}
      {mode === "cadre_digital_work" && (
        <>
          {cadreDigitalWorkList?.length ? (
            <React.Fragment>
              <div className="flex flex-wrap grid-gap-15">
                {cadreDigitalWorkList?.map((item) => (
                  <DigitalSubmissionCard
                    value={item}
                    key={item?.id}
                    readMore={onClickDescriptionReadMore}
                    refreshPage={refreshDigitalWorkPage}
                  />
                ))}
              </div>
              <CustomPagination
                current={cadreDigitalWork?.filters?.page + 1}
                totalCount={cadreDigitalWork?.count}
                onChange={handlePageChange}
              />
            </React.Fragment>
          ) : (
            <div className="flex items-center justify-center border">
              <p>No submissions</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CadreFullDetailForm;
