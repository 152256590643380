import { AntInput } from "components/CreateAntFields/CreateAntField";
import { MARATHI } from "constants/language";
import { Field } from "formik";

const MarathiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 mt-22 field-label">Title</p>
      <Field
        value={values.title}
        name="data[mr].title"
        type="text"
        suffix={
          <div>{40 - (values?.[MARATHI].title?.length || 0)} letters</div>
        }
        component={AntInput}
        placeholder={"Enter title for the reward"}
        maxLength={40}
      />
    </>
  );
};

export default MarathiLanguageForm;
