import { Card, Space, Tag } from "antd";
import { ImageCell } from "components/TableCells/ImageCell";
import moment from "moment";
import React, { Fragment } from "react";
import { Map, TrashIcon } from "assets/svgs";
import PDFLogo from "assets/images/pdf-logo.png";
import { Modal as AntdModal } from "antd";
import "./styles.scss";
import { deleteMedia } from "utils/helpers";

function DigitalSubmissionCard({ value, readMore, refreshPage }) {
  const deleteDigitalMedia = (mediaId, type) => {
    AntdModal.confirm({
      className: "confirm-modal",
      style: { width: "600px !importnat" },
      title: `Are you sure you want to delete this ${type}?`,
      centered: true,
      okText: "Yes",
      cancelText: "No",
      content: <div></div>,
      cancelButtonProps: {
        type: "link",
        style: { background: "#273054" },
      },
      okButtonProps: {
        type: "link",
        style: {
          background: "#CD0000",
        },
      },
      onOk: async () => {
        let response = await deleteMedia(mediaId);
        console.log(response);
        refreshPage();
      },
    });
  };

  return (
    <Card
      style={{
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: 400,
      }}
    >
      <div className="width-full flex justify-between">
        <span className="size-16 weight-900">{value?.type}</span>
        <span className="size-14 color-default">
          {moment(value?.dateOrganized).format("Do MMM, YYYY")}
        </span>
      </div>
      <div
        className="grid grid-gap-15 mb-20 mt-20"
        style={{
          gridTemplateColumns: "repeat(5, 1fr)",
        }}
      >
        {value?.contentMedia?.filter((media) => media.media !== null)
          ?.length ? (
          value?.contentMedia
            .filter((media) => media.media !== null)
            ?.slice(0, 5)
            .map((media, index) => (
              <div className="media-container">
                <div
                  onClick={() =>
                    deleteDigitalMedia(media?.media?.id, media?.media?.type)
                  }
                  className="delete-icon"
                >
                  <TrashIcon />
                </div>
                <a
                  href={media?.media?.link}
                  key={media?.media?.id}
                  target="_blank"
                  rel="noreferrer"
                  className="flex align-centre"
                >
                  {media?.media?.type === "image" && (
                    <img
                      alt="media"
                      className="width-full"
                      src={media?.media?.link}
                    />
                  )}
                  {media?.media?.type === "video" && (
                    <video className="width-full">
                      <source src={media.media.link}></source>
                    </video>
                  )}
                  {media?.media?.type === "pdf" && (
                    <img alt="pdf" className="width-full" src={PDFLogo} />
                  )}
                </a>
              </div>
            ))
        ) : (
          <>
            <ImageCell className="width-full" />
          </>
        )}
      </div>

      <div className="address">
        <Space align="center">
          <Map />
          <span className="size-14 color-default">
            {value?.locationName || "-"}
          </span>
        </Space>
      </div>

      <div>
        <Space direction="vertical" size={0}>
          <span className="size-14 color-default weight-600">
            Title of meeting:
          </span>
          <span>{value?.summary}</span>
        </Space>
      </div>
      <Space direction="vertical" size={0} className="mt-10">
        <span className="size-14 color-default weight-600">
          Brief about the work:
        </span>
        <p className="size-14">
          {value?.description?.slice(0, 105)}
          {value?.description?.length > 105 && (
            <span
              onClick={() => readMore(value?.description)}
              className="ml-10 cursor-pointer color-default"
            >
              Read more
            </span>
          )}
        </p>
      </Space>
      <div className="flex justify-between grid-gap-15">
        <div className="width-full">
          <div className="size-14 color-default weight-600">People present</div>
          <div className="size-14 color-default">
            {value?.peopleAttending?.map((people) => (
              <Tag color="default">{people?.name}</Tag>
            ))}
          </div>
        </div>
        <div className="width-full">
          <div className="size-14 color-default weight-600">Leader present</div>
          <div className="size-14 color-default">
            {value?.cadreAttending?.map((people) => (
              <Tag color="default">{people?.name}</Tag>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-15">
        <p className="weight-600 mb-5">Additional Info</p>
        <div className="flex justify-between grid-gap-15">
          <div className="width-full">
            <p className="size-14 color-default mb-0 weight-600">Name</p>
            <p className="size-14">{value.userDetails?.name || ""}</p>
          </div>
          <div className="width-full">
            <p className="size-14 color-default mb-0 weight-600">Designation</p>
            <p className="size-14">
              {value.cadreDetails?.designation?.en?.title || ""}
            </p>
          </div>
        </div>
        <div className="flex justify-between grid-gap-15 width-full">
          <div className="width-full">
            <p className="size-14 color-default mb-0 weight-600">
              Phone Number
            </p>
            <p className="size-14">{value.userDetails?.phoneNo || ""}</p>
          </div>
          <div className="width-full">
            <p className="size-14 color-default mb-0 weight-600">
              WhatsApp Number
            </p>
            <p className="size-14">{value.cadreDetails?.whatsappNo || ""}</p>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default DigitalSubmissionCard;
