import { Button, Col, Row } from "antd";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { FastField, Field, Form } from "formik";
import React, { useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import Date from "components/Date/date";
import "../index.scss";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";

const AddDivisionForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  taskType,
  points,
  errors,
  touched,
  access,
  state,
  submitButtonLabel,
  showOptionalField = true,
  disableStateField = true,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en?.name;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              {showOptionalField && (
                <Row
                  className="mt-32"
                  style={{ justifyContent: "space-between" }}
                >
                  <Col span={11}>
                    <p className="mb-8 field-label">Date added</p>
                    <Field
                      defaultValue={values?.createdAt}
                      disabled={true}
                      name="createdAt"
                      format="DD/MM/YY"
                      component={Date}
                      type={"datePicker"}
                      placeholder={"dd/mm/yy"}
                    />
                  </Col>

                  <Col span={11}>
                    <p className="mb-8 field-label">Division code</p>
                    <FastField
                      value={values?.divisionCode}
                      name="divisionCode"
                      type="text"
                      component={AntInput}
                      placeholder={"123456"}
                    />
                  </Col>
                </Row>
              )}
              <p className="mb-8 field-label">Division name</p>
              <Field
                value={values?.division}
                name="data.en.name"
                type="text"
                component={AntInput}
                suffix={
                  <div>
                    {100 - (values?.data?.[ENGLISH]?.name?.length || 0)} letters
                  </div>
                }
                placeholder={"Enter content title "}
                maxLength={100}
              />
              <p className="mb-8 field-label">State</p>
              <Field
                value={values?.stateId}
                selectOptions={state}
                optionValueKey="id"
                optionNameKey="english"
                filterOption={true}
                showSearch={true}
                disabled={disableStateField}
                autoComplete="off"
                mode={"single"}
                name="stateId"
                component={AntSelect}
                placeholder={"Maharastra"}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data?.[HINDI]} state={state} />
          ) : (
            <MarathiLanguageForm
              values={values?.data?.[MARATHI]}
              state={state}
            />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddDivisionForm;
