import AuthApi from "../../services/apis/auth";
//import { handleError } from "../../../utils/logger/errorHandler";
import * as authActionCreators from "./actionCreators";
import AxiosSingleton from "../../services/config/AxiosSingleton";
import Toast from "../../components/Toast";

export const login = (payload) => async (dispatch) => {
  try {
    dispatch(authActionCreators.dataFetching(null));
    const response = await AuthApi.login(payload);
    AxiosSingleton.resetInstance();
    if (response.status === 200) {
      Toast("success", ``, "Logged in successfully");
      dispatch(authActionCreators.dataFetched(response.data));
    }
    return response;
  } catch (err) {
    dispatch(authActionCreators.errorFetching(err));
  }
};

export const forgotPassword = (payload) => async (dispatch) => {
  try {
    const response = await AuthApi.forgotPassword(payload);
    AxiosSingleton.resetInstance();
    if (response.status === 200) {
      Toast("success", ``, "OTP sent to registered Mobile number");
      dispatch(authActionCreators.dataFetched(response.data));
    }
    return response;
  } catch (err) {
    dispatch(authActionCreators.errorFetching(err));
  }
};

export const createPassword = (payload) => async (dispatch) => {
  try {
    dispatch(authActionCreators.dataFetching(null));
    const response = await AuthApi.createPassword(payload);
    AxiosSingleton.resetInstance();
    if (response.status === 200) {
      Toast("success", ``, "Password changed successfully");
      dispatch(authActionCreators.dataFetched(response.data));
    }
    return response;
  } catch (err) {
    dispatch(authActionCreators.errorFetching(err));
  }
};
