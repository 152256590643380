import { Button, Row, Upload, Col, Progress, Menu, Divider } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { RTEditor } from "../../components/CreateAntFields/CreateAntField";
import { AddButton, PlusIcon, TrashSquareButton } from "../../assets/svgs";
import "../index.scss";
import {
  beforeUpload,
  beforeVideoUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
  uploadMultipleImage,
  uploadVideo,
} from "../../utils/helpers";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import "../AddOrganizationForm/AddOrganizationForm.scss";
import "./AddLeaderForm.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RootActions from "../../store/root/actions";
import Date from "components/Date/date";
import {
  UploadAuthorityLogo,
  UploadButton,
  UploadImages,
  UploadVideoButton,
} from "components/UploadButtons";
import LeadersQuestions from "./QuestionsToLeaders/QuestionsToLeaders";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import HindiLanguageForm from "./Formlanguage/HindiLanguageForm";
import MarathiLanguageForm from "./Formlanguage/MarathiLanguageForm";
import _ from "lodash";
import ImageEditor from "components/ImageEditor";

const AddLeaderForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  city,
  isEditing,
  publicRepresentative,
  department,
  experience,
  order,
  position,
  location,
  section,
  tab,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [activeImage, setActiveImage] = useState(values?.imageList[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [successStoryImageProgress, setSuccessStoryImageProgress] = useState(0);
  const [leaderImageProgress, setLeaderImageProgress] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [thumbnailUploadprogress, setThumbnailUploadprogress] = useState(0);
  const [currentAchievementIndex, setCurrentAchievementIndex] = useState(0);
  const [AuthorityUploadprogress, setAuthorityUploadprogress] = useState(0);
  const [menuKey, setMenuKey] = useState("about-leader");
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);
  const [page, setPage] = useState(1);

  const languageErrors = (array, language) => {
    let errors = array?.filter((e) => e?.data?.[language]);
    if (errors?.[0]) {
      return true;
    } else {
      return false;
    }
  };

  const findArrayErrors = (array, fieldName) => {
    let errors = array?.filter((e) => e?.[fieldName] !== undefined);
    if (errors?.[0]) {
      return true;
    } else {
      return false;
    }
  };

  const findHindiFormError = () => {
    if (!isEditing && page === 1) {
      let error = errors?.data?.hi;
      delete error?.["successStorySummary"];
      return !_.isEmpty(error);
    } else {
      return (
        errors?.data?.hi ||
        languageErrors(errors?.videos, "hi") ||
        languageErrors(errors?.achievements, "hi")
      );
    }
  };

  const findEnglishFormError = () => {
    if (!isEditing && page === 1) {
      let error = {
        ...errors?.imagesMediaId,
        ...errors?.data?.en,
        ...errors?.experience,
        ...errors?.wins,
        ...errors?.mediaId,
        ...errors?.order,
      };
      delete error?.["successStorySummary"];
      return !_.isEmpty(error);
    } else {
      return (
        errors?.experience ||
        errors?.order ||
        errors?.wins ||
        errors?.data?.en ||
        errors?.imagesMediaId ||
        errors?.successStoryImage ||
        languageErrors(errors?.videos, "en") ||
        findArrayErrors(errors?.videos, "thumbnailId") ||
        findArrayErrors(errors?.videos, "mediaId") ||
        findArrayErrors(errors?.achievements, "mediaId") ||
        languageErrors(errors?.achievements, "en") ||
        findArrayErrors(errors?.videos, "date") ||
        findArrayErrors(errors?.achievements, "awardDate")
      );
    }
  };

  const findMarathiFormError = () => {
    if (!isEditing && page === 1) {
      let error = errors?.data?.mr;
      delete error?.["successStorySummary"];
      return !_.isEmpty(error);
    } else {
      return (
        errors?.data?.mr ||
        languageErrors(errors?.videos, "mr") ||
        languageErrors(errors?.achievements, "mr")
      );
    }
  };

  const hindiFormError = submit && findHindiFormError();
  const englishFormError = submit && findEnglishFormError();
  const marathiFormError = submit && findMarathiFormError();

  let englishValues = values?.data?.[ENGLISH];
  let hindiValues = values?.data?.[HINDI];
  let marathiValues = values?.data?.[MARATHI];

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    props.fetchFromReduxOrNetwork(tab);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubmit(false);
  }, [page]);

  useEffect(() => {
    let publicRepresentativeCurrentValue;
    // filling up data for parliamentory status field
    if (englishValues?.publicRepresentative) {
      // eslint-disable-next-line no-unused-vars
      publicRepresentativeCurrentValue = getMultiLanguageValues(
        publicRepresentativeCurrentValue,
        englishValues?.publicRepresentative,
        publicRepresentative,
        "publicRepresentative"
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [englishValues, publicRepresentative]);

  useEffect(() => {
    for (let i = 0; i < values.videos.length; i++) {
      let currentVideoData = values?.videos?.[i]?.data;
      let englishValue = currentVideoData?.[ENGLISH];
      if (englishValue?.location) {
        getVideosMultilanguageValues(
          city,
          englishValue.location,
          "location",
          i
        );
      }
      if (englishValue?.section) {
        getVideosMultilanguageValues(
          section,
          englishValue.section,
          "section",
          i
        );
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.videos, location, section]);

  const getVideosMultilanguageValues = (
    dropdownArray,
    value,
    fieldName,
    index
  ) => {
    const data = dropdownArray?.filter((e) => e.english === value);
    let hindiVideoData = values?.videos?.[index]?.data?.[HINDI];
    let marathiVideoData = values?.videos?.[index]?.data?.[MARATHI];

    hindiVideoData[fieldName] = data?.[0].hindi;
    marathiVideoData[fieldName] = data?.[0].marathi;
  };

  const findCurrentDropdownValue = (value, fieldArray) => {
    let data = fieldArray?.filter((e) => e.english === value);
    return data?.[0];
  };

  const getMultiLanguageValues = (
    currentArray,
    englishValue,
    fieldArray,
    fieldName
  ) => {
    if (!currentArray || currentArray?.english !== englishValue) {
      currentArray = findCurrentDropdownValue(englishValue, fieldArray);

      hindiValues[fieldName] = currentArray?.hindi;
      marathiValues[fieldName] = currentArray?.marathi;
    }
    return currentArray;
  };

  const handleMenuTab = ({ key }) => {
    setMenuKey(key);
  };

  const hanldeProgress = (progress) => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        progress(percent);
      },
    };
    return options;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback((info) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue("image", imageUrl);
        setActiveImage(imageUrl);
        setLoading(false);
      });
    }
    // }
  });

  const handleStoryImageChange = (info) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue("successStoryImageLink", imageUrl);
        setLoading(false);
      });
    }
    // }
  };

  const handleVideoChange = useCallback((info, index) => {
    const videoValidation = beforeVideoUpload(info);
    if (videoValidation) {
      getBase64(info, async (videoUrl) => {
        let response = await uploadVideo(
          info,
          hanldeProgress(setUploadprogress)
        );
        hanldeProgress();
        setFieldValue(`videos[${index}].mediaId`, response);
        setFieldValue(`videos[${index}].link`, videoUrl);
        setLoading(true);
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleThumbnail = (info, index) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        values.videos[index].thumbnaillink = imageUrl;
        setLoading(false);
      });
    }
    // }
  };

  const handleAuthorityLogo = (info, index) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        values.achievements[index].link = imageUrl;
        setLoading(false);
      });
    }
    // }
  };

  const handleAchievementList = () => {
    const payload = {
      mediaId: "",
      link: "",
      awardDate: undefined,
      data: {
        en: {
          name: "",
          authority: "",
        },
        hi: {
          name: "",
          authority: "",
        },
        mr: {
          name: "",
          authority: "",
        },
      },
    };
    const achievements = values?.achievements;
    setAuthorityUploadprogress(0);
    setFieldValue("achievements", [...achievements, { ...payload, link: "" }]);
  };

  const deleteAchievement = (index) => {
    if (values.achievements.length !== 1) {
      let achievements = values.achievements.filter((e, i) => i !== index);
      setFieldValue("achievements", achievements);
    } else {
      setFieldValue("achievements", []);
    }
  };

  const handleVideoList = () => {
    const payload = {
      mediaId: "",
      thumbnailId: "",
      date: undefined,
      data: {
        en: {
          title: "",
          section: undefined,
          location: undefined,
        },
        hi: {
          title: "",
          section: undefined,
          location: undefined,
        },
        mr: {
          title: "",
          section: undefined,
          location: undefined,
        },
      },
    };
    payload.thumbnaillink = "";
    const videos = values?.videos;
    setUploadprogress(0);
    setFieldValue("videos", [...videos, payload]);
  };

  const handleDelete = (index) => {
    if (values.videos.length !== 1) {
      let videos = values.videos.filter((e, i) => i !== index);
      setFieldValue("videos", videos);
    } else {
      setFieldValue("videos", []);
    }
  };

  const deleteVideo = (index) => {
    let list = values.videos;
    list[index].link = "";
    list[index].mediaId = "";
    setUploadprogress(0);
    setFieldValue("videos", list);
  };

  const addToImageList = useCallback(
    async (url) => {
      let images = values.imageList;
      let mediaId = values.imagesMediaId;
      let formData = new FormData();
      let file = dataURLtoFile(url, `image_${Math.random()}.png`);
      formData.append("file", file);
      let response = await uploadMultipleImage(
        file,
        hanldeProgress(setLeaderImageProgress)
      );
      images[activeImageIndex] = url;
      mediaId[activeImageIndex] = response?.data?.id;
      setFieldValue("");
      setLoading(false);
      setFieldValue("imagesMediaId", mediaId);
      setFieldValue("imageList", images);
      setActiveImage(url);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImageIndex]
  );

  const deleteImageFromList = useCallback(() => {
    let imageList = values.imageList;
    let mediaId = values.imagesMediaId;
    imageList[activeImageIndex] = "";
    mediaId[activeImageIndex] = "";
    setFieldValue("imagesMediaId", mediaId);
    setFieldValue("imageList", imageList);

    let lastValidImage =
      imageList?.filter((item) => item)?.slice(-1)?.[0] || [];
    setActiveImage(lastValidImage);
    let lastIndex = 0;
    for (let i = 0; i < imageList?.length; i++) {
      if (imageList[i]) {
        lastIndex = i;
      }
    }
    setActiveImageIndex(lastIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const handleErrors = () => {
    props.setTouched({
      data: {
        en: {
          department: true,
          partyPosition: true,
          publicRepresentative: true,
          about: true,
          name: true,
          constituency: true,
        },
        hi: {
          department: true,
          partyPosition: true,
          publicRepresentative: true,
          about: true,
          name: true,
          constituency: true,
        },
        mr: {
          department: true,
          partyPosition: true,
          publicRepresentative: true,
          about: true,
          name: true,
          constituency: true,
        },
      },
      order: true,
      experience: true,
      wins: true,
      imagesMediaId: true,
    });
    let data = values.data;
    if (
      values.order &&
      values.experience &&
      values.wins &&
      values.imagesMediaId?.[0] &&
      data?.[ENGLISH]?.publicRepresentative &&
      data?.[ENGLISH]?.constituency &&
      data?.[HINDI]?.constituency &&
      data?.[MARATHI]?.constituency &&
      data?.[ENGLISH]?.name &&
      data?.[ENGLISH]?.about &&
      data?.[HINDI]?.name &&
      data?.[HINDI]?.about &&
      data?.[MARATHI]?.name &&
      data?.[MARATHI]?.about &&
      data?.[ENGLISH]?.partyPosition
    ) {
      setPage(2);
    }
    setSubmit(true);
  };

  const hasError = (field, index, fieldName) => {
    if (fieldName) {
      return (
        errors?.[field]?.[index]?.[fieldName] &&
        touched?.[field]?.[index]?.[fieldName] &&
        errors?.[field]?.[index]?.[fieldName]
      );
    } else return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="leaders-form">
      <Menu
        onClick={handleMenuTab}
        mode={"horizontal"}
        selectedKeys={menuKey}
        className="display-flex justify-center"
      >
        <Menu.Item key="about-leader">About the leader</Menu.Item>
        <Menu.Item disabled={!isEditing ? true : false} key="questions">
          Questions to the leader
        </Menu.Item>
      </Menu>
      {menuKey === "about-leader" ? (
        <div className="form-wrapper">
          <FormMenu
            language={language}
            handleFormLanguage={handleFormLanguage}
            hindiFormError={hindiFormError}
            englishFormError={englishFormError}
            marathiFormError={marathiFormError}
          />
          <Form className="ml-40">
            <div className="add-form">
              {language === ENGLISH ? (
                <>
                  {(page === 1 || isEditing) && (
                    <div>
                      <p className="mt-24 field-label">Images</p>
                      {activeImage && activeImage.length ? (
                        <ImageEditor
                          image={activeImage}
                          renderMultipleImage={true}
                          onDelete={() => {
                            setActiveImage("");
                            deleteImageFromList();
                            setLeaderImageProgress(0);
                          }}
                          isEditing={leaderImageProgress}
                          handleEditing={(status) => {
                            if (status && leaderImageProgress) {
                              setLeaderImageProgress(0);
                            }
                          }}
                          onSave={async (url) => {
                            addToImageList(url);
                          }}
                          width={228}
                        />
                      ) : (
                        <Upload
                          listType={`picture-card ${
                            hasError("imagesMediaId") ? "error" : ""
                          }`}
                          className="picture-uploader"
                          accept="image/png, image/jpeg, image/jpg"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={handleChange}
                          customRequest={dummyRequest}
                          progress={
                            <Progress
                              percent={leaderImageProgress}
                              showInfo={true}
                            />
                          }
                        >
                          {<UploadImages loading={loading} />}
                        </Upload>
                      )}
                      {leaderImageProgress === 100 &&
                        !hasError("imagesMediaId") && (
                          <div className="success-text">
                            Image uploaded Successfully
                          </div>
                        )}
                      {leaderImageProgress > 0 && leaderImageProgress < 100 && (
                        <div className="progress-bar">
                          <Progress
                            percent={leaderImageProgress}
                            showInfo={true}
                          />
                        </div>
                      )}
                      {hasError("imagesMediaId") && (
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {hasError("imagesMediaId")}
                        </div>
                      )}
                      <div className="upload-list">
                        {values.imageList?.map((item, index) =>
                          item ? (
                            <div
                              key={index}
                              className={[
                                "uploaded-image-container",
                                index === activeImageIndex ? "selected" : "",
                              ].join(" ")}
                            >
                              <img
                                onClick={() => {
                                  setActiveImageIndex(index);
                                  setActiveImage(item);
                                  setLeaderImageProgress(0);
                                }}
                                alt={"upload-pic"}
                                className={[
                                  "uploaded-image-container-item",
                                ].join(" ")}
                                src={item}
                              />
                            </div>
                          ) : (
                            <div key={index} className={"upload-button-small"}>
                              <input
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  setLeaderImageProgress(0);
                                  getBase64(file, (imageUrl) => {
                                    setActiveImage(imageUrl);
                                  });
                                }}
                                type="file"
                                id="file"
                                accept="image/png, image/jpeg, image/jpg"
                              />
                              <label
                                onClick={() => setActiveImageIndex(index)}
                                htmlFor="file"
                              >
                                <PlusIcon />
                              </label>
                            </div>
                          )
                        )}
                      </div>
                      <p
                        className={
                          isEditing ? "field-label mt-25" : "field-label mt-29"
                        }
                      >
                        Leader Name{" "}
                      </p>
                      <FastField
                        value={values.name}
                        name="data[en]name"
                        type="text"
                        suffix={
                          <div>
                            {50 - (values?.data?.[ENGLISH]?.name?.length || 0)}{" "}
                            letters
                          </div>
                        }
                        component={AntInput}
                        placeholder={"Enter content title "}
                        maxLength={50}
                      />
                      <p className="mt-10 field-label">Public Representative</p>
                      <Field
                        value={values.publicRepresentative}
                        selectOptions={publicRepresentative}
                        optionValueKey="english"
                        optionNameKey="english"
                        filterOption={false}
                        showSearch={false}
                        mode={"single"}
                        name="data[en]publicRepresentative"
                        component={AntSelect}
                        placeholder={"Select staus (e.g. MP/ MLA)"}
                      />
                      <p className="mt-10 field-label">Constituency</p>
                      <Field
                        value={values.constituency}
                        type="text"
                        suffix={
                          <div>
                            {50 -
                              (values?.data?.[ENGLISH]?.constituency?.length ||
                                0)}{" "}
                            letters
                          </div>
                        }
                        component={AntInput}
                        name="data[en]constituency"
                        placeholder={"Enter constituency"}
                        maxLength={50}
                      />
                      <p className="mt-10 field-label">Position in party</p>
                      <Field
                        value={values.partyPosition}
                        type="text"
                        suffix={
                          <div>
                            {100 -
                              (values?.data?.[ENGLISH]?.partyPosition?.length ||
                                0)}{" "}
                            letters
                          </div>
                        }
                        component={AntInput}
                        name="data[en]partyPosition"
                        placeholder={"Enter position"}
                        maxLength={100}
                      />
                      <p className="mt-8 field-label">Order </p>
                      <FastField
                        value={values.order}
                        name="order"
                        type="text"
                        component={AntInput}
                        placeholder={"Enter order "}
                        maxLength={4}
                      />
                      <Row style={{ justifyContent: "space-between" }}>
                        <Col span={11}>
                          <p className="mt-0 field-label">Experience</p>
                          <Field
                            value={values.experience}
                            name="experience"
                            type="text"
                            component={AntInput}
                            placeholder={"In Years"}
                          />
                        </Col>
                        <Col span={11}>
                          <p className="mt-0 field-label">Number of wins</p>
                          <Field
                            value={values.wins}
                            name="wins"
                            type="text"
                            component={AntInput}
                            placeholder={"Enter here"}
                          />
                        </Col>
                      </Row>
                      <p className="field-label">About the leaders</p>
                      <FastField
                        value={values.about}
                        name="data[en]about"
                        type="editor"
                        component={RTEditor}
                        characterLimit={20000}
                        placeholder={"Enter details here"}
                      />
                    </div>
                  )}
                  <div
                    style={
                      page === 2 || isEditing
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <p className="mt-24 field-label">Success story</p>
                    {values?.successStoryImageLink ? (
                      <ImageEditor
                        image={values?.successStoryImageLink}
                        onDelete={() => {
                          setFieldValue("successStoryImage", "");
                          setFieldValue("successStoryImageLink", "");
                          setSuccessStoryImageProgress(0);
                        }}
                        isEditing={successStoryImageProgress}
                        handleEditing={(status) => {
                          if (status && successStoryImageProgress) {
                            setSuccessStoryImageProgress(0);
                          }
                        }}
                        onSave={async (url) => {
                          let file = dataURLtoFile(
                            url,
                            `image_${Math.random()}.png`
                          );
                          let response = await uploadImage(
                            file,
                            hanldeProgress(setSuccessStoryImageProgress)
                          );
                          if (response) {
                            setFieldValue("successStoryImage", response);
                            setFieldValue("successStoryImageLink", url);
                            setLoading(false);
                          }
                        }}
                        width={380}
                      />
                    ) : (
                      <Upload
                        name="successStoryImage"
                        listType={`picture-card ${
                          hasError("successStoryImage") ? "error" : ""
                        }`}
                        accept="image/png, image/jpeg, image/jpg"
                        className="picture-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleStoryImageChange}
                        customRequest={dummyRequest}
                      >
                        {<UploadButton loading={loading} />}
                      </Upload>
                    )}
                    {successStoryImageProgress === 100 &&
                      !hasError("successStoryImage") && (
                        <div className="success-text">
                          Image uploaded Successfully
                        </div>
                      )}
                    {successStoryImageProgress > 0 &&
                      successStoryImageProgress < 100 && (
                        <div className="progress-bar">
                          <Progress
                            percent={successStoryImageProgress}
                            showInfo={true}
                          />
                        </div>
                      )}
                    <span className="ant-form-item-explain ant-form-item-explain-error">
                      {hasError("successStoryImage")}
                    </span>
                    <br />
                    <FastField
                      value={values.successStorySummary}
                      name="data[en].successStorySummary"
                      type="editor"
                      component={RTEditor}
                      characterLimit={20000}
                      placeholder={"Enter short summary about the content"}
                    />
                    <Divider
                      style={{
                        width: "536px",
                        position: "relative",
                        left: "-40px",
                      }}
                    />
                    <p className="video-label mt-23 mb-20">Achievements</p>
                    {values?.achievements?.map((items, i) => (
                      <div key={i}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="field-label">Achievement {i + 1}</p>
                          <p
                            style={{ color: "#e70000", cursor: "pointer" }}
                            onClick={() => deleteAchievement(i)}
                            className="field-label"
                          >
                            Delete Achievement
                          </p>
                        </div>
                        <p className="field-label">Awarding authority logo</p>
                        {items?.link ? (
                          <ImageEditor
                            image={items?.link}
                            onDelete={() => {
                              setFieldValue(`achievements[${i}].link`, "");
                              setFieldValue(`achievements[${i}].mediaId`, "");
                              setThumbnailUploadprogress(0);
                              setUploadprogress(0);
                              setAuthorityUploadprogress(0);
                            }}
                            isEditing={AuthorityUploadprogress}
                            handleEditing={(status) => {
                              if (status && AuthorityUploadprogress) {
                                setAuthorityUploadprogress(0);
                              }
                            }}
                            onSave={async (url) => {
                              let file = dataURLtoFile(
                                url,
                                `image_${Math.random()}.png`
                              );
                              currentAchievementIndex !== i &&
                                setCurrentAchievementIndex(i);
                              let response = await uploadImage(
                                file,
                                hanldeProgress(setAuthorityUploadprogress)
                              );
                              if (response) {
                                setFieldValue(
                                  `achievements[${i}].mediaId`,
                                  response
                                );
                                setFieldValue(`achievements[${i}].link`, url);
                                setLoading(false);
                              }
                            }}
                            width={220}
                          />
                        ) : (
                          <Upload
                            name="mediaID"
                            listType={`picture-card ${
                              hasError("achievements", i, "mediaId")
                                ? "error"
                                : ""
                            }`}
                            className="picture-uploader"
                            accept="image/png, image/jpeg, image/jpg"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            // action={handleUpload}
                            beforeUpload={beforeUpload}
                            onChange={(e) => handleAuthorityLogo(e, i)}
                            onClick={() => setCurrentAchievementIndex(i)}
                            progress={
                              <Progress
                                percent={AuthorityUploadprogress}
                                showInfo={true}
                              />
                            }
                          >
                            {<UploadAuthorityLogo loading={loading} />}
                          </Upload>
                        )}
                        {AuthorityUploadprogress === 100 &&
                          !hasError("achievements", i, "mediaId") &&
                          i === currentAchievementIndex && (
                            <div className="success-text">
                              Image uploaded Successfully
                            </div>
                          )}
                        {AuthorityUploadprogress > 0 &&
                          AuthorityUploadprogress < 100 &&
                          i === currentAchievementIndex && (
                            <div className="progress-bar">
                              <Progress
                                percent={AuthorityUploadprogress}
                                showInfo={true}
                              />
                            </div>
                          )}
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {hasError("achievements", i, "mediaId") &&
                            `Image is required`}
                        </div>
                        <p className="mb-10 field-label">Award Date</p>
                        <Field
                          defaultValue={items?.awardDate}
                          name={`achievements[${i}].awardDate`}
                          format="DD/MM/YY"
                          component={Date}
                          type={"datePicker"}
                          placeholder={"dd/mm/yy"}
                        />
                        <p className=" field-label">Awarding authority </p>
                        <FastField
                          value={items?.authority}
                          name={`achievements[${i}].data.[en].authority`}
                          type="text"
                          suffix={
                            <div>
                              {50 -
                                (items?.data?.[ENGLISH]?.authority?.length ||
                                  0)}{" "}
                              letters
                            </div>
                          }
                          component={AntInput}
                          placeholder={"Enter content title "}
                          maxLength={50}
                        />
                        <p className="mt-16 field-label">Award name</p>
                        <FastField
                          value={items?.name}
                          name={`achievements[${i}].data.[en].name`}
                          type="text"
                          suffix={
                            <div>
                              {50 - (items?.data?.[ENGLISH]?.name?.length || 0)}{" "}
                              letters
                            </div>
                          }
                          component={AntInput}
                          placeholder={"Enter content title "}
                          maxLength={50}
                        />
                      </div>
                    ))}
                    <Button
                      className="mb-31 add-btn"
                      type="dashed"
                      onClick={handleAchievementList}
                      style={{ background: "transparent !important" }}
                    >
                      <span>
                        <AddButton style={{ width: "14px", height: "14px" }} />{" "}
                        Add Achievement
                      </span>
                    </Button>
                    <p className="video-label mt-23 mb-20">Videos</p>
                    {values?.videos?.map((items, i) => (
                      <div key={i}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="field-label">Video {i + 1}</p>
                          <p
                            style={{ color: "#e70000", cursor: "pointer" }}
                            onClick={() => handleDelete(i)}
                            className="field-label"
                          >
                            Delete Video
                          </p>
                        </div>
                        {items.link ? (
                          <div className="video-container">
                            <div
                              onClick={() => deleteVideo(i)}
                              className="video-trash-icon"
                            >
                              <TrashSquareButton />
                            </div>
                            <video
                              width="460"
                              height="115"
                              src={items.link}
                              controls
                            />
                          </div>
                        ) : (
                          <Upload
                            name={`mediaId`}
                            listType={`video-card ${
                              hasError("videos", i, "mediaId") ? "error" : ""
                            }`}
                            className="picture-uploader"
                            accept="video/avi, video/mp4, video/mpeg4"
                            showUploadList={false}
                            action={(e) => handleVideoChange(e, i)}
                            onClick={() => setCurrentVideoIndex(i)}
                            customRequest={dummyRequest}
                            progress={
                              <Progress
                                percent={uploadprogress}
                                showInfo={true}
                              />
                            }
                          >
                            {<UploadVideoButton loading={loading} />}
                          </Upload>
                        )}
                        {uploadprogress === 100 && i === currentVideoIndex && (
                          <div className="success-text">
                            Video uploaded Successfully
                          </div>
                        )}
                        {uploadprogress > 0 &&
                          uploadprogress < 100 &&
                          i === currentVideoIndex && (
                            <div className="progress-bar">
                              <Progress
                                percent={uploadprogress}
                                showInfo={true}
                              />
                            </div>
                          )}
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {hasError("videos", i, "mediaId") &&
                            uploadprogress < 90 &&
                            `Video is required`}
                        </div>
                        <p className="field-label">Thumbnail {i + 1}</p>
                        {items?.thumbnaillink ? (
                          <ImageEditor
                            image={items?.thumbnaillink}
                            onDelete={() => {
                              setFieldValue(`videos[${i}].thumbnaillink`, "");
                              setFieldValue(`videos[${i}].thumbnailId`, "");
                              setThumbnailUploadprogress(0);
                              setUploadprogress(0);
                            }}
                            isEditing={thumbnailUploadprogress}
                            handleEditing={(status) => {
                              if (status && thumbnailUploadprogress) {
                                setThumbnailUploadprogress(0);
                              }
                            }}
                            onSave={async (url) => {
                              let file = dataURLtoFile(
                                url,
                                `image_${Math.random()}.png`
                              );
                              currentVideoIndex !== i &&
                                setCurrentVideoIndex(i);
                              let response = await uploadImage(
                                file,
                                hanldeProgress(setThumbnailUploadprogress)
                              );
                              if (response) {
                                setFieldValue(
                                  `videos[${i}].thumbnailId`,
                                  response
                                );
                                setFieldValue(
                                  `videos[${i}].thumbnaillink`,
                                  url
                                );
                                setLoading(false);
                              }
                            }}
                            width={380}
                          />
                        ) : (
                          <Upload
                            name="thumbnailId"
                            listType={`picture-card ${
                              hasError("videos", i, "thumbnailId")
                                ? "error"
                                : ""
                            }`}
                            className="picture-uploader"
                            accept="image/png, image/jpeg, image/jpg"
                            showUploadList={false}
                            // action={handleUpload}
                            beforeUpload={beforeUpload}
                            customRequest={dummyRequest}
                            onChange={(e) => handleThumbnail(e, i)}
                            onClick={() => setCurrentVideoIndex(i)}
                            progress={
                              <Progress
                                percent={thumbnailUploadprogress}
                                showInfo={true}
                              />
                            }
                          >
                            {<UploadButton loading={loading} />}
                          </Upload>
                        )}
                        {thumbnailUploadprogress === 100 &&
                          !hasError("videos", i, "thumbnailId") &&
                          i === currentVideoIndex && (
                            <div className="success-text">
                              Image uploaded Successfully
                            </div>
                          )}
                        {thumbnailUploadprogress > 0 &&
                          thumbnailUploadprogress < 100 &&
                          i === currentVideoIndex && (
                            <div className="progress-bar">
                              <Progress
                                percent={thumbnailUploadprogress}
                                showInfo={true}
                              />
                            </div>
                          )}
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {hasError("videos", i, "thumbnailId") &&
                            `Thumbnail is required`}
                        </div>
                        <p className="field-label">Video title</p>
                        <FastField
                          value={items?.title}
                          name={`videos.[${i}].data[en].title`}
                          type="text"
                          suffix={
                            <div>
                              {50 -
                                (values?.videos?.[i]?.data?.[ENGLISH]?.title
                                  ?.length || 0)}{" "}
                              letters
                            </div>
                          }
                          component={AntInput}
                          placeholder={"Enter content title "}
                          shortSummary
                          maxLength={50}
                        />
                        <Row style={{ justifyContent: "space-between" }}>
                          <Col span={11}>
                            <p className="field-label">Location</p>
                            <Field
                              value={items?.location}
                              selectOptions={city}
                              optionValueKey="english"
                              optionNameKey="english"
                              filterOption={false}
                              showSearch={true}
                              mode={"single"}
                              name={`videos.[${i}].data[en].location`}
                              component={AntSelect}
                              placeholder={"Mumbai"}
                            />
                          </Col>
                          <Col span={11}>
                            <p className="field-label">Date of video</p>
                            <Field
                              defaultValue={items?.date}
                              name={`videos[${i}].date`}
                              format="DD/MM/YY"
                              component={Date}
                              type={"datePicker"}
                              placeholder={"dd/mm/yy"}
                            />
                          </Col>
                        </Row>
                        <p className="field-label">Section</p>
                        <Field
                          value={items?.section}
                          selectOptions={section}
                          optionValueKey="english"
                          optionNameKey="english"
                          filterOption={false}
                          showSearch={false}
                          mode={"single"}
                          name={`videos.[${i}].data[en].section`}
                          component={AntSelect}
                          placeholder={"Select a section for the video"}
                        />
                      </div>
                    ))}
                    <Button
                      className="mb-31 add-btn"
                      type="dashed"
                      onClick={handleVideoList}
                      style={{ background: "transparent !important" }}
                    >
                      <span>
                        <AddButton style={{ width: "14px", height: "14px" }} />{" "}
                        Add Video
                      </span>
                    </Button>
                  </div>
                </>
              ) : language === HINDI ? (
                <HindiLanguageForm
                  values={values?.data?.hi}
                  videos={values?.videos}
                  achievements={values?.achievements}
                  position={position}
                  department={department}
                  publicRepresentative={publicRepresentative}
                  city={city}
                  section={section}
                  showAllFields={page > 1 || isEditing}
                />
              ) : (
                <MarathiLanguageForm
                  values={values?.data?.mr}
                  videos={values?.videos}
                  achievements={values?.achievements}
                  position={position}
                  department={department}
                  publicRepresentative={publicRepresentative}
                  city={city}
                  section={section}
                  showAllFields={page > 1 || isEditing}
                />
              )}
              {page === 1 && !isEditing && (
                <Button
                  className="mt-40 submit-btn"
                  type="primary"
                  onClick={() => {
                    handleErrors();
                  }}
                >
                  Next
                </Button>
              )}
              {(page === 2 || isEditing) && (
                <Button
                  loading={isSubmitting}
                  className="mt-40  ml-80 submit-btn"
                  type="primary"
                  htmlType="submit"
                  onClick={() => setSubmit(true)}
                >
                  {submitButtonLabel}
                </Button>
              )}
            </div>
          </Form>
        </div>
      ) : (
        <LeadersQuestions id={values?.id} />
      )}
    </div>
  );
};

const mapStateToProps = ({ leaders }) => ({
  leaders: leaders?.list,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFromReduxOrNetwork: bindActionCreators(
    RootActions.fetchFromReduxOrNetwork,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLeaderForm);
