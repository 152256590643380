import { ENGLISH, HINDI, MARATHI } from "constants/language";
import {
  returnDistrictColumns,
  returnDivisionColumns,
  returnTalukaColumns,
  returnAssemblyColumns,
  returnVillageColumns,
} from "./columns";

export const INNER_HEADERS = [
  {
    label: "Division",
    singular: "Division",
    title: {
      en: "Division",
      hi: "विभाजन",
      mr: "विभागणी",
    },
  },
  {
    label: "District",
    singular: "District",
    title: {
      en: "District",
      hi: "जिला",
      mr: "जिल्हा",
    },
  },
  {
    label: "Taluka",
    singular: "Taluka",
    title: {
      en: "Taluka",
      hi: "तालुका",
      mr: "तालुका",
    },
  },
  {
    label: "Assembly",
    singular: "Assembly",
    title: {
      en: "Assembly",
      hi: "सभा",
      mr: "विधानसभा",
    },
  },
  {
    label: "Village",
    singular: "Village",
    title: {
      en: "Village",
      hi: "गाँव",
      mr: "गाव",
    },
  },
  {
    label: "Policy",
    singular: "Policy",
    title: {
      en: "Policy",
      hi: "नीति",
      mr: "धोरण",
    },
  },
  {
    label: "Welcome video",
    singular: "Welcome video",
    title: {
      en: "Welcome video",
      hi: "स्वागत वीडियो",
      mr: "स्वागत व्हिडिओ",
    },
  },
];

export const TABS = {
  DIVISIONS: "division",
  DISTRICTS: "district",
  TALUKAS: "taluka",
  ASSEMBLY: "assembly",
  VILLAGES: "village",
  POLICIES: "policy",
  WELCOMEVIDEO: "welcomevideo",
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  fetchModal,
  languageId
) => {
  let args = {
    onSort,
    sortColumn,
    fetchModal,
    languageId,
  };

  if (innerTab[innerTab.length - 1] === "s")
    innerTab = innerTab.slice(0, innerTab.length - 1);

  switch (innerTab) {
    case TABS.DIVISIONS:
      return returnDivisionColumns(args);
    case TABS.DISTRICTS || TABS.DISTRICTS + "s":
      return returnDistrictColumns(args);
    case TABS.TALUKAS:
      return returnTalukaColumns(args);
    case TABS.ASSEMBLY:
      return returnAssemblyColumns(args);
    case TABS.VILLAGES:
      return returnVillageColumns(args);
    default:
      return returnDivisionColumns(args);
  }
};

export const METADATA = {
  division: ["state"],
  district: ["division"],
};

export const getButtonLabel = (innerTab) => {
  const label =
    innerTab === TABS.WELCOMEVIDEO
      ? "video"
      : innerTab
          .split("")
          .map((e, i) => (i === 0 ? e.toUpperCase() : e))
          .join("");
  return label;
};

export const createPayload = (innerTab, values) => {
  switch (innerTab) {
    case TABS.DIVISIONS: {
      const payload = {
        stateId: values.stateId,
        countryId: "59fefd61-1a80-4e17-b2cf-7e0df354173c", // hardcoded for india
        data: values.data,
        isDivision: true,
      };
      if (values.divisionCode) {
        payload["divisionCode"] = +values.divisionCode;
      }
      return payload;
    }
    case TABS.DISTRICTS: {
      const payload = {
        divisionId: values.divisionId,
        data: values.data,
        stateId: "8b096998-d741-4375-bcc3-4522d242eda1",
      };
      if (values.districtCode) {
        payload["districtCode"] = +values.districtCode;
      }
      return payload;
    }
    case TABS.TALUKAS: {
      const payload = {
        districtId: values.districtId,
        data: values.data,
      };
      if (values.talukaCode) {
        payload["talukaCode"] = +values.talukaCode;
      }
      return payload;
    }
    case TABS.ASSEMBLY: {
      const payload = {
        districtId: values.districtId,
        data: values.data,
      };
      if (values.assemblyCode) {
        payload["assemblyCode"] = +values.assemblyCode;
      }
      return payload;
    }
    case TABS.VILLAGES: {
      const payload = {
        talukaId: values.talukaId,
        data: values.data,
      };
      if (values.villageCode) {
        payload["villageCode"] = +values.villageCode;
      }
      return payload;
    }
    case TABS.POLICIES: {
      return {
        policyData: values.policyData,
      };
    }
    case TABS.WELCOMEVIDEO: {
      return {
        videoData: values.videoData,
        welcome_video: values.welcome_video,
        welcome_thub: values.welcome_thub,
      };
    }
    default: {
      return null;
    }
  }
};

export const handlePutRequest = (currentValues, prevValues) => {
  let payload = {};

  for (let keys in currentValues) {
    for (let currKeys in prevValues) {
      if (keys === currKeys) {
        if (
          JSON.stringify(currentValues[keys]) !==
          JSON.stringify(prevValues[keys])
        ) {
          payload[keys] = currentValues[keys];
        }
      }
    }
  }
  return payload;
};

export const getDropDowns = (array) => {
  if (Array.isArray(array)) {
    let validArray = array?.filter((item) => item?.data !== null);
    return validArray?.map((item) => {
      return {
        id: item.id,
        english: item.data?.[ENGLISH]?.name,
        hindi: item?.data?.[HINDI]?.name,
        marathi: item?.data?.[MARATHI]?.name,
      };
    });
  }
};
