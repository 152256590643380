import { Button, Row, Upload, Col, Progress } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useCallback, useState } from "react";
import { PlusIcon } from "../../assets/svgs";

import "../index.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadMultipleImage,
} from "../../utils/helpers";
import Date from "components/Date/date";
import { useEffect } from "react";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import FormMenu from "components/FormMenu";
import { UploadImages } from "components/UploadButtons";
import { ENGLISH, HINDI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import moment from "moment";
import ImageEditor from "components/ImageEditor";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";

const AddTimelineForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  city,
  years,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [activeImage, setActiveImage] = useState(values?.imageList[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    if (values.date?._d) {
      let year = moment(values.date._d).format("YYYY");
      setFieldValue("year", year);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.date]);

  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const handleChange = useCallback((info) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue("image", imageUrl);
        setActiveImage(imageUrl);
        setLoading(false);
      });
    }
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToImageList = useCallback(
    async (url) => {
      let images = values.imageList;
      let mediaId = values.imagesMediaId;
      let formData = new FormData();
      let file = dataURLtoFile(url, `image_${Math.random()}.png`);
      formData.append("file", file);
      let response = await uploadMultipleImage(
        file,
        hanldeProgress(setUploadprogress)
      );
      images[activeImageIndex] = url;
      mediaId[activeImageIndex] = response?.data?.id;
      setFieldValue("");
      setLoading(false);
      setFieldValue("imagesMediaId", mediaId);
      setFieldValue("imageList", images);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImageIndex]
  );

  const deleteImageFromList = useCallback(() => {
    let imageList = values.imageList;
    let mediaId = values.imagesMediaId;
    imageList[activeImageIndex] = "";
    mediaId[activeImageIndex] = "";
    setFieldValue("imagesMediaId", mediaId);
    setFieldValue("imageList", imageList);

    let lastValidImage =
      imageList?.filter((item) => item)?.slice(-1)?.[0] || [];
    setActiveImage(lastValidImage);
    let lastIndex = 0;
    for (let i = 0; i < imageList?.length; i++) {
      if (imageList[i]) {
        lastIndex = i;
      }
    }
    setActiveImageIndex(lastIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mt-24 field-label">Images</p>
              {activeImage && activeImage.length ? (
                <ImageEditor
                  image={activeImage}
                  renderMultipleImage={true}
                  onDelete={() => {
                    setActiveImage("");
                    deleteImageFromList();
                    setUploadprogress(0);
                  }}
                  isEditing={uploadprogress}
                  handleEditing={(status) => {
                    if (status && uploadprogress) {
                      setUploadprogress(0);
                    }
                  }}
                  onSave={async (url) => {
                    addToImageList(url);
                  }}
                  width={380}
                />
              ) : (
                <Upload
                  listType={`picture-card ${
                    hasError("imagesMediaId") ? "error" : ""
                  }`}
                  className="picture-uploader"
                  accept="image/png, image/jpeg, image/jpg"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  customRequest={dummyRequest}
                  progress={
                    <Progress percent={uploadprogress} showInfo={true} />
                  }
                >
                  {<UploadImages loading={loading} />}
                </Upload>
              )}
              {uploadprogress === 100 && !hasError("imagesMediaId") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadprogress > 0 && uploadprogress < 100 && (
                <div className="progress-bar">
                  <Progress percent={uploadprogress} showInfo={true} />
                </div>
              )}
              {hasError("imagesMediaId") && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {hasError("imagesMediaId")}
                </div>
              )}
              <div className="mt-6 upload-list">
                {values.imageList?.map((item, index) =>
                  item ? (
                    <div
                      key={index}
                      className={[
                        "uploaded-image-container",
                        index === activeImageIndex ? "selected" : "",
                      ].join(" ")}
                    >
                      <img
                        onClick={() => {
                          setActiveImageIndex(index);
                          setActiveImage(item);
                        }}
                        alt={"upload-pic"}
                        className={["uploaded-image-container-item"].join(" ")}
                        src={item}
                      />
                    </div>
                  ) : (
                    <div key={index} className={"upload-button-small"}>
                      <input
                        onChange={(e) => {
                          setUploadprogress(0);
                          const file = e.target.files[0];
                          getBase64(file, (imageUrl) => {
                            setActiveImage(imageUrl);
                            setActiveImageIndex(index);
                          });
                        }}
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <label
                        onClick={() => setActiveImageIndex(index)}
                        htmlFor="file"
                      >
                        <PlusIcon />
                      </label>
                    </div>
                  )
                )}
              </div>
              <Row style={{ justifyContent: "space-between" }}>
                <Col span={11}>
                  <p className="mt-29 field-label">Year</p>
                  <Field
                    defaultValue={values.year}
                    disabled={true}
                    name="year"
                    type="text"
                    autoComplete={"off"}
                    component={AntInput}
                    placeholder={"Year"}
                  />
                </Col>
                <Col span={11}>
                  <p className="mt-29 field-label">Date</p>
                  <Field
                    defaultValue={values.date}
                    name="date"
                    format="DD/MM/YY"
                    component={Date}
                    placeholder={"dd/mm/yy"}
                  />
                </Col>
              </Row>
              <p className="mb-8 field-label">Title </p>
              <FastField
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {100 - (values?.data?.[ENGLISH]?.title?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title "}
                maxLength={100}
              />
              <p className="mb-8 field-label">Short summary</p>
              <Field
                value={values.shortSummary}
                name="data.en.shortSummary"
                type="text"
                component={AntTextArea}
                maxLength={1500}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short summary about the content"}
              />
              <p className="mb-8 field-label">Web URL</p>
              <FastField
                value={values.urlLink}
                defaultValue={values.urlLink}
                name="urlLink"
                type="text"
                autoComplete={"off"}
                component={AntInput}
                placeholder={"Enter web url"}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data} />
          ) : (
            <MarathiLanguageForm values={values?.data} />
          )}
          <Button
            loading={isSubmitting}
            className={
              language === ENGLISH
                ? "submit-btn mt-40"
                : "absolute-submit-btn submit-btn"
            }
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddTimelineForm;
