/* eslint-disable no-undef */
import { storage } from "../../services/config/storage";
/**
 **
 **/
import axios from "axios";
import AxiosSingleton from "./AxiosSingleton";
import Toast from "components/Toast";
import { fetchAccessToken } from "utils/helpers";

const errHandler = (res) => {
  if (res.response?.status === 401) {
    storage.destroy.authToken();
    let url = window.location.origin;
    window.location.href = url + "/login";
  } else if (res.status === 400) {
    const contentType = res.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      res.json().then((res) => {
        if (
          res.error &&
          res.message &&
          (typeof res.message === "string" || res.message instanceof String)
        ) {
          console.log("error", `Opps! Error.. ${res.message}`);
        } else {
          console.log("error", `Opps! Error`);
        }
      });
    } else {
      res.text().then((res) => {
        Toast("error", `Opps! Error. ${res.error ? res.message : ""}`);
      });
    }
  } else if (res.status === 403) {
    console.log("error", "Your session has expired, please, re-login.");
    storage.destroy.authToken();
    setTimeout(() => {
      window.href = "/login";
    }, 100);
  } else {
    let errorMessage = res?.response?.data?.message;
    Toast("error", typeof errorMessage === "string" ? errorMessage : "");
  }
};

const _authorizationHeaders = async () => {
  const token = fetchAccessToken();
  return {
    Authorization: `${token}`,
    "Content-Type": "application/json",
  };
};

const handleError = (err) => {
  return Promise.reject(err);
};

export const getRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await AxiosSingleton.getInstance().get(url, headers);
    return res?.data;
  } catch (err) {
    errHandler(err);
    handleError(err);
  }
};

export const getExternalRequest = async (url) => {
  try {
    return await axios.get(url);
  } catch (err) {
    handleError(err);
  }
};

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await AxiosSingleton.getInstance().post(url, data, headers);
    return res;
  } catch (err) {
    errHandler(err);
    handleError(err);
  }
};

export const putRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders(),
  absoluteUrl = false
) => {
  try {
    const res = await AxiosSingleton.getInstance().put(url, data, headers);
    return res;
  } catch (err) {
    errHandler(err);
    handleError(err);
  }
};

export const patchRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await AxiosSingleton.getInstance().patch(url, data, headers);
    return res;
  } catch (err) {
    errHandler(err);
    handleError(err);
  }
};

export const deleteRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await AxiosSingleton.getInstance().delete(url, data, headers);
    return res;
  } catch (err) {
    errHandler(err);
    handleError(err);
  }
};

export const Api = {
  deleteRequest,
  getRequest,
  getExternalRequest,
  postRequest,
  putRequest,
  patchRequest,
};
