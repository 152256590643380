import { Button, Upload, Progress } from "antd";
import { FastField, Form } from "formik";
import React, { useState } from "react";
import { AntInput } from "../../components/CreateAntFields/CreateAntField";
import {
  beforeUpload,
  beforeVideoUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
  uploadVideo,
} from "../../utils/helpers";
import { UploadButton, UploadVideoButton } from "components/UploadButtons";
import "../index.scss";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import { ENGLISH } from "constants/language";
import ImageEditor from "components/ImageEditor";
import { useCallback } from "react";
import { TrashSquareButton } from "assets/svgs";

const WelcomeVideoForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  defaultCategory,
  tab,
  isEditing,
  formModalVisible,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [Image, setImage] = useState(values?.image_link);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.videoData?.hi;
  const englishFormError =
    submit &&
    (errors?.welcome_video || errors?.videoData?.en || errors?.welcome_thub);
  const marathiFormError = submit && errors?.videoData?.mr;
  const hindiFormValues = values?.videoData?.hi;
  const marathiFormValues = values?.videoData?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  const hanldeProgress = (progress) => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        progress(percent);
      },
    };
    return options;
  };

  const handleChange = (info) => {
    let imageValidation = beforeUpload(info.file.originFileObj);
    hanldeProgress(setUploadprogress);
    if (imageValidation) {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const handleVideoChange = useCallback((info, index) => {
    const videoValidation = beforeVideoUpload(info);
    if (videoValidation) {
      getBase64(info, async (videoUrl) => {
        let response = await uploadVideo(
          info,
          hanldeProgress(setVideoUploadProgress)
        );
        hanldeProgress(setVideoUploadProgress);
        setFieldValue(`welcome_video`, response);
        setFieldValue(`video_link`, videoUrl);
        setLoading(true);
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === "en" ? (
            <>
              <p className="mb-10 mt-10 field-label">Welcome video</p>
              {values?.video_link ? (
                <div className="video-container">
                  <div
                    onClick={() => {
                      setFieldValue("welcome_video", "");
                      setFieldValue("video_link", "");
                      setVideoUploadProgress(0);
                    }}
                    className="video-trash-icon"
                  >
                    <TrashSquareButton />
                  </div>
                  <video
                    width="460"
                    height="115"
                    src={values?.video_link}
                    controls
                  />
                </div>
              ) : (
                <>
                  <Upload
                    name={`welcome_video`}
                    listType={`video-card ${
                      hasError("welcome_video") ? "error" : ""
                    }`}
                    accept="video/avi, video/mp4, video/mpeg4"
                    className="picture-uploader"
                    showUploadList={false}
                    action={(e) => handleVideoChange(e)}
                    customRequest={dummyRequest}
                    progress={
                      <Progress percent={videoUploadProgress} showInfo={true} />
                    }
                  >
                    {<UploadVideoButton loading={loading} />}
                  </Upload>
                </>
              )}
              {videoUploadProgress === 100 && !hasError("welcome_video") && (
                <div className="success-text">Video uploaded Successfully</div>
              )}
              {videoUploadProgress > 0 && videoUploadProgress < 100 && (
                <div className="progress-bar">
                  <Progress percent={videoUploadProgress} showInfo={true} />
                </div>
              )}
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {hasError("welcome_video") &&
                  videoUploadProgress < 80 &&
                  `Video is required`}
              </div>
              <p className="mb-10 field-label">Cover Image</p>
              {Image ? (
                <ImageEditor
                  image={Image}
                  onDelete={() => {
                    setImage("");
                    setFieldValue("welcome_thub", "");
                    setUploadprogress(0);
                  }}
                  isEditing={uploadprogress}
                  handleEditing={(status) => {
                    if (status && uploadprogress) {
                      setUploadprogress(0);
                    }
                  }}
                  onSave={async (url) => {
                    let file = dataURLtoFile(url, `image_${Math.random()}.png`);
                    let response = await uploadImage(
                      file,
                      hanldeProgress(setUploadprogress)
                    );
                    if (response) {
                      setFieldValue("welcome_thub", response);
                      setImage(url);
                      setLoading(false);
                    }
                  }}
                />
              ) : (
                <Upload
                  name="welcome_thub"
                  listType={`picture-card ${
                    hasError("welcome_thub") ? "error" : ""
                  }`}
                  className="picture-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={dummyRequest}
                  onChange={handleChange}
                  progress={
                    <Progress percent={uploadprogress} showInfo={true} />
                  }
                >
                  {Image ? (
                    <img src={Image} alt="cropper" />
                  ) : (
                    <UploadButton loading={loading} />
                  )}
                </Upload>
              )}
              {uploadprogress === 100 && !hasError("welcome_thub") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadprogress > 0 && uploadprogress < 100 && (
                <div
                  className="progress-bar"
                  style={{ border: "2px solid black" }}
                >
                  <Progress percent={uploadprogress} showInfo={true} />
                </div>
              )}
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {hasError("welcome_thub")}
              </div>
              <p className="mb-8 field-label">Title</p>
              <FastField
                value={values?.title}
                name="videoData[en].title"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.videoData?.[ENGLISH]?.title?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content name"}
                maxLength={50}
              />
            </>
          ) : language === "hi" ? (
            <HindiLanguageForm values={hindiFormValues} />
          ) : (
            <MarathiLanguageForm values={marathiFormValues} />
          )}
          <Button
            loading={isSubmitting}
            className={
              language === "en"
                ? "submit-btn mt-40"
                : "absolute-submit-btn submit-btn"
            }
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default WelcomeVideoForm;
