import React from 'react'
import { useHistory } from "react-router-dom";

export const Facebook = () => {
  const history = useHistory()
  
  const {search} = history.location

  const videoSrc = decodeURI(search)?.substring(1)
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <iframe
        src={videoSrc}
        style={{border:"none", overflow:"hidden", width: "100%", height: "100vh"}}
        scrolling="no"
        frameBorder={0}
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowFullScreen={true}
        title="facebook-video2"
      >
      </iframe>
      {/* https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F10153231379946729%2F&width=500&show_text=false&height=280&appId */}
    </div>
  );
}
