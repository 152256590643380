import { Button, DatePicker, Layout, Skeleton, Table } from "antd";
import { connect } from "react-redux";
import AppHeader from "components/Header";
import { useCallback, useEffect, useState } from "react";
import {
  fetchCMSLanguage,
  generateFilterUrl,
  getSerializedData,
} from "utils/helpers";
import InnerHeader from "components/InnerHeader";
import {
  INNER_HEADERS,
  getColumns,
  getDropDowns,
  TABS,
  getButtonLabel,
  createPayload,
} from "./utils";
import TableToolbar from "components/TableToolbar";
import {
  CADRE_COMMON_SCHEMA,
  DESIGNATION_SCHEMA,
  getApproverFormInitialValues,
  getDesignationInitialValues,
  getSurveyInitialValues,
  SURVEY_SCHEMA,
} from "./validate";
import { bindActionCreators } from "redux";
import * as RootActions from "store/root/actions";
import { CustomPagination } from "components/CustomPagination";
import { Formik } from "formik";
import Modal from "components/Modal";
import AddDesignationForm from "forms/AddDesignationForm/AddDesignationForm";
import { COLUMN_ACTIONS } from "constants/dummyData";
import "./Cadre.scss";
import CadreDetailForm from "forms/CadreDetailForm/CadreDetailForm";
import CadreFullDetailForm from "forms/CadreDetailForm/CadreFullDetailForm";
import DigitalSubmissionCard from "components/DigitalSubmissionCard/DigitalSubmissionCard";
import AddSurveyForm from "forms/AddSurveyForm/AddSurveyForm";
import { Modal as AntdModal } from "antd";
import { DesignationFilters } from "./designationFIlters";
import { AddApproverForm } from "forms/AddApproverForm/AddApproverForm";

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

const Cadre = (props) => {
  let dropdownData = props?.dropdownData;
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());
  const [innerTab, setInnerTab] = useState(TABS.CADRES);
  const [isEditing, setIsEditing] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [fullCadreDetails, setFullCadreDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [openApproverModal, setOpenApproverModal] = useState(false);
  const [dropDownDataFetching, setDropdownDataFetching] = useState(false);

  useEffect(() => {
    let page = 0;
    let payload = getDataFromRedux("filters")?.multiSelectFilters;
    fetchData(innerTab, getDataFromRedux("filters"), page, payload);
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  const onSearch = (type, value) => {
    const paramsObj = {
      lang: "en",
    };
    if (value) paramsObj.search = value;

    const params = new URLSearchParams(paramsObj);

    switch (type) {
      case "cadreDesignation":
        props.fetchDropdownData(type, "designations", params);
        break;
      case "cadreOrganisation":
        props.fetchDropdownData(type, "organisations", params);
        break;
      case "cadreCommittee":
        props.fetchDropdownData(type, "committees", params);
        break;
      case "cadrePost":
        props.fetchDropdownData(type, "posts", params);
        break;
      case "cadreState":
        props.fetchDropdownData(type, "states", params);
        break;
      case "cadreDistrict":
        props.fetchDropdownData(type, "districts", params);
        break;
      case "cadreDivision":
        props.fetchDropdownData(type, "divisions", params);
        break;
      case "cadreTaluka":
        props.fetchDropdownData(type, "taluka", params);
        break;
      case "cadreVidhansabha":
        props.fetchDropdownData(type, "vidhanSabha", params);
        break;
      case "cadreMunicipalcouncil":
        props.fetchDropdownData(type, "municipalCouncil", params);
        break;
      case "cadreNagarpanchayat":
        props.fetchDropdownData(type, "nagarPanchayat", params);
        break;
      case "cadreWard":
        props.fetchDropdownData(type, "wards", params);
        break;
      case "cadrePrabhag":
        props.fetchDropdownData(type, "prabhag", params);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const dropdowns = [
      "cadreOrganisation",
      "cadreDesignation",
      "cadreCommittee",
      "cadrePost",
      "cadreState",
      "cadreDistrict",
      "cadreDivision",
      "cadreTaluka",
      "cadreVidhansabha",
      "cadreMunicipalcouncil",
      "cadreNagarpanchayat",
      "cadreWard",
      "cadrePrabhag",
    ];
    dropdowns.forEach((type) => onSearch(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0, data) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs(innerTab, urlParams, data);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      switch (innerTab) {
        case TABS.CADRES:
          return props.cadres?.[key];
        case TABS.DESIGNATIONS:
          return props.cadre_designations?.[key];
        case TABS.CADRE_EXPERIENCES:
          return props.cadreExperiences?.[key];
        case TABS.CADRE_DIGITAL_WORK:
          return props.cadre_digital_work?.[key];
        case TABS.DIGITAL_SUBMISSIONS:
          return props.digital_submissions?.[key];
        case TABS.SURVEY:
          return props.survey?.[key];
        default:
          return null;
      }
    },
    [
      props.cadres,
      props.cadre_designations,
      props.cadreExperiences,
      props.cadre_digital_work,
      props.digital_submissions,
      props.survey,
      innerTab,
    ]
  );

  const setInitialValues = (data = {}) => {
    let values =
      innerTab === TABS.DESIGNATIONS
        ? getDesignationInitialValues(data)
        : innerTab === TABS.CADRES
        ? data
        : innerTab === TABS.SURVEY
        ? getSurveyInitialValues(data, dropdownData)
        : null;
    setFormInitialValues(() => values);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    if (query.length && query.length > 1) {
      applyFilters({ search: query });
    } else if (!query.length) {
      applyFilters({ search: query });
    }
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    let payload = apiFilters.multiSelectFilters || {};
    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page, payload);
    setOpen(false);
  };

  const openFormModal = () => {
    setFormModalVisible(true);
  };
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const filterOverlay = () => {
    const filterDate = () => {
      if (selectedDateRange) {
        applyFilters({
          startAt: selectedDateRange[0].format("YYYY-MM-DD"),
          endAt: selectedDateRange[1].format("YYYY-MM-DD"),
          page: 0,
        });
      } else {
        applyFilters({
          startAt: null,
          endAt: null,
        });
      }
    };

    const clearFilters = () => {
      applyFilters({
        startAt: null,
        endAt: null,
        search: "",
        page: 0,
      });
    };

    switch (innerTab) {
      case TABS.DIGITAL_SUBMISSIONS:
        return {
          title: "Digital Submission Filter",
          content: (
            <div>
              <RangePicker onChange={setSelectedDateRange} />
              <div>
                <Button
                  onClick={filterDate}
                  type="primary"
                  className={"toggle-form-button"}
                  style={{
                    margin: 10,
                    background: "#273054",
                  }}
                >
                  Filter
                </Button>
                <Button
                  onClick={clearFilters}
                  type="primary"
                  className={"toggle-form-button"}
                  style={{
                    margin: 10,
                    background: "#273054",
                  }}
                >
                  Clear
                </Button>
              </div>
            </div>
          ),
        };
      default:
        return null;
    }
  };

  const [desc, setDesc] = useState("");

  const onClickDescriptionReadMore = (val) => {
    setDesc(val);
  };

  const filterData = [
    {
      title: "Organisation",
      data: getDropDowns(dropdownData?.organisations),
      id: "organisationId",
    },
    {
      title: "Posts",
      data: getDropDowns(dropdownData?.posts),
      id: "postId",
    },
    {
      title: "Committee",
      data: getDropDowns(dropdownData?.committees),
      id: "committeeId",
    },
    {
      title: "States",
      data: getDropDowns(dropdownData?.states),
      id: "stateId",
    },
    {
      title: "Divisions",
      data: getDropDowns(dropdownData?.divisions),
      id: "divisionId",
    },
    {
      title: "Districts",
      data: getDropDowns(dropdownData?.districts),
      id: "districtId",
    },
    {
      title: "Taluka",
      data: getDropDowns(dropdownData?.taluka),
      id: "talukaId",
    },
    {
      title: "Municipal Council",
      data: getDropDowns(dropdownData?.municipalCouncil),
      id: "municipalcouncilId",
    },
    {
      title: "Nagar Panchayat",
      data: getDropDowns(dropdownData?.nagarPanchayat),
      id: "nagarpanchayatId",
    },
    {
      title: "Vidhan Sabha",
      data: getDropDowns(dropdownData?.vidhanSabha),
      id: "vidhansabhaId",
    },
    {
      title: "Prabhag",
      data: getDropDowns(dropdownData?.prabhag),
      id: "prabhagId",
    },
    {
      title: "Wards",
      data: getDropDowns(dropdownData?.wards),
      id: "wardId",
    },
  ];

  const isMultiSelectFilterActive = () => {
    if (innerTab === TABS.DESIGNATIONS) {
      let multiSelectFilter =
        getDataFromRedux("filters")?.multiSelectFilters || {};
      if (Object.keys(multiSelectFilter).length) return true;
      else return false;
    } else {
      return false;
    }
  };

  const fetchApproverData = (filters = {}) => {
    setDropdownDataFetching(true);
    let data = { ...filters };

    delete data.approverId;
    for (let key in data) {
      if (data[key]) {
        data[key] = [data[key]];
      } else {
        delete data[key];
      }
    }

    const payload = { whereCondition: data };
    let params = {};
    props.fetchDropdownData(
      "cadreDesignation",
      "designations",
      params,
      payload,
      () => setDropdownDataFetching(false)
    );
  };

  const handleDesignationApprover = (id) => {
    if (id) {
      let approver = dropdownData?.designations?.find((el) => el?.id === id);
      setFormInitialValues({
        ...formInitialValues,
        approverId: id,
        approverName: approver?.data?.en?.title,
      });
    } else {
      setFormInitialValues({
        ...formInitialValues,
        approverName: "",
        approverId: undefined,
      });
    }
    setOpenApproverModal(false);
  };

  const downloadCadreData = async () => {
    if (innerTab === TABS.CADRES) {
      let data = await props.downloadExportedData(innerTab);
      window.open(data, "_self");
    }
  };

  const refreshDigitalSubmissionPage = () => {
    applyFilters({});
  };

  return (
    <div className="cadre wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props?.[innerTab]?.count || "0"}
            onclick={(...props) => {
              setFullCadreDetails(false);
              setInnerTab(...props);
            }}
            showCount
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                {fullCadreDetails ? (
                  <div className="container pl-40 pr-40">
                    <CadreFullDetailForm
                      cadreExperiences={props.cadreExperiences}
                      cadreDigitalWork={props.cadre_digital_work}
                      cadreDetails={props.cadres.formData}
                      setFullCadreDetails={setFullCadreDetails}
                      fetchDataForInnerTabs={props.fetchDataForInnerTabs}
                      updateFilters={props.updateFilters}
                      onClickDescriptionReadMore={onClickDescriptionReadMore}
                    />
                  </div>
                ) : (
                  <>
                    <TableToolbar
                      innerTab={innerTab}
                      onSearch={handleSearch}
                      showSearch={false}
                      onClickAdd={openFormModal}
                      showLabel
                      ctaLabel={getButtonLabel(innerTab)}
                      query={props[innerTab]?.filters?.search || ""}
                      showMultiSelectFilter={innerTab === TABS.DESIGNATIONS}
                      isActive={isMultiSelectFilterActive()}
                      onFilterClick={() => setOpen(true)}
                      exportData={downloadCadreData}
                      dataAvailabilty={innerTab === TABS.CADRES}
                      disableModalButton={
                        innerTab === TABS.CADRES ||
                        innerTab === TABS.DIGITAL_SUBMISSIONS
                      }
                      filterOverlay={
                        innerTab === TABS.DIGITAL_SUBMISSIONS
                          ? filterOverlay()
                          : null
                      }
                      innerText={
                        props?.digital_submissions?.filters?.startAt &&
                        props.digital_submissions.filters.endAt && (
                          <div style={{ width: 400 }}>
                            Searched Results from{" "}
                            <b>
                              {props?.digital_submissions?.filters?.startAt}
                            </b>{" "}
                            to{" "}
                            <b>{props?.digital_submissions?.filters?.endAt}</b>
                          </div>
                        )
                      }
                    />
                    <div
                      className={
                        innerTab === TABS.ABOUT_PARTY
                          ? "container"
                          : "container pl-40 pr-40"
                      }
                    >
                      {innerTab === TABS.DIGITAL_SUBMISSIONS ? (
                        <>
                          <div className="flex flex-wrap grid-gap-15">
                            {props.digital_submissions.list?.map((item) => (
                              <DigitalSubmissionCard
                                value={item}
                                key={item?.id}
                                readMore={onClickDescriptionReadMore}
                                refreshPage={refreshDigitalSubmissionPage}
                              />
                            ))}
                          </div>
                          <CustomPagination
                            current={props[innerTab]?.filters?.page + 1}
                            totalCount={props[innerTab]?.count}
                            onChange={handlePageChange}
                          />
                        </>
                      ) : (
                        <>
                          <Table
                            className={"is_editable"}
                            columns={getColumns(
                              innerTab,
                              handleSort,
                              sortColumn,
                              languageId,
                              async (actionType, rowId, value) => {
                                switch (actionType) {
                                  case COLUMN_ACTIONS.TOGGLE_STATUS:
                                    if (rowId) {
                                      let request = await props.updateData(
                                        innerTab,
                                        rowId,
                                        {
                                          isActive: value,
                                        },
                                        "putRequest"
                                      );
                                      if (request?.status === 200) {
                                        fetchData(
                                          innerTab,
                                          getDataFromRedux("filters")
                                        );
                                      }
                                    }
                                    break;
                                  case COLUMN_ACTIONS.ACTIVE_DATA:
                                    const data = await props.fetchDataForTable(
                                      innerTab,
                                      rowId,
                                      null,
                                      null,
                                      "cadreDetails"
                                    );
                                    setActiveRow(data);
                                    if (data) {
                                      setFullCadreDetails(data);
                                    }
                                    break;
                                  default:
                                    break;
                                }
                              },
                              (rowId) => {
                                AntdModal.confirm({
                                  className: "confirm-modal",
                                  style: { width: "600px !importnat" },
                                  title: `Are you sure you want to delete this survey?`,
                                  centered: true,
                                  okText: "Yes",
                                  cancelText: "No",
                                  content: <div></div>,
                                  cancelButtonProps: {
                                    type: "link",
                                    style: { background: "#273054" },
                                  },
                                  okButtonProps: {
                                    type: "link",
                                    style: {
                                      background: "#CD0000",
                                    },
                                  },
                                  onOk: async () => {
                                    let request = await props.deleteData(
                                      innerTab,
                                      rowId
                                    );
                                    if (request?.status === 200) {
                                      fetchData(
                                        innerTab,
                                        getDataFromRedux("filters")
                                      );
                                    }
                                  },
                                });
                              }
                            ).map((column, index) => {
                              return {
                                ...column,
                                ...(props.loading && {
                                  render: () => (
                                    <Skeleton
                                      key={index}
                                      title={true}
                                      paragraph={false}
                                    />
                                  ),
                                }),
                              };
                            })}
                            onRow={(record, rowIndex) => {
                              return {
                                onClick: async (event) => {
                                  try {
                                    let responseKey = innerTab;
                                    if (innerTab === TABS.DESIGNATIONS) {
                                      responseKey = "cadreDesignation";
                                    }

                                    let data = null;
                                    if (innerTab === TABS.CADRES) {
                                      data = await props.fetchDataForTable(
                                        innerTab,
                                        record.userId,
                                        null,
                                        null,
                                        "cadreDetails"
                                      );
                                    } else {
                                      data = await props.fetchDataForTable(
                                        innerTab,
                                        record.id,
                                        null,
                                        null,
                                        responseKey
                                      );
                                    }
                                    setInitialValues(data || record);
                                    setIsEditing(true);
                                    setActiveRow(record);
                                    openFormModal(true);
                                  } catch {}
                                  setFormModalVisible(() => true);
                                },
                              };
                            }}
                            dataSource={getSerializedData(
                              props[innerTab]?.filters?.page,
                              getDataFromRedux()
                            )}
                            rowKey={"id"}
                            pagination={false}
                            onChange={(
                              pagination,
                              filters,
                              sorter,
                              { currentDataSource, action }
                            ) => {
                              if (action === "filter") {
                                applyFilters({
                                  approvalStatus: filters.approvalStatus?.[0],
                                  page: 0,
                                });
                              }
                            }}
                          />
                          <CustomPagination
                            current={props[innerTab]?.filters?.page + 1}
                            totalCount={props[innerTab]?.count}
                            onChange={handlePageChange}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
                <Modal
                  width="500px"
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      Description
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setDesc(false);
                  }}
                  visible={desc}
                >
                  <div className="pl-20 pr-20 pt-40 pb-40">{desc}</div>
                </Modal>
                <Modal
                  width={TABS.POLICIES ? "540px" : "500px"}
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing
                        ? innerTab === TABS.CADRES
                          ? "View Cadre - Personal Information"
                          : `Edit ${getButtonLabel(innerTab)}`
                        : `Add ${getButtonLabel(innerTab)}`}
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setInitialValues();
                    setFormModalVisible(false);
                  }}
                  visible={formModalVisible}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(innerTab, values);
                        let request;
                        if (isEditing || values?.id) {
                          request = props.updateData(
                            innerTab,
                            activeRow.id || values?.id,
                            payload
                          );
                        } else request = props.createData(innerTab, payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setSortColumn(null);
                              setFormModalVisible(false);
                              setIsEditing(false);
                              fetchData(
                                innerTab,
                                getDataFromRedux("filters"),
                                0,
                                getDataFromRedux("filters")?.multiSelectFilters
                              );
                              setInitialValues({});
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                          });
                        setSubmitting(false);
                      }}
                      validationSchema={
                        innerTab === TABS.DESIGNATIONS
                          ? DESIGNATION_SCHEMA
                          : innerTab === TABS.CADRES
                          ? false
                          : innerTab === TABS.SURVEY
                          ? SURVEY_SCHEMA
                          : CADRE_COMMON_SCHEMA
                      }
                    >
                      {(rest) =>
                        innerTab === TABS.DESIGNATIONS ? (
                          <AddDesignationForm
                            onSearch={onSearch}
                            organisations={getDropDowns(
                              dropdownData?.organisations
                            )}
                            committees={getDropDowns(dropdownData?.committees)}
                            posts={getDropDowns(dropdownData?.posts)}
                            states={getDropDowns(dropdownData?.states)}
                            districts={getDropDowns(dropdownData?.districts)}
                            divisions={getDropDowns(dropdownData?.divisions)}
                            taluka={getDropDowns(dropdownData?.taluka)}
                            vidhanSabha={getDropDowns(
                              dropdownData?.vidhanSabha
                            )}
                            municipalCouncil={getDropDowns(
                              dropdownData?.municipalCouncil
                            )}
                            nagarPanchayat={getDropDowns(
                              dropdownData?.nagarPanchayat
                            )}
                            wards={getDropDowns(dropdownData?.wards)}
                            prabhag={getDropDowns(dropdownData?.prabhag)}
                            designations={getDropDowns(
                              dropdownData?.designations
                            )}
                            setOpenModal={() => setOpenApproverModal(true)}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : innerTab === TABS.CADRES ? (
                          <CadreDetailForm {...rest} />
                        ) : innerTab === TABS.CADRES ? (
                          <CadreDetailForm {...rest} />
                        ) : innerTab === TABS.SURVEY ? (
                          <AddSurveyForm
                            onSearch={onSearch}
                            organisations={dropdownData?.organisations}
                            committees={dropdownData?.committees}
                            posts={dropdownData?.posts}
                            states={dropdownData?.states}
                            districts={dropdownData?.districts}
                            divisions={dropdownData?.divisions}
                            taluka={dropdownData?.taluka}
                            vidhanSabha={dropdownData?.vidhanSabha}
                            municipalCouncil={dropdownData?.municipalCouncil}
                            nagarPanchayat={dropdownData?.nagarPanchayat}
                            wards={dropdownData?.wards}
                            prabhag={dropdownData?.prabhag}
                            designations={dropdownData?.designations}
                            submitButtonLabel={`${
                              isEditing
                                ? "Update "
                                : `Add ${getButtonLabel(innerTab)}`
                            } `}
                            {...rest}
                          />
                        ) : null
                      }
                    </Formik>
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
      <DesignationFilters
        open={open}
        onClose={() => setOpen(false)}
        filters={filterData}
        appliedFilters={
          getDataFromRedux("filters")?.multiSelectFilters?.whereCondition
        }
        applyFilter={(filter) => applyFilters({ ...filter, page: 0 })}
        clearFilter={() => applyFilters({ multiSelectFilters: {}, page: 0 })}
      />
      <AddApproverForm
        handleClose={() => setOpenApproverModal(false)}
        visible={openApproverModal}
        loading={dropDownDataFetching}
        fetchApproverData={fetchApproverData}
        setApprover={handleDesignationApprover}
        values={getApproverFormInitialValues()}
        organisations={getDropDowns(dropdownData?.organisations)}
        committees={getDropDowns(dropdownData?.committees)}
        posts={getDropDowns(dropdownData?.posts)}
        states={getDropDowns(dropdownData?.states)}
        districts={getDropDowns(dropdownData?.districts)}
        divisions={getDropDowns(dropdownData?.divisions)}
        taluka={getDropDowns(dropdownData?.taluka)}
        vidhanSabha={getDropDowns(dropdownData?.vidhanSabha)}
        municipalCouncil={getDropDowns(dropdownData?.municipalCouncil)}
        nagarPanchayat={getDropDowns(dropdownData?.nagarPanchayat)}
        wards={getDropDowns(dropdownData?.wards)}
        prabhag={getDropDowns(dropdownData?.prabhag)}
        designations={getDropDowns(dropdownData?.designations)}
      />
    </div>
  );
};

Cadre.propTypes = {};

const mapStateToProps = ({
  cadres,
  cadre_designations,
  digital_submissions,
  cadre_digital_work,
  cadreExperiences,
  dropdownData,
  survey,
}) => ({
  cadres,
  cadre_designations,
  digital_submissions,
  cadre_digital_work,
  cadreExperiences,
  dropdownData,
  survey,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
  deleteData: bindActionCreators(RootActions.deleteData, dispatch),
  downloadExportedData: bindActionCreators(
    RootActions.downloadExportedData,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cadre);
