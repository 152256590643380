import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const HindiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 mt-22 field-label">Title</p>
      <Field
        value={values.title}
        name="data[hi].name"
        type="text"
        suffix={<div>{40 - (values?.name?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter trophy name"}
        maxLength={40}
      />
    </>
  );
};

export default HindiLanguageForm;
