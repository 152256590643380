import { AntInput } from "components/CreateAntFields/CreateAntField";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { FastField, Field } from "formik";

const HindiLanguageForm = ({
  values,
  categoryTags,
  categoryTagsValues = undefined,
}) => {
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={values?.title}
        name="data[hi].title"
        type="text"
        suffix={<div>{50 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Short Summary</p>
      <Field
        value={values?.shortSummary}
        name="data[hi].shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={400}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
      <p className="mb-8 mt-10 field-label">Category Tags</p>
      <FastField
        value={categoryTagsValues}
        name="categoryTags"
        optionValueKey="tagName"
        optionNameKey="id"
        disabled={true}
        filterOption={false}
        showSearch={false}
        component={MultiSelect}
        mode={"multiple"}
        selectOptions={categoryTags}
        showArrow={true}
        placeholder={"Select content category tags"}
      />
      <p className="mb-8 field-label">Detailed article</p>
      <Field
        value={values?.detailedArticle}
        name="data[hi].detailedArticle"
        type="editor"
        component={RTEditor}
        placeholder={"Enter details here"}
        characterLimit = {20000}
      />
      <p className="mb-8 field-label">Credits/Author</p>
      <FastField
        value={values?.credits}
        name="data[hi].credits"
        type="text"
        suffix={<div>{30 - (values?.credits?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content credits/author"}
        maxLength={30}
      />
    </>
  );
};

export default HindiLanguageForm;
