import { Button } from "antd";

import { FastField, Field, Form } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { RTEditor } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import "../AddOrganizationForm/AddOrganizationForm.scss";
import "./AddProductForm.scss";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import {
  MERCHANDISE_PRODUCT_ATTRIBUTES,
  MERCHANDISE_PRODUCT_COLORS,
  MERCHANDISE_PRODUCT_SIZES,
  MERCHANDISE_PRODUCT_STYLES,
} from "constants/data"
import HindiLanguageForm from "./FormLanguages/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguages/MarathiLanguageForm";
import Variants from "./Variants";
import {ArrowLeft} from 'assets/svgs'

const AddProductForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  errors,
  touched,
  submitButtonLabel,
  isEditing,
  setProductFormPage,
  page,
  categories,
  getAttributes,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const englishFormError =
    submit && (
      errors?.data?.en ||
      errors?.categories?.length > 0 ||
      errors?.attributes?.length > 0
    )
  const hindiFormError =
    submit && errors?.data?.hi;
  const marathiFormError =
    submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    setSubmit(false);
  }, [page]);

  useEffect(() => {
    if (values?.categories?.length) {
      let categories = values.categories.map((e) => {
        return {
          label: e?.data?.[ENGLISH]?.name,
          value: e.id,
        };
      });
      setFieldValue("categories", categories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const variantPayload = {
    data: {
      size: undefined,
      color: {
        name: undefined,
        rgbcode: undefined,
      },
      style: undefined,
    },
    pointPrice: undefined,
    inventoryCount: 0,
    isDefault: false,
    imageList: new Array(5).fill(''),
    images: new Array(5).fill(''),
  };

  const combine = (result=[], arr=[], name) => {
    if (arr.length && result.length) {
      return result.flatMap(item => arr.map(element => {
        return {
          [name]: element,
          ...item
        }
      }))
    }
    else if (arr.length) {
      return arr.map((item) => ({
        [name]: item
      }))
    }
    else return result
  }


  const getVariantsList = ({
    colors = [],
    sizes = [],
    styles = [],
    variants = []
  }) => {
    const Colors = colors?.map((item) => (
      MERCHANDISE_PRODUCT_COLORS.find(
        (color) => color.id === item.value
      )?.color
    ))
    const Sizes = sizes?.map((item) => item.value)
    const Styles = styles?.map((item) => item.value)
    const result1 = combine([], Colors, 'color')
    const result2 = combine(result1, Styles, 'style')
    const result3 = combine(result2, Sizes, 'size')
    
    const result = result3.map((item) => {
      const temp = variants.find((variant) => {
        return (
          variant?.data?.size === item?.size &&
          variant?.data?.style === item?.style &&
          variant?.data?.color?.name === item?.color?.name
        )
      })
      if (temp) {
        const contentImages = temp?.contentMedia?.map((media) => media.media.id)
        const contentImageList = temp?.contentMedia?.map((media) => media.media.link)
        let emptySpaces = 5;
        if (contentImages?.length) {
          if (contentImages.length <= 5) {
            emptySpaces = emptySpaces - contentImages.length
          } else {
            emptySpaces = 0
          }
        }

        if (item?.images?.length) {
          if (item.images.length <= 5) {
            emptySpaces = emptySpaces - item.images.length
          } else {
            emptySpaces = 0
          }
        }

        const images = [
          ...(contentImages ? contentImages: []),
          ...(item?.images ? item.images: []),
          ...new Array(emptySpaces).fill('')
        ]
        const imageList = [
          ...(contentImageList ? contentImageList: []),
          ...(item?.imageList ? item.imageList: []),
          ...new Array(emptySpaces).fill('')
        ]
        
        return {
          ...variantPayload,
          data: {
            ...item,
          },
          ...temp,
          images,
          imageList,
        }
      }
      return {
        ...variantPayload,
        data: {
          ...item,
        }
      }
    })
    return result;
  };

  useEffect(() => {
    const variants = values?.variants
    if (Array.isArray(variants) && variants.length) {
      const temp = getAttributes(variants)
      const {
        colors, sizes, styles, attributes
      } = temp
      const updatedVariants = getVariantsList({colors, sizes, styles, variants})
      setFieldValue('sizes', sizes)
      setFieldValue('styles', styles)
      setFieldValue('colors', colors)
      setFieldValue('attributes', attributes)
      setFieldValue("variants", updatedVariants);
    }
  }, [])

  useEffect(() => {
    const {
      colors, sizes, styles, variants
    } = values

    if (colors || sizes || styles) {
      const updatedVariants = getVariantsList({colors, sizes, styles, variants})
      setFieldValue("variants", updatedVariants);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.colors, values?.sizes, values?.styles, values?.attributes])
  
  const handleErrors = () => {
    props.setTouched({
      data: {
        en: {
          name: true,
          description: true,
        },
        hi: {
          name: true,
          description: true,
        },
        mr: {
          name: true,
          description: true,
        },
      },
      categories: true,
      attributes: true,
    });
    const data = values.data;
    if (
      data?.[ENGLISH]?.name &&
      data?.[ENGLISH]?.description &&
      data?.[HINDI]?.name &&
      data?.[HINDI]?.description &&
      data?.[MARATHI]?.name &&
      data?.[MARATHI]?.description &&
      values?.categories?.length > 0 &&
      values?.attributes?.length > 0
    ) {
      setProductFormPage(2);
    }
    setSubmit(true);
  };

  return (
    <div className="product-form">
      {(
        <div className="form-wrapper">
          {
            (page !== 1) && (
              <div className="back-btn" onClick={()=> setProductFormPage(1)}>
                <ArrowLeft/>&nbsp;Back
              </div>
            ) 
          }
          <FormMenu
            language={language}
            handleFormLanguage={handleFormLanguage}
            hindiFormError={hindiFormError}
            englishFormError={englishFormError}
            marathiFormError={marathiFormError}
          />
          <Form>
            <div className="add-form">
              {language === ENGLISH ? (
                <>
                  {(page === 1 || isEditing) && (
                    <div>
                      <p className="mb-8 field-label">Name</p>
                      <FastField
                        value={values.name}
                        name="data[en].name"
                        type="text"
                        suffix={
                          <div>
                            {100 - (values?.data?.[ENGLISH]?.name?.length || 0)}{" "}
                            letters
                          </div>
                        }
                        component={AntInput}
                        placeholder={"Enter Product name"}
                        maxLength={100}
                      />
                      <p className="mt-10 field-label">Category</p>
                      <Field
                        value={values.categories}
                        filterOption={false}
                        showSearch={false}
                        name="categories"
                        selectOptions={categories}
                        optionValueKey="name"
                        optionNameKey="id"
                        component={MultiSelect}
                        mode={"multiple"}
                        showArrow={true}
                        placeholder={"Select category (can multi-select)"}
                        language="en"
                        disabled={false}
                      />
                      <p className="mt-10 field-label">Attributes</p>
                      <FastField
                        value={values.attributes}
                        name="attributes"
                        optionValueKey="name"
                        optionNameKey="id"
                        component={MultiSelect}
                        mode={"multiple"}
                        selectOptions={MERCHANDISE_PRODUCT_ATTRIBUTES}
                        showArrow={true}
                        placeholder={"Select attribute/s (can multi-select)"}
                        language="en"
                        disabled={false}
                        filterOption={false}
                        showSearch={false}
                      />
                      <p className="field-label">Product description</p>
                      <FastField
                        value={values.description}
                        name="data[en].description"
                        type="editor"
                        component={RTEditor}
                        characterLimit={20000}
                        placeholder={"Enter details here"}
                      />
                    </div>
                  )}
                  <div
                    style={
                      page === 2 || isEditing
                      ? { display: "block" }
                      : { display: "none" }
                    }
                  >
                    <p className="mt-23 weight-700 size-20">Add Variants</p>
                    {
                      values?.attributes?.map((item) => (<Fragment key={item.value}>
                        {item.value === 'COLOR' && (
                          <>
                            <p className="field-label mb-8">Color</p>
                            <FastField
                              value={values.colors}
                              name="colors"
                              optionValueKey="name"
                              optionNameKey="id"
                              component={MultiSelect}
                              mode={"multiple"}
                              selectOptions={MERCHANDISE_PRODUCT_COLORS}
                              showArrow={true}
                              placeholder={"Select attribute/s (can multi-select)"}
                              language="en"
                              disabled={false}
                              filterOption={false}
                              showSearch={false}
                            />
                          </>
                        )}
                        {item.value === 'STYLE' && (
                          <>
                            <p className="field-label mb-8">Style</p>
                            <FastField
                              value={values.styles}
                              name="styles"
                              optionValueKey="name"
                              optionNameKey="id"
                              component={MultiSelect}
                              mode={"multiple"}
                              selectOptions={MERCHANDISE_PRODUCT_STYLES}
                              showArrow={true}
                              placeholder={"Select attribute/s (can multi-select)"}
                              language="en"
                              disabled={false}
                              filterOption={false}
                              showSearch={false}
                            />
                          </>
                        )}
                        {item.value === 'SIZE' && (
                          <>
                            <p className="field-label mb-8">Size</p>
                            <FastField
                              value={values.sizes}
                              name="sizes"
                              optionValueKey="name"
                              optionNameKey="id"
                              component={MultiSelect}
                              mode={"multiple"}
                              selectOptions={MERCHANDISE_PRODUCT_SIZES}
                              showArrow={true}
                              placeholder={"Select attribute/s (can multi-select)"}
                              language="en"
                              disabled={false}
                              filterOption={false}
                              showSearch={false}
                            />
                          </>
                        )}
                      </Fragment>))
                    }
                    {values?.variants?.map((item, idx) => (
                      <Variants
                        key={idx}
                        index={idx}
                        values={item}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        formValue={values}
                      />
                    ))}
                  </div>
                </>
              ) : language === HINDI ? (
                <HindiLanguageForm
                  values={values?.data?.hi}
                  showAllFields={page > 1 || isEditing}
                />
              ) : (
                <MarathiLanguageForm
                  values={values?.data?.mr}
                  showAllFields={page > 1 || isEditing}
                />
              )}
              {page === 1 && !isEditing && (
                <Button
                  className="mt-40 submit-btn"
                  type="primary"
                  onClick={() => {
                    handleErrors();
                  }}
                >
                  Next
                </Button>
              )}
              {(page === 2 || isEditing) && (
                <Button
                  loading={isSubmitting}
                  className="mt-40 ml-80 submit-btn"
                  type="primary"
                  htmlType="submit"
                  onClick={() => setSubmit(true)}
                >
                  {submitButtonLabel}
                </Button>
              )}
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default AddProductForm;
