import { AntInput } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const MarathiLanguageForm = ({ values, fieldName }) => {
  return (
    <>
      <p className="mb-8 field-label">{fieldName}</p>
      <Field
        value={values.division}
        name="data.mr.name"
        type="text"
        component={AntInput}
        suffix={<div>{100 - (values?.name?.length || 0)} letters</div>}
        placeholder={"Enter content title "}
        maxLength = {100}
      />
    </>
  );
};

export default MarathiLanguageForm;
