import * as Yup from "yup";

export const EVENT_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
      organizer: Yup.string().required("Organizer name is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
      organizer: Yup.string().required("Organizer name is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
      organizer: Yup.string().required("Organizer name is required"),
    }),
  }),
  location: Yup.object().shape({
    data: Yup.object().shape({
      en: Yup.object().shape({
        name: Yup.string()
          .required("Location is required")
          .test("name", "Location is required", function (value) {
            let schema = this.from[this?.from?.length - 1];
            if (schema?.value) {
              let { latitude, longitude } = schema.value;
              if (!latitude || !longitude) return false;
              else return true;
            } else {
              return true;
            }
          }),
      }),
      hi: Yup.object().shape({
        name: Yup.string().required("Location is required"),
      }),
      mr: Yup.object().shape({
        name: Yup.string().required("Location is required"),
      }),
    }),
  }),

  publishedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
  eventStartDate: Yup.string()
    .typeError("Date is required")
    .required("Date is required"),
  eventEndDate: Yup.date()
    .typeError("Date is required")
    .required("Date is required"),
  categoryTags: Yup.array()
    .min(1, "Tag is required")
    .required("Tag is required"),
  mediaId: Yup.string().required("Deep link image is required field"),
  images: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { images } = this.parent;
        for (let i = 0; i < images?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = images[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
});

export const POLL_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
  }),
  publishedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
  likesCount: Yup.number().nullable(true),
  sharesCount: Yup.number().nullable(true),
  bookmarksCount: Yup.number().nullable(true),
  id: Yup.string(),
  webUrl: Yup.string().matches(
    // eslint-disable-next-line no-useless-escape
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
    "Enter a valid url!"
  ),
  isActive: Yup.bool(),
});

export const INFOGRAPHICS_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
  }),
  mediaId: Yup.string().required("Deep link image is required field"),
  publishedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
  categoryTags: Yup.array()
    .min(1, "Tag is required")
    .required("Tag is required"),
  likesCount: Yup.number().nullable(true),
  sharesCount: Yup.number().nullable(true),
  bookmarksCount: Yup.number().nullable(true),
  id: Yup.string(),
  images: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { images } = this.parent;
        for (let i = 0; i < images?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = images[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  isActive: Yup.bool(),
});

export const ARTICLE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      detailedArticle: Yup.string()
        .test(
          "detailedArticle",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Detailed article is required"),
      shortSummary: Yup.string().required("Short summary is required"),
      credits: Yup.string().required("Credits/Author is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      detailedArticle: Yup.string()
        .test(
          "detailedArticle",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Detailed article is required"),
      shortSummary: Yup.string().required("Short summary is required"),
      credits: Yup.string().required("Credits/Author is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      detailedArticle: Yup.string()
        .test(
          "detailedArticle",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Detailed article is required"),
      shortSummary: Yup.string().required("Short summary is required"),
      credits: Yup.string().required("Credits/Author is required"),
    }),
  }),
  publishedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
  categoryTags: Yup.array()
    .min(1, "Tag is required")
    .required("Tag is required"),
  images: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { images } = this.parent;
        for (let i = 0; i < images?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = images[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  mediaId: Yup.string().required("Deep link image is required field"),
  likesCount: Yup.number().nullable(true),
  sharesCount: Yup.number().nullable(true),
  bookmarksCount: Yup.number().nullable(true),
  id: Yup.string(),
  isActive: Yup.bool(),
});

export const IMPORTANT_MESSAGE_SCHEMA = Yup.object().shape({
  publishedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
  categoryTags: Yup.array()
    .min(1, "Tag is required")
    .required("Tag is required"),
  likesCount: Yup.number().nullable(true),
  sharesCount: Yup.number().nullable(true),
  bookmarksCount: Yup.number().nullable(true),
  isActive: Yup.bool(),
  backgroundColor: Yup.string(),
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      author: Yup.string().required("Author is required"),
      detailedArticle: Yup.string()
        .test(
          "detailedArticle",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Detailed article is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      author: Yup.string().required("Author is required"),
      detailedArticle: Yup.string()
        .test(
          "detailedArticle",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Detailed article is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      author: Yup.string().required("Author is required"),
      detailedArticle: Yup.string()
        .test(
          "detailedArticle",
          "Character length must be less then 5000",
          (val) => {
            if (val?.length && val?.length > 5000) {
              return false;
            } else {
              return true;
            }
          }
        )
        .required("Detailed article is required"),
      shortSummary: Yup.string().required("Short summary is required"),
    }),
  }),
});

export const VOTES_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
  }),
  publishedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
  isActive: Yup.bool(),
  isPopular: Yup.bool(),
  choices: Yup.array()
    .of(
      Yup.object().shape({
        mediaId: Yup.string().required("Image is required"),
        data: Yup.object().shape({
          en: Yup.object().shape({
            choiceTitle: Yup.string().required("Choice title is required"),
            choiceSubtext: Yup.string().required("Subtext is required"),
          }),
          hi: Yup.object().shape({
            choiceTitle: Yup.string().required("Choice title is required"),
            choiceSubtext: Yup.string().required("Subtext is required"),
          }),
          mr: Yup.object().shape({
            choiceTitle: Yup.string().required("Choice title is required"),
            choiceSubtext: Yup.string().required("Subtext is required"),
          }),
        }),
      })
    )
    .required("Fields is required"),
});

export const YOUTUBE_VIDEO_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),
  }),
  images: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { images } = this.parent;
        for (let i = 0; i < images?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = images[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  id: Yup.string(),
  isActive: Yup.bool(),
  webUrl: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
      "Enter a valid url!"
    )
    .required("Web URL is required"),
  mediaId: Yup.string().required("Deep link image is required field"),
});

export const ANNOUNCEMENT_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string(),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string(),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      shortSummary: Yup.string(),
    }),
  }),
  publishedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
  bookmarksCount: Yup.number().nullable(true),
  id: Yup.string(),
  isActive: Yup.bool(),
});

export const FACEBOOK_LIVE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      organizer: Yup.string().required("Organizer name is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      organizer: Yup.string().required("Organizer name is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      organizer: Yup.string().required("Organizer name is required"),
    }),
  }),
  facebookLiveStartDate: Yup.string()
    .typeError("Date is required")
    .required("Date is required"),
  facebookLiveEndDate: Yup.date()
    .typeError("Date is required")
    .required("Date is required"),
  images: Yup.array()
    .of(Yup.string())
    .test(
      "imagesMediaId",
      "Please upload at-least one image",
      function (value) {
        let count = 0;
        const { images } = this.parent;
        for (let i = 0; i < images?.length; i++) {
          if (count === 1) {
            break;
          }
          let value = images[i];
          if (value) count++;
        }
        return count > 0;
      }
    ),
  webUrl: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
      "Enter a valid url!"
    )
    .required("Web URL is required"),
});

export const getEventInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      shortSummary: "",
      organizer: "",
    },
    hi: {
      title: "",
      shortSummary: "",
      organizer: "",
    },
    mr: {
      title: "",
      shortSummary: "",
      organizer: "",
    },
  },
  isNotification: false,
  location: values.location || {
    data: {
      en: {
        name: "",
      },
      hi: {
        name: "",
      },
      mr: {
        name: "",
      },
    },
  },
  publishedDate: values?.publishedDate || new Date(),
  eventStartDate: values?.eventStartDate || undefined,
  eventEndDate: values?.eventEndDate || undefined,
  categoryTags: values?.categoryTags || undefined,
  webUrl: values?.webUrl || "",
  bookmarksCount: values.bookmarksCount || undefined,
  isPopular: values.isPopular || false,
  latitude: values.latitude || undefined,
  longitude: values.longitude || undefined,
  imageList: populateImagesList(values?.contentMedia),
  images: populateMediaId(values?.contentMedia),
  sharesCount: values.sharesCount || 0,
  users: values.users || [],
  mediaId: values.mediaId || "",
  image: {
    type: values?.image?.type || "",
    link: values?.image?.link || "",
  },
});

export const getPollInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      shortSummary: "",
    },
    hi: {
      title: "",
      shortSummary: "",
    },
    mr: {
      title: "",
      shortSummary: "",
    },
  },
  isNotification: false,
  publishedDate: values?.publishedDate || new Date(),
  contentCategory: "Poll",
  webUrl: values?.webUrl || "",
  likesCount: values.likesCount || undefined,
  sharesCount: values.sharesCount || undefined,
  bookmarksCount: values.bookmarksCount || undefined,
  isPopular: values.isPopular || false,
  yesCount: values?.yesCount || 0,
  noCount: values?.noCount || 0,
});

export const getInfographicsInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      shortSummary: "",
    },
    hi: {
      title: "",
      shortSummary: "",
    },
    mr: {
      title: "",
      shortSummary: "",
    },
  },
  isNotification: false,
  publishedDate: values?.publishedDate || new Date(),
  contentCategory: "Infographic",
  categoryTags: values?.categoryTags || undefined,
  webUrl: values?.webUrl || "",
  likesCount: values?.likesCount || undefined,
  sharesCount: values?.sharesCount || undefined,
  bookmarksCount: values.bookmarksCount || undefined,
  isPopular: values.isPopular || false,
  imageList: populateImagesList(values?.contentMedia),
  images: populateMediaId(values?.contentMedia),
  mediaId: values.mediaId || "",
  image: {
    type: values?.image?.type || "",
    link: values?.image?.link || "",
  },
});

export const getArticleInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      shortSummary: "",
      detailedArticle: "",
      credits: "",
    },
    hi: {
      title: "",
      shortSummary: "",
      detailedArticle: "",
      credits: "",
    },
    mr: {
      title: "",
      shortSummary: "",
      detailedArticle: "",
      credits: "",
    },
  },
  isNotification: false,
  mediaId: values?.mediaId || "",
  title: values?.title || "",
  publishedDate: values?.publishedDate || new Date(),
  contentCategory: "Article",
  shortSummary: values?.shortSummary || "",
  categoryTags: values.categoryTags || undefined,
  webUrl: values?.webUrl || "",
  likesCount: values?.likesCount || undefined,
  sharesCount: values?.sharesCount || undefined,
  bookmarksCount: values?.bookmarksCount || undefined,
  detailedArticle: values?.detailedArticle || "",
  isPopular: values.isPopular || false,
  image: {
    type: values?.image?.type || "",
    link: values?.image?.link || "",
  },
  imageList: populateImagesList(values?.contentMedia),
  images: populateMediaId(values?.contentMedia),
  credits: values.credits || "",
  isActive: values?.isActive || true,
});

export const getYoutubeVideoInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
    },
    hi: {
      title: "",
    },
    mr: {
      title: "",
    },
  },
  isNotification: false,
  mediaId: values?.mediaId || "",
  title: values?.title || "",
  webUrl: values?.webUrl || "",
  image: {
    type: values?.image?.type || "",
    link: values?.image?.link || "",
  },
  imageList: populateImagesList(values?.contentMedia),
  images: populateMediaId(values?.contentMedia),
  isActive: values?.isActive || true,
});

export const getAnnouncementInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      shortSummary: "",
    },
    hi: {
      title: "",
      shortSummary: "",
    },
    mr: {
      title: "",
      shortSummary: "",
    },
  },
  isNotification: false,
  publishedDate: values?.publishedDate || new Date(),
  contentCategory: "Announcement",
  likesCount: values?.likesCount || undefined,
  sharesCount: values?.sharesCount || undefined,
  bookmarksCount: values?.bookmarksCount || undefined,
});

export const getimportantmessagesInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      shortSummary: "",
      author: "",
      detailedArticle: "",
    },
    hi: {
      title: "",
      shortSummary: "",
      author: "",
      detailedArticle: "",
    },
    mr: {
      title: "",
      shortSummary: "",
      author: "",
      detailedArticle: "",
    },
  },
  isNotification: false,
  publishedDate: values?.publishedDate || new Date(),
  contentCategory: "Important Message",
  categoryTags: values.categoryTags || undefined,
  webUrl: values?.webUrl || "",
  likesCount: values?.likesCount || undefined,
  sharesCount: values?.sharesCount || undefined,
  bookmarksCount: values?.bookmarksCount || undefined,
  isPopular: values.isPopular || false,
  backgroundColor: values.backgroundColor || "#F69220",
});

export const getVotesInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
    },
    hi: {
      title: "",
    },
    mr: {
      title: "",
    },
  },
  isNotification: false,
  publishedDate: values?.publishedDate || new Date(),
  isPopular: values.isPopular || false,
  choices: values?.choices || [
    {
      mediaId: "",
      data: {
        media: { link: "" },
        en: {
          choiceTitle: "",
          choiceSubtext: "",
        },
        hi: {
          choiceTitle: "",
          choiceSubtext: "",
        },
        mr: {
          choiceTitle: "",
          choiceSubtext: "",
        },
      },
    },
  ],
});

export const getFacebookLiveInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      title: "",
      organizer: "",
    },
    hi: {
      title: "",
      organizer: "",
    },
    mr: {
      title: "",
      organizer: "",
    },
  },
  isNotification: false,
  facebookLiveStartDate: values?.facebookLiveStartDate || undefined,
  facebookLiveEndDate: values?.facebookLiveEndDate || undefined,
  webUrl: values?.webUrl || "",
  imageList: populateImagesList(values?.contentMedia),
  images: populateMediaId(values?.contentMedia),
});

const populateImagesList = (imagesList = []) => {
  let remainingSpace = 5 - imagesList?.length; //TOTAL MAX IMAGES ARE 5
  let arr = [];
  arr = imagesList?.map((item) => item?.media?.link);
  // const data = await Promise.all(arr);
  let i = 1;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};

const populateMediaId = (mediaId = []) => {
  let remainingSpace = 5 - mediaId?.length; //TOTAL MAX IMAGES ARE 5
  let arr = [];
  arr = mediaId?.map((item) => item?.media?.id);
  let i = 1;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};
