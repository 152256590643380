import { AntInput } from "components/CreateAntFields/CreateAntField";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { Field } from "formik";

const MarathiLanguageForm = ({
  values,
  categoryTags,
  categoryTagsValue = undefined,
}) => {
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={values?.title}
        name="data[mr].title"
        type="text"
        suffix={<div>{50 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Author</p>
      <Field
        value={values?.author}
        name="data[mr].author"
        type="text"
        suffix={<div>{30 - (values?.author?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter author name"}
        maxLength={30}
      />
      <p className="mb-8 field-label">Short Summary</p>
      <Field
        value={values?.shortSummary}
        name="data[mr].shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={400}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
      <p className="mb-8 mt-10 field-label">Category Tags</p>
      <Field
        value={categoryTagsValue}
        name="categoryTags"
        optionValueKey="tagName"
        optionNameKey="id"
        disabled={true}
        filterOption={false}
        showSearch={false}
        component={MultiSelect}
        mode={"multiple"}
        selectOptions={categoryTags}
        showArrow={true}
        placeholder={"Select content category tags"}
      />
      <p className="mb-8 field-label">Detailed article</p>
      <Field
        value={values?.detailedArticle}
        name="data[mr].detailedArticle"
        type="editor"
        component={RTEditor}
        placeholder={"Enter details here"}
        characterLimit={20000}
      />
    </>
  );
};

export default MarathiLanguageForm;
