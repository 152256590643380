import { Divider } from "antd";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { MARATHI } from "constants/language";
import { Field } from "formik";

const MarathiLanguageForm = ({ values, choices }) => {
  return (
    <>
      <p className="mb-8 field-label">Title / Question</p>
      <Field
        value={values?.title}
        name="data[mr].title"
        type="text"
        suffix={<div>{40 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter main title/ question "}
        maxLength={40}
      />
      <Divider
        style={{
          position: "relative",
          width: "536px",
          left: "-40px",
        }}
      />
      {choices?.map((items, i) => (
        <div key={i}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="field-label">Choice {i + 1}</p>
          </div>
          <p className="field-label mb-8">Choice title / name</p>
          <Field
            value={items?.["data"]?.choiceTitle}
            name={`choices[${i}].data[mr].choiceTitle`}
            type="text"
            suffix={
              <div>
                {30 - (items?.["data"]?.[MARATHI].choiceTitle?.length || 0)}{" "}
                letters
              </div>
            }
            component={AntInput}
            placeholder={"Enter title "}
            maxLength={30}
          />
          <p className="field-label mb-8">Choice subtext</p>
          <Field
            value={items?.["data"]?.choiceSubtext}
            name={`choices[${i}].data[mr].choiceSubtext`}
            type="text"
            suffix={
              <div>
                {20 - (items?.["data"]?.[MARATHI].choiceSubtext?.length || 0)}{" "}
                letters
              </div>
            }
            component={AntInput}
            placeholder={"Enter title"}
            maxLength={20}
          />
        </div>
      ))}
    </>
  );
};

export default MarathiLanguageForm;
