import "./index.scss";
import { Empty } from "antd";
import { HTMLConatiner } from "components/HTMLContainer";
import { ImageContainer } from "components/ImageContainer";
import { VideoContainer } from "components/VideosContainer";
import { ActivityContainer } from "components/ActivityContainer";

export const AboutParty = ({ isDataPresent, data, languageId }) => {
  return isDataPresent ? (
    <div>
      <div className="party-imageList">
        {data.images && <ImageContainer images={data.images} />}
      </div>
      <div className="pl-40 pr-40 m-auto">
        <p className="party-heading mb-16">Party information</p>
        <HTMLConatiner
          heading={"Party Bio 1"}
          html={data?.data?.[languageId]?.bio1}
        />
        <HTMLConatiner
          heading={"Party Bio 2"}
          html={data?.data?.[languageId]?.bio2}
        />
      </div>
      {data?.videos?.length ? (
        <div className="party-videoList">
          {data.videos && <VideoContainer videos={data.videos} />}
        </div>
      ) : (
        ""
      )}
      {data.activities?.length ? (
        <div className="pl-40 pr-40 pb-70 max-width-1500 m-auto">
          <p className="party-heading">Recent activities</p>
          <ActivityContainer activities={data.activities} />
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div className="empty-data">
      {<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </div>
  );
};
