import { Form } from "antd";
import React from "react";
import get from "lodash/get";
import FloatLabel from "../FloatLabel";
import { CancelCircle, DropdownIcon } from "../../assets/svgs";
import Select, { components } from "react-select";
import "./MultiSelect.scss";

const FormItem = Form.Item;

const Multi =
  () =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    selectTags,
    submitCount,
    type,
    value,
    heading,
    width,
    optionNameKey,
    optionValueKey,
    suffixIcon,
    disableOptionsOn,
    disabled,
    language = "en",
    ...props
  }) => {
    const touched = get(form?.touched, field?.name);
    const submitted = submitCount > 0;
    const hasError = get(form.errors, field.name);
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;

    const customStyles = {
      placeholder: (provided, state) => ({
        ...provided,
        opacity: "0.3",
        fontFamily: "NeueHaasUnicaRegular",
        fontSize: "16px",
        color: "#131415 !important",
      }),
      control: (base, state) => ({
        ...base,
        border: submittedError || touchedError ? "1px solid #ff4d4f" : "none",
        boxShadow: !hasError && "none",
        width: width || "460px !important",
        minHeight: "46px",
        backgroundColor: "#f4f5f7 !important",
        transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
        fontFamily: "NeueHaasUnicaRegular !important",
        cursor: "pointer",
        ...(state.isDisabled && {
          pointerEvents: "auto",
          cursor: "not-allowed",
        }),
        outline: "none !important",
        "&:hover": {
          boxShadow: !hasError && "0px 0px 0px 1px #1890ff",
        },
      }),
      menu: ({ data, isDisabled, isFocused, isSelected }) => {
        return {
          marginTop: "0 !important",
          backgroundColor: "#f4f5f7 !important",
          boxShadow: "none !important",
          width: width || "460px !important",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTop: "1px solid #b0b1b2 !important",
        };
      },
      option: () => {
        return {
          backgroundColor: "#f4f5f7 !important",
          marginLeft: "10px",
          padding: "5px 0",
          cursor: "pointer",
        };
      },
    };

    const onChange = (val) => {
      form.setFieldValue(field.name, val);
    };

    const onBlur = () => form.setFieldTouched(field.name, true);

    let selectProps = {};
    if (selectOptions) {
      selectProps = {
        dropdownClassName: props.mode,
        showRadioButton: props.showRadioButton,
        getPopupContainer: (trigger) => trigger.parentNode,
      };
    }

    const selectOption = () => {
      const data = selectOptions?.map((option) => ({
        label: option?.["data"]?.[language]?.[optionValueKey],
        value: option?.[optionNameKey],
      }));
      return data;
    };

    const Option = (props) => {
      return (
        <div>
          <components.Option
            {...props}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="fieldOptions">
              <input
                style={{ border: "5px solid black !important" }}
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />
              <span className="checkmark"></span>{" "}
              <label className="ml-25">{props.label}</label>
            </div>
          </components.Option>
        </div>
      );
    };

    const handleRemoveValue = (val) => {
      value = value.filter((e) => e.value !== val);
      if (value.length === 0) {
        value = [];
      }
      onChange(value);
    };

    return (
      <div className="field-container">
        <FloatLabel label={label} val={value}>
          <FormItem
            hasFeedback={
              (hasFeedback && submitted) || (hasFeedback && touched)
                ? true
                : false
            }
            help={submittedError || touchedError ? hasError : false}
            validateStatus={
              submittedError || touchedError ? "error" : "success"
            }
          >
            <Select
              {...field}
              onBlur={onBlur}
              spellCheck="off"
              autoComplete="off"
              hideSearch={true}
              styles={customStyles}
              options={selectOption()}
              isSearchable={false}
              dropdownIndicator={<DropdownIcon />}
              isMulti
              isDisabled={disabled}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={onChange}
              components={{
                MultiValueContainer: () => {
                  return "";
                },
                Option,
                DropdownIndicator: () => {
                  return (
                    <div className="dropdownIndicator">
                      <DropdownIcon />
                    </div>
                  );
                },
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              {...selectProps}
              {...props}
            ></Select>
            {value && value.length >= 1 && (
              <>
                <div className="length-tag">
                  <p>{`${value?.length} ${
                    value?.length === 1
                      ? props.selectName || "category"
                      : props.selectName || "categories"
                  } selected`}</p>
                </div>
                <div className="select-tag-content mt-22">
                  {value?.map((val) => (
                    <div key={val?.value} className="mr-10 select-label-values">
                      <p className="mt-0 mb-0 mr-10 font-black text-xs">
                        {val?.label}
                      </p>
                      {!disabled && (
                        <span
                          className="mt-2 mb-0 cursor-pointer"
                          name={val?.label}
                          onClick={() => handleRemoveValue(val.value)}
                        >
                          <CancelCircle />
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </FormItem>
        </FloatLabel>
      </div>
    );
  };

export const MultiSelect = Multi();
