import axios from "axios";
import Toast from "components/Toast";
import isEmpty from "lodash/isEmpty";
import { GetApis } from "services/apis/common";
import { Api } from "services/config/request";
import { storage } from "services/config/storage";

export const getActiveTabLabel = (INNER_HEADERS, innerHeader) => {
  return INNER_HEADERS.filter(
    (item) => item.label.replace(/\s/g, "")?.toLowerCase() === innerHeader
  )?.[0]?.singular;
};

export const uploadImage = async (file, options) => {
  const data = {
    type: "image",
    extension: "png",
    metadata: "image/png",
  };
  try {
    const response = await GetApis.common.createImage(data);
    if (response?.status === 200) {
      const presignedPostUrl = response?.data?.link;
      let instance = axios.create({
        headers: {
          "Content-Type": file?.type,
          "access-control-allow-origin": "*",
        },
      });
      await instance.put(presignedPostUrl, file, options);
    }
    return response?.data?.id;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const uploadVideo = async (file, options) => {
  const data = {
    type: "video",
    extension: "mp4",
    metadata: "video/mp4",
  };

  try {
    const response = await GetApis.common.createImage(data);
    if (response.status === 200) {
      const presignedPostUrl = response.data.link;
      await axios.put(presignedPostUrl, file, options, {
        headers: {
          "Content-Type": file.type,
          "access-control-allow-origin": "*",
        },
      });
    }
    return response.data.id;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const uploadMultipleImage = async (file, options) => {
  const data = {
    type: "image",
    extension: "png",
    metadata: "image/png",
  };

  try {
    const response = await GetApis.common.createImage(data);
    if (response.status === 200) {
      const presignedPostUrl = response.data.link;
      await axios.put(presignedPostUrl, file, options, {
        headers: {
          "Content-Type": file.type,
          "access-control-allow-origin": "*",
        },
      });
    }
    return response;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const uploadPDF = async (file, options) => {
  const data = {
    type: "pdf",
    extension: "pdf",
    metadata: "application/pdf",
  };
  try {
    const response = await GetApis.common.createImage(data);
    if (response.status === 200) {
      const presignedPostUrl = response.data.link;
      await axios.put(presignedPostUrl, file, options, {
        headers: {
          "Content-Type": file.type,
          "access-control-allow-origin": "*",
        },
      });
    }
    return response.data.id;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const deleteMedia = async (mediaId) => {
  try {
    let response = await Api.deleteRequest(`/media/v1/${mediaId}`);
    if (response.data?.success === true) {
      Toast("success", "", `Media deleted successfully`);
    }
    return response?.data?.success;
  } catch (error) {
    return false;
  }
};

export const beforeUpload = async (file, obj) => {
  let maxHeight = obj?.maxHeight || 9999999;
  let maxWidth = obj?.maxWidth || 9999999;
  let maxFileSize = obj?.fileSize || 10000;

  if (file) {
    const isJpgOrPng =
      file?.type === "image/jpeg" || file?.type === "image/png";

    if (!isJpgOrPng) {
      Toast("error", "You can only upload JPG/PNG file!");
    }
    let isValidPixels;
    if (isJpgOrPng) {
      const imageRatio = await validateImage(file);
      if (imageRatio.width > maxWidth || imageRatio.height > maxHeight) {
        isValidPixels = false;
        Toast("error", "Image resolution is greater then accepted resolution");
      } else {
        isValidPixels = true;
      }
    }
    const isLt2M = file?.size / 1000 <= maxFileSize;
    if (!isLt2M) {
      Toast(
        "error",
        `Image must smaller than ${
          // eslint-disable-next-line no-useless-concat
          obj?.fileSize ? obj.fileSize + " " + "Kb!" : "10MB!"
        }`
      );
    }
    return isJpgOrPng && isLt2M && isValidPixels;
  } else {
    return false;
  }
};

export const validateImage = async (file) => {
  let base64 = await toBase64(file);
  return new Promise(function (resolved, rejected) {
    var image = new Image();

    image.src = base64;
    image.onload = function () {
      resolved({ width: image.width, height: image.height });
    };
  });
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });

export const beforeVideoUpload = (file) => {
  const isJpgOrPng = file?.type === "video/avi" || file?.type === "video/mp4";

  if (!isJpgOrPng) {
    Toast("error", "You can only upload MP4/AVI file!");
  }
  return isJpgOrPng;
};

// Default Image Progress
export const handleImageProgress = ({ setUploadProgress }) => {
  let options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setUploadProgress(percent);
    },
  };
  return options;
};

// Default Image Uploader
export const handleImageChange = async ({
  info,
  params = {},
  setLoading,
  setFieldValue,
  fieldName,
  setImage,
}) => {
  const imageValidation = await beforeUpload(info.file.originFileObj, params);
  if (info.file.status === "uploading") {
    setLoading(true);
    return;
  }
  if (imageValidation) {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setImage(imageUrl);
      setLoading(false);
    });
  } else {
    setLoading(false);
  }
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl?.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const getCategoryTags = (value, data, language) => {
  let obj = [];
  for (let i = 0; i < value?.length; i++) {
    for (let j = 0; j < data?.length; j++) {
      if (value[i]?.value === data[j]?.id) {
        obj = [
          ...obj,
          {
            label: data[j]?.["data"]?.[language]?.tagName,
            value: data[j]?.id,
          },
        ];
      }
    }
  }
  return obj;
};

// export const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

export const capitalizeTitle = (title) => {
  const replacedTitle = title.replace(/([a-z])([A-Z])/g, "$1 $2");
  const firstWord = replacedTitle.split(" ")[0];
  const lower = firstWord.toLowerCase();
  const capitalizedFirstWord = lower.charAt(0).toUpperCase() + lower.slice(1);
  return `${capitalizedFirstWord} ${replacedTitle
    .split(" ")
    .slice(1)
    .join(" ")}`;
};
export const generateFilterUrl = (filters) => {
  delete filters.multiSelectFilters;

  let mappings = {},
    params = [],
    url = "";
  if (!isEmpty(filters)) {
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((filterKey) => {
      if (filters?.[filterKey] || filters?.[filterKey] === 0) {
        mappings = { ...mappings, ...filters };
      } else {
        delete filters[filterKey];
        mappings = { ...filters };
      }
      if (Array.isArray(filters?.[filterKey])) {
        if (isEmpty(filters?.[filterKey])) delete filters[filterKey];
        mappings = { ...filters };
      }
    });
    params = createQueryParamUrl(mappings).join("");
    let order;
    let query;
    if (mappings.order) {
      order =
        "order=" +
        '["' +
        mappings.order[0] +
        '"' +
        ',"' +
        mappings.order[1] +
        '"]';
    }
    if (mappings.query) {
      query =
        "query=" +
        '["' +
        mappings.query[0] +
        '"' +
        ',"' +
        mappings.query[1] +
        '"]';
    }
    url = mappings?.order?.[1]
      ? params + order
      : mappings?.query?.[1]
      ? params + query
      : params;
  }
  const data = {
    urlParams: url,
    metaData: mappings,
  };
  return data;
};

export const createQueryParamUrl = (filterKeys) => {
  let params = [];
  try {
    let filterKeysArray = Object.keys(filterKeys);
    // eslint-disable-next-line array-callback-return
    filterKeysArray.map((key, index) => {
      if (filterKeys[key] !== null && !Array.isArray(filterKeys[key])) {
        params.push(`${key}=${filterKeys[key]}${"&"}`);
      }
    });
  } catch (e) {}
  return params;
};

export const fetchAccessToken = () => {
  const isRememberUserEnabled = storage.fetch.rememberUser();
  return isRememberUserEnabled
    ? storage.fetch.authToken()
    : storage.fetch.sessionToken();
};

export const fetchCMSLanguage = () => {
  return storage.fetch.language();
};

export const getSerializedData = (page = 0, data) => {
  const getSerialNumber = (number) => {
    if (number < 10) {
      number = `0${number}`;
    }
    return number;
  };
  let list = data?.map((e, i) => {
    return { ...e, sNo: getSerialNumber(page * 10 + (i + 1)) };
  });
  return list;
};
/*
 * @param {payload after updating form} payload
 * @param {*payload before updating form} oldData
 * @returns fields which is changed or added recently
 */
export function getUpdatedData(payload = {}, oldData = {}) {
  let data = {};
  for (const keys in payload) {
    if (JSON.stringify(payload[keys]) !== JSON.stringify(oldData[keys])) {
      data[keys] = payload[keys];
      delete payload[keys];
    } else {
      delete payload[keys];
    }
  }
  return { ...data, ...payload };
}

export const extractKeysFromArray = (array = [], key = "value") => {
  return array.map((el) => {
    return el[key];
  });
};

export const compareArrays = (selectedValues = [], list = []) => {
  var result = list.filter(function (o1) {
    // filter out (!) items in result2
    return selectedValues.some(function (o2) {
      return o1?.id === o2; // assumes unique id
    });
  });

  return result;
};
