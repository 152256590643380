import "./index.scss";
import moment from "moment";
import { Row, Col } from "antd";
import { ENGLISH } from "constants/language";

export const ActivityContainer = ({ activities }) => {
  return (
    <Row
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      className="activity-container"
    >
      {activities?.map((activity, i) => (
        <div className="activity-wrapper" key={i}>
          <p>Activity {i + 1}</p>
          <Row>
            <img src={activity?.media?.link} alt="img" />
            <Col>
              <p className="activity-title">
                {activity?.data?.[ENGLISH]?.title}
              </p>
              <Row span={1}>
                {/* <Col className="para" span={8}>
                  By: Rohit Kappor
                </Col> */}
                <Col className="para">
                  Date created: {moment(activity.createdAt).format("DD/MM/YY")}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ))}
    </Row>
  );
};
