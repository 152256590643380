import { ENGLISH, HINDI, MARATHI } from "constants/language";
import {
  returnCategoryColumns,
  returnProductColumns,
  returnOrdersColumns,
} from "./columns";
import {
  MERCHANDISE_PRODUCT_ATTRIBUTES,
  MERCHANDISE_PRODUCT_COLORS,
  MERCHANDISE_PRODUCT_SIZES,
  MERCHANDISE_PRODUCT_STYLES,
} from "constants/data"
import { COLUMN_ACTIONS } from "constants/dummyData";

export const INNER_HEADERS = [
  {
    label: "Category",
    singular: "Category",
    title: {
      en: "Category",
      hi: "श्रेणी",
      mr: "श्रेणी",
    },
  },
  {
    label: "Products",
    singular: "Products",
    title: {
      en: "Products",
      hi: "उत्पाद",
      mr: "उत्पादन",
    },
  },
  {
    label: "Orders",
    singular: "Order",
    title: {
      en: "Orders",
      hi: "उत्पाद",
      mr: "उत्पादन",
    },
  },
];

export const TABS = {
  CATEGORY: "category",
  PRODUCTS: "products",
  ORDERS: "orders",
};

export const buttonField = (innerTab, showMemberField) => {
  switch (innerTab) {
    case TABS.CATEGORY:
      return "Category";
    case TABS.PRODUCTS:
      return "Products";
    case TABS.ORDERS:
      return "Orders";
    default:
      return null;
  }
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  languageId,
  filters,
  performColumnAction
) => {
  let args = {
    onSort,
    sortColumn,
    languageId,
    filters,
    performColumnAction,
  };

  switch (innerTab) {
    case TABS.CATEGORY:
      return returnCategoryColumns(args);
    case TABS.PRODUCTS:
      return returnProductColumns(args);
    case TABS.ORDERS:
      return returnOrdersColumns(args);
    default:
      return returnCategoryColumns(args);
  }
};

export const METADATA = {
  category: ["utils"],
};

export const getDropdown = (array) => {
  const data = array?.map((e) => {
    return {
      data: {
        en: {
          name: e?.name,
        },
      },
      id: e?.name,
    };
  });
  return data;
};

export const createPayload = (innerTab, values) => {
  switch (innerTab) {
    case TABS.CATEGORY: {
      return {
        data: values.data,
        mediaId: values?.mediaId,
        displayOrder: +values?.displayOrder,
      };
    }
    case TABS.PRODUCTS: {
      return {
        data: values?.data,
        categories: values?.categories?.map((item) => item.value),
        variants: values?.variants?.map((item) => ({
          data: item?.data,
          images: item?.images?.filter((url) => !!url),
          inventoryCount: item?.inventoryCount,
          isDefault: item?.isDefault,
          pointPrice: item?.pointPrice
        })),
      }
    }
    case COLUMN_ACTIONS.ACCEPTED: {
      return {
        orderStatus: COLUMN_ACTIONS.ACCEPTED
      }
    }
    case COLUMN_ACTIONS.REJECTED: {
      return {
        orderMeta: {
          comments: values?.comments,
        },
        orderStatus: COLUMN_ACTIONS.REJECTED
      }
    }
    case COLUMN_ACTIONS.SHIPPED: {
      return {
        orderMeta: {
          courierId: values?.courierId,
          courierTrackingId: values?.courierTrackingId,
          courierDeliveryEstimatedDate: values?.courierDeliveryEstimatedDate
        },
        orderStatus: COLUMN_ACTIONS.SHIPPED
      }
    }
    case COLUMN_ACTIONS.DELIVERED: {
      return {
        orderStatus: COLUMN_ACTIONS.DELIVERED
      }
    }
    default: {
      return null;
    }
  };
};

export const handlePutRequest = (currentValues, prevValues) => {
  let payload = {};
  if (prevValues.access === undefined) {
    prevValues.access = [];
  }

  for (let keys in currentValues) {
    for (let currKeys in prevValues) {
      if (keys === currKeys) {
        if (
          JSON.stringify(currentValues[keys]) !==
          JSON.stringify(prevValues[keys])
        ) {
          payload[keys] = currentValues[keys];
        }
      }
    }
  }
  return currentValues;
};

export const getDropDowns = (array) => {
  let validArray = array?.filter((item) => item?.data !== null);

  return validArray?.map((item) => {
    return {
      id: item.id,
      english: item.data?.[ENGLISH].name,
      hindi: item?.data?.[HINDI].name,
      marathi: item?.data?.[MARATHI].name,
    };
  });
};

export const getAttributes = (variants = []) => {
  const attributes = {};
  const colors = {};
  const sizes = {};
  const styles = {};
  if (variants.length) {
    variants.forEach((item) => {
      if (item?.data?.color) {
        const attr = MERCHANDISE_PRODUCT_ATTRIBUTES.find((attr) => attr.id === 'COLOR')
        attributes[attr.id] = {
          label: attr.data.en.name,
          value: attr.id
        }
        const color = MERCHANDISE_PRODUCT_COLORS.find((color) => color.id === item.data.color.name)
        if (color?.id) {
          colors[color.id] = {
            label: color.data.en.name,
            value: color.id
          }
        }
      }
      if (item?.data?.style) {
        const attr = MERCHANDISE_PRODUCT_ATTRIBUTES.find((attr) => attr.id === 'STYLE')
        attributes[attr.id] = {
          label: attr.data.en.name,
          value: attr.id
        }
        const style = MERCHANDISE_PRODUCT_STYLES.find((style) => style.id === item.data.style)
        if (style?.id) {
          styles[style.id] = {
            label: style.data.en.name,
            value: style.id
          }
        }
      }
      if (item?.data?.size) {
        const attr = MERCHANDISE_PRODUCT_ATTRIBUTES.find((attr) => attr.id === 'SIZE')
        attributes[attr.id] = {
          label: attr.data.en.name,
          value: attr.id
        }
        const size = MERCHANDISE_PRODUCT_SIZES.find((size) => size.id === item.data.size)
        if (size?.id) {
          sizes[size.id] = {
            label: size.data.en.name,
            value: size.id
          }
        }
      }
    })
  }

  return {
    colors: Object.values(colors),
    sizes: Object.values(sizes),
    styles: Object.values(styles),
    attributes: Object.values(attributes),
  }
}
