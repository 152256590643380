import { Layout, Skeleton, Table } from "antd";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import AppHeader from "../../components/Header";
import "../common.scss";
import { Roles_SCHEMA, getRolesInitialValues } from "./validate";
import TableToolbar from "../../components/TableToolbar";
import InnerHeader from "../../components/InnerHeader";
import {
  createPayload,
  getColumns,
  getDropdown,
  INNER_HEADERS,
  TABS,
} from "./utils";
import { CustomPagination } from "components/CustomPagination";
import AddRoleForm from "forms/AddRoleForm/AddRoleForm";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as RootActions from "../../store/root/actions";
import { fetchCMSLanguage, generateFilterUrl } from "utils/helpers";

const { Header, Content } = Layout;

const Roles = (props) => {
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [innerTab, setInnerTab] = useState(TABS.ROLES);
  const [sortColumn, setSortColumn] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [activeRow, setActiveRow] = useState({});
  const [languageId, setLanguageId] = useState(fetchCMSLanguage());

  const dropdownData = props?.dropdownData;

  useEffect(() => {
    fetchData(innerTab, getDataFromRedux("filters"));
    setSortColumn("createdAt");
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerTab]);

  useEffect(() => {
    if (formModalVisible && !isEditing) {
      //reset formData when Add modal opens
      setInitialValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModalVisible]);

  useEffect(() => {
    props.fetchDropdownData("utils");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (innerTab, payload, page = 0) => {
    let filters;
    if (payload && payload.filters) filters = payload.filters;
    else filters = { ...getDataFromRedux("filters") };
    let { urlParams } = generateFilterUrl(filters);
    props.fetchDataForInnerTabs("roles", urlParams);
  };

  const getDataFromRedux = useCallback(
    (key = "list") => {
      //here the `key` argument should match with the store's data key
      return props.roles?.[key];
    },
    [props.roles]
  );
  const setInitialValues = () => {
    let values = getRolesInitialValues();
    setFormInitialValues(values);
  };

  const openModal = () => {
    setFormModalVisible(true);
  };

  const handlePageChange = (page) => {
    if (page) {
      let filterUpdates = {
        page: page - 1,
      };
      applyFilters(filterUpdates);
    }
  };

  const handleSort = (sortOrder, sortKey) => {
    let sortParams = { order: [sortKey, sortOrder] };
    applyFilters(sortParams);
    // setSortedData(sortedData);
    setSortColumn(sortKey);
  };

  const handleSearch = (query) => {
    if (query.length && query.length > 1) {
      applyFilters({ search: query });
    } else if (!query.length) {
      applyFilters({ search: query });
    }
  };

  const applyFilters = (updates = {}) => {
    let apiFilters = {
      ...getDataFromRedux("filters"),
      ...updates,
    };

    props.updateFilters(innerTab, apiFilters);
    fetchData(innerTab, { filters: apiFilters }, apiFilters.page);
  };

  return (
    <div className="wrapper">
      <Layout>
        <Header
          style={{
            position: "sticky",
            zIndex: 99,
            top: 0,
            width: "100%",
          }}
        >
          <AppHeader language={(key) => setLanguageId(key)} />
          <InnerHeader
            data={INNER_HEADERS}
            activeCount={props[innerTab]?.count}
            onclick={setInnerTab}
            languageId={languageId}
          />
        </Header>
        <Content className="site-layout" style={{ padding: "0 50px" }}>
          <Layout>
            <Layout className="site-layout">
              <Content style={{ overflow: "initial" }}>
                <TableToolbar
                  innerTab={innerTab}
                  ctaLabel={"Role"}
                  onClickAdd={openModal}
                  showFilter={true}
                  onSearch={handleSearch}
                  query={props[innerTab]?.filters?.search || ""}
                />
                <div className="container pl-40 pr-40">
                  <Table
                    className={"is_editable"}
                    columns={getColumns(
                      innerTab,
                      handleSort,
                      sortColumn,
                      async (actionType, rowId, value) => {
                        switch (actionType) {
                        }
                      }
                    ).map((column, index) => {
                      return {
                        ...column,
                        ...(props.loading && {
                          render: () => (
                            <Skeleton
                              key={index}
                              title={true}
                              paragraph={false}
                            />
                          ),
                        }),
                      };
                    })}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          let values = getRolesInitialValues(record);
                          setIsEditing(true);
                          openModal();
                          setFormInitialValues(values);
                          setActiveRow(record);
                        },
                      };
                    }}
                    dataSource={getDataFromRedux()}
                    rowKey={"id"}
                    pagination={false}
                  />
                  <CustomPagination
                    current={props[innerTab]?.filters?.page + 1}
                    totalCount={props[innerTab]?.count}
                    onChange={handlePageChange}
                  />
                </div>
                <Modal
                  width="500px"
                  title={
                    <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
                      {isEditing ? "Edit Role" : "New Role"}
                    </p>
                  }
                  destroyOnClose
                  onCancel={() => {
                    setIsEditing(false);
                    setFormModalVisible(false);
                  }}
                  visible={formModalVisible}
                >
                  <div
                    style={{
                      width: "85%",
                      marginLeft: "40px",
                    }}
                  >
                    <Formik
                      enableReinitialize
                      initialValues={formInitialValues}
                      onSubmit={(values, { setSubmitting, validateForm }) => {
                        const payload = createPayload(innerTab, values);
                        let request;
                        if (isEditing) {
                          request = props.updateData(
                            innerTab,
                            activeRow.id,
                            payload
                          );
                        } else request = props.createData("roles", payload);
                        request
                          .then((res) => {
                            if (res?.status === 200) {
                              setIsEditing(false);
                              setSortColumn(null);
                              setFormModalVisible(false);
                              fetchData(innerTab, getDataFromRedux("filters"));
                            }
                          })
                          .catch((e) => {
                            alert("ss");
                          })
                          .finally((e) => {
                            setSubmitting(false);
                          });
                        setSubmitting(false);
                      }}
                      validationSchema={Roles_SCHEMA}
                    >
                      {(rest) => (
                        <AddRoleForm
                          access={getDropdown(dropdownData?.["roleAccess"])}
                          submitButtonLabel={`${
                            isEditing ? "Update " : "Add Role"
                          } `}
                          {...rest}
                        />
                      )}
                    </Formik>
                  </div>
                </Modal>
              </Content>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

Roles.propTypes = {};

const mapStateToProps = ({ roles, dropdownData }) => ({
  roles,
  dropdownData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataForInnerTabs: bindActionCreators(
    RootActions.fetchDataForInnerTabs,
    dispatch
  ),
  fetchDataForTable: bindActionCreators(
    RootActions.fetchDataForTable,
    dispatch
  ),
  createData: bindActionCreators(RootActions.createData, dispatch),
  updateFilters: bindActionCreators(RootActions.updateFilters, dispatch),
  updateData: bindActionCreators(RootActions.updateData, dispatch),
  fetchDropdownData: bindActionCreators(RootActions.getDropdownData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
