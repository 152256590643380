import { ENGLISH, HINDI, MARATHI } from "constants/language";
import moment from "moment";
import {
  returnLeadersColumns,
  returnAboutColumns,
  returnTimelineColumns,
  returnMagazineColumns,
  returnManifestoColumns,
  returnOrganizationColumns,
  returnSchemesColumns,
  returnOrganizationalStructureColumn,
  returnOrganizationalMembersColumn,
  returnMLA_MLCcolumns,
} from "./columns";

export const INNER_HEADERS = [
  {
    label: "Leaders",
    singular: "Leader",
    title: {
      en: "Leaders",
      hi: "नेता",
      mr: "नेते",
    },
  },
  {
    label: "About party",
    singular: "About party",
    title: {
      en: "About party",
      hi: "पार्टी के बारे में",
      mr: "पार्टी बद्दल",
    },
  },
  {
    label: "Timeline",
    singular: "Timeline",
    title: {
      en: "Timeline",
      hi: "समयसीमा",
      mr: "समयोचितपणा",
    },
  },
  {
    label: "Magazine",
    singular: "Magazine",
    title: {
      en: "Magazine",
      hi: "समयसीमा",
      mr: "समयोचितपणा",
    },
  },
  {
    label: "Manifesto",
    singular: "Manifestos",
    title: {
      en: "Manifesto",
      hi: "घोषणापत्र",
      mr: "जाहीरनामा",
    },
  },
  {
    label: "Frontal organisations",
    singular: "Frontal organisation",
    title: {
      en: "Frontal organisations",
      hi: "ललाट संगठन",
      mr: "फ्रंटल संघटना",
    },
  },
  {
    label: "Organisational structure",
    singular: "Organisational structure",
    title: {
      en: "Organisational structure",
      hi: "भूमिकाएँ",
      mr: "भूमिका",
    },
  },
  {
    label: "MLA & MLC",
    singular: "MLA_MLC",
    title: {
      en: "MP / MLA / MLC",
      hi: "एमपी / एमएलए / एमएलसी",
      mr: "खासदार / आमदार / आमदार",
    },
  },
  {
    label: "Schemes",
    singular: "Schemes",
    title: {
      en: "Schemes",
      hi: "योजना",
      mr: "योजना",
    },
  },
];

export const TABS = {
  LEADERS: "leaders",
  TIMELINE: "timeline",
  ABOUT_PARTY: "aboutparty",
  MAGAZINE: "magazine",
  MANIFESTO: "manifesto",
  ORGANIZATION: "frontalorganisations",
  SCHEMES: "schemes",
  ORGANIZATIONALSTRUCTURE: "organisationalstructure",
  MLAandMLC: "mla_mlc",
  ORGANIZATIONALMEMBERS: "organizationalmembers",
  MLA_MLCMEMBERS: "mlaNmlcmembers",
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  handleOrganizationMemberForm,
  handleSubTable,
  languageId,
  performColumnAction,
  deleteColumn
) => {
  let args = {
    onSort,
    sortColumn,
    performColumnAction,
    languageId,
    handleOrganizationMemberForm,
    handleSubTable,
    deleteColumn,
  };
  switch (innerTab) {
    case TABS.LEADERS:
      return returnLeadersColumns(args);
    case TABS.ABOUT:
      return returnAboutColumns(args);
    case TABS.TIMELINE:
      return returnTimelineColumns(args);
    case TABS.MAGAZINE:
      return returnMagazineColumns(args);
    case TABS.MANIFESTO:
      return returnManifestoColumns(args);
    case TABS.ORGANIZATION:
      return returnOrganizationColumns(args);
    case TABS.SCHEMES:
      return returnSchemesColumns(args);
    case TABS.ORGANIZATIONALSTRUCTURE:
      return returnOrganizationalStructureColumn(args);
    case TABS.MLAandMLC:
      return returnMLA_MLCcolumns(args);
    default:
      return returnLeadersColumns(args);
  }
};
export const getSubColumns = (
  innerTab,
  handleOrganizationMemberForm,
  deleteColumn,
  languageId
) => {
  switch (innerTab) {
    case TABS.ORGANIZATIONALMEMBERS:
      return returnOrganizationalMembersColumn(
        handleOrganizationMemberForm,
        deleteColumn,
        languageId
      );
    default:
      return returnOrganizationalMembersColumn(
        handleOrganizationMemberForm,
        deleteColumn,
        languageId
      );
  }
};

export const createPayload = (innerTab, values) => {
  switch (innerTab) {
    case TABS.LEADERS: {
      let payload = {
        data: values.data,
        order: +values?.order,
        experience: values?.experience,
        wins: values?.wins,
        about: values?.about,
        isActive: values?.isActive,
        images: values?.imagesMediaId?.filter((e) => e !== ""),
        successStoryImage: values?.successStoryImage,
        successStorySummary: values?.successStorySummary,
        videos: values?.videos?.map((e) => {
          delete e.link;
          delete e.thumbnaillink;
          e.date = moment(e.date).format("YYYY-MM-DD");
          return e;
        }),
      };
      let achievements = values?.achievements
        // eslint-disable-next-line array-callback-return
        ?.map((e) => {
          if (
            e.mediaId !== "" &&
            e.awardDate !== undefined &&
            e?.data?.[ENGLISH].authority !== "" &&
            e?.data?.[ENGLISH].name !== ""
          ) {
            delete e?.link;
            e.awardDate = moment(e.awardDate).format("YYYY-MM-DD");
            return e;
          }
        })
        .filter((e) => e !== undefined);
      payload["achievements"] = achievements;
      return payload;
    }
    case TABS.MANIFESTO: {
      return {
        date: moment(values.date).format("YYYY-MM-DD"),
        time: moment(values.time).format("HH:mm:ss"),
        data: values?.data,
      };
    }
    case TABS.TIMELINE: {
      return {
        data: values?.data,
        date: moment(values.date).format("YYYY-MM-DD"),
        year: values.year,
        coverImageId: values?.imagesMediaId?.filter((e) => e !== ""),
        urlLink: values.urlLink,
      };
    }
    case TABS.MAGAZINE: {
      let payload = {
        data: values?.data,
        publishedDate: moment(values.publishedDate).format("YYYY-MM-DD"),
        year: values.year,
        images: values?.imagesMediaId?.filter((e) => e !== ""),
        isNotification: values.isNotification,
      };
      if (typeof values.pdf === "string" && !values.pdf.startsWith("https")) {
        payload.pdf = values.pdf
      }
      return payload;
    }
    case TABS.SCHEMES: {
      return {
        mediaId: values.mediaId,
        dateAdded: moment(values.dateAdded).format("YYYY-MM-DD"),
        data: values.data,
      };
    }
    case TABS.ORGANIZATION: {
      return {
        data: values.data,
        order: +values?.order,
        images: values?.imagesMediaId?.filter((e) => e !== ""),
        activities: values.activities.map((e) => {
          delete e.link;
          delete e.webUrl;
          return e;
        }),
      };
    }
    case TABS.ABOUT_PARTY: {
      return {
        id: values.id,
        data: values.data,
        images: values?.imagesMediaId?.filter((e) => e !== ""),
        videos: values?.videos?.map((e) => {
          delete e.link;
          delete e.thumbnaillink;
          e.date = moment(e.date).format("YYYY-MM-DD");
          return e;
        }),
        activities: values?.activities?.map((e) => {
          delete e.link;
          return e;
        }),
      };
    }
    case TABS.ORGANIZATIONALSTRUCTURE: {
      return {
        data: values.data,
        order: +values.order,
      };
    }
    case TABS.ORGANIZATIONALMEMBERS: {
      return {
        id: values.id,
        data: values.data,
        phoneNumbers: values.phoneNumbers,
        organisationalStructureId: values.organisationalStructureId,
        order: +values.order,
      };
    }
    case TABS.MLAandMLC: {
      return {
        data: values.data,
        representativeType: values.representativeType,
        order: +values.order,
      };
    }
    case TABS.MLA_MLCMEMBERS: {
      return {
        id: values.id,
        data: values.data,
        phoneNumber: values.phoneNumbers,
        mlaNMlcId: values.mlaNMlcId,
        order: +values.order,
      };
    }
    default:
      return null;
  }
};

export const getPropField = (innerTab) => {
  switch (innerTab) {
    case TABS.LEADERS:
      return "leaders";
    case TABS.ABOUT:
      return "about";
    case TABS.TIMELINE:
      return "timelines";
    case TABS.MAGAZINE:
      return "magazine";
    case TABS.MANIFESTO:
      return "manifestos";
    case TABS.ORGANIZATION:
      return "frontalorganisations";
    case TABS.ORGANIZATIONALSTRUCTURE:
      return "organisationalstructure";
    case TABS.MLAandMLC:
      return "mla_mlc";
    case TABS.SCHEMES:
      return "schemes";
    default:
      return null;
  }
};

export const buttonField = (innerTab, showMemberField) => {
  switch (innerTab) {
    case TABS.LEADERS:
      return "Leader";
    case TABS.ABOUT_PARTY:
      return "Party bio";
    case TABS.TIMELINE:
      return "Timeline";
    case TABS.MAGAZINE:
      return "Magazine";
    case TABS.MANIFESTO:
      return "Manifesto";
    case TABS.ORGANIZATION:
      return "Organization";
    case TABS.ORGANIZATIONALSTRUCTURE:
      return "Organization";
    case TABS.MLAandMLC:
      return "Organization";
    case TABS.SCHEMES:
      return "Schemes";
    default:
      return null;
  }
};

export const METADATA = {
  leaders: ["utils"],
  aboutparty: ["utils"],
  schemes: ["utils"],
  mla_mlc: ["utils"],
};

export const getDropDowns = (array) => {
  return array?.map((e) => {
    return {
      id: e?.id,
      english: e?.data?.[ENGLISH],
      hindi: e?.data?.[HINDI],
      marathi: e?.data?.[MARATHI],
    };
  });
};
