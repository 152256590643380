import * as Yup from "yup";

export const Merchandise_Category_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Category name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Category name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Category name is required"),
    }),
  }),
  displayOrder: Yup.string()
    .required("Order is required")
    .test("displayOrder", "Please enter a valid integer", function (value) {
      const { displayOrder } = this.parent;
      const str = displayOrder?.toString();
      if (str?.includes("-") || str?.includes("+") || str?.includes(".")) {
        return false;
      } else if (isNaN(+displayOrder)) {
        return false;
      } else {
        return true;
      }
    }),
  mediaId: Yup.string().required("Image is required"),
});

export const PRODUCT_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Product Name is required"),
      description: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Product Name is required"),
      description: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Product Name is required"),
      description: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
  }),
  categories: Yup.array()
    .min(1, "Category is required")
    .required("Category is required"),
  attributes: Yup.array()
    .min(1, "Attributes is required")
    .required("Attributes is required"),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        pointPrice: Yup.number().required("Point Price is required"),
        images: Yup.array()
          .of(Yup.string())
          .test(
            "images",
            "Please upload at-least one image",
            function (value) {
              return value?.some((item) => !!item);
            }
          ),
      })
    )

});

export const PRODUCT_UPDATE_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Product Name is required"),
      description: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Product Name is required"),
      description: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Product Name is required"),
      description: Yup.string()
        .test("about", "Character length must be less then 20,000", (val) => {
          if (val?.length && val?.length > 20000) {
            return false;
          } else {
            return true;
          }
        })
        .required("Description is required"),
    }),
  }),
  categories: Yup.array()
    .min(1, "Category is required")
    .required("Category is required"),
})

export const PRODUCT_VARIANT_UPDATE_SCHEMA = Yup.object().shape({
  pointPrice: Yup.number(),
  images: Yup.array()
    .of(Yup.string())
    .test(
      "images",
      "Please upload at-least one image",
      function (value) {
        return value?.some((item) => !!item);
      }
    ),
  inventoryCount: Yup.number()
});

export const ORDER_REJECT_SCHEMA = Yup.object().shape({
  comments: Yup.string()
    .test("comments", "Character length must be less then 20,000", (val) => {
      if (val?.length && val?.length > 20000) {
        return false;
      } else {
        return true;
      }
    })
    .required("Comments are required"),
});

export const ORDER_SHIP_SCHEMA = Yup.object().shape({
  courierId: Yup.string().required("Courier partner name is required"),
  courierTrackingId: Yup.string().required("Courier tracking id is required"),
  courierDeliveryEstimatedDate: Yup.date()
    .typeError("Time is required")
    .required("Time is required"),
});

export const getMerchandiseCategoryInitialValues = (values = {}) => ({
  data: values.data || {
    en: {
      name: "",
    },
    hi: {
      name: "",
    },
    mr: {
      name: "",
    },
  },
  mediaId: values?.mediaId || "",
  mediaUrl: values?.mediaUrl || undefined,
  displayOrder: values?.displayOrder || "",
});

export const getProductInitialValues = (values = {}) => {
  const categories = values?.categories?.map((item) => ({
    label: item?.data?.en?.name,
    value: item.id,
  }))
  return ({
    data: values.data || {
      en: {
        name: "",
        description: values?.description || undefined,
      },
      hi: {
        name: "",
        description: values?.description || undefined,
      },
      mr: {
        name: "",
        description: values?.description || undefined,
      },
    },
    categories: categories || [],
    attributes: values?.attributes || [],
  })
};

export const getProductDetailInitialValues = (values = {}) => {
  const categories = values?.categories?.map((item) => ({
    label: item?.data?.en?.name,
    value: item.id,
  }))
  return ({
    data: values.data || {
      en: {
        name: "",
        description: values?.description || undefined,
      },
      hi: {
        name: "",
        description: values?.description || undefined,
      },
      mr: {
        name: "",
        description: values?.description || undefined,
      },
    },
    categories: categories || [],
  })
};

export const getProductVariantInitialValues = (values = {}) => {
  return ({
    images: populateMediaId(values?.contentMedia),
    imageList: populateImagesList(values?.contentMedia),
    inventoryCount: values?.inventoryCount || 0,
  })
};

export const getOrderRejectInitialValues = (values = {}) => {
  return ({
    comments: values?.comments || "",
  })
};

export const getOrderShipInitialValues = (values = {}) => {
  return ({
    courierId: values?.courierId || undefined,
    courierTrackingId: values?.courierTrackingId || "",
    courierDeliveryEstimatedDate: values?.courierDeliveryEstimatedDate || undefined,
  })
};

const populateImagesList = (imagesList = []) => {
  let remainingSpace = 5 - imagesList?.length; //TOTAL MAX IMAGES ARE 5
  let arr = [];
  arr = imagesList?.map((item) => item?.media?.link);
  // const data = await Promise.all(arr);
  let i = 1;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};

const populateMediaId = (mediaId = []) => {
  let remainingSpace = 5 - mediaId?.length; //TOTAL MAX IMAGES ARE 5
  let arr = [];
  arr = mediaId?.map((item) => item?.media?.id);
  let i = 1;
  while (i <= remainingSpace) {
    arr.push("");
    i++;
  }
  return arr;
};
