import { Button, Row, Upload, Col, Progress } from "antd";
import { FastField, Field, Form } from "formik";
import React, { useState } from "react";
import { RTEditor } from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
} from "../../utils/helpers";
import Date from "components/Date/date";
import { useEffect } from "react";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import FormMenu from "components/FormMenu";
import { UploadButton } from "components/UploadButtons";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import ImageEditor from "components/ImageEditor";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";

const AddSchemesForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  department,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [Image, setImage] = useState(values?.media?.link);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [language, setLanguage] = useState(ENGLISH);
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;
  const hindiValues = values?.data?.[HINDI];
  const marathiValues = values?.data?.[MARATHI];

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    values?.media?.link && setImage(values.media.link);
  }, [values?.media?.link]);

  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const handleChange = (info) => {
    let imageValidation = beforeUpload(info.file.originFileObj);
    hanldeProgress();

    if (imageValidation) {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mt-24 field-label">Cover Image</p>
              {Image ? (
                <ImageEditor
                  image={Image}
                  onDelete={() => {
                    setFieldValue("mediaId", "");
                    setImage("");
                    setUploadprogress(0);
                  }}
                  isEditing={uploadprogress}
                  handleEditing={(status) => {
                    if (status && uploadprogress) {
                      setUploadprogress(0);
                    }
                  }}
                  onSave={async (url) => {
                    let file = dataURLtoFile(url, `image_${Math.random()}.png`);
                    let response = await uploadImage(file, hanldeProgress());
                    if (response) {
                      setFieldValue("mediaId", response);
                      setImage(url);
                      setLoading(false);
                    }
                  }}
                  width={380}
                />
              ) : (
                <Upload
                  name="mediaId"
                  listType={`picture-card ${
                    hasError("mediaId") ? "error" : ""
                  }`}
                  className="picture-uploader"
                  showUploadList={false}
                  // action={handleUpload}
                  beforeUpload={beforeUpload}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleChange}
                  progress={
                    <Progress percent={uploadprogress} showInfo={true} />
                  }
                >
                  {<UploadButton loading={loading} />}
                </Upload>
              )}
              {uploadprogress === 100 && !hasError("mediaId") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadprogress > 0 && uploadprogress < 100 && (
                <div className="progress-bar">
                  <Progress percent={uploadprogress} showInfo={true} />
                </div>
              )}
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {hasError("mediaId")}
              </div>
              <Row style={{ justifyContent: "space-between" }}>
                <Col span={11}>
                  <p className="mt-2 field-label">Date added</p>
                  <Field
                    defaultValue={values.dateAdded}
                    name="dateAdded"
                    format="DD/MM/YY"
                    component={Date}
                    placeholder={"dd/mm/yy"}
                  />
                </Col>
              </Row>
              <p className="mb-8 field-label">Title</p>
              <FastField
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title"}
                maxLength={50}
              />
              <p className="mt-10 field-label">Department</p>
              <Field
                value={values.department}
                name="data[en].department"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.department?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter department"}
                maxLength={50}
              />
              <p className="mb-8 field-label">Short Summary</p>
              <Field
                value={values.shortSummary}
                name="data.en.shortSummary"
                type="text"
                component={AntTextArea}
                maxLength={100}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short summary about the content"}
              />
              <p className="mb-8 field-label">Scheme details</p>
              <Field
                value={values?.schemaDetails}
                name="data[en].schemeDetails"
                type="editor"
                component={RTEditor}
                characterLimit={20000}
                placeholder={"Enter details here"}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={hindiValues} department={department} />
          ) : (
            <MarathiLanguageForm
              values={marathiValues}
              department={department}
            />
          )}
          <Button
            loading={isSubmitting}
            className={"submit-btn mt-40"}
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddSchemesForm;
