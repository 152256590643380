import { ENGLISH, HINDI, MARATHI } from "constants/language";
import {
  returnCadreColumns,
  returnCommonCadreForm,
  returnCouncilColumns,
  returnDesignationColumns,
  returnDistrictColumns,
  returnDivisionColumns,
  returnPanchyatColumn,
  returnTalukaColumns,
  returnVidhanSabhaColumn,
  returnWardsColumn,
} from "./columns";

export const INNER_HEADERS = [
  {
    label: "cadre_organization",
    singular: "Organization ",
    title: {
      en: "Organization",
      hi: "नगर पालिका परिषद",
      mr: "नगरपरिषद",
    },
  },
  {
    label: "cadre_committee",
    singular: "Committee ",
    title: {
      en: "Committee",
      hi: "समिति",
      mr: "समिती",
    },
  },
  {
    label: "cadre_post",
    singular: "Post ",
    title: {
      en: "Post",
      hi: "पोस्ट",
      mr: "पोस्ट",
    },
  },
  {
    label: "cadre_state",
    singular: "State ",
    title: {
      en: "State",
      hi: "राज्य",
      mr: "राज्य",
    },
  },
  {
    label: "cadre_divisions",
    singular: "Division",
    title: {
      en: "Division",
      hi: "विभाजन",
      mr: "विभागणी",
    },
  },
  {
    label: "cadre_districts",
    singular: "District",
    title: {
      en: "District",
      hi: "जिला",
      mr: "जिल्हा",
    },
  },
  {
    label: "cadre_taluka",
    singular: "Taluka",
    title: {
      en: "Taluka",
      hi: "तालुका",
      mr: "तालुका",
    },
  },
  {
    label: "cadre_council",
    singular: "Municipal Council",
    title: {
      en: "Municipal Council",
      hi: "नगर पालिका परिषद",
      mr: "नगरपरिषद",
    },
  },
  {
    label: "cadre_panchyat",
    singular: "Nagar Panchyat",
    title: {
      en: "Nagar Panchyat",
      hi: "नगर पंचायत",
      mr: "नगर पंचायत",
    },
  },

  {
    label: "cadre_vidhansabha",
    singular: "vidhan-sabha",
    title: {
      en: "Vidhan Sabha",
      hi: "विधान सभा",
      mr: "विधानसभा",
    },
  },
  {
    label: "cadre_prabhag",
    singular: "Prabhag ",
    title: {
      en: "Prabhag",
      hi: "प्रभाग",
      mr: "प्रबळ",
    },
  },
  {
    label: "cadre_ward",
    singular: "Wards ",
    title: {
      en: "Wards",
      hi: "वार्ड",
      mr: "प्रभाग",
    },
  },
];

export const TABS = {
  DIVISIONS: "cadre_divisions",
  DISTRICTS: "cadre_districts",
  VIDHAN_SABHA: "cadre_vidhansabha",
  TALUKA: "cadre_taluka",
  MUNICIPAL_COUNCIL: "cadre_council",
  NAGAR_PANCHAYAT: "cadre_panchyat",
  ORGANIZATION: "cadre_organization",
  COMMITTEE: "cadre_committee",
  POST: "cadre_post",
  STATE: "cadre_state",
  PRABHAG: "cadre_prabhag",
  WARD: "cadre_ward",
};

export const CADRES_FILTERS = {
  REGISTERED: "registered",
  APPROVED: "approved",
  REJECTED: "rejected",
  PENDING: "pending",
}

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  languageId,
  performColumnAction
) => {
  let args = {
    onSort,
    sortColumn,
    languageId,
    performColumnAction,
  };

  switch (innerTab) {
    case TABS.DIVISIONS:
      return returnDivisionColumns(args);

    case TABS.DISTRICTS:
      return returnDistrictColumns(args);

    case TABS.VIDHAN_SABHA:
      return returnVidhanSabhaColumn(args);

    case TABS.TALUKA:
      return returnTalukaColumns(args);

    case TABS.MUNICIPAL_COUNCIL:
      return returnCouncilColumns(args);

    case TABS.NAGAR_PANCHAYAT:
      return returnPanchyatColumn(args);

    case TABS.ORGANIZATION:
      return returnCommonCadreForm(args);

    case TABS.COMMITTEE:
      return returnCommonCadreForm(args);

    case TABS.POST:
      return returnCommonCadreForm(args);

    case TABS.STATE:
      return returnCommonCadreForm(args);

    case TABS.PRABHAG:
      return returnCouncilColumns(args);

    case TABS.WARD:
      return returnWardsColumn(args)

    default:
      return returnDivisionColumns(args);
  }
};

export const getButtonLabel = (innerTab) => {
  let label = innerTab;
  if (innerTab === TABS.VIDHAN_SABHA) {
    return "Vidhan Sabha";
  }

  if (innerTab.includes("_")) {
    label = innerTab.split("_")?.[1];
  }
  label = label.charAt(0).toUpperCase() + label.slice(1);
  return label;
};

export const createPayload = (innerTab, values) => {
  switch (innerTab) {
    case TABS.DIVISIONS: {
      const payload = {
        stateId: values.stateId,
        data: values.data,
      };
      return payload;
    }
    case TABS.DISTRICTS: {
      const payload = {
        divisionId: values.divisionId,
        data: values.data,
        type: values.type,
      };
      return payload;
    }

    case TABS.VIDHAN_SABHA: {
      const payload = {
        districtId: values.districtId,
        data: values.data,
      };
      return payload;
    }

    case TABS.TALUKA: {
      const payload = {
        districtId: values.districtId,
        data: values.data,
      };
      return payload;
    }

    case TABS.MUNICIPAL_COUNCIL: {
      const payload = {
        districtId: values.districtId,
        data: values.data,
      };
      return payload;
    }

    case TABS.NAGAR_PANCHAYAT: {
      const payload = {
        districtId: values.districtId,
        data: values.data,
      };
      return payload;
    }

    case TABS.ORGANIZATION: {
      const payload = {
        data: values.data,
        order: values.order
      };
      return payload;
    }

    case TABS.COMMITTEE: {
      const payload = {
        data: values?.data,
        order: values?.order,
      };
      return payload;
    }

    case TABS.POST: {
      const payload = {
        data: values?.data,
        order: values?.order,
        maxApproveLimit: values?.maxApproveLimit,
      };
      return payload;
    }

    case TABS.STATE: {
      const payload = {
        data: values.data,
      };
      return payload;
    }

    case TABS.WARD: {
      const payload = {
        data: values.data,
        prabhagId: values?.prabhagId || null,
        type: values?.type,
        vidhansabhaId: values?.vidhansabhaId || null
      };
      return payload;
    }
    default: {
      return null;
    }
  }
};

export const handlePutRequest = (currentValues, prevValues) => {
  let payload = {};

  for (let keys in currentValues) {
    for (let currKeys in prevValues) {
      if (keys === currKeys) {
        if (
          JSON.stringify(currentValues[keys]) !==
          JSON.stringify(prevValues[keys])
        ) {
          payload[keys] = currentValues[keys];
        }
      }
    }
  }
  return payload;
};

export const getDropDowns = (array) => {
  if (Array.isArray(array)) {
    let validArray = array?.filter((item) => item?.data !== null);
    return validArray?.map((item) => {
      return {
        id: item.id,
        english: item.data?.[ENGLISH]?.name || item.data?.[ENGLISH]?.title,
        hindi: item?.data?.[HINDI]?.name || item.data?.[HINDI]?.title,
        marathi: item?.data?.[MARATHI]?.name || item.data?.[MARATHI]?.title,
      };
    });
  }
};
