export const initialDataState = {
  list: [],
  tag: [],
  utils: [],
  formData: {},
  readonlyList: [], //this is a readonly data store for static data used in filters, category headers etc
  count: 0,
  page: 0,
  activeCount: 0,
  filters: {
    pageSize: 10,
    page: 0,
    order: [""],
    id__in: [],
    search: "",
    languageId: "en",
  },
  metaData: {},
  loading: false,
  fetching: false,
  error: null,
};
