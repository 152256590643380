import { returnRolesColumns } from "./columns";

export const INNER_HEADERS = [
  {
    label: "Roles",
    singular: "roles",
    title: {
      en: "Roles",
      hi: "भूमिकाएँ",
      mr: "भूमिका",
    },
  },
];

export const TABS = {
  ROLES: "roles",
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  performColumnAction
) => {
  let args = {
    onSort,
    sortColumn,
    performColumnAction,
  };
  return returnRolesColumns(args);
};

export const METADATA = {
  roles: ["utils"],
};

export const getDropdown = (array) => {
  const data = array?.map((e) => {
    return {
      data: {
        en: {
          name: e?.name,
        },
      },
      id: e?.name,
    };
  });
  return data;
};

export const createPayload = (innerTab, values) => {
  return {
    displayName: values.displayName,
    access: values.access?.map((e) => e.label),
  };
};

export const handlePutRequest = (currentValues, prevValues) => {
  let payload = {};
  if (prevValues.access === undefined) {
    prevValues.access = [];
  }

  for (let keys in currentValues) {
    for (let currKeys in prevValues) {
      if (keys === currKeys) {
        if (
          JSON.stringify(currentValues[keys]) !==
          JSON.stringify(prevValues[keys])
        ) {
          payload[keys] = currentValues[keys];
        }
      }
    }
  }
  return currentValues;
};
