import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const HindiLanguageForm = ({ values, department = [] }) => {
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={values.title}
        name="data[hi].title"
        type="text"
        suffix={<div>{50 - (values?.title?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content title"}
        maxLength={50}
      />
      <p className="mt-10 field-label">Department</p>
      <Field
        value={values.department}
        name="data[hi].department"
        type="text"
        suffix={<div>{50 - (values?.department?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter department"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Short Summary</p>
      <Field
        value={values.shortSummary}
        name="data.hi.shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={100}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
      <p className="mb-8 field-label">Scheme details</p>
      <Field
        value={values?.schemaDetails}
        name="data[hi].schemeDetails"
        type="editor"
        component={RTEditor}
        characterLimit={20000}
        placeholder={"Enter details here"}
      />
    </>
  );
};

export default HindiLanguageForm;
