import { Button, Space } from "antd";
import { AntSelect } from "components/CreateAntFields/CreateAntField";
import Modal from "components/Modal";
import { Field, Formik } from "formik";
import { useState } from "react";
import { getApproverFormInitialValues } from "views/Cadre/validate";
import "../index.scss";

export const AddApproverForm = ({
  visible,
  handleClose,
  organisations = [],
  posts = [],
  states = [],
  districts = [],
  divisions = [],
  taluka = [],
  vidhanSabha = [],
  municipalCouncil = [],
  nagarPanchayat = [],
  wards = [],
  prabhag = [],
  committees = [],
  designations = [],
  fetchApproverData,
  setApprover,
  loading,
}) => {
  const [values, setValues] = useState(getApproverFormInitialValues());

  const handleChange = (id, key) => {
    let updatedValues = { ...values, [key]: id };
    setValues(updatedValues);
    if (key === "approverId") {
      setApprover(id);
      setValues({});
    }
  };

  return (
    <Modal
      title={
        <p className="flex items-center color-black m-0 mb-12 ml-40 weight-700 size-26">
          Select Approver
        </p>
      }
      destroyOnClose
      visible={visible}
      onCancel={() => {
        handleClose();
        setValues(getApproverFormInitialValues());
      }}
    >
      <Formik enableReinitialize initialValues={values}>
        <div className="width-full p-30">
          <p className="mb-10 mt-12 field-label">Organisation</p>
          <Field
            value={values.organisationId}
            selectOptions={organisations}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="organisationId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "organisationId")}
          />
          <p className="mb-10 mt-12 field-label">Posts</p>
          <Field
            value={values.postId}
            selectOptions={posts}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="postId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "postId")}
          />
          <p className="mb-10 mt-12 field-label">Committee</p>
          <Field
            value={values.committeeId}
            selectOptions={committees}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="committeeId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "committeeId")}
          />
          <p className="mb-10 mt-12 field-label">States</p>
          <Field
            value={values.stateId}
            selectOptions={states}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="stateId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "stateId")}
          />
          <p className="mb-10 mt-12 field-label">Division</p>
          <Field
            value={values.divisionId}
            selectOptions={divisions}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="divisionId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "divisionId")}
          />
          <p className="mb-10 mt-12 field-label">District</p>
          <Field
            value={values.districtId}
            selectOptions={districts}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="districtId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "districtId")}
          />
          <p className="mb-10 mt-12 field-label">Taluka</p>
          <Field
            value={values.talukaId}
            selectOptions={taluka}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="talukaId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "talukaId")}
          />
          <p className="mb-10 mt-12 field-label">Municipal Council</p>
          <Field
            value={values.municipalcouncilId}
            selectOptions={municipalCouncil}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="municipalcouncilId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "municipalcouncilId")}
          />
          <p className="mb-10 mt-12 field-label">Nagar Panchayat</p>
          <Field
            value={values.nagarpanchayatId}
            selectOptions={nagarPanchayat}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="nagarpanchayatId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "nagarpanchayatId")}
          />
          <p className="mb-10 mt-12 field-label">Vidhan Sabha</p>
          <Field
            value={values.vidhansabhaId}
            selectOptions={vidhanSabha}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="vidhansabhaId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "vidhansabhaId")}
          />
          <p className="mb-10 mt-12 field-label">Prabhag</p>
          <Field
            value={values.prabhagId}
            selectOptions={prabhag}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="prabhagId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "prabhagId")}
          />
          <p className="mb-10 mt-12 field-label">Wards</p>
          <Field
            value={values.wardId}
            selectOptions={wards}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="wardId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "wardId")}
          />
          <div className="flex mb-12">
            <p className="mb-10 mt-12 mr-10 field-label">Approver</p>
            <Space className="add-form">
              <Button
                className="submit-btn small-btn"
                type="primary"
                htmlType="reset"
                disabled={loading}
                onClick={() => {
                  !loading && fetchApproverData(values);
                }}
              >
                {loading ? "Fetching..." : "Get Approver"}
              </Button>
            </Space>
          </div>
          <Field
            value={values.approverId}
            selectOptions={designations}
            optionValueKey="id"
            optionNameKey="english"
            filterOption={true}
            showSearch={true}
            mode={"single"}
            name="approverId"
            component={AntSelect}
            placeholder={"Select type"}
            onChange={(e) => handleChange(e, "approverId")}
          />
        </div>
      </Formik>
      <Space className="add-form">
        <Button
          className="submit-btn mt-20 ml-30"
          type="primary"
          htmlType="reset"
          onClick={() => {
            setValues({});
            fetchApproverData({});
          }}
        >
          Reset
        </Button>
      </Space>
    </Modal>
  );
};
