import AppLogo from "../assets/svgs/AppLogo.svg";
import upload from "../../src/assets/svgs/upload.webp";
import bellIcon from "../assets/svgs/bellIcon.png";
import search from "../assets/svgs/search.svg";
import { CancelCircle, CloseIcon } from "../assets/svgs";
const imagesBaseUrl = "../../../../../assets/images";

export const imagesUrl = {
  icons: {
    appLogo: (
      <img
        src={AppLogo}
        width={64}
        height={32}
        style={{ marginRight: "2vw" }}
        alt="app-logo"
      />
    ),
    bellIcon: (
      <img src={bellIcon} style={{ marginRight: "3vw" }} alt="bell-icon" />
    ),
    cancelBlackBackground: <CloseIcon />,
    UploadIcon: (
      <img
        src={upload}
        style={{ width: "14px", height: "17px" }}
        alt="bell-icon"
      />
    ),
    searchIcon: (
      <img
        src={search}
        style={{ width: "13px", height: "13px", marginRight: "8px" }}
        alt="bell-icon"
      />
    ),
  },
  images: {
    companyLogo: `${imagesBaseUrl}/company_logo.png`,
    noCompanies: `${imagesBaseUrl}/no_companies.svg`,
  },
  svgs: {
    CancelCircleBlue: <CancelCircle />,
  },
};
