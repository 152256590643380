import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { Field } from "formik";

const MarathiLanguageForm = ({ values }) => {
  return (
    <>
      <p className="mb-8 field-label">Policy</p>
      <Field
        value={values}
        name="policyData.mr"
        type="editor"
        component={RTEditor}
        placeholder={"Enter details here"}
      />
    </>
  );
};

export default MarathiLanguageForm;
