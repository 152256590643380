import { Button } from "antd";
import { FastField, Form } from "formik";
import React, { useState } from "react";
import "../index.scss";
import FormMenu from "components/FormMenu";
import { HINDI, ENGLISH, MARATHI } from "constants/language";
import HindiLanguageForm from "./Formlanguage/HindiLanguageForm";
import MarathiLanguageForm from "./Formlanguage/MarathiLanguageForm";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntSelect } from "../../components/CreateAntFields/CreateAntField";
import { TABS } from "views/Party/utils";

const AddOrganizationStructureForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  city,
  innerTab,
  representativeTypes = [],
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mb-8 field-label">Organization name</p>
              <FastField
                value={values.name}
                name="data[en].name"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.name?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter organization name "}
                maxLength={50}
              />
              <p className="mb-8 field-label">Description (optional)</p>
              <FastField
                value={values.description}
                name="data[en].description"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.description?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter details here"}
              />
              <p className="mt-29 field-label">Order </p>
              <FastField
                value={values.order}
                name="order"
                type="text"
                component={AntInput}
                placeholder={"Enter order "}
                maxLength={4}
              />
              {innerTab === TABS.MLAandMLC ? (
                <>
                  <p className="mt-29 field-label">Representative type </p>
                  <FastField
                    value={values.representativeType}
                    name="representativeType"
                    component={AntSelect}
                    placeholder={"Select representative type "}
                    selectOptions={representativeTypes}
                    optionValueKey="id"
                    optionNameKey="english"
                    showSearch={true}
                    autoComplete="off"
                    mode={"single"}
                    searchKey="english"
                  />
                </>
              ) : (
                ""
              )}
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data?.[HINDI]} />
          ) : (
            <MarathiLanguageForm values={values?.data?.[MARATHI]} />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn  submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddOrganizationStructureForm;
