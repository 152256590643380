import { Button, Progress, Card } from "antd";
import { FastField, Form } from "formik";
import React, { useEffect, useState } from "react";
import {
  AntInput,
  AntTextArea,
} from "../../components/CreateAntFields/CreateAntField";
import { CardBackground, CardQuotes } from "../../assets/svgs";
import "../index.scss";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { AntCheckBox } from "components/CreateAntFields/CreateAntField";
import { ColorPicker } from "components/ColorPicker";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguage/MarathiLanguageForm";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import { getCategoryTags } from "utils/helpers";
import { AntToggle } from "components/CreateAntFields/CreateAntField";

const AddImportantMessageForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  defaultCategory,
  tab,
  isEditing,
  formModalVisible,
  ...props
}) => {
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError =
    submit &&
    (errors?.publishedDate || errors?.data?.en || errors?.categoryTags?.length);
  const marathiFormError = submit && errors?.data?.mr;
  const hindiFormValues = values?.data?.hi;
  const marathiFormValues = values?.data?.mr;
  const englishFormValues = values?.data?.en;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    if (values.categoryTags) {
      let tags = values.categoryTags.map((e) => {
        return {
          label: e?.["data"]?.[language].tagName,
          value: e.id,
        };
      });
      setFieldValue("categoryTags", tags);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.categoryTags) {
      let hindiTags = getCategoryTags(values.categoryTags, categoryTags, HINDI);
      let marathiTags = getCategoryTags(
        values.categoryTags,
        categoryTags,
        MARATHI
      );
      setFieldValue("hindiCategoryTags", hindiTags);
      setFieldValue("marathiCategoryTags", marathiTags);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.categoryTags]);

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === "en" ? (
            <>
              <p className="mb-8 field-label">Title</p>
              <FastField
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content name"}
                maxLength={50}
              />
              <p className="mb-8 field-label">Author</p>
              <FastField
                value={values.author}
                name="data[en].author"
                type="text"
                suffix={
                  <div>
                    {30 - (values?.data?.[ENGLISH]?.author?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter author name"}
                maxLength={30}
              />
              <p className="mb-8 field-label">Background color</p>
              <ColorPicker
                colorCode={values.colorCode}
                onChangeColor={(value) =>
                  setFieldValue("backgroundColor", value?.hex)
                }
              />
              {englishFormValues?.title && (
                <Card
                  className="content-card"
                  style={{
                    background: values?.backgroundColor,
                  }}
                >
                  <p className="card-title">
                    <p className="quote">
                      <CardQuotes />
                    </p>{" "}
                    <p className="title">{englishFormValues?.title}</p>
                  </p>
                  <>
                    {englishFormValues?.author ? (
                      <p className="card-author">
                        <span>{englishFormValues?.author}</span>
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                  <div className="backgroundimage">
                    <CardBackground />
                  </div>
                </Card>
              )}
              <p className="mb-8 mt-24 field-label">Short Summary</p>
              <FastField
                value={values.shortSummary}
                name="data[en].shortSummary"
                type="text"
                component={AntTextArea}
                maxLength={400}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short summary about the content"}
              />
              {/* <p className="mb-8 mt-10 field-label">Web URL</p>
              <FastField
                value={values.webUrl}
                defaultValue={values.webUrl}
                name="webUrl"
                type="text"
                autoComplete={"off"}
                component={AntInput}
                placeholder={"Enter web url"}
              /> */}
              <p className="mb-8 mt-10 field-label">Category Tags</p>
              <FastField
                value={values.categoryTags}
                name="categoryTags"
                optionValueKey="tagName"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                mode={"multiple"}
                selectOptions={categoryTags}
                showArrow={true}
                placeholder={"Select content category tags"}
              />
              <p className="mb-8 field-label">Detailed article</p>
              <FastField
                value={values.detailedArticle}
                name="data[en].detailedArticle"
                type="editor"
                component={RTEditor}
                placeholder={"Enter details here"}
                characterLimit={20000}
              />
              {isEditing ? (
                <div className="social-count">
                  {" "}
                  <p className="mb-0 mt-16 field-label">Number of likes</p>
                  <Progress
                    strokeColor="#00a64c"
                    percent={values?.likesCount || 0}
                    format={(percent) => values?.likesCount || 0}
                  />
                  <p className="mb-0 mt-15 field-label">Number of bookmarks</p>
                  <Progress
                    strokeColor="#576bbb"
                    percent={values?.bookmarksCount || 0}
                    format={(percent) => values?.bookmarksCount || 0}
                  />
                  <p className="mb-0 mt-16 field-label">Number of shares</p>
                  <Progress
                    strokeColor="maroon"
                    percent={values?.sharesCount || 0}
                    format={(percent) => values?.sharesCount || 0}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="mt-28 checkbox-container">
                <FastField
                  //checked={values.isPopular}
                  name="isPopular"
                  type="checkbox"
                  component={AntCheckBox}
                />
                <p>
                  {values.isPopular ? "Marked as popular" : "Mark as popular"}
                </p>
              </div>
              {!isEditing && (
                <div>
                  <p className="mt-20 field-label">Send notification</p>
                  <FastField
                    defaultChecked={values.isNotification}
                    name="isNotification"
                    type="switch"
                    component={AntToggle}
                  />
                </div>
              )}
            </>
          ) : language === "hi" ? (
            <HindiLanguageForm
              values={hindiFormValues}
              categoryTags={categoryTags}
              categoryTagsValue={values.hindiCategoryTags}
            />
          ) : (
            <MarathiLanguageForm
              values={marathiFormValues}
              categoryTags={categoryTags}
              categoryTagsValue={values.marathiCategoryTags}
            />
          )}
          <Button
            loading={isSubmitting}
            className="mt-40 submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddImportantMessageForm;
