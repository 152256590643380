import { avoidCache } from "constants/dummyData";
import React from "react";
import "./index.scss";

export const UserCell = ({ data }) => {
  const addDefaultSrc = () => {
    return "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-avatar-vector-isolated-on-white-background-png-image_1694546.jpg";
  };
  return (
    <div className="flex user-cell">
      <img
        className=""
        onError={() => addDefaultSrc}
        src={
          data?.image
            ? data?.image + avoidCache()
            : "https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png"
        }
        alt="user"
      />
      <div>
        <p>{data?.name ? data.name : "Name"}</p>
        <span>{data?.email ? data.email : ""}</span>
      </div>
    </div>
  );
};
