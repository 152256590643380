import { TextCell } from "../../components/TableCells/TextCell";
import { ToggleCell } from "../../components/TableCells/ToggleCell";
import { COLUMN_ACTIONS } from "../../constants/dummyData";
import { ImageCell } from "components/TableCells/ImageCell";
import { ColumnHeaderWithSorter } from "../../components/ColumnHeaderWithSorter";
import { DateCell } from "components/TableCells/DateCell";
import { TimeCell } from "components/TableCells/TimeCell";
import { ENGLISH } from "constants/language";

export const returnCategoryColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "S.no.",
    width: "5%",
    align: "left",
    render: (rowData) => <TextCell sortedColumn={true} data={rowData.sNo} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Name"}
        sortKey={`data.${languageId}.tagName`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "10%",
    align: "left",
    render: (rowData) => <TextCell data={rowData.data?.[languageId].tagName} />,
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Date created"}
        sortKey={"createdAt"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "10%",
    align: "left",
    render: (rowData) => <DateCell data={rowData.createdAt} />,
  },
  {
    title: "Time created",
    width: "10%",
    render: (rowData) => (
      <TimeCell enableReadMore={true} data={rowData.createdAt} />
    ),
  },
  {
    width: "2%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        key={rowData?.isActive}
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnRewardsColumn = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    render: (rowData) => <TextCell data={rowData?.data?.[languageId]?.title} />,
  },
  {
    title: "Points",
    width: "15%",
    render: (rowData) => <TextCell data={`${rowData.rewardPoints} Points`} />,
  },

  {
    title: "Category",
    width: "16%",
    render: (rowData) => <TextCell data={rowData.categoryType} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnTasksColumns = ({
  onSort,
  sortColumn,
  languageId,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "7%",
    render: (rowData) => (
      <ImageCell sortedColumn={true} isBold={true} data={rowData.media?.link} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Title"}
        sortKey={`data.${languageId}.title`}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "25%",
    render: (rowData) => (
      <TextCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.title}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Type"}
        sortKey={"taskType"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "5%",
    render: (rowData) => (
      <TextCell
        data={rowData.taskType === "newpost" ? "New post" : "Existing post"}
      />
    ),
  },
  {
    title: "Description",
    width: "25%",
    render: (rowData) => (
      <TextCell
        enableReadMore={true}
        data={rowData?.data?.[languageId]?.description}
      />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Rewards"}
        sortKey={"rewardId"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    render: (rowData) => (
      <TextCell
        data={`${rowData?.rewardPoints || rowData?.totalPoints} points`}
      />
    ),
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];

export const returnTrophiesColumn = ({
  onSort,
  sortColumn,
  performColumnAction,
}) => [
  {
    title: "Image",
    width: "7%",
    render: (rowData) => (
      <ImageCell sortedColumn={true} isBold={true} data={rowData.media?.link} />
    ),
  },
  {
    title: ({ ...colProp }) => (
      <ColumnHeaderWithSorter
        title={"Trophy name"}
        sortKey={"name"}
        onSort={onSort}
        sortCol={sortColumn}
        {...colProp}
      />
    ),
    width: "15%",
    render: (rowData) => <TextCell data={rowData?.data?.[ENGLISH]?.name} />,
  },
  {
    title: "Points",
    width: "25%",
    render: (rowData) => <TextCell data={`${rowData.trophyPoints} Points`} />,
  },
  {
    width: "8%",
    title: "Status",
    render: (rowData) => (
      <ToggleCell
        status={rowData?.isActive}
        onToggle={(isActive) =>
          isActive !== rowData?.isActive &&
          performColumnAction &&
          performColumnAction(
            COLUMN_ACTIONS.TOGGLE_STATUS,
            rowData.id,
            isActive
          )
        }
      />
    ),
  },
];
