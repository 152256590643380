import * as Yup from "yup";

export const Roles_SCHEMA = Yup.object().shape({
  displayName: Yup.string().required("Role name is required"),
  access: Yup.array().required("Access is required"),
});

export const getRolesInitialValues = (values = {}) => ({
  displayName: values.displayName || "",
  access:
    values.access?.map((e) => {
      return {
        label: e,
        value: e,
      };
    }) || undefined,
});
