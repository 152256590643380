import { AntInput } from "components/CreateAntFields/CreateAntField";
import { MARATHI } from "constants/language";
import { Field } from "formik";

const MarathiLanguageForm = ({
  values = {},
}) => {
  let marathiFieldValues;
  if (values.data) marathiFieldValues = values.data;
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={marathiFieldValues?.title}
        name="data[mr].title"
        type="text"
        suffix={
          <div>
            {50 - (values?.data?.[MARATHI]?.title?.length || 0)} letters
          </div>
        }
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Organizer Name</p>
      <Field
        value={values.organizer}
        name="data[mr].organizer"
        type="text"
        suffix={
          <div>
            {20 - (values?.data?.[MARATHI]?.organizer?.length || 0)} letters
          </div>
        }
        component={AntInput}
        placeholder={"Enter organizer name"}
        maxLength={20}
      />
    </>
  );
};

export default MarathiLanguageForm;
