import { Button, Upload, Progress } from "antd";
import { FastField, Field, FieldArray, Form } from "formik";
import React, { useCallback, useState } from "react";
import { RTEditor } from "../../components/CreateAntFields/CreateAntField";
import { AddButton, PlusIcon } from "../../assets/svgs";
import "../index.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
  uploadMultipleImage,
} from "../../utils/helpers";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import "./AddOrganizationForm.scss";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI } from "constants/language";
import HindiLanguageForm from "./Formlanguage/HindiLanguageForm";
import MarathiLanguageForm from "./Formlanguage/MarathiLanguageForm";
import { UploadButton, UploadImages } from "components/UploadButtons";
import ImageEditor from "components/ImageEditor";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";

const AddOrganizationForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  city,
  years,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [activeImage, setActiveImage] = useState(values?.imageList[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [activityUploadProgress, setActivityUploadProgress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);

  const languageErrors = (array, language) => {
    let errors = array?.filter((e) =>
      e?.data?.[language] || language === "en" ? e?.mediaId : false
    );
    if (errors?.[0]) {
      return true;
    } else {
      return false;
    }
  };

  const findArrayErrors = (array, mediaName) => {
    let errors = array?.filter((e) => e?.[mediaName] !== undefined);
    if (errors?.[0]) {
      return true;
    } else {
      return false;
    }
  };

  const hindiFormError =
    submit && (errors?.data?.hi || languageErrors(errors?.activities, "hi"));
  const englishFormError =
    submit &&
    (errors?.data?.en ||
      languageErrors(errors?.activities, "en") ||
      findArrayErrors(errors?.activities, "url") ||
      errors?.order ||
      errors?.imagesMediaId);
  const marathiFormError =
    submit && (errors?.data?.mr || languageErrors(errors?.activities, "mr"));

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  const handleChange = useCallback((info) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue("image", imageUrl);
        setActiveImage(imageUrl);
        setLoading(false);
      });
    }
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageChange = useCallback((info, index) => {
    const imageValidation = beforeUpload(info.file.originFileObj);
    if (imageValidation) {
      getBase64(info.file.originFileObj, async (imageUrl) => {
        setFieldValue(`activities[${index}].link`, imageUrl);
        setLoading(true);
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hanldeProgress = (progress) => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        progress(percent);
      },
    };
    return options;
  };

  const addToImageList = useCallback(
    async (url) => {
      let images = values.imageList;
      let mediaId = values.imagesMediaId;
      let formData = new FormData();
      let file = dataURLtoFile(url, `image_${Math.random()}.png`);
      formData.append("file", file);
      let response = await uploadMultipleImage(
        file,
        hanldeProgress(setUploadprogress)
      );
      images[activeImageIndex] = url;
      mediaId[activeImageIndex] = response?.data?.id;
      setFieldValue("");
      setLoading(false);
      setFieldValue("imagesMediaId", mediaId);
      setFieldValue("imageList", images);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImageIndex]
  );

  const deleteImageFromList = useCallback(() => {
    let imageList = values.imageList;
    let mediaId = values.imagesMediaId;
    imageList[activeImageIndex] = "";
    mediaId[activeImageIndex] = "";
    setFieldValue("imagesMediaId", mediaId);
    setFieldValue("imageList", imageList);

    let lastValidImage =
      imageList?.filter((item) => item)?.slice(-1)?.[0] || [];
    setActiveImage(lastValidImage);
    let lastIndex = 0;
    for (let i = 0; i < imageList?.length; i++) {
      if (imageList[i]) {
        lastIndex = i;
      }
    }
    setActiveImageIndex(lastIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const handleActivitiesList = (field) => {
    const payload = {
      mediaId: "",
      url: "",
      data: {
        en: {
          title: "",
        },
        hi: {
          title: "",
        },
        mr: {
          title: "",
        },
      },
    };
    const activities = values?.activities;
    setActivityUploadProgress(0);
    setFieldValue("activities", [...activities, payload]);
  };

  const deleteActiviList = (index) => {
    let activities = values.activities.filter((e, i) => i !== index);
    setFieldValue("activities", activities);
  };

  const hasError = (field, index, fieldName) => {
    if (fieldName) {
      return (
        errors[field]?.[index]?.[fieldName] &&
        touched[field]?.[index]?.[fieldName] &&
        errors[field]?.[index]?.[fieldName]
      );
    } else return errors?.[field] && touched?.[field] && errors?.[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mt-24 field-label">Images</p>
              {activeImage && activeImage.length ? (
                <ImageEditor
                  image={activeImage}
                  renderMultipleImage={true}
                  onDelete={() => {
                    setActiveImage("");
                    deleteImageFromList();
                    setUploadprogress(0);
                  }}
                  isEditing={uploadprogress}
                  handleEditing={(status) => {
                    if (status && uploadprogress) {
                      setUploadprogress(0);
                    }
                  }}
                  onSave={async (url) => {
                    addToImageList(url);
                  }}
                  width={380}
                />
              ) : (
                <Upload
                  listType={`picture-card ${
                    hasError("imagesMediaId") ? "error" : ""
                  }`}
                  className="picture-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, image/jpg"
                  progress={
                    <Progress percent={uploadprogress} showInfo={true} />
                  }
                >
                  {<UploadImages loading={loading} />}
                </Upload>
              )}
              {uploadprogress === 100 && !hasError("imagesMediaId") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadprogress > 0 && uploadprogress < 100 && (
                <div className="progress-bar">
                  <Progress percent={uploadprogress} showInfo={true} />
                </div>
              )}
              {hasError("imagesMediaId") && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {hasError("imagesMediaId")}
                </div>
              )}
              <div className="mt-6 upload-list">
                {values.imageList?.map((item, index) =>
                  item ? (
                    <div
                      key={index}
                      className={[
                        "uploaded-image-container",
                        index === activeImageIndex ? "selected" : "",
                      ].join(" ")}
                    >
                      <img
                        onClick={() => {
                          setActiveImageIndex(index);
                          setActiveImage(item);
                        }}
                        alt={"upload-pic"}
                        className={["uploaded-image-container-item"].join(" ")}
                        src={item}
                      />
                    </div>
                  ) : (
                    <div key={index} className={"upload-button-small"}>
                      <input
                        onChange={(e) => {
                          setUploadprogress(0);
                          const file = e.target.files[0];
                          getBase64(file, (imageUrl) => {
                            setActiveImage(imageUrl);
                            setActiveImageIndex(index);
                          });
                        }}
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <label
                        onClick={() => setActiveImageIndex(index)}
                        htmlFor="file"
                      >
                        <PlusIcon />
                      </label>
                    </div>
                  )
                )}
              </div>
              <p className="mt-29 field-label">Organisation name </p>
              <FastField
                value={values.name}
                name="data[en].name"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.name?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title "}
                maxLength={50}
              />
              <p className="mt-16 field-label">Party president</p>
              <FastField
                value={values.president}
                name="data[en].president"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH]?.president?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content title "}
                maxLength={50}
              />
              <p className="mb-8 field-label">Short summary</p>
              <FastField
                value={values.shortSummary}
                name="data.en.shortSummary"
                type="text"
                component={AntTextArea}
                maxLength={100}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short summary about the content"}
              />
              <p className="mb-6 field-label">Party description</p>
              <FastField
                value={values.description}
                name="data[en].description"
                type="editor"
                component={RTEditor}
                characterLimit={20000}
                placeholder={"Enter details here"}
              />
              <p className="mt-8 field-label">Order </p>
              <FastField
                value={values.order}
                name="order"
                type="text"
                component={AntInput}
                placeholder={"Enter order "}
                maxLength={4}
              />
              <FieldArray
                name="activities"
                render={(arrayHelpers) => (
                  <>
                    {values?.activities?.map((items, i) => (
                      <div key={i}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="field-label">Activity {i + 1}</p>
                          <p
                            style={{ color: "#e70000", cursor: "pointer" }}
                            onClick={() => deleteActiviList(i)}
                            className="field-label"
                          >
                            Delete Activity
                          </p>
                        </div>
                        {items.link ? (
                          <ImageEditor
                            image={items?.link}
                            onDelete={() => {
                              setFieldValue(`activities[${i}].link`, "");
                              setFieldValue(`activities[${i}].mediaId`, "");
                              setActivityUploadProgress(0);
                            }}
                            isEditing={activityUploadProgress}
                            handleEditing={(status) => {
                              if (status && activityUploadProgress) {
                                setActivityUploadProgress(0);
                              }
                            }}
                            onSave={async (url) => {
                              let file = dataURLtoFile(
                                url,
                                `image_${Math.random()}.png`
                              );
                              currentActivityIndex !== i &&
                                setCurrentActivityIndex(i);
                              let response = await uploadImage(
                                file,
                                hanldeProgress(setActivityUploadProgress)
                              );
                              if (response) {
                                setFieldValue(
                                  `activities[${i}].mediaId`,
                                  response
                                );
                                setLoading(false);
                              }
                            }}
                            width={380}
                          />
                        ) : (
                          <Upload
                            name={`mediaId`}
                            listType={`picture-card ${
                              hasError("activities", i, "mediaId")
                                ? "error"
                                : ""
                            }`}
                            className="picture-uploader"
                            accept="image/png, image/jpeg, image/jpg"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            onChange={(e) => handleImageChange(e, i)}
                            onClick={() => {
                              setCurrentActivityIndex(i);
                              setActivityUploadProgress(0);
                            }}
                            progress={
                              <Progress
                                percent={uploadprogress}
                                showInfo={true}
                              />
                            }
                          >
                            {<UploadButton loading={loading} />}
                          </Upload>
                        )}
                        {activityUploadProgress === 100 &&
                          !hasError("activities", i, "mediaId") &&
                          i === currentActivityIndex && (
                            <div className="success-text">
                              Image uploaded Successfully
                            </div>
                          )}
                        {activityUploadProgress > 0 &&
                          activityUploadProgress < 100 &&
                          i === currentActivityIndex && (
                            <div className="progress-bar">
                              <Progress
                                percent={activityUploadProgress}
                                showInfo={true}
                              />
                            </div>
                          )}
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {hasError("activities", i, "mediaId") &&
                            `Image is required`}
                        </div>
                        <p className="field-label">Activity title</p>
                        <Field
                          value={items?.data?.[ENGLISH]?.title}
                          name={`activities[${i}].data[en].title`}
                          type="text"
                          suffix={
                            <div>
                              {50 -
                                (items?.data?.[ENGLISH]?.title?.length ||
                                  0)}{" "}
                              letters
                            </div>
                          }
                          component={AntInput}
                          placeholder={"Enter title "}
                          shortSummary
                          maxLength={50}
                        />
                        <p className="field-label">Web URL</p>
                        <FastField
                          value={items?.url}
                          name={`activities[${i}].url`}
                          type="text"
                          component={AntInput}
                          placeholder={"Enter URL  "}
                          shortSummary
                        />
                      </div>
                    ))}
                  </>
                )}
              />
              <Button
                className="mb-31 add-btn"
                type="dashed"
                onClick={() => handleActivitiesList("activities")}
                style={{ background: "transparent !important" }}
              >
                <span>
                  <AddButton style={{ width: "14px", height: "14px" }} /> Add
                  Activity
                </span>
              </Button>
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm
              values={values?.data?.hi}
              activities={values?.activities}
            />
          ) : (
            <MarathiLanguageForm
              values={values?.data?.mr}
              activities={values?.activities}
            />
          )}
          <Button
            loading={isSubmitting}
            className="mt-40 submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddOrganizationForm;
