import { Button, Upload, Progress } from "antd";
import { FastField, Form } from "formik";
import React, { useState, useEffect } from "react";
import "../index.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  uploadImage,
} from "../../utils/helpers";
import { AntInput } from "components/CreateAntFields/CreateAntField";
import FormMenu from "components/FormMenu";
import { ENGLISH, HINDI } from "constants/language";
import HindiLanguageForm from "./FormLanguages/HindiLanguageForm";
import MarathiLanguageForm from "./FormLanguages/MarathiLanguageForm";
import ImageEditor from "components/ImageEditor";
import { UploadButton } from "components/UploadButtons";

const AddMerchandiseCategoryForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [Image, setImage] = useState(values?.mediaUrl);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);
  

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError = submit && errors?.data?.en;
  const marathiFormError = submit && errors?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    values?.mediaUrl && setImage(values.mediaUrl);
  }, [values?.mediaUrl]);

  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const handleChange = (info) => {
    let imageValidation = beforeUpload(info.file.originFileObj);
    hanldeProgress();

    if (imageValidation) {
      if (info.file.status === "uploading") {
        setLoading(true);
        return;
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === ENGLISH ? (
            <>
              <p className="mt-24 field-label">Cover Image</p>
              {Image ? (
                <ImageEditor
                  image={Image}
                  onDelete={() => {
                    setImage("");
                    setFieldValue("mediaId", "");
                    setUploadprogress(0);
                  }}
                  isEditing={uploadprogress}
                  handleEditing={(status) => {
                    if (status && uploadprogress) {
                      setUploadprogress(0);
                    }
                  }}
                  onSave={async (url) => {
                    let file = dataURLtoFile(url, `image_${Math.random()}.png`);
                    let response = await uploadImage(file, hanldeProgress());
                    if (response) {
                      setFieldValue("mediaId", response);
                      setImage(url);
                      setLoading(false);
                    }
                  }}
                  width={200}
                />
              ) : (
                <Upload
                  name="mediaId"
                  listType={`picture-card ${
                    hasError("mediaId") ? "error" : ""
                  }`}
                  accept="image/png, image/jpeg, image/jpg"
                  className="picture-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  customRequest={dummyRequest}
                  progress={
                    <Progress percent={uploadprogress} showInfo={true} />
                  }
                >
                  {<UploadButton loading={loading} />}
                </Upload>
              )}
              {uploadprogress === 100 && !hasError("mediaId") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadprogress > 0 && uploadprogress < 100 && (
                <div className="progress-bar">
                  <Progress percent={uploadprogress} showInfo={true} />
                </div>
              )}
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {hasError("mediaId")}
              </div>
              <p className="mb-8 field-label">Name</p>
              <FastField
                value={values.name}
                name="data[en].name"
                type="text"
                suffix={
                  <div>
                    {100 - (values?.data?.[ENGLISH]?.name?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter category name"}
                maxLength={100}
              />
              <p className="mt-8 field-label">Order</p>
              <FastField
                value={values.displayOrder}
                name="displayOrder"
                type="text"
                component={AntInput}
                placeholder={"Enter order "}
                maxLength={4}
              />
            </>
          ) : language === HINDI ? (
            <HindiLanguageForm values={values?.data} />
          ) : (
            <MarathiLanguageForm values={values?.data} />
          )}
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddMerchandiseCategoryForm;
