import { ArrowButtonIcon } from "../../assets/svgs";
import { Pagination } from "antd";
import React from "react";
import "./index.scss";

export const CustomPagination = ({
  totalCount,
  onChange,
  pageSize = 10,
  current,
}) => {
  if (totalCount === 0 || !totalCount) return null;
  return (
    <>
      <Pagination
        style={{ marginBottom: "26px" }}
        total={totalCount}
        pageSize={pageSize}
        current={current}
        showSizeChanger={false}
        showTotal={(total, range) => (
          <div>
            Viewing
            <span
              style={{
                color: "#4b68ef",
              }}
              className={"page-range"}
            >{` ${
              (current - 1) * pageSize > total
                ? 1
                : (current - 1) * pageSize + 1
            }- ${
              (current - 1) * pageSize + pageSize > total
                ? total
                : (current - 1) * pageSize + pageSize
            } `}</span>
            of {total}
          </div>
        )}
        onChange={onChange}
        itemRender={(current, type, originalElement) => {
          if (type === "prev") {
            return <ArrowButtonIcon />;
          }
          if (type === "next") {
            return <ArrowButtonIcon />;
          }
          if (type === "page") return null;
          return originalElement;
        }}
      />
    </>
  );
};
