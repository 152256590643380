import { AntInput } from "components/CreateAntFields/CreateAntField";
import { HINDI } from "constants/language";
import { Field } from "formik";

const HindiLanguageForm = ({
  values,
}) => {
  let hindiFieldValues;
  if (values.data) hindiFieldValues = values.data;
  return (
    <>
      <p className="mb-8 field-label">Title</p>
      <Field
        value={hindiFieldValues?.title}
        name="data[hi].title"
        type="text"
        suffix={
          <div>{50 - (values?.data?.[HINDI]?.title?.length || 0)} letters</div>
        }
        component={AntInput}
        placeholder={"Enter content name"}
        maxLength={50}
      />
      <p className="mb-8 field-label">Organizer Name</p>
      <Field
        value={values.organizer}
        name="data[hi].organizer"
        type="text"
        suffix={
          <div>
            {20 - (values?.data?.[HINDI]?.organizer?.length || 0)} letters
          </div>
        }
        component={AntInput}
        placeholder={"Enter organizer name"}
        maxLength={20}
      />
    </>
  );
};

export default HindiLanguageForm;
