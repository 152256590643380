import { TABS } from "./utils";
import * as Yup from "yup";
import { compareArrays } from "utils/helpers";

export const DESIGNATION_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Survey title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Survey title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Survey title is required"),
    }),
  }),
  organisationId: Yup.string().required("Cell/Org is required"),
  committeeId: Yup.string().required("Committee is required"),
  postId: Yup.string().required("Post is required"),
  approverName: Yup.string().required("Approver is required"),
});

export const CADRE_COMMON_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    hi: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    mr: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
  }),
});

export const SURVEY_SCHEMA = Yup.object().shape({
  data: Yup.object().shape({
    en: Yup.object().shape({
      title: Yup.string().required("Designation title is required"),
    }),
    hi: Yup.object().shape({
      title: Yup.string().required("Designation title is required"),
    }),
    mr: Yup.object().shape({
      title: Yup.string().required("Designation title is required"),
    }),
  }),
  mediaId: Yup.string().required("Image is required"),
});

export const getDesignationInitialValues = (values = {}) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      title: "",
    },
    hi: {
      title: "",
    },
    mr: {
      title: "",
    },
  },
  organisationId: values?.organisationId || undefined,
  committeeId: values?.committeeId || undefined,
  postId: values?.postId || undefined,
  stateId: values?.stateId || undefined,
  divisionId: values?.divisionId || undefined,
  districtId: values?.districtId || undefined,
  vidhansabhaId: values?.vidhansabhaId || undefined,
  talukaId: values?.talukaId || undefined,
  municipalcouncilId: values?.municipalcouncilId || undefined,
  nagarpanchayatId: values?.nagarpanchayatId || undefined,
  prabhagId: values?.prabhagId || undefined,
  wardId: values?.wardId || undefined,
  approverId: values?.approverId || undefined,
  approverName: values?.approverName?.en?.title || "",
});

export const getSurveyInitialValues = (values = {}, dropdownData) => ({
  createdAt: values.createdAt || new Date().toISOString(),
  data: values.data || {
    en: {
      title: "",
    },
    hi: {
      title: "",
    },
    mr: {
      title: "",
    },
  },
  mediaId: values.mediaId || "",
  organisationId: getMultiSelectValues(
    values?.organisationId,
    dropdownData?.organisations
  ),
  committeeId: getMultiSelectValues(
    values?.committeeId,
    dropdownData?.committees
  ),
  postId: getMultiSelectValues(values?.postId, dropdownData?.posts),
  stateId: getMultiSelectValues(values?.stateId, dropdownData?.states),
  divisionId: getMultiSelectValues(values?.divisionId, dropdownData?.divisions),
  districtId: getMultiSelectValues(values?.districtId, dropdownData?.districts),
  vidhansabhaId: getMultiSelectValues(
    values?.vidhansabhaId,
    dropdownData?.vidhanSabha
  ),
  talukaId: getMultiSelectValues(values?.talukaId, dropdownData?.taluka),
  municipalcouncilId: getMultiSelectValues(
    values?.municipalcouncilId,
    dropdownData?.municipalCouncil
  ),
  nagarpanchayatId: getMultiSelectValues(
    values?.nagarpanchayatId,
    dropdownData?.nagarPanchayat
  ),
  prabhagId: getMultiSelectValues(values?.prabhagId, dropdownData?.prabhag),
  wardId: getMultiSelectValues(values?.wardId, dropdownData?.wards),
  media: values?.media || {},
});

export const getApproverFormInitialValues = (approverId) => ({
  organisationId: undefined,
  committeeId: undefined,
  postId: undefined,
  stateId: undefined,
  divisionId: undefined,
  districtId: undefined,
  vidhansabhaId: undefined,
  talukaId: undefined,
  municipalcouncilId: undefined,
  nagarpanchayatId: undefined,
  prabhagId: undefined,
  wardId: undefined,
  approverId: approverId || undefined,
});

const getMultiSelectValues = (values = [], dropdownList = []) => {
  if (values?.length) {
    let val = compareArrays(values, dropdownList);

    return val.map((el) => {
      return {
        value: el?.id,
        label: el?.data?.en?.name,
      };
    });
  } else {
    return undefined;
  }
};

export const getValidationSchema = (innerTab) => {
  switch (innerTab) {
    case TABS.DESIGNATION: {
      return DESIGNATION_SCHEMA;
    }
    default: {
      return null;
    }
  }
};
