import { Button, Collapse, Drawer } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { MultiselectFilter } from "components/MultiselectFIlter";
import { useState } from "react";
import "./styles.scss";

export const DesignationFilters = ({
  open,
  onClose,
  width = 500,
  placement = "right",
  filters,
  appliedFilters = {},
  applyFilter = () => {},
  clearFilter = () => {},
}) => {
  const [collapseKey, setCollapseKey] = useState(filters?.[0].key);
  const [selectedFilters, setSelectedFilters] = useState({});

  const onChange = (key) => {
    setCollapseKey(key);
  };

  const getFilterOptions = ({ options = [], selectedFilters = [] }) => {
    let filters = options?.map((el) => {
      let option = selectedFilters.find((data) => data === el?.id);
      if (option) return { ...el, isActive: true };
      else return el;
    });
    return filters;
  };

  const handleFilter = ({ id, value }) => {
    setSelectedFilters({ ...selectedFilters, [id]: value });
  };

  const resetFilters = () => {
    setSelectedFilters([]);
    clearFilter();
  };

  const addFilters = () => {
    let filters = { ...selectedFilters };
    for (let key in filters) {
      if (!filters[key].length) delete filters[key];
    }

    if (Object.keys(filters).length) {
      applyFilter({
        multiSelectFilters: { whereCondition: filters },
      });
    } else {
      clearFilter();
    }
  };

  return (
    <Drawer
      title={<div className="size-16 weight-600">Filters</div>}
      placement={placement}
      width={width}
      onClose={onClose}
      visible={open}
    >
      <div className="filter-wrapper">
        <Collapse defaultActiveKey={collapseKey} onChange={onChange}>
          {filters?.map((filter) => (
            <CollapsePanel header={filter?.title} index={filter.id}>
              <MultiselectFilter
                options={getFilterOptions({
                  options: filter.data,
                  selectedFilters: appliedFilters[filter?.id],
                })}
                onUnmount={!open}
                id={filter.id}
                onSelect={handleFilter}
                name={filter.title}
              />
            </CollapsePanel>
          ))}
        </Collapse>
        <div className="flex justify-evenly mt-60">
          <Button
            className="submit-btn"
            type="primary"
            onClick={() => {
              addFilters();
            }}
          >
            Apply Filter
          </Button>
          <Button className="submit-btn" type="primary" onClick={resetFilters}>
            Clear Filter
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
