import { ENGLISH, HINDI, MARATHI } from "constants/language";
import {
  returnCategoryColumns,
  returnTasksColumns,
  returnTrophiesColumn,
} from "./columns";

export const INNER_HEADERS = [
  {
    label: "Tasks",
    singular: "Task",
    title: {
      en: "Tasks",
      hi: "कार्य",
      mr: "कार्ये",
    },
  },
  {
    label: "Categories Tags",
    singular: "Category Tag",
    title: {
      en: "Categories Tags",
      hi: "श्रेणियाँ टैग",
      mr: "श्रेणी टॅग्ज",
    },
  },
  {
    label: "Trophies",
    singular: "Trophy",
    title: {
      en: "Trophies",
      hi: "ट्राफी",
      mr: "ट्रॉफी",
    },
  },
];

export const TABS = {
  TASKS: "tasks",
  CATEGORIES: "categoriestags",
  TROPHIES: "trophies",
};

export const getColumns = (
  innerTab,
  onSort,
  sortColumn,
  languageId,
  performColumnAction
) => {
  let args = {
    onSort,
    sortColumn,
    languageId,
    performColumnAction,
  };
  switch (innerTab) {
    case TABS.TASKS:
      return returnTasksColumns(args);
    case TABS.CATEGORIES:
      return returnCategoryColumns(args);
    case TABS.TROPHIES:
      return returnTrophiesColumn(args);
    default:
      return returnTasksColumns(args);
  }
};

export const getTagname = (innerTab) => {
  switch (innerTab) {
    case TABS.TASKS:
      return "tasks";
    case TABS.CATEGORIES:
      return "categoryTags";
    case TABS.TROPHIES:
      return "trophies";
    default:
      return "tasks";
  }
};

export const METADATA = {
  tasks: ["utils"],
};

export const createPayload = (innerTab, values) => {
  switch (innerTab) {
    case "categoriestags": {
      return {
        data: values.data,
      };
    }
    case TABS.TASKS: {
      if (values?.taskType === "newpost") {
        let data = {
          taskType: values?.taskType,
          data: values?.data,
          deepLinkMediaId: values.deepLinkMediaId,
          isNotification: values.isNotification,
          totalPoints: values.totalPoints,
          socialMedia: values.socialMedia?.map((item) => {
            return {
              ...item,
              points: +item.points,
            };
          }),
        };
        if (values.mediaId) data["mediaId"] = values.mediaId;
        return data;
      } else {
        return {
          taskType: values?.taskType,
          data: values?.data,
          webUrl: values.webUrl,
          isNotification: values.isNotification,
          totalPoints: values.totalPoints,
        };
      }
    }
    case TABS.REWARDS: {
      return {
        data: values.data,
        categoryType: values?.categoryType,
        rewardPoints: +values?.rewardPoints,
      };
    }
    case TABS.TROPHIES: {
      return {
        data: values.data,
        mediaId: values?.mediaId,
        trophyPoints: +values?.trophyPoints,
      };
    }
    default: {
      return null;
    }
  }
};

export const handlePutRequest = (currentValues, prevValues) => {
  let payload = {};
  for (let keys in currentValues) {
    for (let currKeys in prevValues) {
      if (keys === currKeys) {
        if (
          JSON.stringify(currentValues[keys]) !==
          JSON.stringify(prevValues[keys])
        ) {
          payload[keys] = currentValues[keys];
        }
      }
    }
  }
  if (payload.isNotification) {
    delete payload["isNotification"];
  }
  if (currentValues?.socialMedia) {
    delete payload["socialMedia"];
  }
  return payload;
};

export const getDropDowns = (array) => {
  let validArray = array?.filter((item) => item?.data !== null);

  return validArray?.map((item) => {
    return {
      id: item.id,
      english: item.data?.[ENGLISH],
      hindi: item?.data?.[HINDI],
      marathi: item?.data?.[MARATHI],
    };
  });
};
