import { FastField, Form } from "formik";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { Button } from "antd";

function OrderRejectForm({
  values,
  isSubmitting,
}) {
  return (
    <div className="form-wrapper">
      <Form>
        <div className="add-form">
          <p className="field-label">Comments</p>
          <FastField
            value={values?.comments}
            name="comments"
            type="text"
            component={AntTextArea}
            maxLength={160}
            showCount={{
              formatter: ({ maxLength, count }) => {
                return `${maxLength - count} letters`;
              },
            }}
            placeholder={"Enter Comments"}
          />
          <Button
            loading={isSubmitting}
            className="absolute-submit-btn submit-btn"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default OrderRejectForm