import * as actionTypes from "./actionTypes";

export function fetchingData() {
  return {
    type: actionTypes.FETCHING_DATA,
  };
}

export function fetchingDataError(payload) {
  return {
    type: actionTypes.FETCHING_DATA_FAILED,
  };
}

export function addData(payload, reducerName) {
  return {
    type: actionTypes.ADD_DATA,
    payload,
    name: reducerName,
  };
}

export function updateData(payload, reducerName) {
  return {
    type: actionTypes.UPDATE_DATA,
    payload,
    name: reducerName,
  };
}

export function updateFilters(payload, reducerName) {
  return {
    type: actionTypes.UPDATE_FILTERS,
    payload: { ...payload },
    name: reducerName,
  };
}

export function setTableData(
  payload,
  dataName,
  reducername,
  updateReadonlyList
) {
  return {
    type: actionTypes.SET_DATA,
    payload: {
      list: payload?.[dataName] || [],
      activeCount: payload.isActiveCount,
    },
    name: reducername || dataName,
    updateReadonlyList,
  };
}

export function setUtils(payload) {
  return {
    type: actionTypes.SET_UTILS,
    payload: payload,
    name: "utils",
  };
}

export function setDropDownData(payload) {
  return {
    type: actionTypes.SET_DROPDOWN_DATA,
    name: "dropdown",
    payload: payload,
  };
}

export function setFormData(payload, reducerName) {
  const name = Object.keys(payload)[1];
  return {
    type: actionTypes.SET_FORM_DATA,
    payload: payload[name],
    name: reducerName,
  };
}
