import { Button, Upload, Progress } from "antd";
import { FastField, Form } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  AntInput,
  AntTextArea,
} from "../../components/CreateAntFields/CreateAntField";
import "../index.scss";
import {
  beforeUpload,
  dataURLtoFile,
  dummyRequest,
  getBase64,
  getCategoryTags,
  handleImageChange,
  handleImageProgress,
  uploadImage,
  uploadMultipleImage,
} from "../../utils/helpers";
import { MultiSelect } from "components/MultiSelect/MultiSelect";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { AntCheckBox } from "components/CreateAntFields/CreateAntField";
import { UploadButton, UploadImages } from "components/UploadButtons";
import FormMenu from "components/FormMenu";
import HindiLanguageForm from "./FormLanguage/HindiFormLanguage";
import MarathiLanguageForm from "./FormLanguage/MarathiFormLanguage";
import { ENGLISH, HINDI, MARATHI } from "constants/language";
import ImageEditor from "components/ImageEditor";
import { AntToggle } from "components/CreateAntFields/CreateAntField";
import { PlusIcon } from "assets/svgs";

let imageParams = {
  maxHeight: 350,
  maxWidth: 350,
  fileSize: 50,
};

const AddArticleForm = ({
  values,
  setFieldValue,
  isSubmitting,
  handleSubmit,
  states,
  errors,
  touched,
  submitButtonLabel,
  contentCategory,
  handleForm,
  categoryTags,
  defaultCategory,
  tab,
  isEditing,
  formModalVisible,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [activeImage, setActiveImage] = useState(values?.imageList?.[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [uploadprogress, setUploadprogress] = useState(0);
  const [language, setLanguage] = useState("en");
  const [submit, setSubmit] = useState(false);
  const [image, setImage] = useState("");
  const [deepLinkImageLoading, setDeepLinkImageLoading] = useState(false);
  const [deepLinkImageProgress, setDeepLinkImageProgress] = useState(0);

  const hindiFormError = submit && errors?.data?.hi;
  const englishFormError =
    submit &&
    (errors?.data?.en ||
      errors?.publishedDate ||
      errors?.images?.[0] ||
      errors?.categoryTags?.length);
  const marathiFormError = submit && errors?.data?.mr;
  const hindiFormValues = values?.data?.hi;
  const marathiFormValues = values?.data?.mr;

  const handleFormLanguage = ({ key }) => {
    setLanguage(key);
  };

  useEffect(() => {
    if (values.categoryTags) {
      let tags = values.categoryTags.map((e) => {
        return {
          label: e?.["data"]?.[language].tagName,
          value: e.id,
        };
      });
      setFieldValue("categoryTags", tags);
    }

    if (values?.image?.link) {
      setImage(values.image.link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.categoryTags) {
      let hindiTags = getCategoryTags(values.categoryTags, categoryTags, HINDI);
      let marathiTags = getCategoryTags(
        values.categoryTags,
        categoryTags,
        MARATHI
      );
      setFieldValue("hindiCategoryTags", hindiTags);
      setFieldValue("marathiCategoryTags", marathiTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.categoryTags]);

  const hanldeProgress = () => {
    let options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadprogress(percent);
      },
    };
    return options;
  };

  const handleChange = async (info, params = {}) => {
    const imageValidation = await beforeUpload(info.file.originFileObj, params);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (imageValidation) {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setFieldValue("image", imageUrl);
        setActiveImage(imageUrl);
        setLoading(false);
      });
    }
  };

  const addToImageList = useCallback(
    async (url) => {
      let images = values.imageList;
      let mediaId = values.images;
      let formData = new FormData();
      let file = dataURLtoFile(url, `image_${Math.random()}.png`);
      formData.append("file", file);
      let response = await uploadMultipleImage(
        file,
        hanldeProgress(setUploadprogress)
      );
      images[activeImageIndex] = url;
      mediaId[activeImageIndex] = response?.data?.id;
      setFieldValue("");
      setLoading(false);
      setFieldValue("images", mediaId);
      setFieldValue("imageList", images);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeImageIndex]
  );

  const deleteImageFromList = useCallback(() => {
    let imageList = values.imageList;
    let mediaId = values.images;
    imageList[activeImageIndex] = "";
    mediaId[activeImageIndex] = "";
    setFieldValue("images", mediaId);
    setFieldValue("imageList", imageList);

    let lastValidImage =
      imageList?.filter((item) => item)?.slice(-1)?.[0] || [];
    setActiveImage(lastValidImage);
    let lastIndex = 0;
    for (let i = 0; i < imageList?.length; i++) {
      if (imageList[i]) {
        lastIndex = i;
      }
    }
    setActiveImageIndex(lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImageIndex]);

  const hasError = (field) => {
    return errors[field] && touched[field] && errors[field];
  };

  return (
    <div className="form-wrapper">
      <FormMenu
        language={language}
        handleFormLanguage={handleFormLanguage}
        hindiFormError={hindiFormError}
        englishFormError={englishFormError}
        marathiFormError={marathiFormError}
      />
      <Form>
        <div className="add-form">
          {language === "en" ? (
            <>
              {" "}
              <p className="mb-10 mt-10 field-label">Cover Image</p>
              {activeImage && activeImage.length ? (
                <ImageEditor
                  image={activeImage}
                  renderMultipleImage={true}
                  onDelete={() => {
                    setActiveImage("");
                    deleteImageFromList();
                    setUploadprogress(0);
                  }}
                  isEditing={uploadprogress}
                  handleEditing={(status) => {
                    if (status && uploadprogress) {
                      setUploadprogress(0);
                    }
                  }}
                  onSave={async (url) => {
                    addToImageList(url);
                  }}
                  width={220}
                />
              ) : (
                <Upload
                  listType={`picture-card ${hasError("images") ? "error" : ""}`}
                  className="picture-uploader"
                  showUploadList={false}
                  beforeUpload={(file) => beforeUpload(file)}
                  onChange={handleChange}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, image/jpg"
                  progress={
                    <Progress percent={uploadprogress} showInfo={true} />
                  }
                >
                  {
                    <UploadImages
                      width="350 : 350"
                      ratio="1 : 1"
                      loading={loading}
                    />
                  }
                </Upload>
              )}
              {uploadprogress === 100 && !hasError("images") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {uploadprogress > 0 && uploadprogress < 100 && (
                <div className="progress-bar">
                  <Progress percent={uploadprogress} showInfo={true} />
                </div>
              )}
              {hasError("images") && (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  {hasError("images")}
                </div>
              )}
              <div className="mt-6 upload-list">
                {values.imageList?.map((item, index) =>
                  item ? (
                    <div
                      key={index}
                      className={[
                        "uploaded-image-container",
                        index === activeImageIndex ? "selected" : "",
                      ].join(" ")}
                    >
                      <img
                        onClick={() => {
                          setActiveImageIndex(index);
                          setActiveImage(item);
                        }}
                        alt={"upload-pic"}
                        className={["uploaded-image-container-item"].join(" ")}
                        src={item}
                      />
                    </div>
                  ) : (
                    <div key={index} className={"upload-button-small"}>
                      <input
                        onChange={async (e) => {
                          const file = e.target.files[0];
                          let isValidImage = await beforeUpload(file);
                          if (isValidImage) {
                            setUploadprogress(0);
                            getBase64(file, (imageUrl) => {
                              setActiveImage(imageUrl);
                              setActiveImageIndex(index);
                            });
                          } else {
                            setActiveImage("");
                            setUploadprogress(0);
                          }
                        }}
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <label
                        onClick={() => setActiveImageIndex(index)}
                        htmlFor="file"
                      >
                        <PlusIcon />
                      </label>
                    </div>
                  )
                )}
              </div>
              <p className="mt-24 field-label">Deep link cover image</p>
              {image ? (
                <ImageEditor
                  image={image}
                  onDelete={() => {
                    setFieldValue("mediaId", "");
                    setImage("");
                    setDeepLinkImageProgress(0);
                  }}
                  isEditing={deepLinkImageProgress}
                  handleEditing={(status) => {
                    if (status && deepLinkImageProgress) {
                      setDeepLinkImageProgress(0);
                    }
                  }}
                  onSave={async (url) => {
                    let file = dataURLtoFile(url, `image_${Math.random()}.jpg`);
                    let response = await uploadImage(
                      file,
                      handleImageProgress({
                        setUploadProgress: setDeepLinkImageProgress,
                      })
                    );
                    if (response) {
                      setFieldValue("mediaId", response);
                      setImage(url);
                      setLoading(false);
                    }
                  }}
                  width={220}
                />
              ) : (
                <Upload
                  name="mediaId"
                  listType={`picture-card ${
                    hasError("mediaId") ? "error" : ""
                  }`}
                  className="picture-uploader"
                  showUploadList={false}
                  beforeUpload={(file) => beforeUpload(file, imageParams)}
                  customRequest={dummyRequest}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(file) => {
                    handleImageChange({
                      info: file,
                      params: imageParams,
                      setLoading: setDeepLinkImageLoading,
                      setFieldValue: setFieldValue,
                      fieldName: "mediaId",
                      setImage: setImage,
                    });
                  }}
                  progress={
                    <Progress percent={deepLinkImageProgress} showInfo={true} />
                  }
                >
                  {
                    <UploadButton
                      maxResolution={"350px * 350px"}
                      maxSize="50KB"
                      ratio="1:1"
                      loading={deepLinkImageLoading}
                    />
                  }
                </Upload>
              )}
              {deepLinkImageProgress === 100 && !hasError("mediaId") && (
                <div className="success-text">Image uploaded Successfully</div>
              )}
              {deepLinkImageProgress > 0 && deepLinkImageProgress < 100 && (
                <div className="progress-bar">
                  <Progress percent={deepLinkImageProgress} showInfo={true} />
                </div>
              )}
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {hasError("mediaId")}
              </div>
              <p className="mb-8 mt-8 field-label">Title</p>
              <FastField
                value={values.title}
                name="data[en].title"
                type="text"
                suffix={
                  <div>
                    {50 - (values?.data?.[ENGLISH].title?.length || 0)} letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content name"}
                maxLength={50}
              />
              <p className="mb-8 field-label">Short Summary</p>
              <FastField
                value={values.shortSummary}
                name="data[en].shortSummary"
                type="text"
                component={AntTextArea}
                preventNextLineOnEnter={tab === "polls" ? true : false}
                maxLength={400}
                showCount={{
                  formatter: ({ maxLength, count }) => {
                    return `${maxLength - count} letters`;
                  },
                }}
                placeholder={"Enter short summary about the content"}
              />
              {/* <p className="mb-8 mt-10 field-label">Web URL</p>
              <FastField
                value={values.webUrl}
                defaultValue={values.webUrl}
                name="webUrl"
                type="text"
                autoComplete={"off"}
                component={AntInput}
                placeholder={"Enter web url"}
              /> */}
              <p className="mb-8 mt-10 field-label">Category Tags</p>
              <FastField
                value={values.categoryTags}
                name="categoryTags"
                optionValueKey="tagName"
                optionNameKey="id"
                filterOption={false}
                showSearch={false}
                component={MultiSelect}
                mode={"multiple"}
                selectOptions={categoryTags}
                showArrow={true}
                placeholder={"Select content category tags"}
              />
              <p className="mb-8 field-label">Detailed article</p>
              <FastField
                value={values.detailedArticle}
                name="data[en].detailedArticle"
                type="editor"
                component={RTEditor}
                placeholder={"Enter details here"}
                characterLimit={20000}
              />
              {isEditing ? (
                <div className="social-count">
                  {" "}
                  <p className="mb-0 mt-16 field-label">Number of likes</p>
                  <Progress
                    strokeColor="#00a64c"
                    percent={values?.likesCount || 0}
                    format={() => values?.likesCount || 0}
                  />
                  <p className="mb-0 mt-15 field-label">Number of bookmarks</p>
                  <Progress
                    strokeColor="#576bbb"
                    percent={values?.bookmarksCount || 0}
                    format={() => values?.bookmarksCount || 0}
                  />
                  <p className="mb-0 mt-16 field-label">Number of shares</p>
                  <Progress
                    strokeColor="maroon"
                    percent={values?.sharesCount || 0}
                    format={() => values?.sharesCount || 0}
                  />
                </div>
              ) : (
                ""
              )}
              <p className="mb-8 mt-16 field-label">Credits/Author</p>
              <FastField
                value={values.credits}
                name="data[en].credits"
                type="text"
                suffix={
                  <div>
                    {30 - (values?.data?.[ENGLISH].credits?.length || 0)}{" "}
                    letters
                  </div>
                }
                component={AntInput}
                placeholder={"Enter content credits/author"}
                maxLength={30}
              />
              <div className="mt-28 checkbox-container">
                <FastField
                  name="isPopular"
                  type="checkbox"
                  component={AntCheckBox}
                />
                <p>
                  {values.isPopular ? "Marked as popular" : "Mark as popular"}
                </p>
              </div>
              {!isEditing && (
                <div>
                  <p className="mt-20 field-label">Send notification</p>
                  <FastField
                    defaultChecked={values.isNotification}
                    name="isNotification"
                    type="switch"
                    component={AntToggle}
                  />
                </div>
              )}
            </>
          ) : language === "hi" ? (
            <HindiLanguageForm
              values={hindiFormValues}
              categoryTags={categoryTags}
              categoryTagsValues={values.hindiCategoryTags}
            />
          ) : (
            <MarathiLanguageForm
              values={marathiFormValues}
              categoryTags={categoryTags}
              categoryTagsValues={values.marathiCategoryTags}
            />
          )}
          <Button
            loading={isSubmitting}
            className="mt-40 submit-btn"
            type="primary"
            htmlType="submit"
            onClick={() => setSubmit(true)}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddArticleForm;
