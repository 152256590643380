import { Menu } from "antd";

const FormMenu = ({
  handleFormLanguage,
  language,
  englishFormError,
  hindiFormError,
  marathiFormError,
  disabled = false,
}) => {
  return (
    <Menu
      onClick={handleFormLanguage}
      mode="horizontal"
      selectedKeys={language}
      className="mb-20"
    >
      <Menu.Item key="en">
        English
        <span className={englishFormError ? "error" : ""}></span>
      </Menu.Item>
      <Menu.Item key="hi" disabled={disabled}>
        Hindi
        <span className={hindiFormError ? "error" : ""}></span>
      </Menu.Item>
      <Menu.Item key="mr" disabled={disabled}>
        Marathi
        <span className={marathiFormError ? "error" : ""}></span>
      </Menu.Item>
    </Menu>
  );
};

export default FormMenu;
