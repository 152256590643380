import { Component } from "react";
import "./index.scss";
import { Switch } from "antd";

export class ToggleCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.status,
    };
  }

  toggleStatus = (isActive, e) => {
    e?.stopPropagation();
    this.setState(
      {
        isActive: isActive !== undefined ? isActive : !this.state.isActive,
      },
      () => this.props.onToggle && this.props.onToggle(this.state.isActive)
    );
  };

  render() {
    return [
      <Switch
        checked={this.props.status}
        onChange={this.toggleStatus}
        className={"toggle-switch"}
      />,
      <div style={{ display: "none" }}>
        {this.state.isActive ? "Active" : "Inactive"}
      </div>, //for keeping the values in exported excel
    ];
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.toggleStatus(this.props.status);
    }
  }
}
