import { AntInput } from "components/CreateAntFields/CreateAntField";
import { AntTextArea } from "components/CreateAntFields/CreateAntField";
import { RTEditor } from "components/CreateAntFields/CreateAntField";
import { HINDI } from "constants/language";
import { FastField } from "formik";

const HindiLanguageForm = ({ values, activities = [] }) => {
  return (
    <>
      <p className="field-label">Organisation name </p>
      <FastField
        value={values.name}
        name="data[hi].name"
        type="text"
        suffix={<div>{50 - (values?.name?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content title "}
        maxLength={100}
      />
      <p className="mt-16 field-label">Party president</p>
      <FastField
        value={values.president}
        name="data[hi].president"
        type="text"
        suffix={<div>{100 - (values?.president?.length || 0)} letters</div>}
        component={AntInput}
        placeholder={"Enter content title "}
        maxLength={100}
      />
      <p className="mb-8 field-label">Short summary</p>
      <FastField
        value={values.shortSummary}
        name="data.hi.shortSummary"
        type="text"
        component={AntTextArea}
        maxLength={100}
        showCount={{
          formatter: ({ maxLength, count }) => {
            return `${maxLength - count} letters`;
          },
        }}
        placeholder={"Enter short summary about the content"}
      />
      <p className="mb-6 field-label">Party description</p>
      <FastField
        value={values.description}
        name="data[hi].description"
        type="editor"
        component={RTEditor}
        characterLimit={20000}
        placeholder={"Enter details here"}
      />
      {activities?.map((items, i) => (
        <div key={i}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="field-label">Activity {i + 1}</p>
          </div>
          <p className="field-label">Activity title</p>
          <FastField
            value={items?.title}
            name={`activities[${i}].data[hi].title`}
            type="text"
            suffix={
              <div>
                {50 - (items?.data?.[HINDI]?.title?.length || 0)} letters
              </div>
            }
            component={AntInput}
            placeholder={"Enter title "}
            shortSummary
            maxLength={50}
          />
        </div>
      ))}

    </>
  );
};

export default HindiLanguageForm;
