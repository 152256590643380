import { useState } from "react";
import ReactPlayer from "react-player";
import "./index.scss";

export const VideoContainer = ({ videos, heading }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const isArray = function (a) {
    return !!a && a.constructor === Array;
  };

  return (
    <>
      <p className="video-heading">{heading || "Videos"}</p>
      <div className="video-list-container">
        {isArray(videos) ? (
          videos?.map((videoList, i) => (
            <div className="video-box pb-20" key={i}>
              <p className="mb-16">Video {i + 1}</p>
              <ReactPlayer
                className="react-player"
                width="45vw"
                height="auto"
                controls={i === currentIndex && playVideo}
                pip
                onClick={() => {
                  setCurrentIndex(i);
                  setPlayVideo(!playVideo);
                }}
                config={{
                  file: { attributes: { autoPlay: false } },
                }}
                url={videoList?.link}
              />
              {i !== currentIndex && (
                <>
                  <p className="video-title">{videoList?.title}</p>
                </>
              )}
            </div>
          ))
        ) : (
          <div className="video-box-container pb-20">
            {/* <p className="mb-16">Welcome video</p> */}
            <ReactPlayer
              className="react-player"
              width="100%"
              height="500px"
              controls={true}
              pip
              onClick={() => {
                setPlayVideo(!playVideo);
              }}
              config={{
                file: { attributes: { autoPlay: false } },
              }}
              url={videos?.link}
            />
            <>
              <p className="video-title">{videos?.title}</p>
            </>
          </div>
        )}
      </div>
    </>
  );
};
