import { Checkbox, Input } from "antd";
import { useEffect, useState } from "react";
import "./styles.scss";

export const MultiselectFilter = ({
  options = [],
  name,
  id,
  onSelect,
  onUnmount,
}) => {
  const [selectOptions, setSelectOptions] = useState([...options]);

  useEffect(() => {
    if (onUnmount) {
      setSelectOptions([...options]);
    }
  }, [onUnmount]);

  function searchFilter(e) {
    let value = e.target.value;

    let filtersOption = options?.filter((option) =>
      option.english?.toLowerCase().includes(value.toLowerCase())
    );
    setSelectOptions([...filtersOption]);
  }

  const handleChange = ({ option, id }) => {
    let updatedOptions = selectOptions.map((el) => {
      if (el.id === option.id) {
        el.isActive = !el.isActive;
      }
      return el;
    });

    setSelectOptions(updatedOptions);
    onSelect({
      value: updatedOptions.filter((el) => el.isActive).map((el) => el.id),
      id,
    });
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="wrapper"
    >
      <div className="filter-container">
        <Input
          placeholder={`Find ${name || "filter"}`}
          onChange={searchFilter}
        />
        <div className="filters">
          {selectOptions?.map((option) => (
            <div className="option-container">
              <Checkbox
                checked={option.isActive}
                onChange={() => handleChange({ option, id })}
              />
              <p className={option.isActive ? "bold size-14" : "size-14"}>
                {option.english}
              </p>
            </div>
          ))}
          {!selectOptions.length && (
            <p className="size-14 color-default">No options found</p>
          )}
        </div>
      </div>
    </div>
  );
};
